<template>
    <b-card
        :title="title"
        :tag="tag"
        >

        <template #header> 
            <slot name="header">
                {{$route.name.charAt(0).toUpperCase() + $route.name.substring(1) }}
            </slot>
        </template>

        <b-card-body>
            <b-overlay :show="currentRequestStatus" rounded="sm">
                <slot name="body"></slot>
            </b-overlay>
        </b-card-body>    

        <template #footer v-if="!!this.$slots.footer">
            <slot name="footer"></slot>
        </template>


  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        props:[
            'title',
            'tag'
        ],
        computed: {
            ...mapGetters(['currentRequestStatus'])
        },
    }
</script>

<style>
.card-header, 
.card-footer {
    background-color: rgba(248, 249, 250, 1);
    font-weight:100;
}

.card-body {
    padding: 0.5rem 0.2rem;
}


</style>