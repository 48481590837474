<template>
    <div class="p-3">
        <b-form ref="filterForm"  @submit.prevent="onSubmit" @reset="onReset">
            <b-row>
                <b-col col cols=3>
                    <b-form-group label="Clients:">
                        <multiselect 
                            @input="uuidSelected"
                            :multiple="true"
                            v-model="filterClientIds" 
                            :options="clientsList" 
                            placeholder="Select UUIDs" 
                            label="value" 
                            track-by="value">
                        </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>
             <b-row class="mt-2">
                <b-col>
                    <b-button-group>
                        <b-button type="submit" variant="outline-primary">Submit</b-button>
                        <b-button type="reset" variant="outline-danger">Reset</b-button>
                    </b-button-group>
                    
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
    components: {
        Multiselect
    },
    props:['clientsList'],
    data(){
        return {
            filterClientIds: null,
            //clientsList: [],
            providersList: []
        }
    }, 
    created(){
        //this.fetchLists()
    },
    methods: {
        uuidSelected(){
            //this.$emit('clientsList',  this.filterClientIds)
           
        },
       async fetchLists(){
            let promises = await Promise.all([
                this.$http.get('/api/clients/get-all-http-tokens'),
                this.$http.get('/api/providers/read-with-accounts'),  
            ])
            // tokens
            let tokensPromise = promises[0]
            let providersPromise = promises[1]

            if(tokensPromise.status == 200 ){
                this.tokensList = tokensPromise.data.map(item => {
                    item['value'] = item.http_api_jwt_short_token
                    return item
                })
                 
                this.clientsList = Object.keys(this.tokensList.reduce((acc,item)=>{
                    acc[item.http_api_jwt_uuid] = 1;
                    return acc;
                },{})).map(item => {
                    return {
                        value: item
                    }
                })
                
                
            }

            if(providersPromise.status == 200 ){
                //this.$emit('providersList', providersPromise.data)
                this.providersList = providersPromise.data.map(item => {
                    item['value'] = `${item.provider_id}${item.providers_accounts_id}`
                    return item;
                })
            }

           
            
        },
        onSubmit() {
            this.$emit('filter', this.filterClientIds)
            
        },
        onReset() {
            this.filterClientIds = []
        }
    }
}
</script>

<style>

</style>