<template>
    <b-form @submit.prevent="onSubmit()">
        <b-row>
            <b-col cols="5">
                <b-form-group
                    invalid-feedback="This is required"
                    :state="waveProduct != null"
                    label="Item description:">                  
                        <v-select 
                            :options="waveItems"
                            :get-option-label='option => option.node.name'  
                            return-object 
                            placeholder="Item"
                            v-model="waveProduct">
                            <template #option="{ node }">
                                {{ node.name }}
                            </template>
                        </v-select>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    invalid-feedback="This is required"
                    :state="quantity != null"
                    label="Quantity:">
                    <b-form-input
                        required
                        id="input-item-qty"
                        v-model="quantity"
                        type="text"
                        placeholder="Quantity">
                    </b-form-input>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    label="Tax rate:">
                    <v-select 
                        :options="waveTaxes"
                        :get-option-label='option => option.node.name'  
                        return-object 
                        placeholder="Tax"
                        v-model="waveTax">
                        <template #option="{ node }">
                            {{ node.name }}
                        </template>
                    </v-select>

                </b-form-group>
            </b-col>
            <b-col lg="2">
                <b-form-group
                      invalid-feedback="This is required"
                    :state="price != null"
                    label="Price:">
                    <b-form-input
                    required
                    v-model="price"
                    type="text"
                    placeholder="Price">
                    </b-form-input>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group
                    label="Actions:">
                        <b-button 
                            :disabled="disabled"
                            type="submit"
                            variant="success" 
                            block>
                            <b-icon icon="plus"></b-icon> Add item
                        </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import vSelect from "vue-select";
export default {
    components: {
        vSelect
    },
    props: {
        waveItems: {
            type: Array,
            default: () => []
        },
        waveTaxes: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },    
    methods: {
        reset(){
            this.waveProduct = null
            this.waveTax = null
            this.quantity = null
            this.price = null
            this.total =  null
        },
        onSubmit(){
            const valid = this.waveProduct != null
            if(!valid) return
            
            this.total = this.quantity * this.price

            var summery = {
                waveProductId: this.waveProduct.node.id,
                waveProductDescription: this.waveProduct.node.name,
                price: parseFloat(this.price),
                quantity: Number(this.quantity),
                total: this.total
            }
            
            if(this.waveTax){
                const rate = parseFloat(this.waveTax.node.rate)
                summery['waveTaxItemId'] = this.waveTax.node.id
                summery['waveTaxItemDescription'] = this.waveTax.node.name
                summery['waveTaxItemRatePercent'] =  rate 
                summery['waveTaxItemRate'] = rate * 100
                summery['totalTax'] =  summery.price * rate
                summery.totalWithTax = summery.total * ( 1 + rate )
            }

            this.$emit('add', summery)
            this.reset()
        }
    },
    data(){
        return {
            waveProduct: null,
            waveTax: null,
            quantity: null,
            price: null,
            total: null
        }
    }
}
</script>

<style>

</style>