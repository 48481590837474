<template>
  <div>
    <b-row>
        <b-col clo lg=8>
            <FilterTop @filter="filter"/>
        </b-col>
    </b-row>
    <br>
    <router-view :rowData="history"></router-view>
  </div>
</template>

<script>
import FilterTop from './Filter.vue'

export default {
    components: {
        FilterTop
    },
    data(){
        return {
            history: []
        }

    },
    methods: {
        filter(data){
            this.fetchData(data)
        },
        async fetchData(filterData){
            let res = await this.$http.post('/api/analytics/delify/history', filterData)
            if(res.status == 200){                
                this.history = res.data
            }
        }
    }
}
</script>

<style>

</style>