<template>
  <b-modal
      id="modal-test-smsc"
      ref="modal"
      title="Test SMSc"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
        <b-overlay :show="showOverlay" rounded="sm">
        
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    id="input-group-1"
                    label="Test origin"
                    label-for="input-1"
                    description="Origin to test">
                        <b-form-input
                        id="input-1"
                        v-model="testForm.testOrigin"
                        type="text"
                        placeholder="Enter test origin"
                        :state="/^\S*$/g.test(testForm.testOrigin)"
                        required>
                        </b-form-input>
                </b-form-group>
                <b-form-group
                label="Test number"            
                description="Number to test">

                    <b-form-input
                        id="input-3"
                        v-model="testForm.testNumber"
                        type="text"
                        placeholder="Enter test number"
                        :state="/^[0-9]{7,}$/.test(testForm.testNumber)"
                        required>
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-4"
                    label="Text encoding"
                    label-for="input-4"
                    description="Encoding of test message">

                    <b-form-select v-model="testForm.testCoding" class="mb-3">
                        <b-form-select-option :value="null">Please select an option</b-form-select-option>
                        <b-form-select-option value="0">GSM</b-form-select-option>
                        <b-form-select-option value="1">binary</b-form-select-option>
                        <b-form-select-option value="2">UTF-16BE (UNICODE)</b-form-select-option>
                    </b-form-select>

                </b-form-group>
                <b-form-group
                label="Test text"
                description="Text to test">

                
                    <b-form-input
                    id="input-2"
                    v-model="testForm.testText"
                    type="text"
                    placeholder="Enter test text"
                    :state="/^[^\s]+/.test(testForm.testText)"
                    required>
                    </b-form-input>
                </b-form-group>
            </form>
        </b-overlay>
    </b-modal>
</template>

<script>
export default {
    props:['rowdata'],
    data() {
      return {
        showOverlay: false,
        testForm: {
            /*
            testOrigin: null,
            testNumber: null,
            testCoding: 0,
            testText: null
            */
            testOrigin: 'origin',
            testNumber: 97254935444,
            testCoding: 0,
            testText: 'some test text'
        }
      }
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        return valid
      },
      resetModal() {
        this.bbox = null
      },
      handleOk(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      async handleSubmit() {
        // Exit when the form isn't valid
        
        if (!this.checkFormValidity()) return
        this.showOverlay = !this.showOverlay
        const test = {...this.testForm, ...this.rowdata}

        const res = await this.$http.post(`/api/gateway/test/smsc`, test)
        if(res && res.status == 200){
            this.showOverlay = !this.showOverlay
            this.testResponse = res.data

            this.$nextTick(() => {
                this.$bvModal.hide('modal-test-smsc')
            })
        }
        
      }
    }
}
</script>

<style>

</style>