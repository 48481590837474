<template>
<div>
   
    <base-card>
        <template #header>
            Filter
        </template>
        <div slot="body" class="p-3">
          <b-form inline @submit="onSubmit" @reset="onReset" v-if="show">
            <b-row align-v="center">
              <b-col >
                <b-form-group
                  id="input-group-1"
                  label="Dates range:"
                  label-for="input-1"
                  
                >
                  <date-range-picker
                  style="width: 100% !important;"
                    opens="right"
                    ref="picker"
                    :locale-data="locale"
                    v-model="dateRange"
                    @update="updateValues">
                    
                    <!--    input slot (new slot syntax)-->
                    <template #input="picker">
                      <b-icon icon="calendar"></b-icon>
                      {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    </template>
                  </date-range-picker>
                </b-form-group>
              </b-col>

              <b-col >    
                <b-form-group
                  id="input-group-2" 
                  label="Country:" 
                  label-for="input-2">
                  <multiselect 
                      v-model="localCountryCode" 
                      :options="countries" 
                      placeholder="Select one" 
                      label="text" 
                      track-by="value"
                      required>
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col >
                  <b-form-group 
                  id="input-group-3" 
                  label="Operator:" 
                  label-for="input-3">
                    <multiselect 
                        v-model="localOperators" 
                        :options="operators" 
                        :custom-label="nameWithMccMnc"
                        placeholder="Select one account" 
                        label="text" 
                        track-by="mccmnc"
                        required>
                    </multiselect>
                </b-form-group>
              </b-col>
              
              
            </b-row>

            <b-row align-v="center">
              <b-col>    
                <b-form-group
                  id="input-group-2" 
                  label="Account:" 
                  label-for="input-2">
                  <multiselect 
                      v-model="localAccounts" 
                      :options="accounts" 
                      :custom-label="nameWithUuid"
                      placeholder="Select one account" 
                      label="text" 
                      track-by="client_accounts_cliend_uuid"
                      required>
                  </multiselect>
                </b-form-group>
              </b-col>

              <b-col >
                  <b-form-group 
                  id="input-group-3" 
                  label="Accounting token:" 
                  label-for="input-3">  
                  <multiselect 
                      v-model="localTokens" 
                      :options="apiKeys" 
                      :custom-label="nameWithUuidTokens"
                      placeholder="Select one account" 
                      label="text" 
                      track-by="http_api_jwt_short_token"
                      required>
                  </multiselect>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group 
                  id="input-group-4" 
                  label="Route:" 
                  label-for="input-4">
                    <multiselect 
                      v-model="localRoute" 
                      :options="smscs" 
                      placeholder="Select one account" 
                      label="label" 
                      track-by="value"
                      required>
                    </multiselect>
                </b-form-group>
              </b-col>
              
        
            </b-row>
            <br>

            <b-button type="submit" variant="primary">Submit</b-button>&nbsp;
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-form>
          
        </div>
        
    </base-card>
</div>    
</template>

<script>
import BaseCard from '@/components/Base/card.base.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
import {countries} from '@/json/countries'
//import mccmnc1 from '@/json/mcc-mnc.json'
import mccmnc from '../../../json/newmccmnc.json'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'



//import axios from 'axios'

export default {
  components: {
    //CircleIcon,
    BaseCard,
    DateRangePicker,
    Multiselect
    
    //ZapOffIcon,
    //ZapIcon
  },
  created () {
    this.fetchData()
  },
  watch:{
    'localAccounts': function (){
      if(this.localAccounts)
          this.getKeys()
    },
    //'form.country_code': function (newVal) {
    'localCountryCode': function (newVal) {
      if(newVal){
        this.form.country_code = newVal.value
        this.operators = mccmnc.filter((obj) => {
          return obj.countryCode == newVal.value
        }).map(item=>{
          item.mccmnc = `${item.mcc}${item.mnc}`
          return item
        })

      }else{
        this.operators = []
      }



    },
  },
  methods: {
    nameWithMccMnc(item){
      return `${item.mcc}${item.mnc} - ${item.operator}`
    },
    nameWithUuidTokens(item){
      return `${item.http_api_jwt_description} - ${item.http_api_jwt_short_token}`
    },
    nameWithUuid(item){
      return `${item.client_account_name} - ${item.client_accounts_cliend_uuid}`
    },
    getKeys(){
      
      Promise.all([
        this.$http.get("/api/clients/get-account-api-keys?client_account=" + this.localAccounts.client_accounts_cliend_uuid)
      ]).then((values) => {
        this.apiKeys = values[0].data || []
      });
    },
    async fetchData(){

      const promises = await Promise.all([
        this.$http.get("/api/gateway/groups/smsc/list"), 
        this.$http.get("/api/clients/read")
      ])

      if(promises[0] && promises[0].status == 200){
        this.smscs = promises[0].data.map( item => { 
          return { label: item.config['smsc-id'], value: item.config['smsc-id'] }
        })
      }

      if(promises[1] && promises[1].status == 200){
        this.accounts = promises[1].data
      }
      
    },
    updateValues(){
      
    },
    onSubmit(event) {
      event.preventDefault()
     
     this.form.client_accounting_token = this.localTokens ? this.localTokens.http_api_jwt_short_token : null
     this.form.client_account = this.localAccounts ? this.localAccounts.client_accounts_cliend_uuid : null
     this.form.current_dlr_mask = this.localDlrStatus ? this.localDlrStatus.value : null
     this.form.mccmnc = this.localOperators ? this.localOperators.mccmnc : null
     this.form.smsc_id = this.localRoute ? this.localRoute.value : null
     
     this.form.period_start = moment(this.dateRange.startDate).format("YYYY-MM-DD")
     this.form.period_end = moment(this.dateRange.endDate).format("YYYY-MM-DD")
     
      
     this.$emit('filterData', this.form)
      
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      
        this.form =  {
          period_start: null,
          period_end: null,
          current_dlr_mask: null,
          mccmnc: null,
          country_code:null,
          client_account: null,
          client_accounting_token: null,
          smsc_id: null
        }

        this.localCountryCode = null
        this.localAccounts = null
        this.localTokens = null
        this.localOperators = null
        this.localDlrStatus = null
        this.localRoute = null

        this.$nextTick(() => {
          this.show = true
        })
    }
  },
  data(){
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(endDate.getDate() - 7)

    return {
      localRoute: null,
      localDlrStatus: null,
      localOperators: null,
      localTokens: null,
      localAccounts: null,
      localCountryCode: null,
      accountKeys:[],
      fiterData: null,
      form: {
          period_start: null,
          period_end: null,
          current_dlr_mask: null,
          mccmnc: null,
          country_code:null,
          client_account: null,
          client_accounting_token: null,
          smsc_id: null
        },
        accounts:[],
        nonFilteredAccounts:[],
        apiKeys: [],
        countries:countries,
        operators:[],
        smscs:[],
        options: [
          { value: 1, text: 'SUCCESS' },
          { value: 2, text: 'FAILED' },
          { value: 4, text: 'BUFFERED' },
          { value: 8, text: 'PENDING' },
          { value: 16, text: 'REJECTED' },
          { value: 34, text: 'UNKNOWN' }
          
        ],
        foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        show: true,
        startDate: String((new Date()).getDate()).padStart(2, '0') ,
        endDate: new Date(),
        dateRange: { startDate, endDate },
        locale: {
            direction: 'ltr', //direction of text
            format: 'DD-MM-YYYY', //fomart of the dates displayed
            separator: ' - ', //separator between the two ranges
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
            monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
            firstDay: 1 //ISO first day of week - see moment documenations for details
        }
    }
  },
  filters: {
    dateCell (value) {
      let dt = new Date(value)
      return dt.getDate()
    },
    date (val) {
      //return val ? val.toLocaleString() : ''
       return val ? moment(val).format("DD-MM-YYYY") : "";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.multiselect {
  width: inherit !important;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.custom-select{
  width: 100% !important
}

.dashbaord{
  text-align:left;
}

.multiselect__select {
  color:black;
}
</style>
