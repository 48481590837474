<template>
    <b-modal        
        id="modal-mo-batch-routing" 
        ref="modal-mo-batch-routing"
        size="md"
        title="Batch MO routing"
        :ok-disabled="okDisabled"
        @ok="handleOk"
        @hidden="resetModal">
        <form ref="createForm" @submit.stop.prevent="handleSubmit">
            <b-row>
                <b-col>
                    <b-row>
                        <b-col>
                            <b-form-group
                                style="text-align:left"
                                
                                :label="`Client UUIDs (${uuids ? uuids.length : 0})`">
                                <multiselect 
                                    :class="'multiselect-accounts-managment'"
                                    :multiple="true"
                                    v-model="form.uuid" 
                                    :options="uuids" 
                                    @select="selected"
                                    placeholder="Selected uuids" 
                                    label="value" 
                                    track-by="value">
                                </multiselect>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <!--
                    <b-row>
                        <b-col>
                            <b-form-checkbox 
                            class="m-3 text-primary"
                            v-model="form.isDedicated" 
                            style="text-align:left">
                                &nbsp;&nbsp;&nbsp;Dedicated ShID
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    -->
                    <b-row>
                        <b-col col cols=6>
                            <b-form-group
                                style="text-align:left"
                                label="Optional ShID:"
                                description="Optional ShID"
                            >
                                <b-form-input
                                    v-model="form.to"
                                    placeholder="ShID of the MO"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col col cols=6>
                            <b-form-group
                                style="text-align:left"
                                label="Mandatory Keyword:"
                                description="Keyword text of MO"
                            >
                                <b-form-input
                                    :disabled="form.isDedicated"
                                    v-model="form.keyword"
                                    placeholder="Mandatory Keyword MO"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                   <b-row>
                    <b-col>
                        <b-form-group  title="Country code:">
                            <b-form-select v-model="form.ccode">
                                <b-select-option :value="null">Please choose a country</b-select-option>
                                <b-select-option  v-for="item in countries" :key="item.value" :value="item.value">{{item.text}}</b-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                   </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                                title="Available macros:"
                                v-b-popover.hover.top="popoverText" 
                                
                                style="text-align:left"
                                label="Callback URL:"
                                :invalid-feedback="'This callback URL is not valid'"
                                description="Callback for the MO"
                            >
                                <b-form-input
                                    v-model="form.callback"
                                    placeholder="Callback for the MO"
                                    :state="validateUri()"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>

            </b-row>
        </form>
    </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
var countriesModule = require('../../../json/countries')

export default {
    components: {
        Multiselect
    },
    props: ['uuids'],
    watch:{
        /*
        'uuids': function () {
            this.form.uuid = this.uuids
            this.okDisabled = false
        }
        */
    },
    data(){
        return {
            //uuids:[],
            countries: countriesModule.countries,
            okDisabled: true,
            popoverText: `%I - Our Message Id, 
                          %p - Recipient, 
                          %p - Sender, 
                          %T - Time Stamp, 
                          %a - Action, 
                          %k - Keyword
                          Example: http://localhost:3000/test/mo?msgId=%I&to=%p&from=%P&ts=%T&action=%a&keyword=%k`,
            //valid: false,
            form: {
                ccode: 'GR',
                to: 123,
                keyword: 'KW',
                callback: 'http://host:port/mo?msgId=%I&to=%p&from=%P&ts=%T&action=%a&keyword=%k',
                uuid: this.uuids,
                isDedicated: false,

            }
        }
    },
    methods: {
        selected(){
            this.okDisabled = !this.okDisabled
        },
        valid(){
            const formValid = this.$refs['createForm'] && this.$refs['createForm'].checkValidity()
            return this.form.uuid && this.form.uuid.length && formValid
        },
        validateUri(){
            
            try{
                new URL(this.form.callback);
                return true
            }catch(e){
                return false
            }
            
            
        },
        handleOk(bvModalEvent){
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        async handleSubmit() {
            if(!this.valid()){
                return
            }

            const uuid = this.form.uuid.pop()
            let res = await this.$http.post('/api/routing/smsc/mo/create',{
                "callback": this.form.callback,
                "keyword": this.form.keyword,
                "to": this.form.to,
                "uuid" : uuid.value,
                ccode: this.form.ccode
            })
            if(res && res.status == 200){
                this.$parent.routingData.push(res.data)

                
                this.$nextTick(function() {      
                    this.$bvModal.hide('modal-mo-batch-routing')
                    this.resetModal()
                })

            }
            
        },
        resetModal(){
            
            this.form = {
                ccode: 'GR',
                to: 123,
                keyword: 'KW',
                callback: 'http://host:port/mo?msgId=%I&to=%p&from=%P&ts=%T&action=%a&keyword=%k',
                uuid: this.uuids,
                isDedicated: false,
            }

        },
                
    }
}
</script>

<style>
.multiselect-hlr-managment .multiselect__tags{
  min-height:200px;
  max-height:300px;
  overflow-y: scroll;
}

.multiselect-accounts-managment .multiselect__tags{
  height:135px;
  overflow-y: scroll;
}
</style>