<template>
    <b-row>
        
        <b-col lg="12">
            <UploadListModal :providerAccountId="providerAccount" @uploadCsv="uploadCsv"/>
            <b-overlay :show="showOverlay" rounded="sm">
                <vue-good-table 
                    :fixed-header="true"
                    theme="polar-bear"
                    :columns="fields" 
                    :rows="routes" 
                    :line-numbers="false"
                    :pagination-options="paginationOptions"
                    :search-options="searchOptions"
                    :select-options="selectOptions"
                    @on-selected-rows-change="selectionChanged"
                    @on-select-all="selectionChanged"
                >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'routes_random_originator'">
                        <span @click="toggleRandomOriginator(props.row)">
                            <b-icon v-if="props.row.routes_random_originator" icon="toggle-on" variant="info" font-scale="1.5" ></b-icon>
                            <b-icon v-else icon="toggle-off" variant="info" font-scale="1.5" ></b-icon>
                        </span>
                    </span>
                </template>

                <div slot="emptystate">
                    <h1>No data</h1>
                </div>
                <div slot="selected-row-actions">

                    <b-button-group>
                        <b-button 
                        variant="danger">Delete selected</b-button>
                    </b-button-group>
                </div>
                
                <div slot="table-actions" class="custom_actions">
                    
                   
                    <!--
                    <b-form-select     
                        class="local-customs-select"
                        v-model="providerAccountId"
                        :options="providerAccountsList"
                        required
                        v-on:change="onAccountSelected">
                    </b-form-select>
                    -->
                    <b-row>
                        <b-col class="multiselect-wrapper">
                            <multiselect 
                                @input="onAccountSelected"
                                :multiple="false"
                                v-model="providerAccountId" 
                                :options="providerAccountsList" 
                                :custom-label="getAccountLabel"
                                placeholder="Select SMSC Account" 
                                label="text" 
                                track-by="value">
                            </multiselect>
                        </b-col>
                        <b-col>
                            <b-input-group>
                                <b-input-group-append>
                                    <b-button-group>
                                        <b-button
                                        size="sm"
                                        :disabled="!( providerAccountId && Object.keys(providerAccountId).length)"
                                        v-b-modal.modal-upload-csv
                                        variant="success">
                                        Upload CSV
                                        </b-button>

                                        <b-button
                                            size="sm" 
                                            @click="deleteRouting"
                                            :disabled="!( providerAccountId && Object.keys(providerAccountId).length) || !routes.length"
                                            variant="danger">Delete routing
                                        </b-button>

                                        <b-button
                                            size="sm"
                                            :disabled="disableSetGlobaly"
                                            @click=setGlobaly
                                            variant="warning">
                                            Set globally
                                        </b-button>


                                        <b-button
                                        size="sm"
                                        @click=fetchData                           
                                        variant="primary">
                                        Refresh table
                                        </b-button>
                                    </b-button-group>

                                    
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    

                    


                </div>

                <div slot="table-actions-bottom">
                    <h6 class="p-3">
                        Row count: {{routes.length}}  
                    </h6>
                    
                </div>
                </vue-good-table>
            </b-overlay>
        </b-col>
        
    </b-row>
</template>

<script>

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import UploadListModal from './UploadListModal.vue'

export default {
    components: {
        Multiselect,
        UploadListModal
    },
    props:{
        providerAccountsList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showOverlay: false,
            disableSetGlobaly: true,
            bulkActionsObject: {},
            pricingFile: null,
            toDeleteSelcted: [],
            providerAccountId: null,
            providerAccount: {},
            counter: 1,
            interval: null,
            processing: false,
            busyFileUpload: false,
            totalRows: 0,
            filter: null,
            perPage: 10,
            currentPage: 1,
            searchTerm:null,
            searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
                externalQuery: this.searchTerm
            },
            selectOptions: { enabled: true },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5,10,100,2000],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
            fields: [
                { label: '', field: 'select_row'},
                { label: 'Account ID', field: 'route_account_id', width:'120px',},
                { label: 'Account alias', field: 'route_account_alias'},
                { label: 'Country name',  field: 'routes_country_name',
                    filterOptions:{
                        enabled: true,
                        placeholder: 'Filter country name'
                    }
                },
                { label: 'Country code',  field: 'routes_country_code', width:'120px',
                    filterOptions:{
                        enabled: true,
                        placeholder: 'Filter country code'
                    }
                },
                { label: 'Operator',  field: 'routes_operator_name',
                    filterOptions:{
                        enabled: true,
                        placeholder: 'Filter operator'
                    }
                },
                { label: 'Mcc',      field: 'routes_mcc', width:'120px',
                    filterOptions:{
                        enabled: true,
                        placeholder: 'Filter MCC'
                    }
                },
                { label: 'Mnc',      field: 'routes_mnc', width:'120px',
                    filterOptions:{
                        enabled: true,
                        placeholder: 'Filter MNC'
                    }
                },
                { label: 'Cost',     field: 'routes_route_cost'},
                { label: 'Currency', field: 'routes_route_cost_curency'},
                { label: 'Random Sender ID', field: 'routes_random_originator'},
                { label: 'Markup',   field: 'routes_route_mark_up'},
                { label: 'Created', field: 'createdAt'},
                { label: 'Updated', field: 'updatedAt'},
            ],
            routes: []
        };
    },
    methods: {
        getAccountLabel(obj){
            if(obj.value){
                const item = obj.value
                return `${item.providers_accounts_account_alias} ${item.providers_accounts_our_provider_id} ${item.providers_accounts_id}`
            }
            
        },
        async toggleRandomOriginator(row){
            this.toggleBusy()
            let res = await this.$http.post('/api/routing/smsc/routes/update', {
                routes_random_originator: row.routes_random_originator,
                routes_our_provider_id: row.routes_our_provider_id,
                route_account_id: row.route_account_id,
                routes_mcc: row.routes_mcc,
                routes_mnc: row.routes_mnc
            })
            this.toggleBusy()
    
            if(res.status == 200){
                row.routes_random_originator = !row.routes_random_originator
            }

        },
        toggleBusy(){
            this.showOverlay = !this.showOverlay
        },
        hasItems(){
            return Object.keys(this.bulkActionsObject).length ? true : false
        },
        async setGlobaly(){
            
            let selectedRouted = Object.values(this.bulkActionsObject)
            if(!selectedRouted.length){
                await this.doneModel(`Please seleceted a few routes first.`)
                return
            }else{
                let confirmed = await this.confirmChangesModel(`Set selected ${selectedRouted.length} providers routing to all active tokens?`)
                if(!confirmed){
                    return
                }
            }
            this.toggleBusy()
            let res = await this.$http.post('/api/routing/token/copy/global', {
                providerAccountId: this.providerAccountId,
                toUpdateArray: this.bulkActionsObject
            })
            this.toggleBusy()
            this.resetGlobalActions();
            if(res.status == 200){
                await this.doneModel(`Done. ${res.data.upserted}`)
            }
            
        },
       
        async doneModel(text){
            
            return this.$bvModal.msgBoxOk(text,{
                title: 'Confirmation',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
            })
        },
        async confirmChangesModel(text){
            return this.$bvModal.msgBoxConfirm(text, {
                    title: 'Notification',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
        },
        async uploadCsv(formData){
            
            let confirmed = await this.confirmChangesModel("Are you sure?")
            this.$nextTick(() => {
                this.$bvModal.hide('modal-upload-csv')
            })

            if(confirmed){
                this.toggleBusy()
                let res = await this.$http.post(
                //'/api/csv/create', 
                '/api/routing/smsc/routes/create', 
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                );
                this.toggleBusy()
                if(res.status == 200){

                    //await this.$parent.doneModel(JSON.stringify(res.data, null, 2))
                    //this.$parent.fetchData()

                }
            }
        },
        selectionChanged(selected){
            this.bulkActionsObject = {}
            selected.selectedRows.forEach(row => {
                let mccmnc = `${row.routes_mcc}${row.routes_mnc}`
                this.bulkActionsObject[mccmnc] = row
            })
            this.disableSetGlobaly = Object.keys(this.bulkActionsObject).length ? false : true
        },
        resetGlobalActions(){
            this.bulkActionsObject = {}
        },
        async deleteRouting(){
            
            let selectedRouted = Object.values(this.bulkActionsObject)
            if(!selectedRouted.length){
                let confirmed = await this.confirmChangesModel(`Delete all routing for account?`)
                if(confirmed){
                    selectedRouted = this.routes;
                }else{
                    return
                }
            }

            let confirmed = await this.confirmChangesModel("Are you sure?")
            if(confirmed){
                this.reset()
                this.toggleBusy()
                //let res = await this.$http.post('/api/routes/delete-batch', {
                let res = await this.$http.post('/api/routing/smsc/routes/delete', {
                    info: this.providerAccountId,
                    rows: selectedRouted
                })
                this.toggleBusy()
                if(res.status == 200){
                    //console.log(res.data)
                    await this.fetchData()
                }
            }
            
            
        },
        onAccountSelected(){
            this.fetchData()
        },
        reset(){
            //this.$refs.file.reset();
            //this.$refs.pricingFile.reset();
            this.bulkActionsObject = []
        },
        onCancel() {
            this.busyFileUpload = false
        },
        async onOK() {
            let formData = new FormData();
            //let file = this.$refs.file.files[0]
            let file = this.pricingFile
          
            if(!file){
                return
            }
            //let me = this;
            formData.append("fileToUpload", file);
            
            //formData.append("providerName", this.$route.params.id);
            
            formData.append("providerName", this.providerAccountId.providers_accounts_provider_id);
            formData.append("providerAccoutAlias", this.providerAccountId.providers_accounts_account_alias);
            formData.append("providerAccoutId", this.providerAccountId.providers_accounts_id);
            formData.append("ourProviderAccoutId", this.providerAccountId.providers_accounts_our_provider_id);
            this.toggleBusy()
            let res = this.$http.post(
                '/api/csv/create', 
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            this.toggleBusy()
            if(res && res.status == 200 ){
                this.$bvModal.msgBoxConfirm(res.data, {
                    title: 'Notification',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
            }
            
        },
        onShown() {
            // Focus the dialog prompt
            //this.$refs.dialog.focus()
        },
        onHidden() {
            // In this case, we return focus to the submit button
            // You may need to alter this based on your application requirements
            //this.$refs.submit.focus()
        },
        onSubmit(){
            this.processing = false
            this.busyFileUpload = true
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        async fetchData () {
            if(!this.providerAccountId){
                return 
            }

            const obj = this.providerAccountId.value
            this.providerAccount = obj
            this.toggleBusy()

            const res = await  this.$http.get('/api/routing/smsc/routes/read', {
                params: {
                    routes_our_provider_id: obj.providers_accounts_our_provider_id,
                    route_account_id: obj.providers_accounts_id
                }
            })
            this.toggleBusy()

            if(res && res.status == 200){
                this.routes = res.data;
                this.totalRows = this.routes.length

            }

          
        },
        addRoute: function () {
            
            

        }
    },
    
}
</script>
<style scoped>

.multiselect-wrapper div {
    display:unset;
}

.input-group-append{
    display: flex;
}
.local-customs-select {
    position: relative !important;
    display: flex !important;
    max-width: 50% !important;
}

@media (min-width: 900px) {
    .custom_actions{
        min-width:800px !important;
    }
}


.class-green{
    cursor:pointer;
    color:rgb(17, 192, 17);
}

.class-red{
    cursor:pointer;
    color:rgb(233, 12, 12);
}

</style>