<template>
    <b-modal 
    
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
    size="lg" 
    :id="modalId" 
    :ref="modalId" 
    :title="modalTitle">
        
        <ScheduleRecurring 
            v-if="editRowData.notifications_schedule_recurring"
            ref="form"  
            @submit="submit"
            :editRowData="editRowData" 
            :templates="templates" 
            :users="users"/>
        
        
        <ScheduleByDate v-else 
            ref="form"  
            @submit="submit"
            :editRowData="editRowData" 
            :templates="templates" 
            :users="users"/>
        
        

    </b-modal>
</template>

<script>

//import Form from './EditScheduleForm.vue'
import ScheduleRecurring from './ScheduleRecurring.vue'
import ScheduleByDate from './ScheduleByDate.vue'

export default {
    components:{
        //Form
        ScheduleRecurring,
        ScheduleByDate
    },
    props: ['modalId', 'editRowData'],
    mounted(){
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            if(modalId == this.modalId){
                this.getTemplates()
            }
        })

        this.loadUsersData()
    },
    data() {
      return {
        rowData: this.editRowData,
        recurring: false,
        name: '',
        modalTitle: 'Edit Scheduled notification',
        nameState: null,
        submittedNames: [],
        templates: [],
        users: []
      }
    },
    methods: {
        addRow(rowData, action){
            if(action == 'create'){
                this.$emit('addedRow',rowData)
            }
            this.$refs['modal-schedule'].hide()
            this.$parent.getData()
        },
        async loadUsersData(){
            const res = await this.$http.get(`/api/notifications/users/read`)
            if(res && res.status == 200){
                
                const data = {}
                res.data.forEach(item=>{
                    data[item.notifications_users_uuid] = data[item.notifications_users_uuid] ? 
                    data[item.notifications_users_uuid] : []
                    data[item.notifications_users_uuid].push(item)
                })
                
                Object.keys(data).forEach(item => {
                    this.users.push({
                        notifications_users_uuid: item,
                        emails: data[item].map(sitem=>{
                            return {
                                notifications_users_email: sitem.notifications_users_email,
                                uuid: item
                            }
                        })
                    })
                
                })
            
                
            }
        },
        async getTemplates(){
            const [response] = await Promise.all([
                //this.$http.get(`/api/notifications/templates/read?category=`),
                this.$http.get(`/api/notifications/templates/read`),
            ]);
                
            if(response && response.status == 200){
                this.templates = response.data
            }
        },
        resetModal(){
            this.$parent.resetRow()
        },
        handleOk(bvModalEvent){
            bvModalEvent.preventDefault()
            this.$refs.form.submitForm()
            
            
        },
        async submit(form){
            
            let action = 'create'
            if(this.editRowData.id){
                action = 'update'
            }

            let res = await this.$http.post(`/api/notifications/scheduler/${action}`, form);
            if(res && res.status == 200){
                this.addRow(res.data, action)
                
            }
        }
      
    }
}
</script>

<style>

</style>