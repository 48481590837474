<template>
  <b-container>
    <b-row>
        <b-col lg="6">
        </b-col>
        <b-col lg="3">
            <b>Sub total:</b>
        </b-col>
        <b-col lg="3">
            {{subTotal}}
        </b-col>
    </b-row>
    <b-row>
        <b-col lg="6">
        </b-col>
        <b-col lg="6">
            <hr>
        </b-col>
    </b-row>

    <b-row>
        <b-col lg="6">
        </b-col>
        <b-col lg="3">
            <b>Tax:</b>
        </b-col>
        <b-col lg="3">
            {{tax}}
        </b-col>
    </b-row>
    
    <b-row>
        <b-col lg="6">
        </b-col>
        <b-col lg="3">
            <b>Total:</b>
        </b-col>
        <b-col lg="3">
            {{total}}
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
    props:{
        subTotal: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        },
        tax: {
            type: Number,
            default: 0
        },
    },
    methods:{
        reset(){}
    },
    data(){
        return {}
    }
}
</script>

<style>

</style>
