<template>
  <b-container class="container-fluid p-0">
    <b-row>
      <b-col sm="12" lg="4" class="mt-1">
        <Card 
          header="Incoming messages"
          :data="smsMoTilesData" 
          :styles="{'height' : '155px'}"/>
      </b-col>
      <b-col sm="12" lg="4" class="mt-1">
        <Card 
          header="Outgoing messages"
          :data="smsMtTilesData" 
          :styles="{'height' : '155px'}"
        />
      </b-col>
      <b-col sm="12" lg="4" class="mt-1">
        <Card 
          header="Dlivery reports"
          :data="dlrTilesData"  
          :styles="{'height' : '155px'}"
        />
      </b-col>
      
    </b-row>
  </b-container>
  
</template>

<script>
import Card from './Tiles/Card.vue'
export default {
  components: {
    Card
  },
  props: {
    data: {
      type: Object,
    },
    smsMtTilesData: {
      type: Object,
    },
    smsMoTilesData: {
      type: Object,
    },
    dlrTilesData: {
      type: Object,
    }
  },
  data() {
    return {}
  }
}
</script>

<style>

</style>