<template>
    <b-modal 
        size="lg"
        id="modal-add-block" 
        title="Add Block Rule"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk">
        
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-row>
                    <b-col>
                        <b-form-group 
                            label="Field Name:" 
                            style="text-align:left"
                            :state="!!type"
                            :invalid-feedback="`Field name is required`">
                                <b-form-select required v-model="type" size="xs">
                                    <b-form-select-option :value="null" disabled>Rule Field Type</b-form-select-option>
                                    <b-form-select-option value="country">Country</b-form-select-option>
                                    <b-form-select-option value="ip">IP</b-form-select-option>
                                    <b-form-select-option value="UserAgent">UserAgent</b-form-select-option>
                                    <b-form-select-option value="Recipient">Recipient</b-form-select-option>
                                </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group 
                            label="Is Regex Rule:" 
                            style="text-align:left">
                                <b-form-select required v-model="isRegex" size="xs">
                                    <b-form-select-option :value="null">Is Regex Rule</b-form-select-option>
                                    <b-form-select-option :value="true">Yes</b-form-select-option>
                                    <b-form-select-option :value="false">No</b-form-select-option>
                                </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col>
                        <b-form-group
                            v-if="type && type != 'country'"
                            :label="type"
                            :invalid-feedback="`${type} is required`"
                            :state="!!typeVlaue">
                            <b-form-input
                                :state="!!typeVlaue"
                                :placeholder="type"
                                v-model="typeVlaue"
                                type="text"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            v-if="type && type == 'country'"
                            :label="type"
                            :invalid-feedback="`${type} is required`"
                            :state="!!typeVlaue">
                            <b-form-select required v-model="typeVlaue" size="xs">
                                <b-form-select-option :value="null">Please select a country</b-form-select-option>
                                <b-form-select-option v-for="country in countries" :key="country.value" :value="country.value">
                                    {{country.label}}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="8">
                        <b-form-group 
                            v-if="type != 'UserAgent'"
                            label="User Agent:">
                            <b-form-input
                                placeholder="User Agent"
                                v-model="userAgent"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group
                            v-if="type != 'country'"
                            label="Country">
                            <b-form-select v-model="countryCode" size="xs">
                                <b-form-select-option :value="null">Please select a country</b-form-select-option>
                                <b-form-select-option v-for="country in countries" :key="country.value" :value="country.value">
                                    {{country.label}}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group 
                        v-if="type != 'ip'"
                        label="IP:">
                            <b-form-input
                                placeholder="IP"
                                v-model="ip"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group 
                        v-if="type != 'Recipient'"
                        label="msisdn:">
                            <b-form-input
                                placeholder="msisdn"
                                v-model="msisdn"
                                type="tel"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

            </form>
    </b-modal>
</template>

<script>
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
const list = countries.getNames('en', { select: 'official' })
const listArray = Object.keys(list).map((key) => ({ value: key, label: list[key] }))
export default {
     data() {
      return {
        countries: listArray,
        typeVlaue: null,
        type: null,
        countryCode: null,
        ip: null,
        userAgent: null,
        msisdn: null,
        isRegex: null,
        regex: null
      }
    },
    props: ['submit'],
    methods: {

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            return valid
        },
        resetModal() {
            this.isRegex = false
            this.countryCode = null
            this.ip = null
            this.userAgent = null
            this.msisdn = null
            this.type = null
            this.regex = null
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        async handleSubmit() {
            const valid = this.checkFormValidity()
            console.log(`valid: ${valid}`)
            if (!valid) return
    /*
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
*/
    
            this.submit({
                typeVlaue: this.typeVlaue,
                type: this.type,
                countryCode: this.countryCode,
                ip: this.ip,
                userAgent: this.userAgent,
                msisdn: this.msisdn,
                isRegex: this.isRegex,
                regex: this.regex
            })
  

        }
    }
}
</script>

<style>

</style>