<template>
  <div class="tbl-wrpr">
    <b-modal size="xl" ref="smsc-modal-form" hide-footer>
      <template #modal-header>
        <h6>{{`${params.mode} SMSC Configuration File`}}</h6>
      </template>

      <Form :params="params" 
            :onSubmit="submitForm" 
            :onClose="closeForm"
            :getRoundRobinIndexForSmc="getNextRoundRobinSmsc"
            :accounts="accounts" />
    </b-modal>

    <vue-good-table 
      theme="polar-bear"
      :line-numbers="false"
      :rows="table.rows" 
      :columns="table.cols" 
      :pagination-options="table.pagination"
    >
      <div slot="table-actions">
        <b-button-group>
          <Button icon="file-plus" text="New Config" variant="primary" :onClick="() => openForm('Create')" />
          <Button icon="recycle" text="Refresh lists" variant="primary" :onClick="reload" />
        </b-button-group>
      </div>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'meta.roundRobin'" style="align:center">
          <b-icon v-if="props.row.meta && props.row.meta.roundRobin == 'rrobin'" icon="check" variant="success"></b-icon>
          <b-icon v-else icon="dash" variant="info"></b-icon>
        </span>
        <span v-else-if="props.column.field == 'meta.fixedSenderId'">
          <span v-if="props.row.meta && props.row.meta.fixedSenderId">{{props.row.meta.fixedSenderId}}</span>
          <b-icon v-else icon="dash" variant="info"></b-icon>
        </span>
        <span v-else-if="props.column.field == 'options'">
          <b-button-group>
            <Button icon="pencil-square" title="Edit" variant="outline-primary" :onClick="() => openForm('Edit', props.row)" />
            <Button icon="plus-square" title="Add SMSC To Bearerbox" variant="outline-success" :onClick="() => showAddSmscModal(props.row)" />
            <Button icon="trash" title="Delete" variant="outline-danger" :onClick="() => remove(props.row)" />
            <Button icon="files" title="Duplicate" variant="outline-primary" :onClick="() => dupliacte(props.row)" />
            <Button icon="broadcast-pin" title="Test SMSC" variant="outline-primary" :onClick="() => testSmsc(props.row)" />
            
          </b-button-group>
        </span>
      </template>

    </vue-good-table>

    <AddSMScModal :bearerboxes="bearerboxes" @bbox="addSmsc"/>
    <TestSMSCModal :rowdata="modalRowData"/>

  </div>
</template>

<script>
  import cols from './Table'
  import api from '../../../api'
  import Form from './Form.vue'
  import Button from '../../Common/Button.vue'
  //import alert from '../../Common/Alerts/DefaultAlertConfig'
  import pagination from '../../Common/Tables/DefaultPaginationConfig'
  import AddSMScModal from './AddSMScModal.vue'
  import TestSMSCModal from './TestSMSCModal.vue'
  export default {
    components:{
      Form,
      Button,
      AddSMScModal,
      TestSMSCModal
    },
    props:{
      rows: {
        type: Array
      },
      bearerboxes: {
        type: Array
      },
      accounts: {
        type: Array
      },
      fetch: {
        type: Function
      }
    },
    data () {
      return {
        modalRowData: {},
        addData: null,
        table: {
          cols,
          rows: [],
          pagination
        },
        params: {},
        group: 'smsc',
      }
    },
    watch:{
      'rows': function (nv) {
        this.table.rows = nv
      }
    },
    methods: {
      async ok (text, title) {
        return await this.$bvModal.msgBoxOk(text , { title, ...alert })
        
      },
      async confirm (text, title) {     
        return await this.$bvModal.msgBoxConfirm(text , { title, ...alert })
      },
      async reload () {
        this.fetch(['bearerbox', this.group]);
      },
      async addSmsc(bbox){
        
        const obj = {
          smsc: this.addData.config['smsc-admin-id'],
          'admin-port': bbox.config['admin-port'],
          'status-password': bbox.config['admin-password'],
        }


        const result = await this.$http.post(`/api/gateway/${this.group}/add`, obj)
        
        if(result.status == 200 ){
          await this.confirm(result.data, 'Notification')
        }
        
      },
      async showAddSmscModal (rowData) {
        this.addData = rowData
        this.$bvModal.show('modal-add-smsc')
        
      },
      async create (params) {
        if (await this.confirm('Are you sure?', 'Confirmation')) {
          const result = await api.groups.create(this.group, params)
          this.table.rows.push(result)
        }
      },
      async update (uid, params) {
        if (await this.confirm('Are you sure?', 'Confirmation')) {
          const result = await api.groups.update(uid, this.group, params)
          const index = this.table.rows.findIndex(item => item.id == result.id)
          this.table.rows.splice(index,1)
          this.table.rows.push(result)
        }
      },
      async remove (rowData) {
        if (await this.confirm('Are you sure?', 'Confirmation')) {
          const result = await api.groups.delete(rowData.uid, this.group)
          if(result) this.table.rows.splice(rowData.originalIndex, 1)  
        }
        
      },
      async dupliacte(rowData){
        //TODO: finish this
        console.log(rowData)
      },
      async testSmsc(rowData){
        this.modalRowData = rowData
        this.$bvModal.show('modal-test-smsc')
      },
      async openForm (mode, params = { tag: '', config: {}, includes: [], meta: {}}) {
        this.params = { mode, ...params }
        this.$refs['smsc-modal-form'].show()
      },
      async submitForm (tag, config, includes, meta) {
        if (this.params.mode === 'Create') {
          await this.create({ tag, config, includes, meta })
        } else {
          await this.update(this.params.uid, { tag, config, includes, meta })
        }

        this.$refs['smsc-modal-form'].hide()
        
      },
      async closeForm () {
        this.$refs['smsc-modal-form'].hide()
      },
      async getNextRoundRobinSmsc (groupId) {
        const res = await this.$http.get(`/api/gateway/robin/${groupId}/next`)
        if(res && res.status == 200)
          return res.data
      }
    }
  }
</script>

<style>
  .tbl-wrpr .vgt-global-search__input {
    display: none !important;
  }
</style>
