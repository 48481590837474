<template>
    <b-row>
        
        <b-row>
            <b-col>
                <b-card
                    border-variant="primary"
                    header-border-variant="primary"
                    header-bg-variant="primary"
                    header-text-variant="white">
                    <template #header>
                        <h4 class="mb-0">Total</h4>
                    </template>
                    <b-card-text class="p-3"><h4>{{total}} ({{parts}})</h4></b-card-text>
                </b-card>
            </b-col>
            <b-col>
                <b-card
                border-variant="success"
                header-bg-variant="success"
                header-text-variant="white">
                    <template #header>
                        <h4 class="mb-0">Delivered</h4>
                    </template>
                    <b-card-text class="p-3"><h4>{{delivered}}</h4></b-card-text>
                </b-card>
            </b-col>
            <b-col >
                <b-card
                border-variant="primary"
                header-text-variant="primary">
                    <template #header>
                        <h4 class="mb-0">Revenue</h4>
                    </template>
                    <b-card-text class="p-3"><h4>{{ sell ? sell.toFixed(4) : 0 }}</h4></b-card-text>
                </b-card>
            </b-col>
            <b-col >
                <b-card
                border-variant="primary"
                header-text-variant="primary">
                    <template #header>
                        <h4 class="mb-0">Cost</h4>
                    </template>
                    <b-card-text class="p-3"><h4>{{ cost ? ( Number(cost) + Number(hlrCost) ).toFixed(4) : 0 }}</h4></b-card-text>
                </b-card>
            </b-col>
            <b-col>
                <b-card
                :border-variant="( Number(sell) - Number(cost) + Number(hlrCost)  ) > 0 ? 'success': 'warning'"
                :header-bg-variant="( Number(sell) - Number(cost) + Number(hlrCost)  ) > 0 ? 'success': 'warning'"
                header-text-variant="white">
                    <template #header>
                        <h4 class="mb-0">Profit</h4>
                    </template>
                    <b-card-text class="p-3"><h4>
                        {{ ( Number(sell) - Number(cost) + Number(hlrCost)  ).toFixed(4)}}
                    </h4>
                </b-card-text>
                </b-card>
            </b-col>
        </b-row>
        <b-row>   
            
            
            <b-col >
                <b-card
                border-variant="warning"
                header-bg-variant="warning"
                header-text-variant="white">
                    <template #header>
                        <h4 class="mb-0">Proccessing</h4>
                    </template>
                    <b-card-text class="p-3"><h4>{{processing}}</h4></b-card-text>
                </b-card>
            </b-col>
            <b-col >
                <b-card
                border-variant="warning"
                header-bg-variant="warning"
                header-text-variant="white">
                    <template #header>
                        <h4 class="mb-0">Buffered</h4>
                    </template>
                    <b-card-text class="p-3"><h4>{{buffered}}</h4></b-card-text>
                </b-card>
            </b-col>
            <b-col >
                <b-card
                border-variant="warning"
                header-bg-variant="warning"
                header-text-variant="white">
                    <template #header>
                        <h4 class="mb-0">Pending</h4>
                    </template>
                    <b-card-text class="p-3"><h4>{{pending}}</h4></b-card-text>
                </b-card>
            </b-col>

            <b-col >
                <b-card
                border-variant="danger"
                header-bg-variant="danger"
                header-text-variant="white">
                    <template #header>
                        <h4 class="mb-0">Rejected</h4>
                    </template>
                    <b-card-text class="p-3"><h4>{{noreceipt}}</h4></b-card-text>
                </b-card>
            </b-col>

            <b-col >
                <b-card
                border-variant="danger"
                header-border-variant="danger"
                header-bg-variant="danger"
                header-text-variant="white">
                    <template #header>
                        <h4 class="mb-0">Failed</h4>
                    </template>
                    <b-card-text class="p-3"><h4>{{failed}}</h4></b-card-text>
                </b-card>
            </b-col>
        </b-row>

        
        
    </b-row>
</template>

<script>
export default {
    props:['FilteredtData'],
    watch:{
        'FilteredtData': function(data){
            
            this.resetTiles()
            data.forEach(item => {
                
                this.total += Number(item.total_sent)
                this.parts += Number(item.total_parts)
                this.delivered += Number(item.total_delivered)
                this.failed += Number(item.total_failed)
                this.processing += Number(item.total_pending)
                this.buffered += Number(item.total_buffered)
                this.pending += Number(item.total_pending)
                this.queued += Number(item.total_other)
                this.noreceipt += Number(item.total_rejected)
                this.cost += Number(item.total_cost)
                this.sell += Number(item.total_sell)
                this.hlrCost += Number(item.total_hlr_cost)
            })
            /*
            this.total = data.total_sent
            this.delivered = data.delivered
            this.failed = data.failed
            this.processing = data.processing
            this.queued = data.queued
            this.noreceipt = data.noreceipt
            this.cost = data.cost
            this.sell = data.sell
            this.hlrCost = data.hlrCost
            */
        }
    },
    methods: {
        resetTiles(){
            this.total = 0
            this.delivered= 0,
            this.failed= 0
            this.processing= 0
            this.queued= 0
            this.noreceipt= 0
            this.sell=0
            this.cost=0
            this.hlrCost= 0 
            this.parts = 0
            this.buffered = 0
            this.pending = 0
        }
    },
    data(){
        return {
            total: 0,
            delivered: 0,
            failed: 0,
            processing: 0,
            buffered: 0,
            pending: 0,
            queued: 0,
            noreceipt: 0,
            sell:0,
            cost:0,
            hlrCost: 0 ,
            parts: 0
        }
    }

}
</script>

<style>

</style>
