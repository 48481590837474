<template>
  <b-form>
     <b-form-input id="range-1" v-model="value" type="range" min="0" max="5"></b-form-input>
  </b-form>
</template>

<script>
export default {

}
</script>

<style>

</style>