<template>
    <Dashboard />
</template>

<script>
import Dashboard from './Dashboard.vue'


export default {
    components:{
        Dashboard
    },
    mounted () {
        
    },
    methods:{

        
    },
    data(){
        return {
          
        }
    }
}
</script>

<style>

</style>