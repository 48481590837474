export default [
  {
    width: '100px',
    label: 'ID',
    field: 'id',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Id'
    }
  },
  {
    width: '100px',
    label: 'Tag',
    field: 'tag',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Tag'
    }
  },
  {
    width: '150px',
    label: 'Smsc id (Alias)',
    field: 'config.smsc-id',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Type'
    }
  },
  {
    width: '150px',
    label: 'System Type',
    field: 'config.system-type',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Type'
    }
  },
  {
    width: '150px',
    label: 'SMSC Type',
    field: 'config.smsc',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Type'
    }
  },
  {
    width: '150px',
    label: 'Round Robin',
    field: 'meta.roundRobin'
  },
  {
    width: '150px',
    label: 'Fixed Sender',
    field: 'meta.fixedSenderId'
  },
  {
    width: '150px',
    label: 'Port',
    field: 'config.port'
  },
  {
    width: '100px',
    label: 'Log level',
    field: 'config.log-level',
  },
  {
    width: '250px',
    label: 'Options',
    field: 'options',
  },
]
