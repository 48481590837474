<template>
    <b-modal 

    size="lg" 
    id="account-modal"
    ref="account-modal"
    :hide-footer="false"
    :title="`${action} provider account`"
    @shown="setData"
    @ok="handleOk"
    @hidden="onReset">
        <div class="d-block text-center" >
            <b-form 
                ref="accountForm"
                @submit.prevent="onSubmit" 
                @reset="onReset">
                <b-row>
                    <b-col>
                        <b-form-group
                        style="text-align:left"
                        label="Account status">
                            <b-form-select 
                                style="text-align:left"
                                class="select"
                                v-model="formAddProviderAccount.accountStatus"
                                :options="accountStatusList"
                                required>
                            </b-form-select>
                        </b-form-group>

                    </b-col>
                    <b-col>
                        <b-form-group
                        style="text-align:left"
                        label="Account type">
                            <b-form-select 
                                @change="setProviderAccountAlias"
                                style="text-align:left"
                                class="select"
                                :disabled="formAddProviderAccount.accountId != null"
                                v-model="accountType"
                                :options="accountTypesList"
                                required>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col col cols=6>
                        <b-form-group
                            style="text-align:left"
                            label="Our Account Alias:"
                            
                            description="Account Alias of the new account"
                            valid-feedback="Thank you!"
                            :invalid-feedback="'Length: 4-10 chars. uppercase letters, numbers.'"
                            >
                            <b-form-input 
                            v-model="formAddProviderAccount.accountAlias"
                            :state="this.formAddProviderAccount.accountAlias != null"
                            placeholder="Account Alias"
                            required
                            trim
                            :disabled="accountType != 'custom'">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                     <b-col>
                        <b-form-group
                        style="text-align:left"
                        label="Provider ID">
                            <b-form-input 
                                disabled
                                placeholder="Provider ID"
                                v-model="formAddProviderAccount.providerId"
                                required
                                trim>
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col col cols=6>
                        <b-form-group
                            style="text-align:left"
                            id="input-group-1"
                            label="SMSC system id:"
                            label-for="input-1"
                            
                            description="SMSC system id"
                            valid-feedback="Thank you!"
                            :invalid-feedback="'Please enter a valid system ID'"
                            
                            >
                            <b-form-input 
                            id="input-1" 
                            v-model="formAddProviderAccount.accounUser"
                            :state="/.{2,10}$/.test(this.formAddProviderAccount.accounUser)"
                            required
                            placeholder="SMSC system id"
                            trim>
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col col cols=6>
                        <b-form-group
                            style="text-align:left"
                            id="input-group-2"
                            label="SMSC password:"
                            label-for="input-2"
                            
                            description="SMSC password"
                            valid-feedback="Thank you!"
                            :invalid-feedback="'Please enter at least 4 chars'"
                            
                        >
                            <b-form-input
                                id="input-2"
                                v-model="formAddProviderAccount.accountPassword"
                                :state="/.{4,}/.test(this.formAddProviderAccount.accountPassword)"
                                placeholder="SMSC password"
                                required
                                trim>
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
export default {
    components: {},
    props: ['provider','account'],
    data() {
        return {
            apiaction: 'create',
            action: 'Add',
            accountType: null,
            accountStatusList: [
                { 
                    text: 'Status', 
                    value: null 
                },
                { 
                    text: 'Active', 
                    value: true
                }, 
                { 
                    text: 'Stopped', 
                    value: false
                }
            ],
            accountTypesList: [
                {
                    text: 'Account type', 
                    value: null 
                },
                { 
                    text: 'OTP', 
                    value: 'OTP'
                }, 
                { 
                    text: 'PROMO', 
                    value: 'PROMO'
                },
                { 
                    text: 'RELAY', 
                    value: 'RELAY'
                },
                { 
                    text: 'EXCG', 
                    value: 'EXCG'
                },
                { 
                    text: 'Custom', 
                    value: 'custom'
                },
            ],
            formAddProviderAccount: {
                providerId: null,
                providerOurId: null,
                providerName: null,
                accountId: null,
                accounUser: "",
                accountPassword: "",
                accountStatus: null,
                accountAlias: ""        
            }
        }
    },
    methods: {
        setProviderAccountAlias(value){
            //if(!this.formAddProviderAccount.providerName) return
            this.formAddProviderAccount.accountAlias = `${this.formAddProviderAccount.providerName}${value}`
            console.log(this.formAddProviderAccount)
        },
        async handleOk(event){
            event.preventDefault()
            const valid = this.$refs['accountForm'].checkValidity()
            
            if(!valid){
                return
            }
            
            
            let res = await this.$http.post(`/api/routing/smsc/accounts/${this.apiaction}`, {
                providers_accounts_account_status : this.formAddProviderAccount.accountStatus,
                providers_accounts_provider_id: this.formAddProviderAccount.providerId,
                providers_accounts_our_provider_id: this.formAddProviderAccount.providerOurId,
                providers_accounts_id: this.formAddProviderAccount.accountId,
                providers_accounts_user_name: this.formAddProviderAccount.accounUser,
                providers_accounts_password: this.formAddProviderAccount.accountPassword,
                providers_accounts_account_alias:this.formAddProviderAccount.accountAlias
            })
            
            if(res && res.status == 200) {
                
                if(this.apiaction == 'create'){
                    this.$emit('added', res.data)   
                }
                this.$emit('done', null)   
            }
            

        },
        setData(){

            this.formAddProviderAccount.providerId = this.provider.kannel_smsc_provider
            this.formAddProviderAccount.providerOurId = this.provider.provider_id
            this.formAddProviderAccount.providerName = this.provider.kannel_smsc_provider
            
            if(!this.account) return
            this.apiaction = 'update'
            const parts = this.account.split('-')
            this.formAddProviderAccount.accountAlias = parts[0]
            this.formAddProviderAccount.accountStatus = !!parts[1]
            this.formAddProviderAccount.accountId = parts[2]
            this.formAddProviderAccount.accounUser = parts[3]
            this.formAddProviderAccount.accountPassword = parts[4]
            
            this.action = 'Edit' 
            this.accountType = /OTP/i.test(parts[0]) ? 'OTP' : /PROMO/i.test(parts[0]) ? 'PROMO' : /RELAY/i.test(parts[0]) ? 'RELAY' : /EXCG/i.test(parts[0]) ? 'EXCG' : 'custom'

        },
        onSubmit(){},
        onReset(){
            this.accountType = null
            this.apiaction = 'create'
            this.formAddProviderAccount =  {
                providerId: null,
                providerName: null,
                accountId: null,
                accounUser: "",
                accountPassword: "",
                accountStatus: null,
                accountAlias: ""        
            }

        },

    }
}
</script>

<style>

</style>