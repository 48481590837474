<template>
    <b-modal 
        size="md" 
        id="new-provider-modal"
        ref="new-provider-modal"
        :hide-footer="false"
        :title="`${provider ? 'Edit' : 'Create'} Provider`"
        @ok="handleOk"
        @hidden="onReset"
        @shown="setForm">

        <b-row>
            <b-col >
                <b-overlay :show="show" rounded="sm">
                    <b-form @submit="handleSubmit" @reset="onReset">
                    <b-form-select 
                        class="select"
                        v-model="form.provider_status"
                        :options="providerStatusList"
                        required
                        >
                    </b-form-select>

                    <b-form-group
                        id="input-group-1"
                        label="Provider name:"
                        description="The name of the  provider"
                        label-for="input-1"
                        valid-feedback="Thank you!"
                        :invalid-feedback="invalidFeedback"
                        :state="state"
                        >
                        <b-form-input 
                        id="input-1" 
                        v-model="form.kannel_smsc_provider"
                        :state="state"
                        trim></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-1"
                        label="Provider system id:"
                        description="The system id of the provider"
                        label-for="input-1"
                        valid-feedback="Thank you!"
                        :invalid-feedback="invalidFeedback"
                        :state="state"
                        >
                        <b-form-input 
                        id="input-1" 
                        v-model="form.provider_id"
                        :state="state"
                        disabled
                        trim></b-form-input>
                    </b-form-group>

                    
                    <b-form-group
                        id="input-group-2"
                        label="Provider web site:"
                        label-for="input-2"
                        description="Link to provider web site"
                    >
                        <b-form-input
                            id="input-2"
                            v-model="form.provider_company_site"
                            type="text"
                            placeholder="Enter provider web site"
                            required
                        ></b-form-input>
                    </b-form-group>
                   
                    </b-form>
                </b-overlay>
            </b-col>
        </b-row>
    
    </b-modal>
</template>

<script>


export default {
    data() {
        return {
            action: 'create',
            ModalShow: false,
            toastCount: 0,
            show: false,
            form: {
                kannel_smsc_provider: null,
                provider_id: null,
                provider_status: null,
                provider_company_site: null
            },
            providerStatusList: [
                { 
                    text: 'Select Provider Status', 
                    value: null 
                }, 
                { value: true, text: 'OK' },
                { value: false, text: 'NOK' }
            ],
            selected: null,
            options: [
            { value: null, text: 'Please select an option' },
            { value: 'a', text: 'This is First option' },
            { value: 'b', text: 'Selected Option' },
            { value: { C: '3PO' }, text: 'This is an option with object value' },
            { value: 'd', text: 'This one is disabled', disabled: true }
            ]
        }
    },
    props: ['provider'],

    computed:{
        invalidFeedback() {
            if (!this.form.kannel_smsc_provider) {
                return 'Enter at least 4 characters.' 
            }


            if (this.form.kannel_smsc_provider && this.form.kannel_smsc_provider.length > 0) {
                return 'Enter at least 4 characters.' 
            }
            return ""
            //return 'Please enter something.'            
        },
        state() {
            //console.log(!this.form.kannel_smsc_provider)
            //if (!this.form.kannel_smsc_provider) { !!this.form.kannel_smsc_provider}
            //return this.form.kannel_smsc_provider && this.form.kannel_smsc_provider.length >= 4
            return !!this.form.kannel_smsc_provider
        },
    },
    methods: {
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        setForm(){
            if(!this.provider) return

            this.action = 'update'
            this.form = this.provider
            this.form.provider_status = !!this.provider.provider_status
            
            
            
        },
        async handleSubmit() {

            this.show = !this.show; 
            const res = await this.$http.post(`/api/routing/smsc/${this.action}`, this.form)
            this.show = !this.show; 
            if(res && res.status == 200){
               
                
                if(res.data.status) return this.$emit('added', null)

                this.$emit('added', res.data)
            }
            

        },
        onReset(event) {
            event.preventDefault()
            this.reset();
        },
        makeToast(append = false){
            this.toastCount++
            this.$bvToast.toast(`This is toast number ${this.toastCount}`, {
            title: 'BootstrapVue Toast',
            autoHideDelay: 5000,
            appendToast: append
            })
        },
        reset() {
            // Reset our form values
            this.form = {
                kannel_smsc_provider: null,
                provider_id: null,
                provider_status: null,
                provider_company_site: null
            }
            
        }
    }
}
</script>

<style >

</style>