<template>
    <div>
         
        <form id="recurring-form" ref="recurring-form" @submit.prevent="handleSubmit">
            <b-row>
                <b-col lg="6" sm="12">
                    <b-form-group
                        label="Subject"
                        label-for="subject-input"
                        invalid-feedback="Subject is required"
                        :state="form.notifications_schedule_subject != null">
                        <b-form-input
                            id="subject-input"
                            v-model="form.notifications_schedule_subject"
                            placeholder="Enter subject"
                            required
                            ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                    <b-form-group
                        label="From"
                        label-for="from-input"
                        invalid-feedback="From is required"
                        :state="form.notifications_schedule_from != null">
                        <b-form-input
                            type="email"
                            id="subject-input"
                            v-model="form.notifications_schedule_from"
                            placeholder="Enter subject"
                            required
                            ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="8" sm="12">
                    <b-form-group 
                        label="Template"
                        label-for="template-input"
                        invalid-feedback="Template is required"
                        :state="form.notifications_schedule_template != null">
                        
                        <multiselect 
                            v-model="multiselect" 
                            @input="form.notifications_schedule_template = multiselect.template_name_short;  form.notifications_schedule_template_category = multiselect.template_category"
                            :max-height="150"
                            :allow-empty="false"
                            :multiple="false" 
                            :searchable="true"
                            label="template_name_short" 
                            track-by="template_name_short"
                            :options="templates">
                        </multiselect>
                        
                    </b-form-group>
                </b-col>
                <b-col lg="4" sm="12">
                    <b-form-group 
                        label="Timezone"
                        label-for="timezone-input"
                        invalid-feedback="Timezone is required"
                        :state="form.notifications_schedule_tz != null">
                            <b-form-select
                                style="width:100% !important"
                                id="timezone-input"
                                v-model="form.notifications_schedule_tz"
                                @input="setTimeZone()"
                                required>

                                    <b-form-select-option :value="null" disabled>-- Please select timezone --</b-form-select-option>
                                    <b-form-select-option value="CET" >Central European Time</b-form-select-option>
                                    <b-form-select-option value="EET" >Eastern European Time</b-form-select-option>
                                    <b-form-select-option value="WET" >Western European Time</b-form-select-option>
                                    <b-form-select-option value="JST" >Japan Standard Time</b-form-select-option>
                                    <b-form-select-option value="CST" >China Standard Time</b-form-select-option>                                    
                                    <b-form-select-option value="EST" >Eastern Standard Time (US)</b-form-select-option>
                                    <b-form-select-option value="CST" >Central Standard Time (US)</b-form-select-option>
                                    <b-form-select-option value="PST" >Pacific Standard Time (US)</b-form-select-option>
                                    <b-form-select-option value="GMT" >GMT</b-form-select-option>
                                    <b-form-select-option value="UTC" >UTC</b-form-select-option>
                                    
                                    
                            </b-form-select>
                        
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                    <b-col lg="4" sm="12">
                        <b-form-group
                        label="Hour/Minute"
                        label-for="datepicker-input"
                        invalid-feedback="Time is required"
                        :state="form.notifications_schedule_full_time != null">
                        
                            <b-input-group class="mb-3">
                                <b-form-input
                                    @input="setHourSecond"
                                    v-model="timePicker"
                                    type="time"
                                    required
                                    :disabled="form.notifications_schedule_tz == null"
                                ></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group
                            label="Date"
                            label-for="datepicker-input"
                            invalid-feedback="Date is required"
                            :state="form.notifications_schedule_full_date ? true: false">
                                <b-form-datepicker 
                                id="datepicker-input"
                                v-model="form.notifications_schedule_full_date" 
                                @input="setMonthDate"
                                required
                                :state="form.notifications_schedule_full_date ? true : false"
                                class="mb-2">
                                </b-form-datepicker>
                        </b-form-group>
                    </b-col>
                    
            </b-row>
            <b-row >
                <b-col lg=12 style="display:inline-flex">
                    <b-form-checkbox
                        class="m-2 p-2"
                        v-model="allRecipients"
                        name="allRecipients"
                        :value="true"
                        :unchecked-value="false">
                            <h6>
                                Send to all recipients
                            </h6>
                            
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg=12>
                    <b-form-group
                        label="Recipients"
                        label-for="recipients-input"
                        invalid-feedback="Recipients are required"
                        :state="form.notifications_schedule_to != null || allRecipients">
                        <multiselect 
                            :disabled="allRecipients"
                            v-model="form.notifications_schedule_to" 
                            :mix-height="300"
                            :allow-empty="false"
                            :group-select="true" 
                            placeholder="Type to search"
                            :multiple="true" 
                            :searchable="true"
                            group-label="notifications_users_uuid"
                            group-values="emails"
                            label="notifications_users_email" 
                            track-by="notifications_users_email"
                            :options="users">
                        </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>
            <button ref="submit" style="display:none;">Submit</button>
        </form>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
    components:{
        Multiselect
    },
    props: ['editRowData', 'templates', 'users'],
    methods:{

        setTimeZone(){
            const tzDate = new Date(new Date().toLocaleString('en', {timeZone: this.form.notifications_schedule_tz}))
            let mins = tzDate.getMinutes()
            let hrs = tzDate.getHours()    
            mins = mins.toString().padStart(2, 0)
            hrs = hrs.toString().padStart(2, 0)
            this.timePicker = `${hrs}:${mins}`
            this.setHourSecond()
        },
        setHourSecond(){
            const [hour, minute] = this.timePicker.split(/:/)
            this.form.notifications_schedule_full_time = this.timePicker
            this.form.notifications_schedule_hour = hour
            this.form.notifications_schedule_minute = minute
        },
        setMonthDate(){
    
            const [year, month, day] = this.form.notifications_schedule_full_date.split(/-/)
            
            this.form.notifications_schedule_year = year
            this.form.notifications_schedule_month = parseInt(month) - 1
            this.form.notifications_schedule_day_of_month = day
            
        },
        submitForm(){
            this.$refs.submit.click()
        },
        async handleSubmit(e) {
            e.preventDefault()
            
            this.valid = this.form.notifications_schedule_full_date != null && this.$refs['recurring-form'].checkValidity() ? true : false
            if(!this.valid)
                return

            this.valid = this.form.notifications_schedule_full_time != null && this.form.notifications_schedule_template != null ? true : false
             if(!this.valid)
                return
            this.valid = this.allRecipients ? true : this.form.notifications_schedule_to != null && this.form.notifications_schedule_to.length > 0
            this.form.notifications_schedule_job_status = 1
            this.form.sendToAll = this.allRecipients

            if(this.valid){
                this.$emit('submit', this.form)
            }
            

        }
    },
    data(){
        return {
            multiselect:null,
            timePicker: this.editRowData.notifications_schedule_full_time,
            form: this.editRowData,
            allRecipients: true
        }
    }
}
</script>

<style>
label,.invalid-feedback {
    text-align: left;
}

.vue__time-picker{
    display:block !important;
}

.display-time{
    border-radius: 5px;
    padding: 18px;
}
</style>