import NotificationsLayout from '../components/Notifications/NLayout.vue'
import NotificationsTemplatesLayout from '../components/Notifications/Templates/Layout.vue'
import NotificationsTemplatesList from '../components/Notifications/Templates/Table.vue'
import NotificationsTemplatesEditor from '../components/Notifications/Templates/Editor.vue'
import NotificationsScheduleLayout  from './../components/Notifications/Scheduler/Layout.vue'
import NotificationsSchedulerList  from './../components/Notifications/Scheduler/Table.vue'

//import NotificationsStatistics from '../components/Notifications/Stats.vue'
//import NotificationsRabbitMQ from '../components/Notifications/RabbitMQ/Layout.vue'

export default  {
    path: 'notifications', 
    name: 'notifications', 
    redirect: 'notifications/statistics',
    component: NotificationsLayout,    
    children:[

      {
        path: 'scheduler', 
        name: 'scheduler',
        redirect: 'scheduler/list',
        component: NotificationsScheduleLayout,
        children:[
          {
            path: 'list',
            component: NotificationsSchedulerList,
            name:'Scheduled notificaions'
          },
        ]
      },
      {
        path: 'templates', 
        name: 'templates',
        redirect: 'templates/list',
        component: NotificationsTemplatesLayout,
        children:[
          {
            path: 'list',
            component: NotificationsTemplatesList,
            name:'Templates'
          },
          {
            path: 'editor',
            component: NotificationsTemplatesEditor,
            name:'Template editor'
          },
        ]
      }
    ]
  }