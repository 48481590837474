<template>
  <b-form @submit.stop.prevent="() => onSubmit(tag, config, includes.filter(i => i))">
    <b-row>

      <b-col cols="7">
        <b-card style="padding: 10px 10px 10px 10px;">
          <b-row>
            <b-form-group style="text-align:left">
              <h6 style="color:green;">{{this.params.uid}}</h6>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-form-group label="Tag:" label-for="input-tag" :invalid-feedback="'Tag is required'" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  v-model="tag"
                  id="input-tag"
                  :state="tag !== ''"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Keyword:" label-for="input-keyword" invalid-feedback="Keyword is required" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  v-model="config['keyword']"
                  id="input-keyword"
                  :state="![null, undefined, ''].includes(config['keyword'])"
                />
              </b-form-group>
            </b-col>
            <b-col>
             <b-form-group label="accepted-smsc:" label-for="input-accepted-smsc" invalid-feedback="accepted-smsc" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['accepted-smsc']"
                  id="input-file "
                  :state="![null, undefined, ''].includes(config['accepted-smsc'])"
                />
              </b-form-group>
            </b-col>
          </b-row>

          

          <b-row>
            <b-col>
              <b-form-group label="MO Submit type:"  invalid-feedback="Please select submit-type" style="text-align:left">
                <b-form-checkbox
                  v-model="checkboxSubmitType"
                  value="GET"
                  :unchecked-value="null"
                >
                  GET
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="checkboxSubmitType"
                  value="POST"
                  :unchecked-value="null"
                >
                  POST
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="checkboxSubmitType"
                  value="XML"
                  :unchecked-value="null"
                >
                  XML
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="file :" label-for="input-file " invalid-feedback="file " style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['file']"
                  id="input-file "
                  :state="![null, undefined, ''].includes(config['file '])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="checkboxSubmitType == 'GET'">
            <b-col>
              <b-form-group label="Get url:" label-for="input-get-url" invalid-feedback="get-url is invalid" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['get-url']"
                  id="input-get-url"
                  :state="isValidURL(config['get-url'])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="checkboxSubmitType == 'POST'">
            <b-col>
              <b-form-group label="Post url:" label-for="input-get-url" invalid-feedback="post-url is invalid" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['post-url']"
                  id="input-post-url"
                  :state="isValidURL(config['post-url'])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else-if="checkboxSubmitType == 'XML'">
            <b-col>
              <b-form-group label="Post xml URL:" label-for="input-post-xml" invalid-feedback="post-xml is invalid" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['gpost-xml']"
                  id="input-post-xml"
                  :state="isValidURL(config['get-url'])"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
              <b-col>
              <b-form-group label="text:" label-for="input-text " invalid-feedback="text" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['text']"
                    id="input-file "
                    :state="![null, undefined, ''].includes(config['text'])"
                  />
                </b-form-group>
              </b-col>
              <b-col>
              <b-form-group label="exec:" label-for="input-exec " invalid-feedback="exec" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['exec']"
                    id="input-file "
                    :state="![null, undefined, ''].includes(config['exec'])"
                  />
                </b-form-group>
              </b-col>
          </b-row>
          <b-row>
            
            <b-col>
              <b-form-group label="max-messages:" label-for="input-max-messages" invalid-feedback="max-messages" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['max-messages']"
                    id="input-file "
                    :state="![null, undefined, ''].includes(config['max-messages'])"
                  />
              </b-form-group>
            </b-col>
            <b-col>
            <b-form-group label="accepted-account:" label-for="input-accepted-account" invalid-feedback="accepted-account" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['accepted-account']"
                  id="input-file "
                  :state="![null, undefined, ''].includes(config['accepted-account'])"
                />
              </b-form-group>
            </b-col>
            
          </b-row>

          <b-row>
            
            <b-col>
              <b-form-group label="aliases:" label-for="input-aliases" invalid-feedback="aliases" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['aliases']"
                    id="input-aliases "
                    :state="![null, undefined, ''].includes(config['aliases'])"
                  />
              </b-form-group>
            </b-col>
            <b-col>
            <b-form-group label="name:" label-for="input-name" invalid-feedback="name" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['name']"
                  id="input-name"
                  :state="![null, undefined, ''].includes(config['name'])"
                />
              </b-form-group>
            </b-col>
            
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="header:" label-for="input-header" invalid-feedback="header" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['header']"
                    id="input-header"
                    :state="![null, undefined, ''].includes(config['header'])"
                  />
              </b-form-group>
            </b-col>
             <b-col>
              <b-form-group label="footer:" label-for="input-footer" invalid-feedback="footer" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['footer']"
                    id="input-footer"
                    :state="![null, undefined, ''].includes(config['footer'])"
                  />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="prefix:" label-for="input-prefix" invalid-feedback="prefix" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['prefix']"
                    id="input-prefix"
                    :state="![null, undefined, ''].includes(config['prefix'])"
                  />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="suffix:" label-for="input-suffix" invalid-feedback="suffix" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['suffix']"
                    id="input-suffix"
                    :state="![null, undefined, ''].includes(config['suffix'])"
                  />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="faked-sender:" label-for="input-faked-sender" invalid-feedback="faked-sender" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['faked-sender']"
                    id="input-faked-sender "
                    :state="![null, undefined, ''].includes(config['faked-sender'])"
                  />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="split-chars:" label-for="input-split-chars" invalid-feedback="split-chars" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['split-chars']"
                    id="input-split-chars"
                    :state="![null, undefined, ''].includes(config['split-chars'])"
                  />
              </b-form-group>
            </b-col>
             <b-col>
              <b-form-group label="split-suffix:" label-for="input-split-suffix" invalid-feedback="split-suffix" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['split-suffix']"
                    id="input-split-suffix"
                    :state="![null, undefined, ''].includes(config['split-suffix'])"
                  />
              </b-form-group>
            </b-col>
          </b-row>
           <b-row>
            <b-col>
              <b-form-group label="dlr-url:" label-for="input-dlr-url" invalid-feedback="dlr-url" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['dlr-url']"
                    id="input-dlr-url"
                    :state="![null, undefined, ''].includes(config['dlr-url'])"
                  />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="dlr-mask:" label-for="input-dlr-mask" invalid-feedback="dlr-mask" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="dlr-mask"
                    v-model="config['dlr-mask']"
                    id="input-dlr-mask"
                    :state="![null, undefined, ''].includes(config['dlr-mask'])"
                  />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="alt-charset:" label-for="input-alt-charset" invalid-feedback="alt-charset" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="alt-charset"
                    v-model="config['alt-charset']"
                    id="input-alt-charset"
                    :state="![null, undefined, ''].includes(config['alt-charset'])"
                  />
              </b-form-group>
            </b-col>
          </b-row>

          

          <b-button 
            class="p-0 w-100"
            style="font-size: 12px"
            variant="outline-primary"
            @click="boolsVisible = !boolsVisible">
              <b-icon v-if="!boolsVisible" icon="chevron-double-down"></b-icon> 
              <b-icon v-else icon="chevron-double-up" ></b-icon>
              Show Boolean fields
          </b-button>
          <b-collapse v-model="boolsVisible" class="mt-2">
            <b-row>
              <b-col>
                <b-form-group label="catch all:" label-for="input-catch-all" invalid-feedback="Catch all" style="text-align:left">
                <b-form-select
                      style="width:100% !important;"
                      v-model="config['catch-all']">
                        <template #first>
                          <b-form-select-option :value="null" disabled>Is Catch all</b-form-select-option>
                          <b-form-select-option :value="true">Yes</b-form-select-option>
                          <b-form-select-option :value="null">No</b-form-select-option>
                        </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="send-sender:" label-for="input-send-sender" invalid-feedback="send-sender" style="text-align:left">
                <b-form-select
                      style="width:100% !important;"
                      v-model="config['send-sender']">
                        <template #first>
                          <b-form-select-option :value="null" disabled>send-sender</b-form-select-option>
                          <b-form-select-option :value="true">Yes</b-form-select-option>
                          <b-form-select-option :value="null">No</b-form-select-option>

                        </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="strip-keyword:" label-for="input-strip-keyword" invalid-feedback="strip-keyword" style="text-align:left">
                <b-form-select
                      style="width:100% !important;"
                      v-model="config['strip-keyword']">
                        <template #first>
                          <b-form-select-option :value="null" disabled>strip-keyword</b-form-select-option>
                          <b-form-select-option :value="true">Yes</b-form-select-option>
                          <b-form-select-option :value="null">No</b-form-select-option>


                        </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="accept-x-kannel-headers:" label-for="input-accept-x-kannel-headers" invalid-feedback="accept-x-kannel-headers" style="text-align:left">
                <b-form-select
                      style="width:100% !important;"
                      v-model="config['accept-x-kannel-headers']">
                        <template #first>
                          <b-form-select-option :value="null" disabled>accept-x-kannel-headers</b-form-select-option>
                          <b-form-select-option :value="true">Yes</b-form-select-option>
                          <b-form-select-option :value="null">No</b-form-select-option>


                        </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="assume-plain-text:" label-for="input-assume-plain-text" invalid-feedback="assume-plain-text" style="text-align:left">
                <b-form-select
                      style="width:100% !important;"
                      v-model="config['assume-plain-text']">
                        <template #first>
                          <b-form-select-option :value="null" disabled>assume-plain-text</b-form-select-option>
                         <b-form-select-option :value="true">Yes</b-form-select-option>
                          <b-form-select-option :value="null">No</b-form-select-option>


                        </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="concatenation:" label-for="input-concatenation" invalid-feedback="concatenation" style="text-align:left">
                <b-form-select
                      style="width:100% !important;"
                      v-model="config['concatenation']">
                        <template #first>
                          <b-form-select-option :value="null" disabled>concatenation</b-form-select-option>
                          <b-form-select-option :value="true">Yes</b-form-select-option>
                          <b-form-select-option :value="null">No</b-form-select-option>


                        </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="omit-empty:" label-for="input-omit-empty" invalid-feedback="omit-empty" style="text-align:left">
                <b-form-select
                      style="width:100% !important;"
                      v-model="config['omit-empty']">
                        <template #first>
                          <b-form-select-option :value="null" disabled>omit-empty</b-form-select-option>
                          <b-form-select-option :value="true">Yes</b-form-select-option>
                          <b-form-select-option :value="null">No</b-form-select-option>

                        </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
            </b-row>
          </b-collapse>


          <b-button 
            class="p-0 w-100"
            style="font-size: 12px"
            variant="outline-primary"
            @click="regVisible = !regVisible">
              <b-icon v-if="!regVisible" icon="chevron-double-down"></b-icon> 
              <b-icon v-else icon="chevron-double-up" ></b-icon>
              Show Regex fields
          </b-button>
          <b-collapse v-model="regVisible" class="mt-2">
            <b-row>
              <b-col>
              <b-form-group label="accepted-smsc-regex:" label-for="input-smsc-regex" invalid-feedback="accepted-smsc-regex" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['accepted-smsc-regex']"
                    id="input-file "
                    :state="![null, undefined, ''].includes(config['accepted-smsc-regex'])"
                  />
                </b-form-group>
              </b-col>
              <b-col>
              <b-form-group label="accepted-account-regex:" label-for="input-accepted-account-regex" invalid-feedback="accepted-account-regex" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['accepted-account-regex']"
                    id="input-file "
                    :state="![null, undefined, ''].includes(config['accepted-account-regex'])"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="keyword regex:" label-for="input-keyword-regex" invalid-feedback="Keyword regex is required" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['keyword-regex']"
                    id="input-keyword-regex"
                    :state="![null, undefined, ''].includes(config['keyword-regex'])"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
              <b-form-group label="allowed-prefix-regex:" label-for="input-allowed-prefix-regex" invalid-feedback="allowed-prefix-regex" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['allowed-prefix-regex']"
                    id="input-file "
                    :state="![null, undefined, ''].includes(config['allowed-prefix-regex'])"
                  />
                </b-form-group>
              </b-col>
              <b-col>
              <b-form-group label="denied-prefix-regex:" label-for="input-denied-prefix-regex" invalid-feedback="denied-prefix-regex" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['denied-prefix-regex']"
                    id="input-file "
                    :state="![null, undefined, ''].includes(config['denied-prefix-regex'])"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="allowed-receiver-prefix-regex:" label-for="input-allowed-receiver-prefix-regex" invalid-feedback="allowed-receiver-prefix-regex invalid" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['allowed-receiver-prefix-regex']"
                    id="input-allowed-receiver-prefix-regex"
                    :state="![null, undefined, ''].includes(config['allowed-receiver-prefix-regex'])"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
              <b-form-group label="denied-receiver-prefix-regex:" label-for="input-denied-receiver-prefix-regex" invalid-feedback="denied-receiver-prefix-regex" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['denied-receiver-prefix-regex']"
                    id="input-file "
                    :state="![null, undefined, ''].includes(config['denied-receiver-prefix-regex'])"
                  />
                </b-form-group>
              </b-col>
              <b-col>
              <b-form-group label="white-list-regex:" label-for="input-white-list-regex" invalid-feedback="white-list-regex" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['white-list-regex']"
                    id="input-file "
                    :state="![null, undefined, ''].includes(config['white-list-regex'])"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="black-list-regex:" label-for="black-list-regex" invalid-feedback="black-list-regex invalid" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['black-list-regex']"
                    id="input-black-list-regex"
                    :state="![null, undefined, ''].includes(config['black-list-regexx'])"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-collapse>

           <b-button 
            class="p-0 w-100"
            style="font-size: 12px"
            variant="outline-primary"
            @click="prefVisible = !prefVisible">
              <b-icon v-if="!prefVisible" icon="chevron-double-down"></b-icon> 
              <b-icon v-else icon="chevron-double-up" ></b-icon>
              Show Prefixes fields
          </b-button>
          <b-collapse v-model="prefVisible" class="mt-2">
           <b-row>
            
            <b-col>
              <b-form-group label="allowed-prefix:" label-for="input-allowed-prefix" invalid-feedback="allowed-prefix" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['allowed-prefix']"
                    id="input-allowed-prefix"
                    :state="![null, undefined, ''].includes(config['allowed-prefix'])"
                  />
              </b-form-group>
            </b-col>
            <b-col>
            <b-form-group label="denied-prefix:" label-for="input-denied-prefix" invalid-feedback="denied-prefix" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['denied-prefix']"
                  id="input-denied-prefix"
                  :state="![null, undefined, ''].includes(config['denied-prefix'])"
                />
              </b-form-group>
            </b-col>
            
            </b-row>
            <b-row>
            
            <b-col>
              <b-form-group label="allowed-allowed-receiver-prefix:" label-for="input-allowed-allowed-receiver-prefix" invalid-feedback="allowed-receiver-prefix invalid" style="text-align:left">
                  <b-form-input
                    size="sm"
                    placeholder="Required"
                    v-model="config['allowed-receiver-prefix']"
                    id="input-allowed-receiver-prefix"
                    :state="![null, undefined, ''].includes(config['allowed-receiver-prefix'])"
                  />
              </b-form-group>
            </b-col>
            <b-col>
            <b-form-group label="denied-receiver-prefix:" label-for="input-denied-receiver-prefix" invalid-feedback="denied-receiver-prefix" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['denied-receiver-prefix']"
                  id="input-denied-receiver-prefix"
                  :state="![null, undefined, ''].includes(config['denied-receiver-prefix'])"
                />
              </b-form-group>
            </b-col>
            
            </b-row>
          </b-collapse>

          <ConfigIncludes :includes="includes" />

          <br />

          <b-row>
            <b-button-group>
              <b-button variant="outline-danger" @click="() => onClose()">Close</b-button>
              <b-button type="submit" variant="outline-primary">Submit</b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="5">
        <ConfigPreview :config="config" :includes="includes" />
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
  import ConfigPreview from '../../Common/Config/ConfigPreview.vue'
  import ConfigIncludes from '../../Common/Config/ConfigIncludes.vue'

  export default {
    components: {
      ConfigPreview,
      ConfigIncludes
    },
    props: ['params', 'onSubmit', 'onClose'],
    methods: {
      isValidURL(url) {
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
        return urlRegex.test(url);
      }
    },
    data () {
      return {
        boolsVisible: false,
        regVisible: false,
        prefVisible: false,
        checkboxSubmitType: null,
        submitTypeOptions: [
          { text: 'GET', value: 'GET' },
          { text: 'POST', value: 'POST' },
          { text: 'XML', value: 'XML' }
        ],
        tag: '',
        config: {
          'keyword': 'default',
          'keyword-regex': null,
          'aliases': null,
          'name': null,
          'get-url': null,
          'post-url': null,
          'post-xml': null,
          'file': null,
          'text': null,
          'exec': null,
          'accepted-smsc': null,
          'accepted-account': null,
          'allowed-prefix': null,
          'denied-prefix': null,
          'allowed-receiver-prefix': null,
          'denied-receiver-prefix': null,
          'catch-all': null,
          'send-sender': null,
          'strip-keyword': null,
          'faked-sender': null,
          'max-messages': null,
          'accept-x-kannel-headers': null,
          'assume-plain-text': null,
          'concatenation': null,
          'split-chars': null,
          'split-suffix': null,
          'omit-empty': null,
          'header': null,
          'footer': null,
          'prefix': null,
          'suffix': null,
          'white-list': null,
          'black-list': null,
          'accepted-smsc-regex': null,
          'accepted-account-regex': null,
          'allowed-prefix-regex':null,
          'denied-prefix-regex':null,
          'allowed-receiver-prefix-regex':null,
          'denied-receiver-prefix-regex':null,
          'white-list-regex':null,
          'black-list-regex':null,
          'alt-charset':null,
          'dlr-url':null,
          'dlr-mask':null,
        },
        includes: []
      }
    },
    created () {
      this.tag = this.params.tag
      this.includes = this.params.includes
      this.config = { ...this.config, ...this.params.config }
    },
  }
</script>

<style>
  .row {
    margin: 10px;
  }
</style>
