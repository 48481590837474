<template>
    <b-container fluid="sm" class="p-0">
        <b>Soft actions:</b>
        <b-button-group class="w-100">
            <b-button 
                :disabled="!bbox"
                v-for="button in buttonsGroup1" :key="button.action"
                :variant="button.variant"
                v-b-tooltip.hover
                :title="button.tooltip"
                style="width: calc(100% / 5)"   
                @click="action(button.action)">
                    
                    <span class="d-inline">
                        <b-icon :icon="button.icon" aria-hidden="true"></b-icon>
                    </span>&nbsp;
                    <span class="d-none d-sm-inline">{{button.text}}</span>
            </b-button>
        </b-button-group>

        <b>Hard actions:</b>
        
        <b-button-group class="w-100">
            <b-button 
                v-for="button in buttonsGroup2" :key="button.action"
                :variant="button.variant"
                style="width: calc(100% / 4)"
                v-b-tooltip.hover
                :title="button.tooltip"
                :disabled="!bbox || button.bbBoxStatus"
                @click="action(button.action)">
                    
                    <span class="d-inline">
                        <b-icon :icon="button.icon" aria-hidden="true"></b-icon>
                    </span>&nbsp;
                    <span class="d-none d-sm-inline">{{button.text}}</span>
            </b-button>
        </b-button-group>

    </b-container>
</template>

<script>
export default {
    props:{
        data: {
            type: Object
        },
        bbox: {
            type: Object
        }
    },
    watch:{
        'data': {
            handler: function (nv) {
                const bbBoxStatus = !!(nv && nv.kannelSystemInfo.status.match(/running/g))
                this.buttonsGroup2 =[...this.buttonsGroup2].map(btn => {
                    if('bbBoxStatus' in btn) btn.bbBoxStatus = bbBoxStatus
                    return btn
                })
            },
            deep: true
        }
    },
    methods:{
        isDisabled(disabledBy){
            return !this[disabledBy]
        },
        async action(action){
            if(!this.bbox) return

            if(action == 'fetch'){
                return this.$emit('refresh', true)
            }
            
            const res = await this.$http.post(`/api/gateway/wide/${action}`, this.bbox.config)
            if(res && res.status == 200){
                if(res.data.toUpperCase() == 'OK') this.$emit('refresh', true)
            }
        }
    },
    data(){
        return {
            
            buttonsGroup1: [
                {
                    action: 'suspend',
                    variant: 'danger',
                    icon: 'stop',
                    title: 'Suspend bearerbox',
                    text: 'Suspend bearerbox',
                    tooltip: 'Suspend bearerbox, mandatory to flush DLR storage'
                },
                {
                    action: 'resume',
                    variant: 'success',
                    icon: 'play',
                    title: 'Resume bearerbox',
                    text:  'Resume bearerbox',
                    tooltip: 'Resume bearerbox'
                },
                
                {
                    action: 'graceful-restart',
                    variant: 'warning',
                    icon: 'bootstrap-reboot',
                    title: "Restart bearerbox, Only the changes in the configuration for the SMSC connections are performed",
                    text: 'Gracefull Restart',
                    tooltip: 'Gracefull Restart bearerbox'

                },
                {
                    action: 'isolate',
                    variant: 'primary',
                    icon: 'bezier2',
                    title: "Isolate bearerbox",
                    text: "Isolate bearerbox" ,
                    tooltip: 'Isolate bearerbox'
                },
                {
                    action: 'reload-lists',
                    variant: 'primary',
                    icon: 'upload',
                    title:"Re-loads the 'white-list' and 'black-list' in the core group",
                    text: "Reload lists",
                    tooltip: 'Reload lists bearerbox' 
                },
                
            ],
            buttonsGroup2: [
                {
                    action: 'restart',
                    variant: 'warning',
                    icon: 'bootstrap-reboot',
                    title: 'Restart bearerbox',
                    text: 'Restart bearerbox',
                    tooltip: 'Restart bearerbox'
                },
                {
                    action: 'shutdown',
                    variant: 'danger',
                    icon: 'stop',
                    title: "Shutdown bearerbox" ,
                    text: 'Shutdown bearerbox',
                    tooltip: 'Shutdown bearerbox'
                },
                
                {
                    action: 'flush-dlr',
                    bbBoxStatus: true,
                    variant: 'primary',
                    icon: 'trash2',
                    title: "Flush DLR storage", 
                    text: "Flush DLR storage",
                    tooltip: 'Flush DLR storage, Suspend bearerbox first' 
                },
                
                {
                    action: 'fetch',
                    variant: 'primary',
                    icon: 'recycle',
                    title:"Refresh tables",
                    text: "Refresh tables",
                    tooltip: 'Refresh tables'  
                }
            ]
        }
    }
}
</script>

<style>

</style>