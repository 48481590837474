<template>
  <b-modal
      id="add-user-modal"
      ref="add-user-modal"
      title="Add user access"
      size="lg"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      @shown="getUuids()"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row>
          <b-col lg="6">
             <b-form-group 
              label="Select an option"
              label-for="myInput"
              :state="form.user_account_uuid != null ? true : false"
              :invalid-feedback="form.user_account_uuid ? null : 'Please select an option'">
              <multiselect
                id="myInput"
                v-model="form.user_account_uuid"
                required
                :options="uuids"
                :multiple="false"
                :allow-empty="false"
                track-by="value"
                label="text"
              ></multiselect>
            </b-form-group>
            
          </b-col>
          <b-col lg="6">
            <b-form-group 
              label="Select an option"
              label-for="myInput"
              :state="form.user_role != null ? true : false"
              :invalid-feedback="form.user_role ? null : 'Please select an option'">
                <b-form-select v-model="form.user_role" class="mb-3 w-100" disabled>
                  <b-form-select-option :value="null">Please select an option</b-form-select-option>
                  <b-form-select-option value="sms-client">SMS Client</b-form-select-option>
                </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="input-group-1"
              label="Auth by:"
              label-for="input-1"
              :state="form.user_subscribe_by != null">
                <b-form-input
                  id="input-1"
                  v-model="form.user_subscribe_by"
                  disabled
                  type="text"
                  placeholder="Auth by"
                  required
                  :state="form.user_subscribe_by != null">
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group 
              
              label="User is a developer"
              label-for="myInput"
              :state="form.user_is_developer != null ? true : false"
              :invalid-feedback="form.user_is_developer ? null : 'Please select an option'">
                <b-form-select required v-model="form.user_is_developer" class="mb-3 w-100">
                  <b-form-select-option :value="null">Please select an option</b-form-select-option>
                  <b-form-select-option :value="true">Yes</b-form-select-option>
                  <b-form-select-option :value="false">No</b-form-select-option>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group 
              id="input-group-2" 
              label="Email address:" 
              label-for="input-2"
              :state="form.user_email != null">
                  <b-form-input
                  :state="form.user_email != null"
                  id="input-2"
                  v-model="form.user_email"
                  placeholder="Enter email"
                  type="email"
                  required
                  ></b-form-input>
              </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group 
            id="input-group-3" label="First Name:" label-for="input-3"
            :state="form.user_first_name != null">
                <b-form-input
                :state="form.user_first_name != null"
                id="input-3"
                v-model="form.user_first_name"
                placeholder="First name"
                required
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
             <b-form-group id="input-group-4" label="Last Name:" label-for="input-4">
                  <b-form-input
                  id="input-4"
                  v-model="form.user_last_name"
                  placeholder="Last name"
                  ></b-form-input>
              </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="input-group-5" label="Phone number:" label-for="input-5">
                <b-form-input
                id="input-5"
                type="tel"
                v-model="form.user_phone_number"
                placeholder="Phone number"
                ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
             <b-form-group id="input-group-6" label="Skype ID:" label-for="input-6">
                  <b-form-input
                  id="input-6"
                  type="text"
                  v-model="form.user_skype_id"
                  placeholder="Skype id"
                  ></b-form-input>
              </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
    components:{
      Multiselect
    },
    props:['uuids'],
    data() {
      return {
        form: {
          user_account_uuid:  { "text": "bNjFqHBQSFSUiMNTxQtzSm", "value": "bNjFqHBQSFSUiMNTxQtzSm" },
          user_subscribe_by : "ADMIN",
          user_first_name : 'blah',
          user_last_name : null,
          user_phone_number : null,
          user_skype_id : null,
          user_email: 'blah@blah.com',
          user_role: 'sms-client',
          user_is_developer: null,
          user_confirm_is_developer: true
        },
        multiselect: null        
      }
    },
    methods: {
      async getUuids(){
        
      },
      checkFormValidity() {
        const valid = this.form.user_account_uuid != null && this.$refs.form.checkValidity()
        return valid
      },
      resetModal() {
        this.form = {
          /*
          user_account_uuid: null,
          user_subscribe_by : "ADMIN",
          user_first_name : null,
          user_last_name : null,
          user_phone_number : null,
          user_skype_id : null,
          user_email: null
          */
          user_account_uuid:  { "text": "bNjFqHBQSFSUiMNTxQtzSm", "value": "bNjFqHBQSFSUiMNTxQtzSm" },
          user_subscribe_by : "ADMIN",
          user_first_name : 'blah',
          user_last_name : null,
          user_phone_number : null,
          user_skype_id : null,
          user_email: 'blah@blah.com',
          user_role: 'sms-client',
          user_is_developer: null,
          user_confirm_is_developer: true


          


        }
      },
      handleOk(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      async handleSubmit() {
        
        
        if (!this.checkFormValidity()) {
          return
        }
        this.form.user_account_uuid = this.form.user_account_uuid.value
        
        const res = await this.$http.post('/api/auth/users/create-local-from-admin', this.form)
        if(res.status == 200){  
          this.$parent.usersData.push(this.form)
        }
        
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('add-user-modal')
        })
      }
    }
}
</script>

<style>

</style>