<template>
    <b-container>
        <b-row>
            <b-col sm="12" lg="6">
                <b-form-group 
                label="Please choose a bbox to view its status"
                style="text-align:left">
                    <b-form-select required v-model="bbox" size="xs" class="w-100" @input="fetch">
                        <b-form-select-option :value="null">Please select a bearerbox</b-form-select-option>
                        <b-form-select-option 
                        v-for="box in bboxes" :key="box.uid"
                        :value="box">{{box.tag}} {{box.id}} {{box.config ? box.config['admin-port'] : ''}}</b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <h3 class="text-right">{{ `${bbox ? `${bbox.tag}` : ""} Bearerbox Dashboard` }}</h3>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <StatusTiles 
                    :data="data"
                    :bbox="bbox"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <Tiles 
                    :data="data"
                    :bbox="bbox"
                    :smsMtTilesData="smsMtTilesData" 
                    :smsMoTilesData="smsMoTilesData" 
                    :dlrTilesData="dlrTilesData" 
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <Buttons :data="data" :bbox="bbox" @refresh="fetch"/>
            </b-col>
        </b-row>

        <base-card>
            <template #header>
                Monitor SMSC And Box Connections
            </template>
            <div slot="body" class="p-0">
                <b-tabs>
                    <b-tab title="SMSc Connections">
                        <SmscTbl :rows="smscRows" :bbox="bbox" @refresh="fetch"/>
                    </b-tab>
                    <b-tab title="Box Connections" >
                        <BoxTbl :rows="boxesRows" :bbox="bbox"/>
                    </b-tab>
                </b-tabs>
            </div>
        
    </base-card>
        
        
    </b-container>
    
</template>

<script>
import BaseCard from '@/components/Base/card.base.vue'
import Tiles from './Tiles.vue'
import StatusTiles from './StatusTiles.vue'
import Buttons from './Buttons.vue'
import SmscTbl from './Tables/SmscTable.vue'
import BoxTbl from './Tables/BoxTable.vue'


export default {
    components:{
        BaseCard,
        Tiles,
        StatusTiles,
        Buttons,
        SmscTbl,
        BoxTbl,
    },
    mounted () {
        this.boxes()
    },
    methods:{

        async fetch () {
            this.smscRows = []
            this.boxesRows = []
            
            if(!this.bbox){
                this.data = null
                return
            }

            

            let res = await this.$http.post('/api/gateway/status/read', this.bbox.config)
            if(res && res.status == 200){

                this.data = res.data[0]
               
               this.smsMtTilesData = {
                    labels: ['Sent', 'Queded'],
                    datasets: [
                        {
                            backgroundColor: ['#41B883', '#E46651'],
                            data: [
                                this.data.kannelStatistics.SMS.sent.total, 
                                this.data.kannelStatistics.SMS.sent.queued
                            ]
                        }
                    ]
                }

                this.smsMoTilesData = {
                    labels: ['Sent', 'Queded'],
                    datasets: [
                        {
                            backgroundColor: ['#41B883', '#E46651'],
                            data: [
                                this.data.kannelStatistics.SMS.received.total, 
                                this.data.kannelStatistics.SMS.received.queued
                            ]
                        }
                    ]
                }

                this.dlrTilesData = {
                    labels: ['Received','Queded', 'Sent'],
                    datasets: [
                        {
                            backgroundColor: ['#007bff',  '#E46651', '#41B883',],
                            data: [
                                this.data.kannelStatistics.DLR.received.total , 
                                this.data.kannelStatistics.DLR.queued,
                                this.data.kannelStatistics.DLR.sent.total
                                
                            ]
                        }
                    ]
                }
                this.boxesRows = []
                this.smscRows = []
                this.smscRows = this.data.kannelSMSCStatistics.smsc
                if(this.data.kannelStatistics.BOXES){
                    this.boxesRows = Array.isArray(this.data.kannelStatistics.BOXES) ? 
                        this.data.kannelStatistics.BOXES : [this.data.kannelStatistics.BOXES]
                }
               
               
            }
        },
        async boxes(){
            const res = await this.$http.get(`/api/gateway/groups/bearerbox/list`)
            if(res && res.status == 200){
                this.bboxes = res.data
            }
        },
    },
    data(){
        return {
            smscRows: [],
            boxesRows: [],
            bbox: null,
            data: null,
            dlrTilesData: {
                //labels: ['Sent', 'Queded', 'queued'],
                datasets: [
                    {
                        backgroundColor: ['#41B883', '#E46651', '#E46651'],
                        data: [0, 0, 0]
                    }
                ]
            },
            smsMoTilesData: {
                //labels: ['Sent', 'Queded'],
                datasets: [
                    {
                        backgroundColor: ['#41B883', '#E46651'],
                        data: [0, 0]
                    }
                ]
            },
            smsMtTilesData: {
                //labels: ['Sent', 'Queded'],
                datasets: [
                    {
                        backgroundColor: ['#41B883', '#E46651'],
                        data: [0, 0]
                    }
                ]
            },
            bboxes: []
        }
    }
}
</script>

<style>

</style>