<template>
  <vue-good-table
    theme="polar-bear"
    :columns="columns" 
    :rows="rows" 
    :line-numbers="false"
    :pagination-options="paginationOptions"
    :search-options="searchOptions"
    :select-options="selectOptions">
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'status'">
          <span class="status-btn"
                  size="sm"
              >
                  <div class="text-left">
                      <b-icon 
                      v-if="props.row.status.match(/online/)"
                      class="text-success"
                      icon="check2-circle"></b-icon> 

                      <b-icon 
                      v-if="!props.row.status.match(/online/)"
                      class="text-danger"
                      icon="exclamation-triangle"></b-icon> 
                      {{props.row.status}}

                  </div>
              </span>
        </span>
        <span v-else-if="props.column.field == 'existsOnFile'">
          <span class="status-btn"
                  size="sm"
              >
                  <div class="text-center">
                      <b-icon v-if="props.row.existsOnFile" icon="check-all" class="text-success"></b-icon> 
                      <b-icon v-if="!props.row.existsOnFile" icon="exclamation-triangle" class="text-danger"></b-icon> 
                  </div>
              </span>
        </span>
        <span v-else-if="props.column.field == 'sms'">
          <span class="status-btn"
                  size="sm"
              >
                  <div class="text-left">
                      <send-icon size="1.0x" class="text-success"></send-icon> 
                      {{props.row.sms.sent}}
                      <inbox-icon size="1.0x" class="text-success"></inbox-icon> 
                      {{props.row.sms.received}}
                  </div>
              </span>
        </span>
        <span v-else-if="props.column.field == 'dlr'">
          <span class="status-btn"
                  size="sm"
              >
                  <div class="text-left">
                      <send-icon size="1.0x" class="text-success"></send-icon> 
                      {{props.row.dlr.sent}}
                      <inbox-icon size="1.0x" class="text-success"></inbox-icon> 
                      {{props.row.dlr.received}}
                  </div>
              </span>
        </span>
        
        <span v-else-if="props.column.field == 'options'">
          
          <span v-if="props.row.status=='loading'">
            <div class="text-center">
              <b-spinner label="Spinning"></b-spinner>
            </div>

          </span>
          <b-button-group v-else-if="props.row.status != 'removed'">
              <b-button 
                v-if="!props.row.status.match(/connecting|online/)"
                  @click="action(props.row, 'start')"
                  v-b-tooltip.hover title="Start SMSC"
                  variant="success" size="sm">
                  <b-icon icon="play"></b-icon>
              </b-button>
              
              <b-button 
                  v-if="!props.row.status.match(/dead|failed/)"
                  @click="action(props.row, 'stop')"
                  v-b-tooltip.hover title="Stop SMSC"
                  variant="outline-warning" size="sm">
                  <b-icon icon="stop"></b-icon> 
              </b-button>
              <b-button 
                  @click="action(props.row, 'remove')"
                  v-b-tooltip.hover title="Remove SMSC"
                  variant="outline-danger" size="sm">
                  <b-icon icon="cloud-minus-fill"></b-icon> 
              </b-button>
          </b-button-group>
        </span>
    </template>
  </vue-good-table>
</template>

<script>
import paginationOptions from './paginationOptions.json'
import searchOptions from './searchOptions.json'
import { SendIcon, InboxIcon } from 'vue-feather-icons'

export default {
  components:{
    
    SendIcon,
    InboxIcon,
    
  },
  methods:{
    async action(rowData, action){
      const res = await this.$http.post(`/api/gateway/smsc/${action}`, {
        smsc: rowData['admin-id'],
        'admin-port': this.bbox.config['admin-port'],
        'status-password': this.bbox.config['admin-password'],
      })

      if(res && res.status == 200){
        if(res.data.toUpperCase() == 'OK') this.$emit('refresh', true)
      }
    }
  },
  props: ['rows', 'bbox'],
  data() {
    return {
      
      paginationOptions: paginationOptions,
      searchOptions: searchOptions,
      selectOptions: { enabled: false },
      columns:[
          { field: 'id', label: 'Alias',
            filterOptions: {
                enabled: true,
                placeholder: 'Filter Alias'
            }
          },
          { field: 'admin-id', label: 'Admin id',
            filterOptions: {
                enabled: true,
                placeholder: 'Filter Admin id'
            }
           },
          { field: 'status', label: 'Status',
          filterOptions: {
                enabled: true,
                placeholder: 'Filter Status'
            }
           },
           /*
          { field: 'existsOnFile', label: 'Synced' },
          */
          { field: 'sms', label: 'SMS Out/In' },
          { field: 'dlr', label: 'DLR Out/In' },
          { field: 'options', label: 'Options' },
      ]
    }
  }
}
</script>

<style>
  
</style>