<template>
<div>
    
  <vue-good-table
        :columns="table.fields" 
        :pagination-options="table.paginationOptions"
        :search-options="table.searchOptions"
        :rows="rows">
        
        <template slot="table-row" slot-scope="props">   
            <span v-if="props.column.field == 'proforma_invoice_generated_by'">
                <span v-if="props.row.proforma_invoice_generated_by == 'BFFA'">Admin</span>
                <span v-else>Client</span>
            </span>    
            <span  v-else-if="props.column.field == 'options'">
                <b-button-group>
                    <b-button 
                        v-if="props.row.proforma_invoice_wave_invoice_status == 'DRAFT'"
                        v-b-tooltip.hover title="Approve Draft"
                        variant="outline-success" 
                        @click="approve(props.row)" >
                        <b-icon icon="check"></b-icon>
                    </b-button>  
                    <b-button 
                        v-if="props.row.proforma_invoice_wave_invoice_status == 'DRAFT'"
                        v-b-tooltip.hover title="Delete Draft"
                        variant="outline-danger" 
                        @click="remove(props.row, props.index)" >
                        <b-icon icon="trash"></b-icon>
                    </b-button> 
                    <b-button 
                        v-if="props.row.proforma_invoice_wave_invoice_status == 'SAVED'"
                        v-b-tooltip.hover title="Add Payment"
                        variant="outline-success" 
                        @click="history(props.row)" >
                        <b-icon icon="file-earmark-medical" ></b-icon>
                    </b-button> 
                    <b-button 
                        v-b-tooltip.hover title="Send to client"
                        variant="outline-success" 
                        @click="send(props.row)" >
                        <b-icon icon="envelope"></b-icon>
                    </b-button> 
                    <b-button 
                        v-b-tooltip.hover title="View Invoice"
                        variant="outline-primary" 
                        @click="view(props.row)" >
                        <b-icon icon="search"></b-icon>
                    </b-button> 
                    <b-button 
                        v-if="/PROFORMA/.test(props.row.proforma_invoice_wave_invoice_status)"
                        v-b-tooltip.hover title="Create draft from proforma"
                        variant="outline-success" 
                        @click="create(props.row)" >
                        <b-icon icon="file-earmark-arrow-up"></b-icon>
                    </b-button>
                </b-button-group> 
            </span>
        </template>
    </vue-good-table>
    <ViewInvoiceModal :readOnlyData="data"/>
</div>    

</template>

<script>
import ViewInvoiceModal from '../../Invoice/CreateInvoice.vue'
const table = require('./Table/tbl')
export default {
    components: {
        ViewInvoiceModal
    },
    props:{
        rows: {
            Type: Array,
            default: () => []
        }
    },
    methods:{
        async confirm(action){
            return await this.$bvModal.msgBoxConfirm(`Are you sure you whish to ${action}?`)
        },
        async approve(rowData){
            
            if(!await this.confirm('approve')) return

            const res = await this.$http.post( '/api/payment/invoice/mutations/invoiceApprove', {
                details: rowData
            } )
            if(res && res.status == 200){
                rowData.proforma_invoice_wave_invoice_status = res.data.proforma_invoice_wave_invoice_status
            }
        },
        async create(rowData){
            
            if(!await this.confirm('approve')) return
    
            const res = await this.$http.post( '/api/payment/invoice/mutations/invoiceCreate', {
                details: rowData
            } )

            if(res && res.status == 200){
                rowData.proforma_invoice_wave_invoice_status = res.data.proforma_invoice_wave_invoice_status
            }
    
        },
        async remove(rowData, index){

            if(!await this.confirm('remove')) return

            const res = await this.$http.post( '/api/payment/invoice/mutations/invoiceDelete', {
                details: rowData
            } )
            if(res && res.status == 200){
                if(res.data.status) this.rows.splice(index,1)
            }
        },
        async history(rowData){
            
            if(!await this.confirm('add payment')) return

            const res = await this.$http.post( '/api/payment/invoice/history', {
                details: rowData
            } )
            if(res && res.status == 200){
                rowData.proforma_invoice_wave_invoice_status = res.data.proforma_invoice_wave_invoice_status
            }
        },
        async send(rowData){
            if(!await this.confirm('send to client')) return
            const type = rowData.proforma_invoice_wave_invoice_status == 'PROFORMA' ? 'proforma' : 'invoice'
            const res = await this.$http.post( `/api/payment/${type}/send`, rowData )
            if(res && res.status == 200){
                if(res.data.status)
                    console.log('sent')
                    //this.confirm('Invoice sent')

            }
            
        },
        async view(rowData){
            await this.$bvModal.show('modal-new-invoice')
            this.data = rowData
        }
    },
    data(){
        return {
            table: table.settings,
            data: {}
        }
    }
}
</script>

<style>

</style>