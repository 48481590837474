import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store.js'

import AppLayout from '../components/Layout/View.vue'
import home from './home.js';
import gateway from './gateway.js';
//import tokens from './tokens.js';
//import clients from './clients.js';
//import providers from './providers.js';
import login from './login.js';
//import delify from './delify.js';
import routing from './routing.js';
//import accounts from './accounts.js';
import finance from './finance.js';
import statistics from './statistics.js';
import settings from './settings.js';
import notifications from './notifications.js';
import media from './media.js';
//import dashboard from './dashboard.js';



const children = [
  home,
  //dashboard,
  gateway,
  media,
  //tokens, // this is loaded in clients js for dual pupose use
  //clients, // this is loaded in clients js for dual pupose use
  //providers, // this is loaded in clients js for dual pupose use
  login,
  //delify,
  routing,
  //accounts,
  finance,
  statistics,
  settings,
  notifications
]


const routes = [
  login,
  {
    path: '/', 
    redirect: '/home',
    component: AppLayout,
    meta: {
      requiresAuth: true
    },
    children: children
  },
  { path: '*', redirect: '/home' }
]

Vue.use(Router)

let router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes: routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    
    next('/login')
  } else {
    next()
  }
})


export default router
