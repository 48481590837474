<template>
<div>
  <b-row>
      <b-col lg="12" sm="12" md="12">
        <filter-area @filterData="loadData"/>
      </b-col>
  </b-row>
  
  
  <br>
  
  <br>
  <b-row>
      <b-col lg="12" sm="12" md="12">
        <Table :rows="rows" :FilteredtData="[]"/>
      </b-col>
  </b-row>
</div>
</template>

<script>
import FilterArea from './Filter.vue'
import Table from './Table.vue'
import moment from 'moment'

export default {
  components: {
    FilterArea,
    Table
  },
  watch:{
    //$route (to, from){
      $route (){
         if(this.$router.currentRoute.path.split(/\//)[2].toUpperCase() == 'HLR'){
            this.showSmsLayout = false
          }else{
            this.showSmsLayout = true
          }
    }
  },
  created () {
   if(this.$router.currentRoute.path.split(/\//)[2].toUpperCase() == 'HLR'){
      this.showSmsLayout = false
    }else{
      this.showSmsLayout = true
    }
    
  },
  data(){
    return {
      rows:[],
      startDate: String((new Date()).getDate()).padStart(2, '0') ,
      endDate: new Date(),
      queryData:{
        filterData: {
          period_start: moment(this.startDate).format("YYYY-MM-DD"),
          period_end: moment(this.endDate).format("YYYY-MM-DD"),
          current_dlr_mask: null,
          mccmnc: null,
          country_code: null,
          client_account: null,
          client_accounting_token: null,
          smsc_id: null
        },
        groupBy: 'send_sms_mt_country_name'
      },
      

    }
  },
  methods: {
    loadDataFromTable(data){
      this.queryData.groupBy = data
      this.fieldsBy = 'standard'
      if(data == 'send_sms_mt_operator_name'){
        this.fieldsBy = 'operator'
      }
      
      this.loadData()// call without passing data
    },
    async loadData(data){
      const res = await this.$http.post('/api/analyticss/mo/read', data)
      if(res && res.status == 200){
        this.rows = res.data
      }

      //
      /*
      if(data){
        this.queryData.filterData = data
      }
      */

      
      //let DataSource = this.$router.currentRoute.path.split(/\//)[2].toUpperCase() == 'HLR' ? 'hlr' : 'sms'
      
      /*
      this.loading = true
      this.$http.post('/api/analytics/' + DataSource, this.queryData)
      .then((res)=>{
        this.loading = false
        if(DataSource == 'hlr'){
          this.hlrData = res.data
        }else{
          this.smsData = res.data
        }
        
      })
      */
      
      
    }
  },

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}



.dashbaord{
  text-align:left;
}
</style>
