import View  from './../components/Routing/Layout.vue'
import Nodes from './../components/BboxDshboard/Layout.vue'
import GroupsLayout from '../components/Groups/Layout.vue'

export default {
    path: 'gateway',
    name: 'gateway',
    component: View,
    redirect: 'gateway/dashboard',
    children:[
        {
            path: 'dashboard',
            name: 'dashboard',
            component: Nodes
        },
        {
            path: 'groups',
            name: 'groups',
            component: GroupsLayout
        }   
    ]
}