<template>
  <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand href="#" class="p-2 m-1">
        <span style="font-family:Tahoma">
          Back-Office
        </span>        
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>


        <b-navbar-nav class="p-2">
          <!--
            No children
            <b-nav-item  
              class="a-nav-item"
              to="/home">
              Home
            </b-nav-item>

            Children
            <b-nav-item-dropdown text="Gateway" right>
              <b-dropdown-item href="#">
                <b-nav-item  
                  class="a-nav-item"
                  to="/nodes">
                  Overview
                </b-nav-item>
              </b-dropdown-item>
              <b-dropdown-item href="#">
                <b-nav-item  
                  class="a-nav-item"
                  to="/groups">
                  Groups
                </b-nav-item>
              </b-dropdown-item>
                
            </b-nav-item-dropdown>
          -->          
          <div v-for="(data, index) in items" :key="index">
            <div v-if="data.children">
                <b-nav-item-dropdown  :text="data.name.charAt(0).toUpperCase() + data.name.slice(1)" right>
                  <div v-for="(child, childindex) in data.children" :key="childindex" >
                    <b-dropdown-item v-if="!/\:/.test(child.path)">
                      <b-nav-item        
                        class="a-nav-item"
                        :to="`/${data.path}/${child.path}`">
                        {{child.name.charAt(0).toUpperCase()}}{{child.name.slice(1)}}
                      </b-nav-item>
                    </b-dropdown-item>
                  </div>
                </b-nav-item-dropdown>
            </div>

            <b-nav-item  
              v-else
              class="a-nav-item"
              :to="`/${data.path}`">
              {{data.path.charAt(0).toUpperCase()}}{{data.path.slice(1)}}
            </b-nav-item>
          </div>
        </b-navbar-nav>
        <b-navbar-nav style="margin-left:auto">

          <b-nav-item-dropdown right class="p-2">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>User</em>
            </template>
            <b-dropdown-item href="#" @click=logout()>Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
  </b-navbar>
</template>
<script>
export default {
  props:['items']
}
</script>
