<template>
    <div>
        
        <vue-good-table
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="fields" 
        :search-options="searchOptions"
        :pagination-options="paginationOptions"
        :rows="rows">
        <template  slot="table-row" slot-scope="props"  >
            <span v-if="props.column.field == 'notifications_users_email'" class="class-bg-white">
                {{props.row.notifications_users_email}}
            </span>
            <span v-else-if="props.column.field == 'notifications_users_uuid'" class="class-bg-white">
                {{props.row.notifications_users_uuid}}
            </span>
            
            <span v-else-if="props.column.field == 'options'" class="class-bg-white">
                <b-spinner label="Spinning" v-if="props.row.testing"></b-spinner>
                <b-button-group>
                    <b-button size="sm" @click="saveChanges(props.row)" variant="outline-success" v-b-tooltip.hover title="Save changes">
                        <b-icon icon="save" size="sm"></b-icon>
                        
                    </b-button>
                    <b-button size="sm" @click="sendTest(props)" variant="outline-primary" v-b-tooltip.hover title="Send test message">
                        <b-icon icon="envelope" size="sm"></b-icon>
                    </b-button>
                </b-button-group>
            </span>
            <div @click="toggleNotification(props.row, props.column)">
                <span v-if="props.column.field == 'notifications_users_low_balance'" class="class-bg-white">
                    <b-icon icon="square" v-if="!props.row.notifications_users_low_balance"></b-icon>
                    <b-icon icon="check-square" v-if="props.row.notifications_users_low_balance"></b-icon>
                </span>
                <span v-else-if="props.column.field == 'notifications_users_price_changes'" class="class-bg-white">
                    <b-icon icon="square" v-if="!props.row.notifications_users_price_changes"></b-icon>
                    <b-icon icon="check-square" v-if="props.row.notifications_users_price_changes"></b-icon>
                </span>
                <span v-else-if="props.column.field == 'notifications_users_product'" class="class-bg-white">
                    <b-icon icon="square" v-if="!props.row.notifications_users_product"></b-icon>
                    <b-icon icon="check-square" v-if="props.row.notifications_users_product"></b-icon>
                </span>
                <span v-else-if="props.column.field == 'notifications_users_maintenance'" class="class-bg-white">
                    <b-icon icon="square" v-if="!props.row.notifications_users_maintenance"></b-icon>
                    <b-icon icon="check-square" v-if="props.row.notifications_users_maintenance"></b-icon>
                </span>
            </div>
            
        </template>
        </vue-good-table>
    
    </div>
</template>
<script>

export default {
    components: {

    },
    props: ['notificationUsersData'],
    watch: {
        'notificationUsersData' : function(nv){
            this.rows = nv
        }
    },
    methods: {
        onPerPageChange(change){
            this.perPage = change.currentPerPage
        },
        onPageChange(change){
            this.currentPage = change.currentPage
            
        },
        async sendTest(props){
            const rowData = props.row
            const index = ( this.perPage * ( this.currentPage - 1 ) ) + props.index
            this.rows[index].testing = true
            
            const res = await this.$http.post('/api/notifications/notification/testEmail', {
                testEmail: rowData.notifications_users_email,
                uuid: rowData.notifications_users_uuid,
                template: 'testEmail',
                templateName: 'Test email',
                catgory: 'MAINTENANCE'
            })
            
            if(res && res.status == 200){
                this.rows[index].testing = false
            }
            
        },
        toggleNotification(rowData, ColumnData){
            this.notificationUsersData[rowData.originalIndex][ColumnData.field] = !rowData[ColumnData.field]
        },
        saveChanges(rowData){
            this.showOver = !this.showOver
            //console.log(rowData)
            
            Promise.all([
                //this.$http.post('/api/settings/upsert-settings', this.notificationUsersData),
                this.$http.post('/api/notifications/upsert-settings', [rowData]),
            //]).then(([res1])=>{
                ]).then(()=>{
                this.showOver = !this.showOver
                this.$emit("switch-on", this.notificationUsersData)
                //console.log(res1.data)
                //this.notificationUsersDataLocal = res1.data
            })
            
        },
        
    },
    data(){
        return {
            rows: [],
            currentPage: 1,
            perPage: 10,
            showOver: false,
            searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
            },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5,10,100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
            fields:[
                { label: 'RECIPIENT', field: 'notifications_users_email', width:'200px', sortable: false,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter recipient'
                    }
                },
                { label: 'UUID', field: 'notifications_users_uuid', width:'200px', sortable: false,
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter UUID'
                    }
                },
                { label: 'LOW BALANCE', field: 'notifications_users_low_balance', width:'200px', sortable: false},
                { label: 'PRICE CHANGES', field: 'notifications_users_price_changes', width:'200px', sortable: false},
                { label: 'PRODUCT', field: 'notifications_users_product', width:'200px', sortable: false},
                { label: 'MAINTENANCE', field: 'notifications_users_maintenance', width:'200px', sortable: false},
                { label: 'OPTIONS', field: 'options', width:'200px', sortable: false}
            ]
        }
    }
}
</script>