<template>
    
    <b-modal
            
            size="xl"
            id="batch-routing-modal" 
            ref="batch-routing-modal"
            :hide-footer="false"
            title="Batch routing"
            v-model="showBatchM"
            @ok="handleOk"
            @hidden="resetModal"
            :ok-disabled="!routing || !markUpFromCostValue || !operators.length || !type"
        >
    
        <b-form ref="form" @submit.stop.prevent="handleSubmit" @reset="resetModal">
        <b-row>
            <b-col lg="6">
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            content-cols-lg="12"
                            description="The country of the routing"
                            label="Enter country"
                            label-for="cc-list-id-input">

                            <multiselect 
                                v-model="countries" 
                                :max-height="150"
                                :allow-empty="false"
                                :multiple="true" 
                                :searchable="true"
                                label="label" 
                                track-by="value"
                                @input="getOperatorsList()"
                                @remove="removeCountry"
                                :options="countriesList">
                            </multiselect>
                            
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            content-cols-lg="12"
                            description="The operator of the routing"
                            label="Enter operator"
                            label-for="op-list-id-input">
                            <multiselect 
                                v-model="operators" 
                                :options="operatorsList"
                                :class="'multiselect-local'"
                                :custom-label="customLabelOps"
                                :max-height="350"
                                :multiple="true" 
                                group-values="ops" 
                                group-label="country" 
                                :group-select="true"                
                                label="operator" 
                                track-by="mccmnc"
                                @input="getRoutesForSingle()"
                            ></multiselect>
                        
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
            <b-col lg="6">
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            content-cols-lg="12"
                            description="The routing"
                            label="Select routing"
                            label-for="rt-list-id-input"> 
                                <multiselect 
                                v-model="routing" 
                                @input="routingSelected"
                                :custom-label="customLabelRoutes"
                                :searchable="true"
                                label="route_account_alias" 
                                track-by="route_account_id"
                                :options="routingList">
                                </multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>   
                
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            content-cols-lg="12"
                            description="Mark up value"
                            label="Mark up"
                            label-for="rt-list-id-input"> 
                            <b-form-input
                            
                                type="number"
                                step="0.0001"
                                v-model="markUpFromCostValue"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row align-v="start">
                    <b-col>
                        <b-form-checkbox
                        
                            style="width:fit-content"
                            id="checkbox-1"
                            v-model="setAsDefault"
                            name="checkbox-1"
                            value="true"
                            unchecked-value="false">
                            
                            <p class="text-primary ">
                                &nbsp;&nbsp;Set route as defualt for selected countries</p>
                                
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            content-cols-lg="12"
                            description="Default pricing type"
                            label="Pricing type"
                            label-for="rt-list-id-input"> 
                            <b-form-select v-model="type" class="mb-3">
                                <b-form-select-option :value="null">Please select an option</b-form-select-option>
                                <b-form-select-option value="OTP">OTP</b-form-select-option>
                                <b-form-select-option value="MARKETING">MARKETING</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                        <b-col>
                            <b-form-group
                                :invalid-feedback="'Please enter a valid number'"
                                style="text-align:left"
                                label="Fixed sender">
                                <b-form-input
                                    :state="/^[0-9]+$/.test(fixedSender)"
                                    v-model="fixedSender"
                                    placeholder="Fixed sender">

                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox
                            class="pt-2"
                            style="width:fit-content"
                            id="checkbox-2"
                            v-model="randomSender"
                            name="checkbox-2"
                            value="true"
                            unchecked-value="false">
                            <p class="text-primary ">
                                &nbsp;&nbsp;Random Local Origintaor</p>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                            style="text-align:left"
                            label="Custom routing rule">
                            <multiselect 
                                :max-height="150"
                                v-model="customRule" 
                                :allow-empty="false"
                                :multiple="false" 
                                :searchable="true"
                                label="text" 
                                track-by="value"
                                :options="customRules">
                                </multiselect>
                            </b-form-group>
                        </b-col>
                    </b-row>
            </b-col>
        </b-row>
        
<!--
<b-button 
type="submit" 
:disabled="!singleRouteForm.country.length || !singleRouteForm.operator.length || !singleRouteForm.routing" 
variant="primary">Submit</b-button>
<b-button type="reset" variant="danger">Reset</b-button>
-->
        </b-form>
        <!--
        <template #modal-footer>
            <b-button type="submit" variant="outline-primary" @click.prevent="handleOk()">Submit</b-button>
            <b-button type="reset" variant="outline-info" @click="resetModal()">Reset</b-button>
        </template>
        -->
    </b-modal>
   
</template>
<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
//const mcc_mnc_list = require('mcc-mnc-list/mcc-mnc-list.json');
const mcc_mnc_list = require('../../../json/newmccmnc.json');

export default {
    components: {
        Multiselect
    },
    props:['showBatch'],
    created(){
        this.getNewSingleRoutes()
    },
    data(){
        return {
            type:null, 
            fixedSender: null,
            randomSender: false,
            customRule: null,
            customRules: this.$customRules,
            routing:null,
            markUpFromCostValue: null,
            countries:[],
            operators: [],
            showBatchM:false,
            setAsDefault: false,
            markUpFromCost: true,
            routingList: [],
            operatorsList: [],
            countriesList: [],
            singleRouteForm: {
                cost:null,
                price: null,
                country: [],
                operator: [],
                routing: null,
                markUpFromCostValue: null
            },
            submitObj: {
                route_account_alias: null,
                routes_provider: null,
                routing_route_sell: null,
                routing_route_cost: null,
                send_notification_to_client: null,
                routing_mark_up_by: null,
                routing_mark_from_cost: null,
                routes_our_provider_id: null,
                route_account_id: null,
                toUpdateArray: []
            }
        }
    },
    methods:{
        routingSelected(item){
            if(!item)
                return
            this.markUpFromCostValue = Number(item.routes_route_mark_up)
        },
        customLabelOps(item){
            return `${item.operator} (${item.mcc}${item.mnc})`
        },
        customLabelRoutes(item){
           return `${item.routes_route_cost},${item.routes_route_mark_up} - ${item.route_account_alias} (${item.routes_provider})`  
        },
        removeCountry(obj){
            console.log(obj)
        },
        showSubmitResponseChangesModel(resp){
            return  this.$bvModal.msgBoxOk(
                    `
                        Server status: ${resp.status} ${resp.statusText}
                    `, 
                    {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
        },
        confirmChangesModel(text){
            
            return this.$bvModal.msgBoxConfirm(text, {
                title: 'Client notification',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'info',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true
            })
        },
        async handleOk(event){
            event.preventDefault()
            const valid = this.$refs.form.checkValidity() 
            if(!valid){
                return
            }
            let submitObj = {
                from_routing_account_object:{
                    route_account_alias: this.routing.route_account_alias,
                    routes_provider: this.routing.routes_provider,
                    routing_mark_from_cost: this.markUpFromCost,
                    routes_our_provider_id: this.routing.routes_our_provider_id,
                    route_account_id: this.routing.route_account_id
                },
                routing_mark_up_by: this.markUpFromCostValue,
                set_as_default: this.setAsDefault,
                countries: this.countries,
                toUpdateArray: this.operators,
                type: this.type,
                fixedSender: this.fixedSender,
                randomSender: this.randomSender,
                customRule: this.customRule
            };
            const confirm = await this.confirmChangesModel('Are you sure?')
            if(confirm){
                const res = await this.$http.post( '/api/routing/token/defaults/create', submitObj )
                if(res && res.status == 200){
                    this.$bvModal.hide('batch-routing-modal')
                    this.$emit('reloadTable', true)
                }
            }
            
        },
        getNewSingleRoutes(){
            const list = countries.getNames('en', { select: 'official' })
            this.countriesList = Object.keys(list).map((key) => ({ value: key, label: list[key] }))
            
        },
        getOperatorsList(){
            this.operatorsList = []

            let countyCodeKeys = this.countries.reduce((acc, item)=>{ 
                acc[item.value] = item
                return acc
            },{})

           
            
            Object.keys(countyCodeKeys).forEach(cCode=>{
                let ops = mcc_mnc_list.filter(obj=>{

                    if(obj.countryCode == cCode){
                        obj['mccmnc'] = `${obj.mcc}${obj.mnc}`
                        return obj
                    }
                })
                this.operatorsList.push({
                    country:  countyCodeKeys[cCode]['label'],
                    ops: ops
                })
            
            })
         },
        getRoutesForSingle(){  
            

            if(this.operators.length){
                this.$http.post('/api/routes/read-singe', {
                    //routing_countries: this.singleRouteForm.country.map(v=>{return v.value})
                    routing_countries: this.countries.map(v=>{return v.value})
                }).then(resp => {
                    this.routingList = resp.data
                    
                }).catch(thrown => {
                    console.log(thrown)
                });
            }
        },
        resetModal(){
            this.countries = []
            this.operators = []
            this.operatorsList = []
            this.routingList = []
            this.markUpFromCostValue=null
            this.setAsDefault = null
            this.markUpFromCost = null
            this.routing = null,
            this.type = null,
            this.fixedSender = null,
            this.randomSender = false,
            this.customRule = null
        }
    },
    
}
</script>
<style >
.multiselect-local .multiselect__tags{
  min-height:200px;
  max-height:300px;
  overflow-y: scroll;
}
</style>