<template>
  <div>
    <b-row>
        <b-col>
            <MyFilter @filter="fetchData" :clientsList="clientsList"/>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <MyTable
                :routing="routingData"
                @toggleModal="toggleModal"
            />
        </b-col>
    </b-row>
    <BatchRouting :uuids="clientsList"/>
  </div>
</template>

<script>
import MyFilter from './Filter.vue'
import MyTable from './Table.vue'
import BatchRouting from './BatchRouting.vue'
export default {
    components: {
        MyFilter,
        MyTable,
        BatchRouting
    },
    data(){
        return {
            uuidList: [],
            routingData: [],
            clientsList: []
        }
    },
    async created(){
      //this.fetchData()
      await this.fetchLists()
    },
    methods: {
        toggleModal(modalId){
            this.$bvModal.show(modalId)
        },
        //clientsList(){},
        //clientsList(list){
            //this.uuidList = list
        //},
        async deleteRow(index){
            this.routingData.splice(index, 1)
        },
        async fetchLists(){
            let promises = await Promise.all([
                this.$http.get('/api/clients/get-all-http-tokens'),
                this.$http.get('/api/providers/read-with-accounts'),  
            ])
            // tokens
            let tokensPromise = promises[0]
            let providersPromise = promises[1]
            
            if(tokensPromise.status == 200 ){
                const tokensList = tokensPromise.data.map(item => {
                    item['value'] = item.http_api_jwt_short_token
                    return item
                })

                this.clientsList = Object.keys(tokensList.reduce((acc,item)=>{
                    acc[item.http_api_jwt_uuid] = 1;
                    return acc;
                },{})).map(item => {
                    return {
                        value: item
                    }
                })

                
            }

            if(providersPromise.status == 200 ){
                
                /*
                this.providersList = providersPromise.data.map(item => {
                    item['value'] = `${item.provider_id}${item.providers_accounts_id}`
                    return item;
                })
                */
            }
        },
        async fetchData(filter){
            //console.log(filter)
            //this.uuidList = filter
            
            this.toggleBusy()
            let res = await this.$http.post('/api/routing/smsc/mo/read', filter)
            
            this.toggleBusy()

            if(res && res.status == 200){
                this.routingData = res.data
            }else{
                this.routingData = []
            }
        },
        toggleBusy(){}
    }
}
</script>

<style>

</style>