<template>
  <div>
    <base-card>
      
      <template #header>
        History
      </template>
      <div slot="body">
        <vue-good-table
          theme="polar-bear"
          :columns="cols" 
          :rows="rows" 
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 5,
            position: 'bottom',
            perPageDropdown: [5,10,100],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast : true,
            firstLabel : 'First Page',
            lastLabel : 'Last Page',
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page', // for 'pages' mode
            allLabel: 'All',
            infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
          }">
        
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'options'" >
                <b-button 
                  v-b-tooltip.hover 
                  title="Details" 
                  variant="outline-primary" 
                  size="sm" 
                  @click="toggleModal(props)">
                    <b-icon icon="file-earmark-medical" variant="primary"></b-icon>
                </b-button>
            
            </span>
          </template>
        </vue-good-table>
        <Details :data="detailsRow"/>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/components/Base/card.base.vue'
import Details from './Details.vue'
export default {
  components: {
    BaseCard,
    Details
  },
  watch:{
    'rowData': {
      handler() {
        console.log('changed')
        this.rows = this.rowData
      },
      deep: true
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Array
    }
  },
  methods: {
    toggleModal(props) {
      this.detailsRow = props.row
      this.$bvModal.show('modal-more-details')
    },
  },
  data(){
    return {
      detailsRow: {},
      cols: [
        /**
 * test_sms_route varchar(100) 
test_sms_route_connection_alias varchar(200) 
test_sms_our_msg_id varchar(100) PK 
test_sms_account varchar(50) 
test_sms_sender varchar(20) 
test_sms_sender_received varchar(30) 
test_sms_receiver varchar(20) 
test_sms_mt_cost decimal(10,5) 
test_sms_mt_sell decimal(10,5) 
test_sms_mt_mcc char(4) 
test_sms_mt_mnc char(4) 
test_sms_mt_operator_name varchar(255) 
test_sms_mt_country_code char(4) 
test_sms_mt_country_name varchar(50) 
test_sms_msg_id varchar(50) 
test_sms_test_id varchar(50)
test_sms_mt_created_ts datetime 
test_sms_mt_updated_ts datetime 
test_sms_mt_last_status varchar(50) 
test_sms_mt_dlr_status varchar(50) 
test_sms_mt_text varchar(1000) 
test_sms_coded_mt_text varchar(2000) 
test_sms_mt_text_received varchar(1000) 
test_sms_mt_parts int 
test_sms_mt_is_gsm tinyint(1)
 */
        { 
          label: 'Route',  
          field: 'test_sms_route', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Account',  
          field: 'test_sms_account', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Sender',  
          field: 'test_sms_sender', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Sender acctual',  
          field: 'test_sms_sender_received', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Receiver',  
          field: 'test_sms_receiver', 
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'MT Cost',  
          field: 'test_sms_mt_cost', 
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Country',  
          field: 'test_sms_mt_country_name', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Operator',  
          field: 'test_sms_mt_operator_name', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Our MSGID',  
          field: 'test_sms_msg_id', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Test ID',  
          field: 'test_sms_test_id', 
          type: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Sent TS',  
          field: 'test_sms_mt_created_ts', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Updated TS',  
          field: 'test_sms_mt_updated_ts', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'MT DLR',  
          field: 'test_sms_mt_dlr_status', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Last status',  
          field: 'test_sms_mt_last_status', 
          type: 'text',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter'
          } 
        },
        { 
          label: 'Options',  
          field: 'options'
        }
        
      ],
      rows: this.rowData
    }
  }
    
}
</script>

<style>

</style>