<template>
    <div>
        <TopMenu :items="menuItems"/>
        <b-navbar
            :class=" leftSideBarIsShown ? 'menu-expanded' : '' "
            class="p-1 border-top"
            variant="light">
            <b-breadcrumb class="p-1" :items="breadcrumbItems"></b-breadcrumb>
        </b-navbar>

        <main class="content" :class=" leftSideBarIsShown ? 'menu-expanded' : '' ">
            <vue-progress-bar
            style="background-color: #1256D5"
            ></vue-progress-bar>
            <div class="d-flex align-items-center justify-content-center">
                <router-view></router-view>
            </div>
        </main>
    </div>
</template>

<script>
import TopMenu from './TopMenu.vue'
import api from '../../api'

import home from '../../router/home.js';
import statistics from '../../router/statistics.js';
import gateway from '../../router/gateway.js';
import routing from '../../router/routing.js';
import finance from '../../router/finance.js';
import notifications from '../../router/notifications.js';
import settings from '../../router/settings.js';
import media from '../../router/media.js';
//import dashboard from '../../router/dashboard.js';


//import delify from '../../router/delify.js';
//import clients from '../../router/clients.js';
//import providers from '../../router/providers.js';
//import accounts from '../../router/accounts.js';


export default {
    components: {
        TopMenu
    },
    watch:{
       '$route' (to) {
            this.updateBreadCrumbItems(to.path)
       }
    },
    created () {
        this.updateBreadCrumbItems(this.$route.path)
        
        let me = this    
        api.axios.interceptors.request.use(function (config) { 
            me.$Progress.start()
            return config;
        },function (error) {
           //return Promise.reject(error);
          console.log(error)
        })

        api.axios.interceptors.response.use(
            response => {
              me.$Progress.finish(); 
                return Promise.resolve(response);
            },
            error => {
              me.$Progress.finish(); 
                return Promise.reject(error);
            }
        );

        //this.connectWebService()
    },
    methods: {
        connectWebService () {
            const webSocket = new WebSocket('ws://localhost:82/ws/');
            this.$store.commit('webSocketConnect', webSocket);
            this.$store.getters.webSocket.onopen = () => {
                console.log('Connection established');
            };
        },
        updateBreadCrumbItems(path){
            let parts = path.split(/\//).filter(item=>{
                return item != ""
            });
            this.breadcrumbItems = parts.map((item, index) => {
            
            return {
                text: item.slice(0,1).toUpperCase() + item.slice(1,item.length),
                href: "/" + parts.slice(0, index+1).join("/")
            }

            })  
        },
      
        logout: function () {
            //e.preventDefault();    
            this.$store.dispatch('logout')
            .then(() => this.$router.push('/login'))
            .catch(err => console.log(err))
        }
    },
    data() {
        return {
            leftSideBarIsShown: false,
            menuItems: [
                home,
                //dashboard,
                statistics,
                gateway,
                routing,
                media,
                //accounts,
                //clients,
                //providers,
                finance,
                notifications,
                //delify,
                settings
            ],
            breadcrumbItems: [],
        }
    }

}
</script>

<style>

 body {
    font-size: .875rem !important;
    padding-top: 0 !important;
    text-align:left;
    background-color: #ebedef !important;
  }

  .content{
    text-align:left;
    padding:2px;
  }

  @media only screen and (min-width: 767px) {
    .content{  
      padding:20px;
    }
  }
 
  .dropdown-menu-right{
    right: 0;
    left: auto;

  }


</style>