<template>
    <vue-good-table
        :columns="TblCols" 
        :pagination-options="paginationOptions"
        :search-options="searchOptions"
        :rows="usersData">
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'notifications_enabled'">           
                <div @click="toggleNotifications(props.row)" class="text-left">
                    <div class="text-center" style="font-size: 1.5rem;">
                    <b-icon 
                    style="color:green"
                    v-if="props.row.notifications_enabled"
                    icon="toggle-on"></b-icon>        
                    <b-icon 
                    v-if="!props.row.notifications_enabled"  
                    icon="toggle-off"></b-icon>
                    </div>
                </div>
            </span>
            <span v-else-if="props.column.field == 'user_confirm_registration_confirmed'">
                <div class="text-center" style="font-size: 1.5rem;">
                    <b-icon 
                    style="color:green"
                    v-if="props.row.user_confirm_registration_confirmed"
                    icon="person-check"></b-icon>        
                    <b-icon 
                    style="color:red"
                    v-if="!props.row.user_confirm_registration_confirmed"  
                    icon="exclamation-diamond"></b-icon>
                </div>     
            </span>
            <span v-else-if="props.column.field == 'user_confirm_terms'">
                <div class="text-center" style="font-size: 1.5rem;">
                    <b-icon 
                    style="color:green"
                    v-if="props.row.user_confirm_terms"
                    icon="file-check"></b-icon>        
                    <b-icon 
                    style="color:red"
                    v-if="!props.row.user_confirm_terms"  
                    icon="exclamation-diamond"></b-icon>
                </div>     
            </span>
            
            <span v-else-if="props.column.field == 'options'">
                <b-button-group>
                    <b-button
                        v-b-tooltip.hover 
                        title="Block access"
                        variant="danger"
                        size="sm">
                        <b-icon icon="slash-circle"></b-icon>
                    </b-button>
                </b-button-group> 
            </span>

        </template>
    </vue-good-table>
</template>

<script>
import TblColsJson from './json/usersTblColumns.json'

export default {
    props: ['usersData'],
    data(){
        return {
            TblCols: TblColsJson,
            searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
            },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5,10,100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
        }
    },
}
</script>

<style>

</style>