<template>
  <b-form @submit.stop.prevent="() => onSubmit(tag, config, includes.filter(i => i))">
    <b-row>

      <b-col cols="6">
        <b-card style="padding: 10px 10px 10px 10px;">
          <b-row>
            <b-form-group style="text-align:left">
              <h6 style="color:green;">{{this.params.uid}}</h6>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-form-group label="Tag:" label-for="input-tag" :invalid-feedback="'Tag is required'" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  v-model="tag"
                  id="input-tag"
                  :state="tag !== ''"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Username:" label-for="input-username" invalid-feedback="username is required" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  placeholder="Required"
                  v-model="config['username']"
                  id="input-username"
                  :state="config['username'] != null"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Password:" label-for="input-password" invalid-feedback="password is required" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  placeholder="Required"
                  v-model="config['password']"
                  id="input-password"
                  :state="config['password'] != null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Default smsc:" label-for="input-default-smsc" invalid-feedback="default-smsc is required" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  placeholder="Required"
                  v-model="config['default-smsc']"
                  id="input-default-smsc"
                  :state="config['default-smsc'] != null"
                />
              </b-form-group>
            </b-col>
             <b-col>
              <b-form-group label="max-messages:" label-for="input-max-messages" invalid-feedback="max-messages is required" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  placeholder="Required"
                  v-model="config['max-messages']"
                  id="input-max-messages"
                  :state="config['max-messages'] != null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Concatenation:" label-for="input-concatenation" invalid-feedback="concatenation is required" style="text-align:left">
                <b-form-select
                    style="width:100% !important;"
                    v-model="config['concatenation']"
                    required>
                      <template #first>
                        <b-form-select-option :value="null" disabled>Concatenation</b-form-select-option>
                        <b-form-select-option :value="true">Allow</b-form-select-option>
                        <b-form-select-option :value="false">Deny</b-form-select-option>

                      </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
             
          </b-row>
          <!--
          <ConfigIncludes :includes="includes" />
          -->
          <br />

          <b-row>
            <b-button-group>
              <b-button variant="outline-danger" @click="() => onClose()">Close</b-button>
              <b-button type="submit" variant="outline-primary">Submit</b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <ConfigPreview :config="config" :includes="includes" />
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
  import ConfigPreview from '../../Common/Config/ConfigPreview.vue'
  //import ConfigIncludes from '../../Common/Config/ConfigIncludes.vue'

  export default {
    components: {
      ConfigPreview,
      //ConfigIncludes
    },
    props: ['params', 'onSubmit', 'onClose'],
    data () {
      return {
        tag: '',
        config: {
          'username': null,
          'password': null,
          'default-smsc': null,
          'max-messages': 100,
          'concatenation': null,
        },
        includes: []
      }
    },
    created () {
      this.tag = this.params.tag
      this.includes = this.params.includes
      this.config = { ...this.config, ...this.params.config }
    },
  }
</script>

<style>
  .row {
    margin: 10px;
  }
</style>
