<template>
    <b-row>
        <b-col cols="12">
            <b-tabs pills>
                
                <b-tab @click="tabChanged('http')" title="HTTP API keys">
                    <ApiTokensLayout :tblData="tblData.http" :type="'http'"/>
                </b-tab>
                <b-tab @click="tabChanged('smpp')" title="SMPP API keys">
                    <SmppTokensList :tblData="tblData.smpp" @updateRow="update"/>
                </b-tab>
                <b-tab @click="tabChanged('campaign')" title="Campaign keys">
                    <ApiTokensLayout :tblData="tblData.campaign" :type="'campaign'"/>
                </b-tab>
            </b-tabs>
        </b-col>
    </b-row>
</template>

<script>
import ApiTokensLayout from '../Clients/ApiTokens/TokensList.vue'
import SmppTokensList from '../Clients/ApiTokens/SmppTokensList.vue'
export default {
    components: {        
        ApiTokensLayout,
        SmppTokensList
    },
    props:['clientData'],
    data(){
        return {
            type: 'http',
            tblData: {
               http: [], 
               smpp: [],
               campaign: []
            }
        }
    },
    created(){
        this.fetch()
    },
    methods: {
        update(rowData){
            console.log(rowData)
            this.tblData[this.type][rowData.index] = {
                ...this.tblData[this.type][rowData.index], 
                ...rowData
            }
        },
        async fetch(){
            const res = await this.$http.get(`/api/clients/keys/${this.type}/read`)
            if(res && res.status == 200){
                this.tblData[this.type] = res.data
            }
        },
        tabChanged(type){
            this.type = type
            this.fetch()
        }
    }
}
</script>

<style>

</style>