<template>
    <vue-good-table 
        theme="polar-bear"
        :columns="table.fields" 
        :rows="rows" 
        :line-numbers="false"
        :pagination-options="table.paginationOptions"
        :search-options="table.searchOptions"
        :select-options="table.selectOptions">
    </vue-good-table>
</template>

<script>
const table = require('./table/tbl.js')

export default {
    props: {
        rows: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            table: {
                fields: table.settings.fields,
                paginationOptions: table.settings.paginationOptions,
                searchOptions: table.settings.searchOptions,
                selectOptions: table.settings.selectOptions
            }
        }

    }
}
</script>

<style>

</style>