<template>
    <b-card>
        

        <vue-good-table 
        theme="polar-bear"
        :columns="tblCfg.fields" 
        :rows="tblRows" 
        :line-numbers="false"
        :sort-options="{
            enabled: true,
            initialSortBy: {field: 'createdAt', type: 'desc'}
        }"
            :pagination-options="tblCfg.paginationOptions"
            :search-options="tblCfg.searchOptions"
            :select-options="tblCfg.selectOptions">

            <template slot="table-row" slot-scope="props">

                <span v-if="props.column.field == 'key_status'">
                    <div @click="toggle(props)" class="text-left">
                        <b-icon v-if="props.row.key_status.toString() == 'true'" icon="toggle-on" font-scale="1.5" ></b-icon>
                        <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
                    </div>
                </span>
                <span v-else-if="props.column.field == 'client_name'">{{ props.row.client_name }}</span>
                <span v-else-if="props.column.field == 'key_confirmed'">
                    <b-icon v-if="props.row.key_confirmed" icon="check"></b-icon>
                    <b-icon v-else icon="x"></b-icon>
                </span>
                
                <span v-else-if="props.column.field == 'options'">
                    <b-button-group>
                        <b-button 
                            v-if="!props.row.key_confirmed"
                            variant="warning" 
                            v-b-tooltip.hover 
                            title="Confirm token" @click="confirm(props)">
                            <b-icon icon="check"></b-icon>
                        </b-button>

                        <b-button 
                            variant="primary" 
                            v-b-tooltip.hover 
                            title="Edit key"
                            @click="toggleModal(props.row)">
                                <b-icon icon="pen"></b-icon>
                        </b-button>
                    </b-button-group>
                </span>
                
            </template>
        </vue-good-table>
        <EditKeyModal ref="key-modal" :rowData="modalRowData" @update="update"/>
    </b-card>

</template>

<script>
const tblCfg = require('./tblConfig/smpp.json')
import EditKeyModal from './EditKeyModal.vue'

export default {
    components:{
        EditKeyModal
    },
    props: ['tblData'],
    watch: {
        'tblData': {
            handler: function(nv){
                this.tblRows = nv
            },
            deep: true
        }
    },
    data(){
        return {
            tblCfg: tblCfg,
            modalRowData: {},
            tblRows: []
        }
    },
    methods: {
        async update(formData){
            let res = await this.$http.post(`/api/clients/keys/smpp/update`, formData)
            if(res && res.status == 200){
                if(res.data.status) {
                    const tblData = [...this.tblRows]
                    this.tblRows = []
                    const index = tblData.findIndex(item => item.username == formData.username)
                    tblData[index] = { ...tblData[index], ...formData }
                    this.tblRows = [...tblData]
                }
            }
        },
        toggleModal(rowData){
            this.modalRowData = rowData
            this.$refs['key-modal'].toggle()
        },
        toggle: async function (rowData) {
            let res = await this.$http.post(`/api/clients/keys/smpp/toggle`, rowData.row)
            if(res && res.status == 200){
                if(res.data.status) {
                    rowData.row.key_status = !rowData.row.key_status 
                }
            }
        },
        confirm: async function (rowData){
            let res = await this.$http.post(`/api/clients/keys/smpp/confirm`, rowData.row)
            if(res && res.status == 200){
                if(res.data.status) rowData.row.key_confirmed = res.data.status
            }
        }
    }
}
</script>

<style>

</style>