import Vue from 'vue';


const basePath = process.env.gwbasepath || '/etc/kannel/conf'
Vue.prototype.getEntityPath = entity => `${basePath}/${entity}`

Vue.prototype.$ton = [
  {
    "text": "Unknown",
    "value": "0"
  },
  {
    "text": "International",
    "value": "1"
  },
  {
    "text": "National",
    "value": "2"
  },
  {
    "text": "Network Specific",
    "value": "3"
  },
  {
    "text": "Subscriber Number",
    "value": "4"
  },
  {
    "text": "Alphanumeric",
    "value": "5"
  },
  {
    "text": "Abbreviated",
    "value": "6"
  }
]


Vue.prototype.$npi = [
  {
    "text": "Unknown",
    "value": "0"
  },
  {
    "text": "ISDN/telephone numbering plan (E163/E164)",
    "value": "1"
  },
  {
    "text": "Data numbering plan (X.121)",
    "value": "3"
  },
  {
    "text": "Telex numbering plan (F.69)",
    "value": "4"
  },
  {
    "text": "Land Mobile (E.212)",
    "value": "6"
  },
  {
    "text": "National numbering plan",
    "value": "8"
  },
  {
    "text": "Private numbering plan",
    "value": "9"
  },
  {
    "text": "ERMES numbering plan (ETSI DE/PS 3 01-3)",
    "value": "10"
  },
  {
    "text": "Internet (IP)",
    "value": "13"
  },
  {
    "text": "WAP Client Id (to be defined by WAP Forum)",
    "value": "18"
  }
]



Vue.prototype.$customRules = [
    {
      text: 'None', 
      value: null
    },
    {
      text: 'Remove county code for sender', 
      value: 'REMOVE_CCODE_SENDER' 
    },
    {
      text: 'Remove county code for receiver', 
      value: 'REMOVE_CCODE_RECEIVER' 
    },
    {
      text: 'Remove county code for both', 
      value: 'REMOVE_CCODE_BOTH' 
    }
  ]
  
  Vue.prototype.$logLevel = [
    { 
        text: 'Connection log level', 
        value: '' 
    }, 
    { 
        text: 'Debug', 
        value: '0' 
    },
    { 
        text: 'Info', 
        value: '1' 
    },
    { 
        text: 'Warning', 
        value: '2' 
    },
    { 
        text: 'Error', 
        value: '3' 
    },
    { 
        text: 'Panic', 
        value: '4' 
    }
  ];
  
  Vue.prototype.$yesNo = [
    { 
        text: 'Yes/No', 
        value: '' 
    }, 
    {
      text: 'Yes',
      value: true
    },
    {
        text: 'No',
        value: false
    }
  ];
  
  Vue.prototype.$notificationTypes = [
    {
      text: 'Product', 
      value: 'PRODUCT' 
    },
    {
      text: 'Pricing', 
      value: 'PRICING' 
    },
    {
      text: 'Maintenance', 
      value: 'MAINTENANCE' 
    },
    {
      text: 'Balance', 
      value: 'BALANCE' 
    },
  ]
  
  
  Vue.prototype.$days = [
    {
      text: 'Sunday', 
      value: '0' 
    },
    {
      text: 'Monday', 
      value: '1' 
    },
    {
      text: 'Tuesday', 
      value: '2' 
    },
    {
      text: 'Wednsday', 
      value: '3' 
    },
    {
      text: 'Thursday', 
      value: '4' 
    },
    {
      text: 'Friday', 
      value: '5' 
    },
    {
      text: 'Sturday', 
      value: '6' 
    }
  ]
  
  Vue.prototype.$months = [
    {
      text: 'Jan', 
      value: '0' 
    },
    {
      text: 'Feb', 
      value: '1' 
    },
    {
      text: 'Mar', 
      value: '2' 
    },
    {
      text: 'Apr', 
      value: '3' 
    },
    {
      text: 'May', 
      value: '4' 
    },
    {
      text: 'Jun', 
      value: '5' 
    },
    {
      text: 'Jul', 
      value: '6' 
    },
    {
      text: 'Aug', 
      value: '7' 
    },
    {
      text: 'Sep', 
      value: '8' 
    },
    {
      text: 'Oct', 
      value: '9' 
    },
    {
      text: 'Nov', 
      value: '10' 
    },
    {
      text: 'Dec', 
      value: '11' 
    }
  ]
  