<template>
  <base-card>
    <template #header>
      Filter
    </template>
    <div slot="body" class="p-3">
      <b-form inline @submit.prevent="onSubmit" @reset="onReset" v-if="show">
        <b-row>
          <b-col>
            <b-form-group label="Dates range:">
              <date-range-picker
                style="width: 100% !important;"
                opens="right"
                ref="picker"
                :locale-data="locale"
                v-model="dateRange"
                @update="updateValues">
                
                <!--    input slot (new slot syntax)-->
                <template #input="picker">
                  <b-icon icon="calendar"></b-icon>
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
              </date-range-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Account UUID:" >
              <multiselect 
                v-model="localAccounts" 
                :options="accounts" 
                :custom-label="nameWithUuid"
                placeholder="Select one account" 
                label="text" 
                track-by="client_accounts_cliend_uuid">
              </multiselect>

            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Country:" >
              <multiselect 
                v-model="localCountryCode" 
                :options="countries" 
                placeholder="Select one" 
                label="text" 
                track-by="value">
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Operator:" >
              <multiselect 
                v-model="localOperators" 
                :options="operators" 
                :custom-label="nameWithMccMnc"
                placeholder="Select one account" 
                label="text" 
                track-by="mccmnc">
              </multiselect>

            </b-form-group>
          </b-col>
        </b-row>

        <b-button-group class="mt-3">
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-button-group>
      </b-form>
    </div>
  </base-card>
</template>

<script>
import BaseCard from '@/components/Base/card.base.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
import {countries} from '@/json/countries'
import mccmnc from '../../json/newmccmnc.json'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  components: {
    BaseCard,
    DateRangePicker,
    Multiselect
  },
  watch:{
    'localCountryCode': function (newVal) {
      if(newVal){
        //this.form.country_code = newVal.value
        this.operators = mccmnc.filter((obj) => {
          return obj.countryCode == newVal.value
        }).map(item=>{
          item.mccmnc = `${item.mcc}${item.mnc}`
          return item
        })

      }else{
        this.operators = []
      }
    }
  },
  created () {
    this.fetchAccounts()
  },
  methods:{
    onSubmit(){
      
      let startDate = new Date(this.dateRange.startDate)
      startDate.setHours(0, 0, 0, 0)
      let endDate = new Date(this.dateRange.endDate)
      endDate.setHours(23, 59, 59, 999)
      
      this.$emit('filter', {
        startDate: moment(startDate).format("YYYY-MM-DD HH:MM:SS"),
        endDate: moment(endDate).format("YYYY-MM-DD") + ' 23:59:59',
        //startDate: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        //endDate: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
        test_sms_account_uuid: this.localAccounts ? this.localAccounts.client_accounts_cliend_uuid : null,
        test_sms_mt_country_code: this.localCountryCode ? this.localCountryCode.value : null,
        test_sms_mt_mcc: this.localOperators ? this.localOperators.mcc : null,
        test_sms_mt_mnc: this.localOperators ? this.localOperators.mnc : null
      })
    },
    onReset(){
        this.localCountryCode = null
        this.localAccounts = null
        this.localOperators = null

        this.$nextTick(() => {
          this.show = true
        })
    },
    updateValues(){},
    nameWithMccMnc(item){
      return `${item.mcc}${item.mnc} - ${item.operator}`
    },
    nameWithUuid(item){
      return `${item.client_account_name} - ${item.client_accounts_cliend_uuid}`
    },
    async fetchAccounts(){
      let res = await this.$http.get("/api/clients/read")
      if(res.status == 200 ){
        this.accounts = res.data
      }
    },
    fetchData(){}
  },
  data(){
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(endDate.getDate() - 7)

    return {
      show:true,
      localCountryCode: null,
      localOperators: null,
      localAccounts: null,
      countries:countries,
      operators:[],
      accounts:[],
      startDate: String((new Date()).getDate()).padStart(2, '0') ,
      endDate: new Date(),
      dateRange: { startDate, endDate },
      locale: {
        direction: 'ltr', //direction of text
        format: 'DD-MM-YYYY', //fomart of the dates displayed
        separator: ' - ', //separator between the two ranges
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1 //ISO first day of week - see moment documenations for details
      }
    }

  },
  filters: {
    dateCell (value) {
      let dt = new Date(value)
      return dt.getDate()
    },
    date (val) {
      //return val ? val.toLocaleString() : ''
       return val ? moment(val).format("DD-MM-YYYY") : "";
    }
  }
}
</script>

<style>

</style>