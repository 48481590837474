<template>
<div style="min-width: 1800px;">
  <b-row>
        <b-col lg="12" sm="12" md="12">
          <b-card>
            <b-card-header >
              MO Statistics
            </b-card-header>
            <b-overlay :show="Loading" rounded="sm">
              <b-card-body>
                
                <vue-good-table
                theme="polar-bear"
                :columns="fields" 
                :rows="rows" 
                :line-numbers="true"
                :pagination-options="paginationOptions"
                :sort-options="{
                  enabled: true,
                  initialSortBy: [
                    {
                      field: 'mo_created_ts', 
                      type: 'desc'
                    }
                  ]
                }"
                :search-options="searchOptions">

                <div slot="table-actions">   
                </div>

                

              </vue-good-table>
              </b-card-body>
            </b-overlay>
          </b-card>
        </b-col>
  </b-row>
  
</div>  
</template>

<script>
//import BaseCard from '@/components/Base/card.base.vue'


export default {
  components: {
    //BaseCard
  },
  props:['FilteredtData', 'Loading', 'Fields', 'rows'],
  watch:{
    //'FilteredtData' : function(dataFromServer) {
      
      //console.log(dataFromServer)
      //this.allData = dataFromServer
      //this.tblData = this.allData[this.groupBy]
    //},
    'Loading': function(value){
      //console.log(value)
      this.isLoading = value
    },
    'FilteredtData' : function() {
      //this.rows = this.FilteredtData
    }
  },
  created () {
    
  },
  methods: {
    onSortChange(){},
    exported() {},
    csvError(){},
    groupByChanged(){
      this.$emit('groupBy', this.groupBy)
    },
    getFields(){},
    fetchData () {}
  },


  data(){
    return {
      fields:[
        { 
          label: 'Route', 
          field: 'mo_route',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Route'
          }
        },
        { 
          label: 'Sender', 
          field: 'mo_sender',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Sender'
          }
        },
        { 
          label: 'Receiver', 
          field: 'mo_receiver',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Route'
          }
        },
        { 
          label: 'Country', 
          field: 'mo_country_name',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Country'
          }
        },
        { 
          label: 'Action', 
          field: 'mo_action',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Action'
          }
        },
        { 
          label: 'Key Word', 
          field: 'mo_key_word',
          width: '110px',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Key Word'
          }
        },
        { 
          label: 'Text', 
          field: 'mo_text',
          width: '250px',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Text'
          }
        },
        { 
          label: 'Uuid', 
          field: 'mo_account_uuid',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Uuid'
          }
        },
        { 
          label: 'Protocol', 
          field: 'mo_protocol_type',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Protocol'
          }
        },
        { 
          label: 'Time Stamp', 
          field: 'mo_created_ts',
          filterOptions: {
              enabled: true,
              placeholder: 'Filter Country'
          }
        }
      ],
      
      groupBy: 'send_sms_mt_country_name',
      groupByOptions:[
        /*
        'byDay',
        'byCountry',
        'byOperator'
        */
       'day',
       'country',
       'operator',
       ''
      ],
      firstColOptions: {
        'byDay': {label : 'Date', field: 'date'},
        'byCountry': {label : 'Country', field: 'country_name'},
        'byOperator': {label : 'Operator', field: 'operator_name'}
      },
      isLoading: false,
      mccmncPerPage:10,
      mccmncCurrentPage:1,
      mccmncFilter:null,
      mccmncPageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      countryPageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      ccSortBy: 'total_sent',
      //sortDesc: false,
      perPage: 30,
      countryCurrentPage:1,
      opCurrentPage:1,
      countryPerPage:10,
      opPerPage:10,
      currentPage: 1,
      testPerPage: 10,
      testCurrentPage: 1,
      testFilter: null,
      countryFilter: null,
      filter: null,
      tblData: [],
      allData: [],
      mccmncTbl: [],
      groupOptions: {
        enabled: true,
        collapsable: true
      },
      searchOptions: {
          enabled: false,
          placeholder: 'Search this table'
      },
      selectOptions: { enabled: false },
      paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: 5,
          position: 'bottom',
          perPageDropdown: [5,10,100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          jumpFirstOrLast : true,
          firstLabel : 'First Page',
          lastLabel : 'Last Page',
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
          infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.custom-select{
  position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1% !important;
    min-width: 0;
    margin-bottom: 0;
}

.dashbaord{
  text-align:left;
}

.input-group-prepend, .input-group-append{
  display: flex;
    margin-right: -1px;
    box-sizing: border-box;
    border-top-right-radius: 0 !important;
}
</style>
