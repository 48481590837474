<template>
  <b-form @submit.stop.prevent="() => onSubmit(tag, config, includes.filter(i => i))">
    <b-row>

      <b-col cols="6">
        <b-card style="padding: 10px 10px 10px 10px;">
          <b-row>
            <b-form-group style="text-align:left">
              <h6 style="color:green;">{{this.params.uid}}</h6>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col >
              <b-form-group label="Tag:" label-for="input-tag" :invalid-feedback="'Tag is required'" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  v-model="tag"
                  id="input-tag"
                  :state="tag.length > 0"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Smsbox id:" label-for="input-smsbox-id" invalid-feedback="Option smsbox id" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  id="smsbox-id"
                  v-model="config['smsbox-id']"
                  :state="/^[0-9]{1,10}$/.test(config['smsbox-id'])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Bearerbox host:" label-for="input-bearerbox-host" invalid-feedback="Enter a valid bearerbox-host" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  placeholder="Required"
                  id="input-bearerbox-host"
                  v-model="config['bearerbox-host']"
                  :state="config['bearerbox-host'] != null"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Bearerbox Port:" label-for="input-bearerbox-port" invalid-feedback="Enter a valid port" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  id="input-bearerbox-port"
                  v-model="config['bearerbox-port']"
                  :state="/^[0-9]{1,10}$/.test(config['bearerbox-port'])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Sendsms port:" label-for="input-sendsms-port" invalid-feedback="Enter a valid sendsms-port" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  id="input-sendsms-port"
                  v-model="config['sendsms-port']"
                  :state="config['sendsms-port'] != null"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Sendsms url:" label-for="input-sendsms-url" invalid-feedback="Enter a valid sendsms-url" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="sendsms-url"
                  id="input-sendsms-url"
                  v-model="config['sendsms-url']"
                  :state="config['sendsms-url'] != null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
                <b-form-group label="mo-recode:" label-for="input-mo-recode" invalid-feedback="mo-recode" style="text-align:left">
                <b-form-select
                      style="width:100% !important;"
                      v-model="config['mo-recode']">
                        <template #first>
                          <b-form-select-option :value="null" disabled>Is mo-recode</b-form-select-option>
                          <b-form-select-option :value="true">Yes</b-form-select-option>
                          <b-form-select-option :value="null">No</b-form-select-option>
                        </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
            <b-col>
              <b-form-group label="Sms Services folder:" label-for="input-table" style="text-align:left">
                <b-form-select v-model="services" size="xs" class="w-100" @input="setIncludeEtitites('smss', services)">
                  <b-form-select-option :value="null" disabled>Services config path</b-form-select-option>
                  <b-form-select-option v-for="service in smss" :key="service.id" :value="`${service.uid}.conf`" >
                        {{service.uid}}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5">
              <b-form-group label="Log Level:" label-for="input-log-level" style="text-align:left">
                <b-form-select v-model="config['log-level']" size="xs" :options="[
                  { value: 0, text: 'Debug' },
                  { value: 1, text: 'Info' },
                  { value: 2, text: 'Warning' },
                  { value: 3, text: 'Error' },
                  { value: 4, text: 'Panic' }
                ]" required />
              </b-form-group>
            </b-col>
          </b-row>

          <ConfigIncludes :disabled="false" :includes="includes" />

          <br />

          <b-row>
            <b-button-group>
              <b-button variant="outline-danger" @click="() => onClose()">Close</b-button>
              <b-button type="submit" variant="outline-primary">Submit</b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <ConfigPreview :config="config" :includes="includes" />
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
  import ConfigPreview from '../../Common/Config/ConfigPreview.vue'
  import ConfigIncludes from '../../Common/Config/ConfigIncludes.vue'

  export default {
    components: {
      ConfigPreview,
      ConfigIncludes
    },
    props: ['params', 'smss', 'onSubmit', 'onClose'],
    data () {
      return {
        services: null,
        tag: '',
        config: {
          'group' : 'smsbox',
          'smsbox-id': 'dlrbox',
          'bearerbox-port': 13033,
          'bearerbox-host': 'localhost',
          'sendsms-port': null,
          'sendsms-url': null,
          'mo-recode': false,
          'log-level': 0
        },
        includes: []
      }
    },
    methods:{
      setIncludeEtitites(entity, value){ 
        if(value){
          const path = this.getEntityPath(entity)
          const idx = this.includes.findIndex(item=> item.includes(path))
          if(idx > -1) this.includes.splice(idx,1)
          if(value.includes(path)){
            this.includes.push(value)
          }else{
            this.includes.push(`${path}/${value}`)
          }
          
        }
      }
    },
    created () {
      this.tag = this.params.tag
      this.includes = this.params.includes

      const idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('smss') ))
      this.services = idx > -1 ? this.includes[idx].split(/\//).pop() : null


      this.config = { ...this.config, ...this.params.config }
    },
  }
</script>

<style>
  .row {
    margin: 10px;
  }
</style>
