export default [
  {
    width: '100px',
    label: 'ID',
    field: 'id',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Id'
    }
  },
  {
    width: '100px',
    label: 'Tag',
    field: 'tag',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Tag'
    }
  },
  {
    width: '150px',
    label: 'Keyword',
    field: 'config.keyword',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter'
    }
  },
  {
    width: '100px',
    label: 'Log level',
    field: 'config.log-level',
  },
  {
    width: '250px',
    label: 'Options',
    field: 'options',
  },
]
