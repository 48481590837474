<template>
    <div>
        <b-row>
            <b-col lg="12" md="12" sm="12">
                <b-overlay :show="show" rounded="sm">
                <b-card style="text-align:left">
                    <b-card-header>
                        HLR providers
                    </b-card-header>
                    <vue-good-table 
                        theme="polar-bear"
                        :group-options="groupOptions"
                        :columns="fields" 
                        :rows="rows" 
                        :line-numbers="true"
                        :pagination-options="paginationOptions"
                        :search-options="searchOptions"
                        :select-options="selectOptions">
                        
                        <div slot="table-actions">       
                            <b-button-group>
                                <router-link class="btn btn-primary btn-sm" to="/accounts/edit/hlr/" tag="button">
                                    <b-icon icon="folder-plus"></b-icon>
                                    New HLR provider
                                </router-link>
                            </b-button-group>
                        </div>

                        <template  slot="table-header-row" slot-scope="props">
                            <span v-if="props.column.field == 'options'">
                                <b-button-group>
                                    <b-button 
                                    v-b-tooltip.hover 
                                    title="Delete provider"
                                    @click="deleteProvider(props)"
                                    variant="outline-danger" 
                                    size="sm">
                                    <b-icon icon="trash"></b-icon>
                                </b-button>
                                <!--
                                <b-button 
                                    v-b-tooltip.hover 
                                    title="Test provider"
                                    @click="testProvider(props)"
                                    variant="outline-success" 
                                    size="sm">
                                        <b-icon icon="broadcast-pin"></b-icon>
                                    </b-button>
                                -->
                                <b-button 
                                v-b-tooltip.hover 
                                title="Test provider"
                                variant="outline-success" 
                                size="sm"
                                @click="$bvModal.show('bv-modal-test-' + props.row.hlr_providers_name)">
                                    <b-icon icon="broadcast-pin"></b-icon>
                                </b-button>
                                </b-button-group>
                                <b-modal
                                    title="Test HLR provider" 
                                    :id="'bv-modal-test-' + props.row.hlr_providers_name" 
                                    hide-footer>
                                    <b-row>
                                        <b-col>
                                            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                                <b-form 
                                                @submit.stop.prevent="handleSubmit(testProvider(props.row))">

                                                    <b-form-group
                                                        id="input-group-1"
                                                        label="Test number"
                                                        label-for="input-1"
                                                        description="Number to test"
                                                    >
                                                    <validation-provider 
                                                    name="msisdn" 
                                                    rules="required|msisdn" 
                                                    v-slot="validationContext">
                                                        <b-form-input
                                                        id="input-1"
                                                        v-model="testNumber"
                                                        type="text"
                                                        placeholder="Enter test number"
                                                        :state="getValidationState(validationContext)"
                                                        required
                                                        ></b-form-input>
                                                        <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </b-form-group>
                                                    
                                                    <b-button-group>
                                                        <b-button type="submit" variant="outline-success">
                                                            Submit
                                                        </b-button>
                                                        <b-button 
                                                        variant="outline-primary"
                                                        @click="$bvModal.hide('bv-modal-test-' + props.row.hlr_providers_name)">
                                                            Close Me
                                                        </b-button>
                                                    </b-button-group>
                                                </b-form>
                                            </validation-observer>
                                        </b-col>
                                        <b-col >
                                            <b-card style="min-height: 200px">
                                                <b-overlay :show="showTestOverlay" rounded="sm">
                                                    <h5>Provider response</h5>
                                                    <vue-json-pretty :data="testResponse" />
                                                </b-overlay>
                                            </b-card>
                                        </b-col>
                                    </b-row>

                                    
                                    
                                    
                                    
                                </b-modal>
                            </span>
                        </template>

                        <!--
                        <template slot="table-row" slot-scope="props">
                            
                            <span v-if="props.column.field == 'options'">
                                <b-button-group>
                                    <b-button 
                                        v-b-tooltip.hover 
                                        title="Delete parameter"
                                        @click="deleteProviderParameter(props)"
                                        variant="outline-danger" 
                                        size="sm">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </b-button-group>
                                
                            </span>
                            
                        </template>
                        -->
                        
                    </vue-good-table>
                </b-card>
                </b-overlay>
            </b-col>
        </b-row>
        
    </div>  
</template>

<script>
import { extend } from "vee-validate";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
extend('msisdn',  {
    validate(value) {
    var pattern = new RegExp('^[0-9]{8,14}')
    return pattern.test(value);
    },
    message: 'Please enter a valid number.'
});
export default {
    components:{
        VueJsonPretty
    },
    created(){
        this.fetchData()
    },
    methods:{
        confirmChangesModel(text){
            
            return this.$bvModal.msgBoxConfirm(text, {
                title: 'Confirmation required',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'info',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true
            })
        },
        testProvider(rowProps){
            
            let object = {
                send_sms_receiver: this.testNumber,
                send_sms_mt_mcc: null,
                hlr_providers_name : rowProps.hlr_providers_name,
                hlr_providers_base_url : rowProps.hlr_providers_base_url,
                hlr_providers_submit_type : rowProps.hlr_providers_submit_type,
                hlr_providers_response_mcc_key : rowProps.hlr_providers_response_mcc_key,
                hlr_providers_response_mnc_key : rowProps.hlr_providers_response_mnc_key,
                hlr_provider_params : rowProps.children.map(item => {
                    return item.hlr_providers_name+"="+item.hlr_providers_base_url //hlr_providers_name = name of parameter, hlr_providers_base_url = value
                }).join(','),
                hlr_providers_response_timeout: 3000
            }
            
            this.showTestOverlay = !this.showTestOverlay
            this.$http({
                url: '/api/hlr/provider/test',
                data: object,
                method: 'POST' 
             })
            .then(resp => {
                this.showTestOverlay = !this.showTestOverlay
                this.testResponse = resp.data
            })
            .catch(err => {
                console.log(err);
                this.showTestOverlay = !this.showTestOverlay
            })
            
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        deleteProviderParameter(rowProps){
            console.log(rowProps)
            
        },
        deleteProvider(rowProps){
            
            this.confirmChangesModel(`Delete ${rowProps.row.hlr_providers_name} provider?`)
                .then(confirmed => {
                    if(confirmed){

                    
                        this.show = !this.show
                        this.$http({
                            url: '/api/hlr/provider/delete',
                            data: rowProps.row,
                            method: 'POST' 
                        })
                        //.then(resp => {
                        .then(()=>{
                            this.rows.splice(rowProps.row.vgt_header_id,1)
                            this.show = !this.show
                            
                        })
                        .catch(err => {
                            console.log(err);
                            this.show = !this.show
                        })
                    }
                })
                .catch(err => {
                        console.log(err)
                })
            
            
            
            
        },
        fetchData(){
            this.show = !this.show
            //this.$http('/api/hlr/provider/read')
            this.$http('/api/gateway/hlr/read')
            .then(resp => {
                
                this.show = !this.show
                this.rows= resp.data
                
            })
            .catch(err => {
                console.log(err);
                this.show = !this.show
            })
        }
    },
    data() {
        return {
            showTestOverlay: false,
            testResponse: {},
            testNumber: null,
            show:false,
            fields:[
                { label: 'Provider/Key', field: 'hlr_providers_name', width:"250px",
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Name'
                    }
                },
                { label: 'Base URL/Value', field: 'hlr_providers_base_url'},
                { label: 'Submit type', field: 'hlr_providers_submit_type'},
                { label: 'Mcc key', field: 'hlr_providers_response_mcc_key'},
                { label: 'Mnc key', field: 'hlr_providers_response_mnc_key'},
                { label: 'Created', field: 'createdAt'},
                { label: 'Updated', field: 'updatedAt'},
                { label: 'Options', field: 'options'},
            ],
            rows: [ ],
            groupOptions: {
                enabled: true,
                collapsable: true
                
            },
            searchOptions: {
                enabled: true,
                placeholder: 'Search this table',
            },
            selectOptions: { enabled: false },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 100,
                position: 'bottom',
                perPageDropdown: [2,5,10,100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
        }
    }
}
</script>

<style>

</style>