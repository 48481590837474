var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{staticClass:"p-3"},[_c('b-col',{attrs:{"col":"","cols":"2"}},[_c('b-button-group',[_c('router-link',{staticClass:"btn btn-outline-primary",attrs:{"tag":"button","to":{
                        'path': '/notifications/templates/editor',
                        query: { 'tid': 'new'}
                    }}},[_c('b-icon',{attrs:{"icon":"file-plus"}}),_vm._v("  New template ")],1)],1)],1)],1),_c('b-row',[_c('vue-good-table',{attrs:{"theme":"polar-bear","columns":_vm.fields,"rows":_vm.rows,"pagination-options":_vm.paginationOptions,"search-options":_vm.searchOptions,"select-options":_vm.selectOptions},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'template_options')?_c('span',[_c('b-button-group',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-outline-primary btn-sm",attrs:{"title":"Edit","tag":"button","to":{
                                'path': '/notifications/templates/editor',
                                query: { 
                                    'tid': props.row.template_name,
                                    'cid': props.row.template_category
                                }
                            }}},[_c('b-icon',{attrs:{"icon":"pencil-square"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Delete","size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.deleteTemplate(props.row)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Duplicate","size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.duplicateTemplate(props.row)}}},[_c('b-icon',{attrs:{"icon":"files"}})],1)],1)],1):_vm._e()]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }