import View  from './../components/Routing/Layout.vue'
import Statistics from '../components/Statistics.vue'
import HlrStatisticsLayout from '../components/Statistics/HLR/Layout.vue' 
import MoStatistics from '../components/Statistics/MO/Statistics.vue'
import SearchMessages from '../components/Statistics/SearchMessages.vue'
import delify from './delify.js';

export default {
    path: 'statistics',
    name: 'statistics',
    component: View,
    redirect: 'statistics/mt',
    children:[
      {
        path: 'search',
        name: 'MT search',
        component: SearchMessages
      },
      {
        path: 'mt',
        name: 'MT stats',
        component: Statistics
      },
      {
        path: 'mo',
        name: 'MO search',
        component: MoStatistics
      },
      {
        path: 'hlr',
        name: 'HLR Stats',
        component: HlrStatisticsLayout
      },
      delify
      
      
    ]
  }