const addBlock = function (editor, blockName ,args = { label:null, attributes: {}, content: [], category: null}) {

    editor.Blocks.add(blockName, {
        label: args.label,
        attributes: args.attributes,
        category: args.category,
        content: args.content,
    });
}


export function macroAnchorOld(editor) {

    editor.Components.addType('macro-anchor', {
        model: {
            defaults: {
                tagName: 'a',
                editable: true,
                //disableRte: false,
                attributes: {
                    //contenteditable: 'true', // Set the contenteditable attribute to true
                    class: ''
                },
                
                style: {
                    //display: 'inline-flex',
                },
                traits: [
                    {
                        name: 'href',
                        label: 'Macro',
                        type: 'select', // Specify the trait type as 'select'
                        options: [
                            { id: '{PWRESETTOEKN}', name: 'Password reset token' },
                            { id: '{CONFIRMTOKEN}', name: 'Registration confirmation token' },
                            { id: '{INVOICELINK}', name: 'Invoice link' },
                            { id: '{PROFOMALINK}', name: 'Proforma link' },
                        ],
                    },
                    {
                        name: 'href',
                        label: 'Href',
                        type: 'text',
                    },
                    'target'
                ],
                
                styles: [
                    //'cke_editable', 'cke_editable_inline', 'cke_contents_ltr','cke_show_borders'
                ],
                content:'Link'
                //components: model => {
                    //var text = model.get('text') ? model.get('text') : 'Link'
                    //console.log(text)
                    //return `<a>Header test: ${model.get('type')}</a>`;
                    //return `<span>${model.get('text') ? model.get('text') : 'link'}</span>`
                    //return `<a>${}</a>`
                    //return text
                //},
            },
            init() {
                //this.on('click', ()=>{console.log('click')});
            },
        },
        //isComponent: el => el.tagName === 'A',
        view: {
            tagName: 'a',
            //content: 'Link',
            onRender({ el }) {
                
                console.log(el.innerHTML)

            },
            events: {

                //'click': (event) => {
                'click': function() {
                    //console.log(this.model.get('disableRte'))
                    //this.attributes.contenteditable = true
                },
                'dblclick': function() {

                    //console.log(this.model.attributes.contenteditable)
                    //console.log(this.rte)
                    this.el.contentEditable = true;
                },
                'focusout': function() {
                    const { el, model } = this;
                    this.el.contentEditable = false;
                    model.set('content', el.innerHTML)
                },
                //'keydown': function(e) {
                'keydown': function() {
                   
                }
            },
        },
        
        
    })
    


}

export function macroAnchor(editor) {
    
    var originalLink = editor.Components.getType('link')

    let defs = Object.assign({}, originalLink.model.prototype.defaults, {
        tagName: 'a',
        //name: '',
        draggable: '*',
        droppable: false,
        attributes: {
            class: 'button'
        },
        content: 'Link',
        traits: [
            {
                name: 'href',
                label: 'Macro',
                type: 'select', // Specify the trait type as 'select'
                options: [
                    { id: '{PWRESETTOEKN}', name: 'Password reset token' },
                    { id: '{CONFIRMTOKEN}', name: 'Registration confirmation token' },
                    { id: '{INVOICELINK}', name: 'Invoice link' },
                ],
            },
            'href', 'target'
        ]
     })
    

    editor.Components.addType('macro-anchor', {
        extend: 'link',
        model: {
            defaults: defs
        },
        extendView: 'link',
        view: {},
    })

    addBlock(editor, 'macro-anchor', {
        label: 'macro-anchor',
        attributes: { class: 'fa fa-link' },
        category: 'macros',
        content: [{ type: 'macro-anchor' }],
    })
    

}

export function blockAnchor(editor) {
    console.log(editor)
}