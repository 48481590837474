export const countries = [
  { text:"Afghanistan", dial_code: "+93", value:"AF", flag: "🇦🇫" },
  { text:"Åland Islands", dial_code: "+358", value:"AX", flag: "🇦🇽" },
  { text:"Albania", dial_code: "+355", value:"AL", flag: "🇦🇱" },
  { text:"Algeria", dial_code: "+213", value:"DZ", flag: "🇩🇿" },
  {
    text:"American Samoa",
    dial_code: "+1684",
    code: "AS",
    flag: "🇺🇸",
  },
  { text:"Andorra", dial_code: "+376", value:"AD", flag: "🇦🇩" },
  { text:"Angola", dial_code: "+244", value:"AO", flag: "🇦🇴" },
  { text:"Anguilla", dial_code: "+1264", value:"AI", flag: "🇦🇮" },
  { text:"Antarctica", dial_code: "+672", value:"AQ", flag: "🇦🇶" },
  {
    text:"Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
    flag: "🇦🇬",
  },
  { text:"Argentina", dial_code: "+54", value:"AR", flag: "🇦🇷" },
  { text:"Armenia", dial_code: "+374", value:"AM", flag: "🇦🇲" },
  { text:"Aruba", dial_code: "+297", value:"AW", flag: "🇦🇼" },
  { text:"Australia", dial_code: "+61", value:"AU", flag: "🇦🇺" },
  { text:"Austria", dial_code: "+43", value:"AT", flag: "🇦🇹" },
  { text:"Azerbaijan", dial_code: "+994", value:"AZ", flag: "🇦🇿" },
  { text:"Bahamas", dial_code: "+1242", value:"BS", flag: "🇧🇸" },
  { text:"Bahrain", dial_code: "+973", value:"BH", flag: "🇧🇸" },
  { text:"Bangladesh", dial_code: "+880", value:"BD", flag: "🇧🇩" },
  { text:"Barbados", dial_code: "+1246", value:"BB", flag: "🇧🇧" },
  { text:"Belarus", dial_code: "+375", value:"BY", flag: "🇧🇾" },
  { text:"Belgium", dial_code: "+32", value:"BE", flag: "🇧🇪" },
  { text:"Belize", dial_code: "+501", value:"BZ", flag: "🇧🇿" },
  { text:"Benin", dial_code: "+229", value:"BJ", flag: "🇧🇯" },
  { text:"Bermuda", dial_code: "+1441", value:"BM", flag: "🇧🇲" },
  { text:"Bhutan", dial_code: "+975", value:"BT", flag: "🇧🇹" },
  {
    text:"Bolivia, Plurinational State of bolivia",
    dial_code: "+591",
    code: "BO",
    flag: "🇧🇴",
  },
  {
    text:"Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
    flag: "🇧🇦",
  },
  { text:"Botswana", dial_code: "+267", value:"BW", flag: "🇧🇼" },
  { text:"Bouvet Island", dial_code: "+47", value:"BV", flag: "🏳" },
  { text:"Brazil", dial_code: "+55", value:"BR", flag: "🇧🇷" },
  {
    text:"British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
    flag: "🇮🇴",
  },
  {
    text:"Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
    flag: "🇧🇳",
  },
  { text:"Bulgaria", dial_code: "+359", value:"BG", flag: "🇧🇬" },
  { text:"Burkina Faso", dial_code: "+226", value:"BF", flag: "🇧🇫" },
  { text:"Burundi", dial_code: "+257", value:"BI", flag: "🇧🇮" },
  { text:"Cambodia", dial_code: "+855", value:"KH", flag: "🇰🇭" },
  { text:"Cameroon", dial_code: "+237", value:"CM", flag: "🇨🇲" },
  { text:"Canada", dial_code: "+1", value:"CA", flag: "🇨🇦" },
  { text:"Cape Verde", dial_code: "+238", value:"CV", flag: "🇨🇻" },
  {
    text:"Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
    flag: "🇰🇾",
  },
  {
    text:"Central African Republic",
    dial_code: "+236",
    code: "CF",
    flag: "🇨🇫",
  },
  { text:"Chad", dial_code: "+235", value:"TD", flag: "🇹🇩" },
  { text:"Chile", dial_code: "+56", value:"CL", flag: "🇨🇱" },
  { text:"China", dial_code: "+86", value:"CN", flag: "🇨🇳" },
  {
    text:"Christmas Island",
    dial_code: "+61",
    code: "CX",
    flag: "🇨🇽",
  },
  {
    text:"Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
    flag: "🇨🇨",
  },
  { text:"Colombia", dial_code: "+57", value:"CO", flag: "🇨🇴" },
  { text:"Comoros", dial_code: "+269", value:"KM", flag: "🇰🇲" },
  { text:"Congo", dial_code: "+242", value:"CG", flag: "🇨🇬" },
  {
    text:"Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
    flag: "🇨🇩",
  },
  { text:"Cook Islands", dial_code: "+682", value:"CK", flag: "🇨🇰" },
  { text:"Costa Rica", dial_code: "+506", value:"CR", flag: "🇨🇷" },
  { text:"Cote d'Ivoire", dial_code: "+225", value:"CI", flag: "🇨🇮" },
  { text:"Croatia", dial_code: "+385", value:"HR", flag: "🇭🇷" },
  { text:"Cuba", dial_code: "+53", value:"CU", flag: "🇨🇺" },
  { text:"Cyprus", dial_code: "+357", value:"CY", flag: "🇨🇾" },
  { text:"Czech Republic", dial_code: "+420", value:"CZ", flag: "🇨🇿" },
  { text:"Denmark", dial_code: "+45", value:"DK", flag: "🇩🇰" },
  { text:"Djibouti", dial_code: "+253", value:"DJ", flag: "🇩🇯" },
  { text:"Dominica", dial_code: "+1767", value:"DM", flag: "🇩🇲" },
  {
    text:"Dominican Republic",
    dial_code: "+1849",
    code: "DO",
    flag: "🇨🇩",
  },
  { text:"Ecuador", dial_code: "+593", value:"EC", flag: "🇪🇨" },
  { text:"Egypt", dial_code: "+20", value:"EG", flag: "🇪🇬" },
  { text:"El Salvador", dial_code: "+503", value:"SV", flag: "🇸🇻" },
  {
    text:"Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
    flag: "🇬🇶",
  },
  { text:"Eritrea", dial_code: "+291", value:"ER", flag: "🇪🇷" },
  { text:"Estonia", dial_code: "+372", value:"EE", flag: "🇪🇪" },
  { text:"Ethiopia", dial_code: "+251", value:"ET", flag: "🇪🇹" },
  {
    text:"Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
    flag: "🇫🇰",
  },
  { text:"Faroe Islands", dial_code: "+298", value:"FO", flag: "🇫🇴" },
  { text:"Fiji", dial_code: "+679", value:"FJ", flag: "🇫🇯" },
  { text:"Finland", dial_code: "+358", value:"FI", flag: "🇫🇮" },
  { text:"France", dial_code: "+33", value:"FR", flag: "🇫🇷" },
  { text:"French Guiana", dial_code: "+594", value:"GF", flag: "🇬🇫" },
  {
    text:"French Polynesia",
    dial_code: "+689",
    code: "PF",
    flag: "🇵🇫",
  },
  {
    text:"French Southern Territories",
    dial_code: "+262",
    code: "TF",
    flag: "🇹🇫",
  },
  { text:"Gabon", dial_code: "+241", value:"GA", flag: "🇬🇦" },
  { text:"Gambia", dial_code: "+220", value:"GM", flag: "🇬🇲" },
  { text:"Georgia", dial_code: "+995", value:"GE", flag: "🇬🇪" },
  { text:"Germany", dial_code: "+49", value:"DE", flag: "🇩🇪" },
  { text:"Ghana", dial_code: "+233", value:"GH", flag: "🇬🇭" },
  { text:"Gibraltar", dial_code: "+350", value:"GI", flag: "🇬🇮" },
  { text:"Greece", dial_code: "+30", value:"GR", flag: "🇬🇷" },
  { text:"Greenland", dial_code: "+299", value:"GL", flag: "🇬🇱" },
  { text:"Grenada", dial_code: "+1473", value:"GD", flag: "🇬🇩" },
  { text:"Guadeloupe", dial_code: "+590", value:"GP", flag: "🇬🇵" },
  { text:"Guam", dial_code: "+1671", value:"GU", flag: "🇬🇺" },
  { text:"Guatemala", dial_code: "+502", value:"GT", flag: "🇬🇹" },
  { text:"Guernsey", dial_code: "+44", value:"GG", flag: "🇬🇬" },
  { text:"Guinea", dial_code: "+224", value:"GN", flag: "🇬🇳" },
  { text:"Guinea-Bissau", dial_code: "+245", value:"GW", flag: "🇬🇼" },
  { text:"Guyana", dial_code: "+592", value:"GY", flag: "🇬🇾" },
  { text:"Haiti", dial_code: "+509", value:"HT", flag: "🇭🇹" },
  {
    text:"Heard Island and Mcdonald Islands",
    dial_code: "+0",
    code: "HM",
    flag: "🏳",
  },
  {
    text:"Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
    flag: "🇻🇦",
  },
  { text:"Honduras", dial_code: "+504", value:"HN", flag: "🇭🇳" },
  { text:"Hong Kong", dial_code: "+852", value:"HK", flag: "🇭🇰" },
  { text:"Hungary", dial_code: "+36", value:"HU", flag: "🇭🇺" },
  { text:"Iceland", dial_code: "+354", value:"IS", flag: "🇮🇸" },
  { text:"India", dial_code: "+91", value:"IN", flag: "🇮🇳" },
  { text:"Indonesia", dial_code: "+62", value:"ID", flag: "🇮🇩" },
  {
    text:"Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
    flag: "🇮🇷",
  },
  { text:"Iraq", dial_code: "+964", value:"IQ", flag: "🇮🇶" },
  { text:"Ireland", dial_code: "+353", value:"IE", flag: "🇮🇪" },
  { text:"Isle of Man", dial_code: "+44", value:"IM", flag: "🇮🇲" },
  { text:"Israel", dial_code: "+972", value:"IL", flag: "🇮🇱" },
  { text:"Italy", dial_code: "+39", value:"IT", flag: "🇮🇹" },
  { text:"Jamaica", dial_code: "+1876", value:"JM", flag: "🇯🇲" },
  { text:"Japan", dial_code: "+81", value:"JP", flag: "🇯🇵" },
  { text:"Jersey", dial_code: "+44", value:"JE", flag: "🇯🇪" },
  { text:"Jordan", dial_code: "+962", value:"JO", flag: "🇯🇴" },
  { text:"Kazakhstan", dial_code: "+7", value:"KZ", flag: "🇰🇿" },
  { text:"Kenya", dial_code: "+254", value:"KE", flag: "🇰🇪" },
  { text:"Kiribati", dial_code: "+686", value:"KI", flag: "🇰🇮" },
  {
    text:"Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
    flag: "🇰🇵",
  },
  {
    text:"Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
    flag: "🇰🇷",
  },
  { text:"Kosovo", dial_code: "+383", value:"XK", flag: "🇽🇰" },
  { text:"Kuwait", dial_code: "+965", value:"KW", flag: "🇰🇼" },
  { text:"Kyrgyzstan", dial_code: "+996", value:"KG", flag: "🇰🇬" },
  { text:"Laos", dial_code: "+856", value:"LA", flag: "🇱🇦" },
  { text:"Latvia", dial_code: "+371", value:"LV", flag: "🇱🇻" },
  { text:"Lebanon", dial_code: "+961", value:"LB", flag: "🇱🇧" },
  { text:"Lesotho", dial_code: "+266", value:"LS", flag: "🇱🇸" },
  { text:"Liberia", dial_code: "+231", value:"LR", flag: "🇱🇷" },
  {
    text:"Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
    flag: "🇱🇾",
  },
  { text:"Liechtenstein", dial_code: "+423", value:"LI", flag: "🇱🇮" },
  { text:"Lithuania", dial_code: "+370", value:"LT", flag: "🇱🇹" },
  { text:"Luxembourg", dial_code: "+352", value:"LU", flag: "🇱🇺" },
  { text:"Macao", dial_code: "+853", value:"MO", flag: "🇲🇴" },
  { text:"Macedonia", dial_code: "+389", value:"MK", flag: "🇲🇰" },
  { text:"Madagascar", dial_code: "+261", value:"MG", flag: "🇲🇬" },
  { text:"Malawi", dial_code: "+265", value:"MW", flag: "🇲🇼" },
  { text:"Malaysia", dial_code: "+60", value:"MY", flag: "🇲🇾" },
  { text:"Maldives", dial_code: "+960", value:"MV", flag: "🇲🇻" },
  { text:"Mali", dial_code: "+223", value:"ML", flag: "🇲🇱" },
  { text:"Malta", dial_code: "+356", value:"MT", flag: "🇲🇹" },
  {
    text:"Marshall Islands",
    dial_code: "+692",
    code: "MH",
    flag: "🇲🇭",
  },
  { text:"Martinique", dial_code: "+596", value:"MQ", flag: "🇲🇶" },
  { text:"Mauritania", dial_code: "+222", value:"MR", flag: "🇲🇷" },
  { text:"Mauritius", dial_code: "+230", value:"MU", flag: "🇲🇺" },
  { text:"Mayotte", dial_code: "+262", value:"YT", flag: "🇾🇹" },
  { text:"Mexico", dial_code: "+52", value:"MX", flag: "🇲🇽" },
  {
    text:"Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
    flag: "🇫🇲",
  },
  { text:"Moldova", dial_code: "+373", value:"MD", flag: "🇲🇩" },
  { text:"Monaco", dial_code: "+377", value:"MC", flag: "🇲🇨" },
  { text:"Mongolia", dial_code: "+976", value:"MN", flag: "🇲🇳" },
  { text:"Montenegro", dial_code: "+382", value:"ME", flag: "🇲🇪" },
  { text:"Montserrat", dial_code: "+1664", value:"MS", flag: "🇲🇸" },
  { text:"Morocco", dial_code: "+212", value:"MA", flag: "🇲🇦" },
  { text:"Mozambique", dial_code: "+258", value:"MZ", flag: "🇲🇿" },
  { text:"Myanmar", dial_code: "+95", value:"MM", flag: "🇲🇲" },
  { text:"Namibia", dial_code: "+264", value:"NA", flag: "🇳🇦" },
  { text:"Nauru", dial_code: "+674", value:"NR", flag: "🇳🇷" },
  { text:"Nepal", dial_code: "+977", value:"NP", flag: "🇳🇵" },
  { text:"Netherlands", dial_code: "+31", value:"NL", flag: "🇳🇱" },
  {
    text:"Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
    flag: "🇳🇱",
  },
  { text:"New Caledonia", dial_code: "+687", value:"NC", flag: "🇳🇨" },
  { text:"New Zealand", dial_code: "+64", value:"NZ", flag: "🇳🇿" },
  { text:"Nicaragua", dial_code: "+505", value:"NI", flag: "🇳🇮" },
  { text:"Niger", dial_code: "+227", value:"NE", flag: "🇳🇪" },
  { text:"Nigeria", dial_code: "+234", value:"NG", flag: "🇳🇬" },
  { text:"Niue", dial_code: "+683", value:"NU", flag: "🇳🇺" },
  { text:"Norfolk Island", dial_code: "+672", value:"NF", flag: "🇳🇫" },
  {
    text:"Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
    flag: "🏳",
  },
  { text:"Norway", dial_code: "+47", value:"NO", flag: "🇳🇴" },
  { text:"Oman", dial_code: "+968", value:"OM", flag: "🇴🇲" },
  { text:"Pakistan", dial_code: "+92", value:"PK", flag: "🇵🇰" },
  { text:"Palau", dial_code: "+680", value:"PW", flag: "🇵🇼" },
  {
    text:"Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
    flag: "🇵🇸",
  },
  { text:"Panama", dial_code: "+507", value:"PA", flag: "🇵🇦" },
  {
    text:"Papua New Guinea",
    dial_code: "+675",
    code: "PG",
    flag: "🇵🇬",
  },
  { text:"Paraguay", dial_code: "+595", value:"PY", flag: "🇵🇾" },
  { text:"Peru", dial_code: "+51", value:"PE", flag: "🇵🇪" },
  { text:"Philippines", dial_code: "+63", value:"PH", flag: "🇵🇭" },
  { text:"Pitcairn", dial_code: "+64", value:"PN", flag: "🇵🇳" },
  { text:"Poland", dial_code: "+48", value:"PL", flag: "🇵🇱" },
  { text:"Portugal", dial_code: "+351", value:"PT", flag: "🇵🇹" },
  { text:"Puerto Rico", dial_code: "+1939", value:"PR", flag: "🇵🇷" },
  { text:"Qatar", dial_code: "+974", value:"QA", flag: "🇶🇦" },
  { text:"Romania", dial_code: "+40", value:"RO", flag: "🇷🇴" },
  { text:"Russia", dial_code: "+7", value:"RU", flag: "🇷🇺" },
  { text:"Rwanda", dial_code: "+250", value:"RW", flag: "🇷🇼" },
  { text:"Reunion", dial_code: "+262", value:"RE", flag: "🇫🇷" },
  {
    text:"Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
    flag: "🇧🇱",
  },
  {
    text:"Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
    flag: "🇸🇭",
  },
  {
    text:"Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
    flag: "🇰🇳",
  },
  { text:"Saint Lucia", dial_code: "+1758", value:"LC", flag: "🇱🇨" },
  { text:"Saint Martin", dial_code: "+590", value:"MF", flag: "🏳" },
  {
    text:"Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
    flag: "🇵🇲",
  },
  {
    text:"Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
    flag: "🇻🇨",
  },
  { text:"Samoa", dial_code: "+685", value:"WS", flag: "🇼🇸" },
  { text:"San Marino", dial_code: "+378", value:"SM", flag: "🇸🇲" },
  {
    text:"Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
    flag: "🇸🇹",
  },
  { text:"Saudi Arabia", dial_code: "+966", value:"SA", flag: "🇸🇦" },
  { text:"Senegal", dial_code: "+221", value:"SN", flag: "🇸🇳" },
  { text:"Serbia", dial_code: "+381", value:"RS", flag: "🇷🇸" },
  { text:"Seychelles", dial_code: "+248", value:"SC", flag: "🇸🇨" },
  { text:"Sierra Leone", dial_code: "+232", value:"SL", flag: "🇸🇱" },
  { text:"Singapore", dial_code: "+65", value:"SG", flag: "🇸🇬" },
  { text:"Slovakia", dial_code: "+421", value:"SK", flag: "🇸🇰" },
  { text:"Slovenia", dial_code: "+386", value:"SI", flag: "🇸🇮" },
  {
    text:"Solomon Islands",
    dial_code: "+677",
    code: "SB",
    flag: "🇸🇧",
  },
  { text:"Somalia", dial_code: "+252", value:"SO", flag: "🇸🇴" },
  { text:"South Africa", dial_code: "+27", value:"ZA", flag: "🇿🇦" },
  { text:"South Sudan", dial_code: "+211", value:"SS", flag: "🇸🇸" },
  {
    text:"South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
    flag: "🇬🇸",
  },
  { text:"Spain", dial_code: "+34", value:"ES", flag: "🇪🇸" },
  { text:"Sri Lanka", dial_code: "+94", value:"LK", flag: "🇱🇰" },
  { text:"Sudan", dial_code: "+249", value:"SD", flag: "🇸🇩" },
  { text:"Suriname", dial_code: "+597", value:"SR", flag: "🇸🇷" },
  {
    text:"Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
    flag: "🇩🇰",
  },
  { text:"Swaziland", dial_code: "+268", value:"SZ", flag: "🇸🇿" },
  { text:"Sweden", dial_code: "+46", value:"SE", flag: "🇸🇪" },
  { text:"Switzerland", dial_code: "+41", value:"CH", flag: "🇨🇭" },
  {
    text:"Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
    flag: "🇸🇾",
  },
  { text:"Taiwan", dial_code: "+886", value:"TW", flag: "🇹🇼" },
  { text:"Tajikistan", dial_code: "+992", value:"TJ", flag: "🇹🇯" },
  {
    text:"Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
    flag: "🇹🇿",
  },
  { text:"Thailand", dial_code: "+66", value:"TH", flag: "🇹🇭" },
  { text:"Timor-Leste", dial_code: "+670", value:"TL", flag: "🇹🇱" },
  { text:"Togo", dial_code: "+228", value:"TG", flag: "🇹🇬" },
  { text:"Tokelau", dial_code: "+690", value:"TK", flag: "🇹🇰" },
  { text:"Tonga", dial_code: "+676", value:"TO", flag: "🇹🇴" },
  {
    text:"Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
    flag: "🇹🇹",
  },
  { text:"Tunisia", dial_code: "+216", value:"TN", flag: "🇹🇳" },
  { text:"Turkey", dial_code: "+90", value:"TR", flag: "🇹🇷" },
  { text:"Turkmenistan", dial_code: "+993", value:"TM", flag: "🇹🇲" },
  {
    text:"Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
    flag: "🇹🇨",
  },
  { text:"Tuvalu", dial_code: "+688", value:"TV", flag: "🇹🇻" },
  { text:"Uganda", dial_code: "+256", value:"UG", flag: "🇺🇬" },
  { text:"Ukraine", dial_code: "+380", value:"UA", flag: "🇺🇦" },
  {
    text:"United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    flag: "🇦🇪",
  },
  { text:"United Kingdom", dial_code: "+44", value:"GB", flag: "🇬🇧" },
  { text:"United States", dial_code: "+1", value:"US", flag: "🇺🇸" },
  { text:"Uruguay", dial_code: "+598", value:"UY", flag: "🇺🇾" },
  { text:"Uzbekistan", dial_code: "+998", value:"UZ", flag: "🇺🇿" },
  { text:"Vanuatu", dial_code: "+678", value:"VU", flag: "🇻🇺" },
  {
    text:"Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
    flag: "🇻🇪",
  },
  { text:"Vietnam", dial_code: "+84", value:"VN", flag: "🇻🇳" },
  {
    text:"Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
    flag: "🇻🇬",
  },
  {
    text:"Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
    flag: "🇻🇮",
  },
  {
    text:"Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
    flag: "🇼🇫",
  },
  { text:"Yemen", dial_code: "+967", value:"YE", flag: "🇾🇪" },
  { text:"Zambia", dial_code: "+260", value:"ZM", flag: "🇿🇲" },
  { text:"Zimbabwe", dial_code: "+263", value:"ZW", flag: "🇿🇼" },
];