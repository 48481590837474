<template>
    <div>
        
        <form ref="form" id="recurring-form" @submit.prevent="handleSubmit">
            <b-row>
                <b-col lg="6" sm="12">
                    <b-form-group
                        label="Subject"
                        label-for="subject-input"
                        invalid-feedback="Subject is required"
                        :state="form.notifications_schedule_subject != null">
                        <b-form-input
                            id="subject-input"
                            v-model="form.notifications_schedule_subject"
                            placeholder="Enter subject"
                            required
                            ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                    <b-form-group
                        label="From"
                        label-for="from-input"
                        invalid-feedback="From is required"
                        :state="form.notifications_schedule_from != null">
                        <b-form-input
                            type="email"
                            id="subject-input"
                            v-model="form.notifications_schedule_from"
                            placeholder="Enter subject"
                            required
                            ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="8" sm="12">
                    <b-form-group 
                        label="Template"
                        label-for="template-input"
                        invalid-feedback="Template is required"
                        :state="form.notifications_schedule_template != null">

                    <multiselect 
                        v-model="multiselect" 
                        @input="form.notifications_schedule_template = multiselect.template_name_short;  form.notifications_schedule_template_category = multiselect.template_category"
                        deselect-label="Can't remove this value" 
                        track-by="template_name_short" 
                        label="template_name_short" 
                        placeholder="Select one" 
                        :options="templates" 
                        :searchable="false" 
                        :allow-empty="false">
                        <template slot="singleLabel" slot-scope="{ option }">{{ option.template_name_short }}</template>
                    </multiselect>
                        
                    </b-form-group>
                </b-col>
                <b-col lg="4" sm="12">
                    <b-form-group 
                        label="Timezone"
                        label-for="timezone-input"
                        invalid-feedback="Timezone is required"
                        :state="form.notifications_schedule_tz != null">
                            <b-form-select
                                style="width:100% !important"
                                id="timezone-input"
                                v-model="form.notifications_schedule_tz"
                                required>

                                    <b-form-select-option :value="null" disabled>-- Please select timezone --</b-form-select-option>
                                    <b-form-select-option value="CET" >Central European Time</b-form-select-option>
                                    <b-form-select-option value="EET" >Eastern European Time</b-form-select-option>
                                    <b-form-select-option value="WET" >Western European Time</b-form-select-option>
                                    <b-form-select-option value="JST" >Japan Standard Time</b-form-select-option>
                                    <b-form-select-option value="CST" >China Standard Time</b-form-select-option>                                    
                                    <b-form-select-option value="EST" >Eastern Standard Time (US)</b-form-select-option>
                                    <b-form-select-option value="CST" >Central Standard Time (US)</b-form-select-option>
                                    <b-form-select-option value="PST" >Pacific Standard Time (US)</b-form-select-option>
                                    <b-form-select-option value="GMT" >GMT</b-form-select-option>
                                    <b-form-select-option value="UTC" >UTC</b-form-select-option>
                                    
                                    
                            </b-form-select>
                        
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row >
                <b-col lg=12 style="display:inline-flex">
                    <b-form-checkbox
                        class="m-2 p-2"
                        v-model="allRecipients"
                        name="allRecipients"
                        :value="true"
                        :unchecked-value="false">
                            <h6>
                                Send to all recipients
                            </h6>
                            
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg=12>
                   <b-form-group
                        label="Recipients"
                        label-for="recipients-input"
                        invalid-feedback="Recipients are required"
                        :state="form.notifications_schedule_to != null || allRecipients">
                        <multiselect 
                            :disabled="allRecipients"
                            v-model="form.notifications_schedule_to" 
                            :mix-height="300"
                            :allow-empty="false"
                            :group-select="true" 
                            placeholder="Type to search"
                            :multiple="true" 
                            :searchable="true"
                            group-label="notifications_users_uuid"
                            group-values="emails"
                            label="notifications_users_email" 
                            track-by="notifications_users_email"
                            :options="users">
                        </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>

            <h6>Cron like settings</h6>

            <ChronLikeRow 
                v-for="(row, index) in chronRows" :key="index" :value="index"
                :ref="row.ref"
                :type="row.type"
                :invalidFeedback="row.invalidFeedback"
                :label="row.label"
                :labelValue="row.labelValue"
                :submitRef="row.submitRef"
                :required="row.required"
                :maxRange="row.maxRange"
                :titles="row.titles"

                v-model="form[row.model]"
            />
            
            <button ref="submit" style="display:none;">Submit</button>
            
        </form>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import ChronLikeRows from './json/chronLikeRows.json'
import ChronLikeRow from './CronLikeRow.vue'
export default {
    components:{
        Multiselect,
        ChronLikeRow
    },
    props: ['editRowData', 'templates', 'users'],
    methods:{
        handleSubmit(){
            const valid = this.form.notifications_schedule_template != null && this.$refs.form.checkValidity()
            this.form.notifications_schedule_job_status = 1
            this.form.sendToAll = this.allRecipients
            
            if(valid){
               this.$emit('submit', this.form)
            }
        },
        submitForm(){
            this.$refs.submit.click()
        },
        
    },
    data(){
        return {
            multiselect:null,
            testing: null,
            form: this.editRowData,
            chronRows: ChronLikeRows,
            allRecipients: true
        }
    }
}
</script>

<style>

</style>