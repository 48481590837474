<template>
    <b-modal
      id="modal-upload-csv"
      ref="modal"
      title="Upload providers pricing CSV file"
      size="lg"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk">
      <b-form ref="form">
        <b-form-group label="CSV file" label-for="form-image" label-cols-lg="2">
            <b-form-file
                required
                id="css-file" 
                v-model="pricingFile"
                :disabled="busy" 
                accept=".csv">
            </b-form-file>
        </b-form-group>
        <br>
        <b-form-checkbox
            id="checkbox-1"
            v-model="notifyClients"
            name="checkbox-1"
            value=true
            unchecked-value=false>
            Notify affected clients
        </b-form-checkbox>
            
      </b-form>
    </b-modal>
</template>

<script>
//import { XOctagonIcon } from 'vue-feather-icons'
export default {
    components: {
        //XOctagonIcon
    },
    props: ['providerAccountId'],
    data() {
        return {
            busy: false,
            pricingFile: null,
            notifyClients: false
        }
    },
    methods: {
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            
            return valid
        },
        resetModal() {
            this.name = ''
            this.nameState = null
            this.notifyClients = false
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            
            let formData = new FormData();

            formData.append("fileToUpload", this.pricingFile);
            formData.append("providerName", this.providerAccountId.providers_accounts_provider_id);
            formData.append("providerAccoutAlias", this.providerAccountId.providers_accounts_account_alias);
            formData.append("providerAccoutId", this.providerAccountId.providers_accounts_id);
            formData.append("ourProviderAccoutId", this.providerAccountId.providers_accounts_our_provider_id);
            formData.append("notifyClients", this.notifyClients);
            this.$emit('uploadCsv', formData)
            
/*
            let confirmed = await this.$parent.confirmChangesModel("Are you sure?")
            this.$nextTick(() => {
                this.$bvModal.hide('modal-upload-csv')
            })

            if(confirmed){
                let res = await this.$http.post(
                '/api/csv/create', 
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                );

                if(res.status == 200){

                    await this.$parent.doneModel(JSON.stringify(res.data, null, 2))
                    this.$parent.fetchData()

                }
            }
*/
            
            
            
      }
    }
}
</script>

<style>


</style>