export default [
  {
    width: '100px',
    label: 'UID',
    field: 'id',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Id'
    }
  },
  {
    width: '50px',
    label: 'Status',
    field: 'status'
  },
  {
    width: '100px',
    label: 'Tag',
    field: 'tag',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Tag'
    }
  },
  {
    width: '50px',
    label: 'OSMPPB Id',
    field: 'config.opensmppbox-id',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    width: '50px',
    label: 'System Id',
    field: 'config.our-system-id',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    width: '50px',
    label: 'Port',
    field: 'config.opensmppbox-port',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    width: '50px',
    label: 'SMSc',
    field: 'config.route-to-smsc',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter SMSc'
    }
  },
  {
    width: '50px',
    label: 'Pam',
    field: 'config.enable-pam'
  },
  {
    width: '50px',
    label: 'BB Host',
    field: 'config.bearerbox-host',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    width: '50px',
    label: 'BB Port',
    field: 'config.bearerbox-port',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    width: '100px',
    label: 'Log level',
    field: 'config.log-level',
  },
  {
    width: '250px',
    label: 'Options',
    field: 'options',
  },
]
