<template>
    <div>
        <b-row>
            <b-col class="m-2">
                 <b-button variant="success"  v-b-modal.add-user-modal>
                    <b-icon icon="person-plus"></b-icon> Add user access
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <Table :usersData="usersData"/>
                <Modal :uuids="uuids"/>
            </b-col>
        </b-row>
        <!--
        <b-row>
            <Tablea :usersData="usersData"/>
        </b-row>
        -->
    </div>
</template>

<script>
//import Tablea from './Settings.vue'
import Table from './Table.vue'
import Modal from './AddUserModal.vue'
export default {
    components:{
        //Tablea,
        Table,
        Modal
    },
    data(){
        return {
            usersData: [],
            uuids: []
        }
    },
    mounted(){
        this.getData()
    },    

    methods:{
        parseUuid(data){
            return data.map(item => {
                return {
                    text: item.user_account_uuid,
                    value: item.user_account_uuid,
                }
            })
        },
        async getData(){
            //const res  = this.$http.get('/api/settings/read-users?uuid=' + this.$route.params.uuid),
            const res  = await this.$http.get('/api/auth/users/read')
            if(res && res.status == 200){
                
                this.usersData = res.data
                this.uuids = res.data ? this.parseUuid(res.data) : []
            }


        }
    }
}
</script>

<style>

</style>