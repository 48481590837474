<template>
    <b-modal 
        id="modal-add-url" 
        title="Add a short Url"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk">
        
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Url:"
                    label-for="url-input"
                    invalid-feedback="Url is required"
                    :state="urlState">
                    <b-form-input
                        @input="urlInput"
                        id="url-input"
                        placeholder="New short Url"
                        v-model="url"
                        type="url"
                        :state="urlState"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Comments:"
                    label-for="comments-input">
                    <b-form-textarea
                        id="textarea"
                        v-model="comments"
                        placeholder="Enter something..."
                        rows="3"
                        max-rows="6">
                    </b-form-textarea>
                </b-form-group>
                <b-form-group label="Is https:">
                    <b-form-checkbox
                        v-model="https"
                        :value="true"
                        :unchecked-value="false">
                        link is https
                    </b-form-checkbox>
                </b-form-group>
            </form>
    </b-modal>
</template>

<script>
export default {
     data() {
      return {
        url: '',
        urlState: null,
        comments: '',
        https: false
        //submittedNames: []
      }
    },
    props: ['submit'],
    methods: {
        urlInput(url){
            const urlRegex = /^https:\/\//;
            this.https = urlRegex.test(url)
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.urlState = valid
            return valid
        },
        resetModal() {
            this.url = ''
            this.comments = ''
            this.urlState = null

        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        async handleSubmit() {
            const valid = this.checkFormValidity()
            
            if (!valid) return

  
            this.submit({
                url: this.url,
                comments: this.comments,
                https: this.https
            })
  

        }
    }
}
</script>

<style>

</style>