<template>
   <b-button-group>
        <b-button
            @click="$bvModal.show('modal-hlr-routing')"
            variant="outline-primary"
            size="sm">
            <b-icon icon="sim"></b-icon> Configure selected GEOs
        </b-button>
        <b-button
            @click="resetToDefaults"
            variant="outline-danger"
            size="sm">
            <b-icon icon="arrow-repeat"></b-icon> Reset to defaults
        </b-button>
        
    </b-button-group>
</template>

<script>
export default {
    methods: {
        resetToDefaults(){},
    }
}
</script>

<style>

</style>