<template>
<div>
  <b-row>
    <b-col lg="12" sm="12" md="12">
      <b-row>
        <b-col lg="12" sm="12" md="12">
          <base-card>
            <template #header>
                Lookup statistics
            </template>
            <div slot="body">
              
              <vue-good-table
              theme="polar-bear"
                :columns="fields" 
                :rows="serverData" 
                :line-numbers="true"
                :pagination-options="paginationOptions"
                :search-options="searchOptions">

                <div slot="table-actions">
                  <b-form-select 
                    style="min-width:100% !important"
                    class="select"
                    v-model="groupBy"
                    :options="groupByOptions"
                    required
                    v-on:change="groupByChanged">
                  </b-form-select>
                </div>

              </vue-good-table>
            </div>
          </base-card>
        </b-col>
      </b-row>
    </b-col>
   
  </b-row>
  
</div>  
</template>

<script>
import BaseCard from '@/components/Base/card.base.vue'
//import axios from 'axios'
//import { CircleIcon } from 'vue-feather-icons'
//import { ZapOffIcon } from 'vue-feather-icons'
//import { ZapIcon } from 'vue-feather-icons'



export default {
  components: {
    //CircleIcon,
    BaseCard
    //ZapOffIcon,
    //ZapIcon
  },
  props:['FilteredtData', 'Loading'],
  watch:{
    'FilteredtData' : function(dataFromServer) {
      this.serverData = dataFromServer
      //this.opTblData = dataFromServer['byOperator']
      //this.ccTblData = dataFromServer['byCountry']
      //this.dateTblData = dataFromServer['byDate']
      //this.providerTblData = dataFromServer['byProvider']
      
      
    },
    'Loading': function(value){
      //console.log(value)
      this.isLoading = value
    }

  },
  created () {
    //this.fetchData()
  },
  methods: {
    groupByChanged(){
      
    },
    fetchData () {
      /** */
    }
  },

  data(){
    return {
      serverData:[],
      groupBy: 'byCountry',
      groupByOptions:[
        'byDay',
        'byCountry',
        'byProvider'
      ],
      groupOptions: {
        enabled: false,
        collapsable: false
      },
      searchOptions: {
          enabled: true,
          placeholder: 'Search this table'
      },
      selectOptions: { enabled: false },
      paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: 5,
          position: 'bottom',
          perPageDropdown: [5,10,100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          jumpFirstOrLast : true,
          firstLabel : 'First Page',
          lastLabel : 'Last Page',
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
          infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
      },
      fields: [
        { label: 'Date', field: 'hlr_created_at'},
        { label: 'Country', field: 'hlr_country_name'},
        { label: 'Operator',  field: 'hlr_operator_name'},
        { label: 'Provider',  field: 'hlr_provider_name'},
        { label: 'Total',  field: 'hlr_count'}
      ],
      opFields: [
        { label: 'Country', field: 'hlr_country_name'},
        { label: 'Operator Name', field: 'hlr_operator_name'},
        { label: 'MccMnc',  field: 'hlr_mccmnc'},
        { label: 'Total',  field: 'hlr_total'}
      ],
      ccFields:[
        { label: 'Country', field: 'hlr_country_name'},
        { label: 'Country code', field: 'hlr_country_code'},
        { label: 'Total',  field: 'hlr_total'}
      ],
      dateFields: [
        { label: 'Date', field: 'hlr_created_at'},
        { label: 'Total', field: 'hlr_total'}
      ],
      providerFields: [
        { label: 'Provider  ', field: 'hlr_provider_name'},
        { label: 'Total', field: 'hlr_total'}
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}



.dashbaord{
  text-align:left;
}
</style>
