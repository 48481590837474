<template>
    <div>
        <b-row class="g-0" style="height:80px">
            <DefaultTopNav v-if="!testTemplateForm" 
                @test-template="testTemplateForm = !testTemplateForm" 
                :originaTemplateName="originaTemplateName"
                :originaTemplateCategory="originaTemplateCategory"
                :templateName="templateName" 
                :templateCategory="templateCategory"/>
            <TestTemplate  v-else @test-template="testTemplateForm = !testTemplateForm"/>
        </b-row>
        <b-row class="g-0">
            <b-col col cols=2 class="" style="max-width:295px">
                <SideNav @templateName="uTemplateName" @templateCategory="uTemplateCategory"/>        
                <SideNavBottomButtons />
            </b-col>
            <b-col col cols="10" class="p-0" style="flex-grow: 100;" >
                <div id="editorWrapper">
                    <div ref="gjs" id="gjs">
                       <css />
                    </div>
                </div>
            </b-col>
        </b-row>
        
    </div>
</template>

<script>
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';

//import CKEDITOR from 'ckeditor4-vue';

//console.log(CKEDITOR)
/*
require('/app/node_modules/ckeditor4/ckeditor.js');
window.CKEDITOR.dtd.$editable.a = 1

*/
//import newsLetterplugin from 'grapesjs-blocks-basic'
import newsLetterplugin from 'grapesjs-preset-newsletter';
//import ckEditorPlugIn from 'grapesjs-plugin-ckeditor';



import DefaultTopNav from './EditorNavs/Default.vue'
import TestTemplate from './EditorNavs/TestEmail.vue'
import SideNav from './EditorNavs/SideNav.vue'
import SideNavBottomButtons from './EditorNavs/SideNavButtons.vue'
import css from './EditorComponentStyles/CssComponent.vue'

import {macroAnchor} from './EditorPlugins/anchors'

export default {
    components:{
        DefaultTopNav,
        TestTemplate,
        SideNav,
        SideNavBottomButtons,
        css
    },
    computed: {
    },
    methods: {
        uTemplateName(blah){
            this.templateName = blah
        },
        uTemplateCategory(blah){
            this.templateCategory = blah
        },
        async getTemaplateJsonFile(){
            /**
             * editor.setComponents(res.data) 
             * works with  JSON.stringify(editor.getComponents()) data, but stripps data so no good
             */

                
            if(!this.$route.query.tid || this.$route.query.tid.toUpperCase() == 'NEW'){
                return
            }
            let uri = `/api/notifications/templates/read-template?tid=${this.$route.query.tid}&cid=${this.$route.query.cid}`
            

            let res = await this.$http.get(uri)
            if(res.status == 200) {
                
                this.editor.loadProjectData(res.data)
                //this.editor.setComponents(res.data)
                this.editor.runCommand('sw-visibility')
            }
            
    
        },  

    },  
    mounted() {
     
        let me = this;
          var editor = this.editor = grapesjs.init({
            container: '#gjs',
            //container: divElement,
            //height: '100%',
            //width: '100%',
            fromElement: true,
            showOffsets: 1,
            noticeOnUnload: 0,
            plugins: [ macroAnchor, newsLetterplugin ],
            //plugins: [macroAnchor,newsLetterplugin],
            pluginsOpts: {
                'grapesjs-preset-newsletter': {
                    /*
                        modalLabelImport: 'Paste all your code here below and click import',
                        modalLabelExport: 'Copy the code and use it wherever you want',
                        codeViewerTheme: 'material',
                        importPlaceholder: '<table class="table"><tr><td class="cell">Hello world!</td></tr></table>',
                        cellStyle: {
                            'font-size': '12px',
                            'font-weight': 300,
                            'vertical-align': 'top',
                            color: 'rgb(111, 119, 125)',
                            margin: 0,
                            padding: 0,
                            updateStyleManager: true,
                            showStylesOnChange: true,
                            useCustomTheme: false,
                        }
                    */
                },
                /*
                'grapesjs-plugin-ckeditor': {
                    options: {
                        CKEditorStatic: 'ttps://cdn.ckeditor.com/4.21.0/standard-all/ckeditor.js',
                        language: 'en',
                        startupFocus: true,
                        extraAllowedContent: '*(*);*{*};', // Allows any class and any inline style
                        allowedContent: true, // Disable auto-formatting, class removing, etc.
                        enterMode: 1,
                        uiColor: '#0000001a', // Inline editor color
                        extraPlugins: 'justify,colorbutton,panelbutton,font,sourcedialog,showblocks,Link,link,anchor',
                        toolbar:[
                        [ "Format", "-", "Bold", "Italic", "Strike", "Underline", "Subscript", "Superscript", "RemoveFormat", "-", "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock", "-", "Link", "Unlink", "Anchor", "TextColor", "BGColor", "-", "ShowBlocks", "-", "Image", "Table", "-", "Sourcedialog"]
                            ]
                    },
                    ckeditor: CKEDITOR,
                    position: 'right',
                }
                */
            },
            /*
            parser: {
                parserCss
            },
            */
            //storageManager: false,
            blockManager: {
                appendTo: '#blocks'
            },
            
            storageManager: { 
                type: 'remote',
                autosave: true,
                stepsBeforeSave: 1
            },
            
            traitManager: {
                appendTo: '#traits'
            },
            styleManager: {
                appendTo: '#styles'
            },
            layerManager:  {
                appendTo: '#layers'
            },
            /*
            assetManager:  {
                appendTo: '#macros'
            },
            */
            selectorManager: {
                appendTo: '#testing',
                componentFirst: true
            },
            cssComposer: {
                rules: [
                    
                ],
            },
            richTextEditor: {
                // options
            },
            commands: {
                defaults: [
                    {
                        id: 'save-command',
                        //run: async (editor) => {  
                        run: async () => {  
                            me.saveTemplateToFile();
                        }
                    },
                    {
                        id: 'save-html-command',
                        //run: async (editor) => {  
                        run: async () => {  
                            me.saveHtmlTemplateToFile();
                        }
                    },
                ]
            },
            canvas: {
                //styles: ['/img/style.css']
                /*
                styles: [
                    './style.css'
                ]
                */
               
            }
           
        });
        
        var block = editor.Blocks.get('macro-anchor');
        block = editor.Blocks.render(block, { external: true });
        document.getElementById('macros').appendChild(block);

        const filtered = editor.Blocks.getAll().filter(block => {
            //if(block.get('id') != 'macro-anchor')
            if(block.get('category').id != 'macros')
                return block
        })
        //console.log(filtered)
        editor.Blocks.render(filtered)
        /*

        const filtered = editor.Blocks.getAll().filter(block => block.get('category'))
        console.log(filtered)
        
        document.getElementById('macros').appendChild(newBlocksEl);
        console.log(newBlocksEl)
        editor.Blocks.render([
            {label: 'Label text', content: '<div>Content</div>'}
        ]);
        */


        //console.log(editor.StyleManager.getBuiltIn('.button'))
        // Panels: commands, devices-c, options, views
        
        const panelManager = editor.Panels;
        panelManager.removePanel('options');
        panelManager.removePanel('views');
        panelManager.removePanel('devices-c');
        
        //console.log(panelManager.getPanels().forEach(panel=>console.log(panel.id)))
        
        editor.Storage.add('remote', {
            async load() {
                me.getTemaplateJsonFile()
            },
            async store() {}
            //async store(data) {}
        })

    
            
        //editor.on('component:add component:remove component:update', () => {
        editor.on('all ', () => {
            
            const dirtyCount = editor.getDirtyCount();
            if(dirtyCount > 0){
                //wtf
            }
            
        });

        
    },
    data(){
        return {
            editor: null,
            templateName: this.$route.query.tid,
            originaTemplateName: this.$route.query.tid,
            templateCategory: this.$route.query.cid,
            originaTemplateCategory: this.$route.query.cid,
            changesMade: false,
            showHideImages: false,
            //testTemplateForm: true,
            testTemplateForm: false,
            topNavActive: 'link1',
            testEmailAddress: null,
            currentEmailTestValue: 0,
            saveProgress:0,
            commands: {
                toggleFullScreen: 'fullscreen',
                togglePreView: 'preview',
                toggleGrid: 'sw-visibility',
                toggleImages: 'gjs-toggle-images',
                importHtml: 'gjs-open-import-template',
                exportHtml: 'export-template',
                clearCanvas: 'canvas-clear',
                getInlineHtml: 'gjs-get-inlined-html'

            },
            //style: require('./EditorComponentStyles/newsLetterPreset.css')
        }
    }

}
</script>



<style>

.layersWrapper::-webkit-scrollbar,
.traitsWrapper::-webkit-scrollbar,
.stylesWrapper::-webkit-scrollbar,
.blocksWrapper::-webkit-scrollbar {
  width: 15px;
}

.layersWrapper::-webkit-scrollbar-track,
.traitsWrapper::-webkit-scrollbar-track,
.stylesWrapper::-webkit-scrollbar-track,
.blocksWrapper::-webkit-scrollbar-track {
  background-color: transparent;
}


.layersWrapper::-webkit-scrollbar-thumb,
.traitsWrapper::-webkit-scrollbar-thumb,
.stylesWrapper::-webkit-scrollbar-thumb,
.blocksWrapper::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.layersWrapper::-webkit-scrollbar-thumb:hover,
.traitsWrapper::-webkit-scrollbar-thumb:hover,
.stylesWrapper::-webkit-scrollbar-thumb:hover,
.blocksWrapper::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}



#editorWrapper .gjs-cv-canvas {
    height: 100%;
    width:100% ;
    top:unset;
}

#editorWrapper .gjs-pn-panel{
    display:none;
}

#bottomButtons .btn-group{
    border-radius: 0;
}

#bottomButtons .btn{
    border-right: none;
    border-left: none;
    border-radius: unset;
    color: #3f4242;
    padding:5px 0px !important;
} 

.templateNav {
    height: 100%;
    width:100%;
    align-items:baseline;
    background:white;
}
.navbar-brand{
    margin:unset !important;
}

#gjs {
    width:100%;
    height:100px;
}

.gjs-one-bg {
    background-color: #ffffff !important;
}


.gjs-one-color {
    color:#000000 !important;
}

.gjs-two-color{
    color:#000000 !important;
}

.gjs-sm-sector-title,
.gjs-layer-title {
    background-color: #ffffff !important;
}

.gjs-sm-sector-title:hover,
.gjs-layer-title:hover {
    background-color: #F1C376 !important;
}

.gjs-block:hover {
    color: #F1C376 !important;
}


</style>