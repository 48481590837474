import View  from './../components/Routing/Layout.vue'
import GlobalRouting  from './../components/Routing/RoutingOptions.vue'
//import TokenRoutingLayout  from './../components/Routing/Tokens/RoutingLayout.vue'

//import ProviderRoutes from './../components/Routes/ProviderRoutes.vue'
import ProviderRoutes from './../components/Routes/TabsLayout.vue'
import Hlr from './../components/Routing/Hlr/TabsLayout.vue'
//import tokens from './tokens.js';

export default {
    path: 'routing',
    name: 'routing',
    component: View,
    redirect: 'routing/global',
    children:[
      //tokens,
      {
        path: 'management',
        name: 'API Keys',
        component: GlobalRouting
      },
      {
        path: 'smsc',
        name: 'smsc',
        component: ProviderRoutes
      },
      {
        path: 'geo-setting',
        name: 'geo setting',
        component: Hlr
      },
      /*
      {
        path: 'key',
        name: 'existing key',
        component: TokenRoutingLayout
      }
      */
    ]
  }