<template>
  <div style="width: 100% !important; max-width:2300px;">
    <base-card @request-made="handleRequest">
      <template #header>
        {{$route.name}}
      </template>
      <div slot="body">
        <router-view></router-view>
      </div>
    </base-card >
  </div>
</template>

<script>
import BaseCard from '../Base/card.base.vue'
export default {
  components: {
    BaseCard,
  },
  methods: {
      handleRequest(){
        console.log('handle req')
      }
    }
}
</script>

<style>

</style>