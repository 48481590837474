<template>
  <b-form @submit.stop.prevent="() => onSubmit(tag, config, includes.filter(i => i))">
    <b-row>

      <b-col cols="6">
        <b-card style="padding: 10px 10px 10px 10px;">
          <b-row>
            <b-form-group style="text-align:left">
              <h6 style="color:green;">{{this.params.uid}}</h6>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Tag:" label-for="input-tag" :invalid-feedback="'Tag is required'" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  v-model="tag"
                  id="input-tag"
                  :state="tag.length > 0"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Id:" label-for="input-id" :invalid-feedback="'Id is required'" style="text-align:left">
                  <b-form-input
                    required
                    size="sm"
                    id="input-id"
                    v-model="config['id']"
                    placeholder="Required"
                    :state="config['id'] != null"
                  />
                </b-form-group>
            </b-col>
            
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Smsbox id:" label-for="input-smsbox-id" :invalid-feedback="'smsbox-id is required'" style="text-align:left">
                  <b-form-input
                    required
                    size="sm"
                    id="input-smsbox-id"
                    v-model="config['smsbox-id']"
                    placeholder="Required"
                    :state="config['smsbox-id'] != null"
                  />
                </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Bearerbox Host:" label-for="input-bearerbox-host" style="text-align:left">
                <b-form-input id="input-bearerbox-host" placeholder="Default `localhost`" size="sm" v-model="config['bearerbox-host']" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Bearerbox Port:" label-for="input-bearerbox-port" invalid-feedback="Enter a valid port" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  id="input-bearerbox-port"
                  v-model="config['bearerbox-port']"
                  :state="/^[0-9]{1,10}$/.test(config['bearerbox-port'])"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="SMS Box Port:" label-for="input-smsbox-port" invalid-feedback="Enter a valid port" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  id="input-smsbox-port"
                  v-model="config['smsbox-port']"
                  :state="/^[0-9]{1,10}$/.test(config['smsbox-port'])"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Database log table name:" label-for="input-sql-log-table" style="text-align:left">
                <b-form-input id="input-sql-log-table" size="sm" v-model="config['sql-log-table']" placeholder="Required" required />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Database insert table name:" label-for="input-sql-insert-table" style="text-align:left">
                <b-form-input id="input-sql-insert-table" size="sm" v-model="config['sql-insert-table']" placeholder="Required" required />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Log Level:" label-for="input-log-level" style="text-align:left">
                <b-form-select v-model="config['log-level']" class="w-100" size="xs" :options="[
                  { value: 0, text: 'Debug' },
                  { value: 1, text: 'Info' },
                  { value: 2, text: 'Warning' },
                  { value: 3, text: 'Error' },
                  { value: 4, text: 'Panic' }
                ]" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="DB connection config id:" label-for="input-db-connection" style="text-align:left">
                <b-form-select v-model="connection" size="xs" class="w-100" required @input="setIncludeEtitites('db-connection', connection)">
                  <b-form-select-option v-for="conn in connections" :key="conn.id" :value="`${conn.uid}.conf`" >
                        {{conn.config.id}}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          
          <ConfigIncludes :includes="includes" />

          <br />

          <b-row>
            <b-button-group>
              <b-button variant="outline-danger" @click="() => onClose()">Close</b-button>
              <b-button type="submit" variant="outline-primary">Submit</b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <ConfigPreview :config="config" :includes="includes" />
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
  import ConfigPreview from '../../Common/Config/ConfigPreview.vue'
  import ConfigIncludes from '../../Common/Config/ConfigIncludes.vue'

  export default {
    components: {
      ConfigPreview,
      ConfigIncludes
    },
    props: ['params', 'onSubmit', 'onClose', 'connections'],
    methods: {
      setIncludeEtitites(entity, value){ 
        if(value){
          const path = this.getEntityPath(entity)
          const idx = this.includes.findIndex(item=> item.includes(path))
          if(idx > -1) this.includes.splice(idx,1)
          this.includes.push(`${path}/${value}`)  
        }
      }
    },
    data () {
      return {
        connection: null,
        tag: '',
        config: {
          'id': 'sqlbox-db',
          'smsbox-id': 'smsbox-id',
          'bearerbox-host': 'localhost',
          'bearerbox-port': 13032,
          'smsbox-port': 13033,
          'sql-log-table': 'sent_sms',
          'sql-insert-table': 'send_sms',
          'log-level': 0,
        },
        includes: []
      }
    },
    created () {
      this.tag = this.params.tag
      this.includes = this.params.includes

      var idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('db-connection') ))
      this.connection = idx > -1 ? this.includes[idx].split(/\//)[5] : null

      this.config = { ...this.config, ...this.params.config }
    },
  }
</script>

<style>
  .row {
    margin: 10px;
  }
</style>
