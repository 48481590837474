<template>
    <b-modal 
        @shown="loadData"
        @ok="handleOk"
        size="md"
        id="modal-edit-client" :title="`Add Payment`">
            <b-overlay :show="show" rounded="sm">
            <b-form @submit="onSubmit" @reset="onReset">
                <b-form-group
                    id="input-group-0"
                    label="Client status:"
                    description="The status of the client"
                    label-for="input-0"
                    valid-feedback="Thank you!"
                    :invalid-feedback="'Client status not set'"
                    :state="form.client_status != null"
                >
                    <b-form-select 
                    required
                    class="select"
                    v-model="form.client_status" 
                    :options="clientStatusList">
                    </b-form-select> 
                </b-form-group>
                    
                <b-form-group
                    id="input-group-1"
                    label="Client name:"
                    description="The name of the new client"
                    label-for="input-1"
                    valid-feedback="Thank you!"
                    :invalid-feedback="'Client name must be at least 4 chars long'"
                    :state="form.client_account_name && 
                        form.client_account_name.length >= 4"
                    >
                    <b-form-input 
                    id="input-1" 
                    v-model="form.client_account_name"
                    :state="form.client_account_name &&
                                form.client_account_name.length >= 4"
                    trim></b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-2"
                    label="Current balance:"
                    description="The current balance of the account"
                    label-for="input-2"
                    valid-feedback="Thank you!"
                    :invalid-feedback="'Current balance is 0 or lower'"
                    :state="form.client_account_balance != null"
                    >
                    <b-form-input 
                    id="input-2" 
                    v-model="form.client_account_balance"
                    
                    disabled
                    trim></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-6"
                    label="Our of funds notification limit:"
                    description="Send notification when limit is reached"
                    label-for="input-2"
                    valid-feedback="Thank you!"
                    :invalid-feedback="'Current setting is 0 or lower'"
                    :state="form.client_low_balance_notification_limit != null"
                    >
                    <b-form-input 
                    id="input-2" 
                    v-model="form.client_low_balance_notification_limit"
                    trim></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-2"
                    label="Allowed balance:"
                    description="The min allowed balance of the account"
                    label-for="input-2"
                    valid-feedback="Thank you!"
                    :invalid-feedback="'Current allowed min balance is 0'"
                    :state="form.client_allowed_balance != null">
                    <b-form-input 
                    id="input-3" 
                    v-model="form.client_allowed_balance"
                    trim></b-form-input>
                </b-form-group>

                <b-button ref="submitBtn" type="submit" style="display: none"></b-button>
            </b-form>
            <b-alert variant="success" :show="form.add_balance_amount > 0">
                Reset out of credit notification setting
            </b-alert>

        </b-overlay>
    </b-modal>
</template>

<script>

export default {
    components: {},
    data() {
        return {
            ModalShow: false,
            toastCount: 0,
            show: false,
            accountData: {},
            form: {
                client_account_name: null,
                client_account_balance: 0,
                client_allowed_balance: 0,
                client_accounts_cliend_uuid: null,
                client_status: null,
                client_low_balance_notification_limit: null,
                client_low_balance_notification_sent: null,
                add_balance_amount:0
            },
            clientStatusList: [
                
                    { value: null, text: 'Please select an option' },
                    { value: true, text: 'OK' },
                    { value: false, text: 'NOK' }
                
            ],
            selected: null,
            clientUuid: this.$route.params.id
        }
    },
    props: ['uuid'],
    methods: {
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.$refs.submitBtn.click()
            //this.$refs.addBalanceForm.submit() this shit doesnt work?!!
        },      
        async loadData () {
            //const res = await this.$http.get('/api/gateway/accounts/common/read-one', { params: { uuid: this.uuid } })
            const res = await this.$http.get('/api/gateway/accounts/read', { params: { uuid: this.uuid } })
            if(res && res.status == 200){
                this.form = {...res.data}
                this.form.client_status =  !!res.data.client_status
            }
            
        },
        async onSubmit(event) {
            event.preventDefault()
            this.show = !this.show
            const res = await this.$http.post('/api/gateway/accounts/update', this.form)
            this.show = !this.show
            if(res && res.status == 200){
                if(res.data) {
                    this.$emit('updateRowData', res.data)
                    this.$emit('done', 'modal-edit-client')
                }
            }
        },
        onReset(event) {
            event.preventDefault()
            this.reset();
        },
        reset() {
            // Reset our form values
            this.form.client_account_name = null
            // Trick to reset/clear native browser form validation state
            this.$nextTick(() => {
                //this.show = true
            })
        }
    }
}
</script>

<style>

.closeEditBox {
    cursor:pointer
}


</style>