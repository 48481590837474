export default [
  {
    width: '100px',
    label: 'ID',
    field: 'id',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Id'
    }
  },
  {
    width: '50px',
    label: 'Status',
    field: 'status'
  },
  {
    width: '100px',
    label: 'Tag',
    field: 'tag',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Tag'
    }
  },
  {
    width: '150px',
    label: 'Admin Port',
    field: 'config.admin-port',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    width: '150px',
    label: 'SMS Box Port',
    field: 'config.smsbox-port',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    width: '100px',
    label: 'Log level',
    field: 'config.log-level',
  },
  {
    width: '250px',
    label: 'Options',
    field: 'options',
  },
]
