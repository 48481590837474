<template>
<div>
   <b-button-group>
        <b-button
            :disabled="disabled"
            @click="$bvModal.show('modal-batch-routing')"
            variant="outline-primary"
            size="sm">
            <b-icon icon="bezier"></b-icon> Existing Routing
        </b-button>
        <b-button
            @click="$bvModal.show('single-routing-modal')"
            variant="outline-primary"
            size="sm">
            <b-icon icon="bezier2"></b-icon> Add Routing
        </b-button>
        <b-button
            :disabled="disabled"
            @click="$bvModal.show('copy-from-existing-account-routing-modal')"
            variant="outline-primary"
            size="sm">
            <b-icon icon="files"></b-icon> Copy from account
        </b-button>
        <b-button
            :disabled="disabled"
            @click="$bvModal.show('reset-defaults-modal')"
            variant="outline-danger"
            size="sm">
            <b-icon icon="arrow-repeat"></b-icon> Reset to defaults
        </b-button>
        <b-button
            :disabled="disabled"
            @click="$bvModal.show('delete-routing-modal')"
            variant="outline-danger"
            size="sm">
            <b-icon icon="trash"></b-icon> Delete
        </b-button>
        <b-button
            :disabled="disabled"
            @click="$bvModal.show('send-routing-modal')"
            variant="outline-primary"
            size="sm">
            <b-icon icon="envelope"></b-icon> Send pricing to client
        </b-button>
        
    </b-button-group>
</div>    
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        resetToDefaults(){},
        deleteRouting(){},
        sendRoutingToClient(){}

    }
}
</script>

<style>

</style>