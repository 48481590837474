<template>
  <div style="height:100%;" class="flex">
    <b-nav class="left-nav" align="start" pills>
      <b-nav-item 
        style="border-right: 1px solid #d6dee1;"
        
        @click="$emit('test-template', false)">
        <b-icon icon="x"></b-icon>
      </b-nav-item>
    </b-nav>
    <b-nav class="right-nav" align="center" pills>
      <b-nav-item 
        style="border-right: 1px solid #d6dee1;border-left: 1px solid #d6dee1;"
        @click="currentEmailTestValue=null">
        <b-icon icon="arrow-repeat"></b-icon>
      </b-nav-item>
      &nbsp;

      <b-progress style="width:290px; margin:30px 5px 0 5px;" 
      v-if="currentEmailTestValue" 
      :value="currentEmailTestValue" 
      :max="100" 
      show-progress animated></b-progress>


      <b-nav-form class=""  @submit="testTemplate" >
        <b-form-group
        v-if="!currentEmailTestValue"
        valid-feedback="Thank you!"
        invalid-feedback="Email is invalid"
        :state="/^\S+@\S+\.\S+$/.test(testEmailAddress)">
            <label style="padding-left:5px;" class="d-block" for="input-live">
                Test Email:
            </label>
            <b-form-input style="width:300px !important;"  required type="email" v-model="testEmailAddress" :state="/^\S+@\S+\.\S+$/.test(testEmailAddress)" trim></b-form-input>
        </b-form-group>
        &nbsp;
        <b-button style="border-right: 1px solid #d6dee1;border-left: 1px solid #d6dee1;padding:10px;" type="submit" variant="outline-dark" >
            <b-icon style="padding:0px;margin:0px;" icon="check"></b-icon>
        </b-button>

    </b-nav-form>
    </b-nav>
  </div>
</template>

<script>
var juice = require('juice');
export default {
  data(){
    return {
      testEmailAddress: 'constantine@message-deck.com',
      currentEmailTestValue: 0
    }
  },
  methods: {
    async testTemplate(e){  
        e.preventDefault()

        const html = this.$parent.editor.getHtml()
        const css = this.$parent.editor.getCss()
        const tmpl =  `${html}<style>${css}</style>`;

        var template = juice(tmpl, {});
        this.currentEmailTestValue = 25;
               
        let res = await this.$http.post('/api/notifications/templates/test/send', {
            //templateName : this.$parent.templateName,
            template: template,
            testEmail: this.testEmailAddress
        });


        if(res && res.status == 200){
          const me = this
          setTimeout(function(){
            me.currentEmailTestValue = 100;
             setTimeout(function(){
                  me.currentEmailTestValue = 0
              }, 100)
          },200)
        }
        
        
    },
  }

}
</script>

<style scoped>
.nav {
    display: inline-flex;
    height: 100%;
    border-bottom: 1px solid #F7E6C4;
}

.left-nav {
  width:20%;
  max-width:300px;
}
.right-nav {
  width:80%;
}

.btn:hover,
.nav-item:hover{
  background: #F1C376;
}

.right-nav .nav-item,
.right-nav .btn,
.left-nav .nav-item {
    width: 100px;
    height: 100%;
    display: inline-block;
    padding: 20px 0 0 25px;
    border:unset;
    border-radius:unset;  
}

.nav .nav-link{
    color:#3f4242;
}

.right-nav-item {
  border-left: 1px solid #F7E6C4;
  border-right: 1px solid #F7E6C4;
}

.form-inline {
  display:inline-flex;
}

.form-control{
  border-color:#F7E6C4;
  border-radius:0px;
}

</style>
