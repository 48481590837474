<template>
    
    <div style="width:100%; max-width:1800px;">
    <base-card @request-made="handleRequest">
        <template #header>
            {{$route.name}}
        </template>
        <div slot="body">
            <router-view></router-view>
        </div>
    </base-card>
    </div>
        
    

</template>

<script>
import BaseCard from '../Base/card.base.vue'
export default {
  components: {
    BaseCard,
  },
  methods: {
      handleRequest(){
        console.log('handle req')
      }
    }
}
/*
import Modal from '../SystemSettings/Modal.vue'
import Table from '../SystemSettings/Table.vue'
export default {
    components: {
        Table,
        Modal
    },
    methods: {
        addedRow(rowData){
            console.log(rowData)
            this.rows.push(rowData)
        },
        async tabChange(tabIndex){
            this.currentTabName = this.$notificationTypes[tabIndex].text
            const [response1, response2] = await Promise.all([
                this.$http.get(`/api/notifications/settings/read?category=${this.$notificationTypes[tabIndex].text.toUpperCase()}`),
                this.$http.get(`/api/notifications/templates/read?category=${this.$notificationTypes[tabIndex].text.toUpperCase()}`),
            ]);
            
            if(response1 && response1.status == 200){
                this.rows = response1.data
            }

            if(response2 && response2.status == 200){
                this.templates = response2.data
            }
        }
    },
    data(){
        return {
            notificationTypes: this.$notificationTypes,
            currentTabName: this.$notificationTypes[0].text,
            rows: [],
            templates: []
        }
    }
    
}
*/
</script>

<style>
#system-noitifications-settings
.col-auto{
    min-height: 600px !important;
}
</style>