<template>
  <div>
    <b-row>
      <b-col lg="12" sm="12" md="6">
        <filter-area @filterData="callLoadItemWithFilterData"/>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col lg="12" sm="12" md="6">
        <b-card>
          <b-card-header>
            Search messages
          </b-card-header>
          <b-overlay :show="isLoading" rounded="sm">
            <vue-good-table

            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :pagination-options="{
              enabled: true,
              mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5,10,50,100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
            }"
            :rows="rows"
            :columns="columns">
              <div slot="table-actions">
                <download-csv 
                      @export-started="exported"
                      @export-finished="exported"
                      
                      :data = "rows">
                  <b-button variant="primary" style="border-top-left-radius: 0; border-bottom-left-radius: 0;color:white" :disabled="!rows.length">
                    <b-icon icon="file-earmark-arrow-down"></b-icon> Download CSV
                  </b-button>
                </download-csv>
              </div>

              <template slot="table-row" slot-scope="props">
                
                <span v-if="props.column.field == 'send_sms_is_hlr_lookup'">
                  <b-icon v-if="props.row.send_sms_is_hlr_lookup != 1" icon="circle-fill" color="red"></b-icon>
                  <b-icon v-else-if="props.row.send_sms_is_hlr_lookup == 1" icon="check-circle" color="green"></b-icon>
                </span>
                <span v-else-if="props.column.field == 'send_sms_current_dlr_mask_code'">
                  <b-icon v-if="props.row.send_sms_current_dlr_mask_code != 1" icon="circle-fill" color="red"></b-icon>
                  <b-icon v-else-if="props.row.send_sms_current_dlr_mask_code == 1" icon="check-circle" color="green"></b-icon>
                </span>
                <span v-else-if="props.column.field == 'send_sms_api_key'">
                  {{ props.row.send_sms_api_key }}
                </span>
                <span v-else-if="props.column.field == 'options'">
                  <b-button 
                  @click="$bvModal.show('modal-' + props.row.id)"
                    size="sm" variant="outline-primary" v-b-tooltip.hover title="View">
                    <b-icon  icon="box-arrow-up-right"></b-icon>
                  </b-button>
                  <b-modal :id="'modal-' + props.row.id" title="Message details" size="lg" hide-footer>
                     
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <th style="text-align:left"><u>Description</u></th>
                              <th ><u>Value</u></th>
                            </thead>
                            <tbody>

                              <tr>
                                <td style="text-align:left">Account uuid:</td>
                                <td>{{ props.row.send_sms_account_uuid }}</td>
                              </tr>
                              <tr>
                                <td style="text-align:left">Route:</td>
                                <td>{{ props.row.send_sms_route }}</td>
                              </tr>
                              <tr>
                                <td style="text-align:left">Timestamp:</td>
                                <td>{{ props.row.send_sms_mt_kannel_timestamp }}</td>
                              </tr>
                              
                              <tr>
                                <td style="text-align:left">Key protocol:</td>
                                <td>{{ props.row.send_sms_api_key_type }}</td>
                              </tr>
                              <tr>
                                <td style="text-align:left">Key mode:</td>
                                <td>{{ props.row.send_sms_mt_key_mode }}</td>
                              </tr>
                              <tr>
                                <td style="text-align:left">Status:</td>
                                <td>
                                  <table class="table">
                                    <thead>
                                      <th>Requested </th>
                                      <th>Acctual </th>
                                      <th>Text</th>
                                      <th>Final timestamp</th>
                                    </thead>
                                    <tr>
                                      <td>{{ props.row.send_sms_requested_dlr_mask_code }}</td>
                                      <td>{{ props.row.send_sms_current_dlr_mask_code }}</td>
                                      <td>{{ props.row.send_sms_current_dlr_mask_text }}</td>
                                      <td>{{ props.row.send_sms_mt_dlr_date }}</td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td style="text-align:left">Operator:</td>
                                <td>
                                  <table class="table">
                                    <thead>
                                      <th>Mcc</th>
                                      <th>Mnc</th>
                                      <th>Text</th>
                                    </thead>
                                    <tr>
                                      <td>{{ props.row.send_sms_mt_mcc }}</td>
                                      <td>{{ props.row.send_sms_mt_mnc }}</td>
                                      <td>{{ props.row.send_sms_mt_operator_name }}</td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td style="text-align:left">Message Text:</td>
                                <td>{{ decodeURIComponent(props.row.send_sms_mt_text) }}</td>
                              </tr>
                              <tr>
                                <td style="text-align:left">Message Finance</td>
                                <td>
                                  <table class="table">
                                    <thead>
                                      <th>Parts</th>
                                      <th>Cost</th>
                                      <th v-if="props.row.send_sms_is_hlr_lookup">Hlr</th>
                                      <th>Price</th>
                                      <th>Profit</th>
                                    </thead>
                                    <tr>
                                      <td>{{ props.row.send_sms_mt_parts }}</td>
                                      <td>{{ props.row.send_sms_mt_cost }}</td>
                                      <td v-if="props.row.send_sms_is_hlr_lookup">{{ props.row.send_sms_hlr_cost }}</td>
                                      <td>{{ props.row.send_sms_mt_sell }}</td>
                                      <td>{{ ( Number(props.row.send_sms_mt_sell) - Number(props.row.send_sms_mt_cost) - Number(props.row.send_sms_hlr_cost) ).toFixed(5)}}</td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td style="text-align:left">Our id:</td>
                                <td>{{ props.row.send_sms_mt_our_msg_id }}</td>
                              </tr>
                              <tr>
                                <td style="text-align:left">Client id:</td>
                                <td>{{ props.row.send_sms_mt_client_msg_id }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        
                     
                      
                        <div class="modal-footer">
                          <b-button class="mt-3" block @click="$bvModal.hide('modal-' + props.row.id)">Close</b-button>
                        </div>
                    
                  </b-modal>
                </span>
              </template>
            </vue-good-table>

          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FilterArea from './Filter.vue'
export default {
  components:{
    FilterArea
  },
  data(){
    return {
      isLoading: false,
      columns: [
        { 
          label: 'Api key', field: 'send_sms_api_key',
          /*
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          }
          */
        },
        { label: 'Country', field: 'send_sms_mt_country_name',
          /*
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          }
          */
        },
        { label: 'Sender ID', field: 'send_sms_sender',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          } 
        },
        { label: 'Receiver', field: 'send_sms_receiver',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          } 
        },
        { label: 'Operator', field: 'send_sms_mt_operator_name',
        /*
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          } 
          */
        },
        { label: 'Route', field: 'send_sms_route',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          } 
        },
        { label: 'Our id', field: 'send_sms_mt_our_msg_id',
        filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          } 
         },
        { label: 'Client id', field: 'send_sms_mt_client_msg_id',
        filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          } 
        },
        { label: 'Cost', field: 'send_sms_mt_cost' },
        { label: 'Price', field: 'send_sms_mt_sell' },
        
        { label: 'Status', field: 'send_sms_current_dlr_mask_code',
        /*
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          }
          */
        },
        { label: 'Key mode', field: 'send_sms_mt_key_mode' ,
        filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          }
         },
        { label: 'Hlr', field: 'send_sms_is_hlr_lookup',filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            trigger: 'enter'
          } },
        { label: 'Options', field: 'options', sortable: false},
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        filterData:{},
        columnFilters: {
        },
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1, 
        perPage: 10
      }
    }
  },
  methods: {
    exported(event) {
      console.log(event)
    },
    async getFromServer(){
      this.loading = !this.isLoading
      let res = await this.$http.post('/api/analytics/search',this.serverParams);
      this.loading = !this.isLoading
     return res.data
      
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);

    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.serverParams.page = 1
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params[0].type,
          field: params[0].field,
        }],
      });
      this.loadItems();
      
    },
    
    onColumnFilter(params) {
    //onColumnFilter() {
      
      this.updateParams(params);
      this.loadItems();
      
    },
    resetServerParams(){
      this.serverParams = {
        filterData:{},
        columnFilters: {
        },
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1, 
        perPage: 10
      }
    },
    async callLoadItemWithFilterData(filterData){
      this.resetServerParams();
      this.serverParams.filterData = filterData
      await this.loadItems();
    },
    // load items is what brings back the rows from server
    async loadItems() {
      
      let resp = await this.getFromServer(this.serverParams);
      this.totalRecords = resp.totalRecords;
      this.rows = resp.rows;
      /*
      this.rows = await this.getFromServer(this.serverParams);
      console.log(this.rows)
      this.totalRecords = this.rows.length;
      */
      /*
      this.getFromServer(this.serverParams).then(response => {
         this.totalRecords = response.totalRecords;
         this.rows = response.rows;
      });
      */
    }
  }
}
</script>

<style>

table.table-fit {
    width: auto !important;
    table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
    width: auto !important;
}
table.table-fit tbody td, table.table-fit tfoot td {
    width: auto !important;
}

</style>