<template>
    <b-modal 
        :ok-disabled="disabled"
        @shown="loadData"
        @ok="handleOk"
        @hidden="resetObject"
        size="xl"
        id="modal-new-invoice" :title="`Create Invoice`">
            <b-row style="width:100%">
                <b-col lg="12">
                    <b-overlay :show="submited" rounded="sm" blur="2px">
                        <b-row>
                            <b-col lg="12">
                                <b-alert :show="formData.clientInfo.validVat" variant="success">
                                Valid EU VAT number has been provided
                                </b-alert>
                                <b-alert :show="!formData.clientInfo.validVat" variant="danger">
                                Valid EU VAT number has not been provided
                                </b-alert>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-form 
                            ref="invoiceForm"  
                            @submit.prevent="onSubmit" 
                            @reset="resetObject">
                            <b-row>
                                <b-col lg="7" style="border-right: 1px solid rgba(0, 0, 0, 0.175); padding:0 20px 0 20px;" >
                                <ClientInfoVue v-model="formData.clientInfo" :disabled="disabled"/>
                                </b-col>
                                <b-col lg="5" style="padding:0 20px 0 20px;">
                                    <InvoiceInfoVue :disabled="disabled" v-model="formData.invoiceInfo"/>
                                </b-col>
                            </b-row>
                            <hr>
                            
                            <h4><u>Line Items</u></h4>
                            <b-row>
                                <b-col>
                                    <LineItemsVue :disabled="disabled" v-model="formData.lineItems" :waveItems="waveSalesItems" :waveTaxes="waveTaxItems"/>
                                </b-col>
                            </b-row>    
                        </b-form>

                        <hr>

                        <TotalSummeryVue :subTotal="subTotal" :total="total" :tax="tax"/>
                        
                    </b-overlay>
                </b-col>

            </b-row>
    </b-modal>
</template>

<script>
import ClientInfoVue from './ClientInfo.vue';
import InvoiceInfoVue from './InvoiceInfo.vue';
import LineItemsVue from './LineItems.vue';
import TotalSummeryVue from './TotalSummery.vue';


export default {
    components: {
        ClientInfoVue,
        InvoiceInfoVue,
        LineItemsVue,
        TotalSummeryVue
    },
    //props:['uuid'],
    props:{
        uuid: {
            type: String,
            default: null
        },
        readOnlyData: {
            type: Object,
            default: () => {}
        },
    },
    watch:{
        'formData.lineItems': {
            handler(nv) {
                this.subTotal = 0
                this.total = 0
                this.tax = 0

                for ( const item of nv ) {
                    this.subTotal += item.proforma_invoice_line_items_line_total
                    this.total += item.proforma_invoice_line_items_line_total
                    if(item.proforma_invoice_line_items_tax_rate){
                        this.tax    += item.proforma_invoice_line_items_total_tax 
                        this.total  += item.proforma_invoice_line_items_total_tax 
                    }
                }

            },
            deep: true 
        }
    },
    methods:{   
        resetObject(){
            return {
                clientInfo: {
                    name: null,
                    city: null,
                    address: null,
                    country: null,
                    zip: null,
                    cCode: null,
                    vat: null,
                    tin: null,
                    validVat: false,
                    waveClientId: null
                },
                invoiceInfo: {
                    number: null,
                    currency: null,
                    from: null,
                    date: null,
                    due: null
                },
                lineItems: []
            }
        },
        toggleBusy(){
            this.submited = !this.submited 
        },
        parseBilling(data){
            
            if(data){
                this.formData.clientInfo.name = data.billing_entity_name || this.formData.clientInfo.name
                this.formData.clientInfo.city = data.billing_entity_city
                this.formData.clientInfo.address = `${data.billing_entity_address_one} ${data.billing_entity_address_two ? data.billing_entity_address_two : ''}`
                this.formData.clientInfo.country = data.billing_entity_country_name
                this.formData.clientInfo.zip = data.billing_entity_postal_code
                this.formData.clientInfo.vat = data.billing_entity_vat_number
                this.formData.clientInfo.tin = data.proforma_invoice_company_tax_id || data.billing_entity_tax_number
                this.formData.clientInfo.validVat = data.billing_entity_valid_eu_vat
                this.formData.clientInfo.cCode = data.billing_entity_country_code
                this.formData.clientInfo.waveClientId = data.billing_entity_wave_customer_id
            }
        },
        async readOnly(){

            const res = await this.$http.get('/api/payment/invoice/items/read', {
                params: {
                    invoiceNumber: this.readOnlyData.proforma_invoice_wave_invoice_number
                }
            })

            if(res && res.status == 200){
                this.formData.lineItems = res.data
            }
           
            // Client Data:
            this.formData.clientInfo.name =  this.readOnlyData.proforma_invoice_company_name
            this.formData.clientInfo.city = this.readOnlyData.proforma_invoice_company_city
            this.formData.clientInfo.address = this.readOnlyData.proforma_invoice_company_address
            this.formData.clientInfo.country =  this.readOnlyData.proforma_invoice_company_country
            this.formData.clientInfo.zip =  this.readOnlyData.proforma_invoice_company_zip
            this.formData.clientInfo.vat = this.readOnlyData.proforma_invoice_company_vat
            this.formData.clientInfo.tin = this.readOnlyData.proforma_invoice_company_tax_id
            this.formData.clientInfo.validVat = false
            this.formData.clientInfo.cCode = this.readOnlyData.proforma_invoice_company_country_code
            this.formData.clientInfo.waveClientId = this.readOnlyData.proforma_invoice_wave_client_id
           
            //Invoice Info:
            this.formData.invoiceInfo.number = this.readOnlyData.proforma_invoice_wave_invoice_number
            this.formData.invoiceInfo.currency =  this.readOnlyData.proforma_invoice_main_currency
            
            this.formData.invoiceInfo.date = this.readOnlyData.proforma_invoice_date
            this.formData.invoiceInfo.due = this.readOnlyData.proforma_invoice_due_date

            this.subTotal = this.readOnlyData.proforma_invoice_sub_total
            this.total = this.readOnlyData.proforma_invoice_total
            this.tax = ( parseFloat(this.readOnlyData.proforma_invoice_total_tax1) + 
                        parseFloat(this.readOnlyData.proforma_invoice_total_tax2) ) || 0

            
            this.formData.invoiceInfo.from = {
               company: this.readOnlyData.proforma_invoice_issue_name,
                address: this.readOnlyData.proforma_invoice_issue_address,
                zip: this.readOnlyData.proforma_invoice_issue_zip,
                city: this.readOnlyData.proforma_invoice_issue_city,
                country: this.readOnlyData.proforma_invoice_issue_country,
                vat: this.readOnlyData.proforma_invoice_issue_vat, //VAT
                reg: this.readOnlyData.proforma_invoice_issue_tax_id, //REG
                bank: {
                        name: 'BANK HAPOALIM B.M.',
                        address: 'ROTHSCHILD BOULEVARD, 50, TEL AVIV ISRAEL',
                        iban: 'IL810126340000000013647',
                        swift: 'POALILITXXX',
                        account: '013647'
                }
            }
            this.disabled = !!this.formData.invoiceInfo.number
            return null
        },
        async loadData(){
            
            if(this.readOnlyData) return this.readOnly()

            this.toggleBusy()

            const resps = await Promise.all([
                //this.$http.get('/api/gateway/accounts/common/read-one', { params: { uuid: this.uuid } }),
                this.$http.get('/api/gateway/accounts/read', { params: { uuid: this.uuid } }),
                //this.$http.get('/api/payment/billing/get-billing-details', { params: { uuid: this.uuid } }),                
                this.$http.get('/api/payment/billing/read', { params: { uuid: this.uuid } }),
                this.$http.get('/api/payment/query/products'),
                this.$http.get('/api/payment/query/salesTaxes'),
            ])

            this.toggleBusy()
            if(resps[0] && resps[0].status == 200 && resps[0].data) this.formData.clientInfo.name = resps[0].data.client_account_name
            if(resps[1] && resps[1].status == 200) this.parseBilling(resps[1].data)
            if(resps[2] && resps[2].status == 200) this.waveSalesItems = resps[2].data
            if(resps[3] && resps[3].status == 200) this.waveTaxItems = resps[3].data
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        async handleSubmit() {
            const valid = this.$refs.invoiceForm.checkValidity() && 
                            this.formData.invoiceInfo.date != null && 
                            this.formData.invoiceInfo.due != null
            
            if (!valid) {
                return
            }
            


            const details = {
                proforma_invoice_wave_client_id: this.formData.clientInfo.waveClientId,

                proforma_invoice_issue_name: this.formData.invoiceInfo.from.company,
                proforma_invoice_issue_address: this.formData.invoiceInfo.from.company,
                proforma_invoice_issue_zip: this.formData.invoiceInfo.from.zip,
                proforma_invoice_issue_country: this.formData.invoiceInfo.from.country,
                proforma_invoice_issue_city: this.formData.invoiceInfo.from.city,
                proforma_invoice_issue_vat: this.formData.invoiceInfo.from.vat,
                proforma_invoice_issue_tax_id: this.formData.invoiceInfo.from.reg,

                //Bank data
                proforma_invoice_issue_tax_bank_name: this.formData.invoiceInfo.from.bank.name,
                proforma_invoice_issue_tax_bank_address: this.formData.invoiceInfo.from.bank.address,
                proforma_invoice_issue_tax_bank_iban: this.formData.invoiceInfo.from.bank.iban,
                proforma_invoice_issue_tax_bank_swift: this.formData.invoiceInfo.from.bank.swift,
                proforma_invoice_issue_tax_bank_account: this.formData.invoiceInfo.from.bank.account,

                //Invoice data
                proforma_invoice_date: this.formData.invoiceInfo.date,
                proforma_invoice_due_date: this.formData.invoiceInfo.due,
                proforma_invoice_generated_by: 'BFFA',
                proforma_invoice_type: 'INVOICE',
                proforma_invoice_main_currency: this.formData.invoiceInfo.currency,
                proforma_invoice_alt_currency: this.formData.invoiceInfo.currency,


                proforma_invoice_total_tax1: this.tax,
                proforma_invoice_total_tax2: 0,
                proforma_invoice_total: this.total,
                proforma_invoice_sub_total: this.subTotal,
                

                //Client data
                proforma_invoice_client_uuid: this.uuid,
                proforma_invoice_company_valid_eu_vat_id: this.formData.clientInfo.validVat,
                proforma_invoice_company_name: this.formData.clientInfo.name,
                proforma_invoice_company_address: this.formData.clientInfo.address,
                proforma_invoice_company_zip: this.formData.clientInfo.zip,
                proforma_invoice_company_country_code: this.formData.clientInfo.cCode,
                proforma_invoice_company_city: this.formData.clientInfo.city,
                proforma_invoice_company_country: this.formData.clientInfo.country,
                proforma_invoice_company_vat: this.formData.clientInfo.vat,
                proforma_invoice_company_tax_id: this.formData.clientInfo.tin,

            }

           
            this.toggleBusy()
            const res = await this.$http.post(
                '/api/payment/invoice/mutations/invoiceCreate',
                {
                    details: details,
                    items: this.formData.lineItems
                }
            )
            this.toggleBusy()
            if(res && res.status == 200){
                if(res.data.created){
                    this.$emit('done', 'modal-new-invoice')
                }
            }
            

        },
    },
    data() {
        return {
            disabled: false,
            subTotal: null,
            total: null,
            tax: null,
            formData: this.resetObject(),
            submited: false,
            invoiceNumber: null,
            waveSalesItems: [],
            waveTaxItems: []
        }
    },
}
</script>

<style>
.custom-select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>