<template>
    <div class="flex">
        <b-nav align="start" pills>
            <b-nav-item 
                class="left-nav"
                @click="$route">
                <router-link 
                    style="color:#606C5D;text-decoration:none"
                    class=""
                    tag="a"
                    :to="{
                        'path': '/notifications/templates'
                    }">
                    <b-icon icon="door-open"></b-icon> Exit Editor
                </router-link>
                
            </b-nav-item>
            <b-nav-item 
                class="left-nav"
                @click="toggleCommand(commands.exportHtml)">
                <b-icon icon="file-arrow-down"></b-icon> Export HTML
            </b-nav-item>
            <b-nav-item 
                class="left-nav"
                @click="toggleCommand(commands.importHtml)">
                <b-icon icon="file-arrow-up"></b-icon> Import HTML
            </b-nav-item>
        </b-nav>

        <b-nav align="end" pills>
            <b-nav-item 
                class="right-nav"
                @click="saveTemplateToFile()" >
                <b-icon icon="save"></b-icon> Save template
                <b-progress v-if="saveProgress" :value="saveProgress" :max="100" show-progress animated></b-progress>

            </b-nav-item>
            <b-nav-item  
                :disabled="saveProgress > 0"
                class="right-nav"
                @click="$emit('test-template', true)" >
                <b-icon icon="file-play"></b-icon> Test template
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
// juice config - https://github.com/Automattic/juice
var juice = require('juice');



export default {
    props: ['templateName', 'originaTemplateName', 'templateCategory' , 'originaTemplateCategory'],
    methods:{
        toggleCommand(command){
            if(command in this.$parent.editor.Commands.getActive()) {
                this.$parent.editor.stopCommand(command)
                return
            }
            this.$parent.editor.runCommand(command)
        },
        runCommand(command){
            if(command in this.$parent.editor.Commands.getAll()) {
                this.$parent.editor.runCommand(command)
            }
        },
        async saveTemplateToFile(){
            
            

            
            if(!this.templateName || this.templateName.toUpperCase() == 'NEW'){
                alert('Please choose a template name')
                return
            }

            if(!this.templateCategory){
                alert('Please choose a category')
                return
            }
            


            this.saveProgress = 25
            
            const html = this.$parent.editor.getHtml()
            const css = this.$parent.editor.getCss()
            const tmpl =  `${html}<style>${css}</style>`;

            var result = juice(tmpl, {
                //applyStyleTags: false, // works
                //removeStyleTags: true,
                //applyAttributesTableElements: false
                //styleToAttribute: false
            });
            

            
            let res = await this.$http.post('/api/notifications/templates/create', {
                templateCategory: this.$parent.templateCategory,
                templateName : this.$parent.templateName,
                originaTemplateName: this.originaTemplateName,
                originaTemplateCategory: this.originaTemplateCategory,
                templateJsonData: JSON.stringify(this.$parent.editor.getProjectData()),
                //templateHtmlData: this.$parent.editor.runCommand(this.$parent.commands.getInlineHtml)
                templateHtmlData: result
                
            });

            if(res && res.status == 200){
                let me = this    
                setTimeout(function(){
                    me.saveProgress = 100
                    setTimeout(function(){
                        me.saveProgress = 0
                    }, 200)
                }, 200)
                
            }



        }
    },
    data(){
        return {
            commands: this.$parent.commands,
            saveProgress: 0,
        }
    }
    
    
}
</script>

<style scoped>

.nav {
    width: 50%;
    display: inline-flex;
    height: 100%;
    border-bottom: 1px solid #F7E6C4;
}

.nav .nav-item {
    width: 150px;
    height: 100%;
    display: inline-block;
    padding-top: 20px;
    
}

.nav .nav-link{
    color:#606C5D;
}

.right-nav {
    border-left: 1px solid #F7E6C4;
}

.left-nav {
    border-right: 1px solid #F7E6C4;
}

.nav-item:hover{
  background: #F1C376;
}

</style>