<template>
  <b-row>
    <Modal :submit="submit"/>
    <b-col cols="12">
        <b-card>
            <b-card-header>
                Short URL
            </b-card-header>
            <b-card-body style="min-height:500px;" class="tbl-wrpr"> 
                <vue-good-table 
                    theme="polar-bear"
                    :columns="fields" 
                    :rows="filteredData" 
                    :line-numbers="true"
                    :pagination-options="paginationOptions"
                    :search-options="searchOptions"
                    :select-options="selectOptions">
                    
                    <div slot="table-actions">
                        <b-button-group>
                            <b-button variant="primary" @click="$bvModal.show('modal-add-url')">
                                <b-icon icon="plus"></b-icon> Add Short Url
                            </b-button>
                        </b-button-group>
                    </div>

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'short_url_status'">
                            <span 
                                class="toggleShortUrlStatus"
                                @click="toggle(props.row)" 
                                v-b-tooltip.hover 
                                title="Toggle Short Url">
                                <b-icon v-if="props.row.short_url_status" icon="toggle-on" variant="success"></b-icon> 
                                <b-icon v-else icon="toggle-off" variant="danger"></b-icon> 
                            </span>
                        </span>
                        <span v-else-if="props.column.field == 'short_url_https'">
                            <b-icon v-if="props.row.short_url_https" icon="lock" variant="success"></b-icon> 
                            <b-icon v-else icon="unlock" variant="danger"></b-icon> 
                        </span>
                        <span v-else-if="props.column.field == 'options'">
                            <b-button-group>
                                <b-button 
                                v-b-tooltip.hover 
                                title="Delete Short Url"
                                variant="danger" @click="deleteRow(props)" >
                                    <b-icon icon="trash"></b-icon> 
                                </b-button>
                                <router-link 
                                    v-b-tooltip.hover 
                                    title="Toggle Short URL GEO status"
                                    class="btn btn-primary" :to="`/media/urls/geos/${props.row.short_url_id}`">
                                    <b-icon icon="globe"></b-icon>
                                </router-link>
                            </b-button-group>
                        </span>
                    </template>
                </vue-good-table>
            </b-card-body> 
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Modal from './Modal.vue'
export default {
    components: {
        Modal
    },
    data() {
        return {
            filteredData: [],
            selectOptions: { enabled: false },
            searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
            },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [5,10,100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
            fields: [
                
                { label: 'Id', type: 'number', field: 'short_url_id', width:'75px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Id'
                    }
                },
                { label: 'URL', field: 'short_url', width:'100px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter URL'
                    }
                },
                { label: 'Status', field: 'short_url_status', width:'100px'},
                { label: 'Https', field: 'short_url_https', width:'100px'},
                { label: 'Created', field: 'createdAt', width:'300px'},
                { label: 'Updated', field: 'updatedAt', width:'300px'},
                { label: 'Comments', field: 'short_url_comments', width:'200px'},
                { label: 'Options', field: 'options', width:'300px'},
            ],
        }
    },
    created(){
       this.loadData()
    },
    methods: {
        async submit(rowData){
            const res = await this.$http.post(`/api/campaigns/urls/create`,rowData)
            if(res && res.status == 200){       
                this.$bvModal.hide('modal-add-url') 
                this.filteredData.push(res.data)
            }
        },
        async deleteRow(props){    
            const res = await this.$http.delete(`/api/campaigns/urls/${props.row.short_url_id}`)
            if(res && res.status == 200){
                //if(res.data.status) this.filteredData.splice(props.index, 1)
                this.filteredData = [...this.filteredData].filter(item => item.short_url_id != props.row.short_url_id)
            }
        
        },
        async toggle(row){
            const res = await this.$http.get(`/api/campaigns/urls/update/status/${row.short_url_id}`)
            if(res && res.status == 200){
                row.short_url_status = res.data.short_url_status
            }
        },
        async loadData(){
            const res = await this.$http.get('/api/campaigns/urls/read')
            if(res && res.status == 200){
                this.filteredData = res.data
            }
       
        }
    }
}
</script>

<style>
    .toggleShortUrlStatus {
        font-size:22px;
        cursor: pointer;
    }

    .tbl-wrpr .vgt-global-search__input {
        display: none !important;
    }
</style>