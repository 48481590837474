import DelifyLayout from '../components/Delify/Layout.vue'
import DelifyTable from '../components/Delify/Table.vue'

export default {
  path: 'delify',
  name: 'delify',
  component: DelifyLayout,
  redirect: 'delify/statistics',
  children:[
    {
      path: 'statistics',
      name: 'Delify Search',
      component: DelifyTable
    }
  ]
}
