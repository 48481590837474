<template>
  <div>
    <b-row class="p-3">
      <b-col lg="4" sm="12">
        <b-form-group
            label="Filter by date"
            label-for="datepicker"
            invalid-feedback="Date is required"
            :state="dateRange != null">
             <date-range-picker
              style="width: 100% !important;"
                opens="right"
                ref="picker"
                :locale-data="locale"
                v-model="dateRange"
                @update="getData">
                
                <!--    input slot (new slot syntax)-->
                <template #input="picker">
                  <b-icon icon="calendar"></b-icon>
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
              </date-range-picker>

        </b-form-group>
      </b-col>  
    </b-row>
    <b-row class="p-3">
        <b-col col cols="12">
            <b-button-group>
                <b-button variant="outline-primary" @click="newByDate()">
                  <b-icon icon="calendar2-date"></b-icon>
                  Schedule a notification by date
                </b-button>
                <b-button variant="outline-primary" @click="newRecurring()">
                  <b-icon icon="calendar2-plus"></b-icon>
                  Schedule a recurring notification
                </b-button>
                <b-button variant="outline-primary" @click="getData()">
                  <b-icon icon="recycle"></b-icon>
                  Refresh table
                </b-button>
                
                <Modal @addedRow="addedRow" :modalId="modalId" :editRowData="rowData"/>
            </b-button-group>
        </b-col>
    </b-row>

    <b-row>
      <vue-good-table 
          theme="polar-bear"
          :columns="fields" 
          :rows="rows" 
          :sort-options="{
            enabled: true,
            initialSortBy: {field: 'id', type: 'desc'}
          }"
          :pagination-options="paginationOptions"
          :search-options="searchOptions"
          :select-options="selectOptions">
          
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'notifications_schedule_job_status'">
              
                <b-icon v-if="props.row.notifications_schedule_job_status == 1" variant="primary" icon="clock" v-b-tooltip.hover title="Scheduled"></b-icon>
                <b-icon v-else-if="props.row.notifications_schedule_job_status == 2" variant="success" icon="check-circle-fill" v-b-tooltip.hover title="Completed"></b-icon>

                <div v-else-if="props.row.notifications_schedule_job_status == 3">
                  <b-icon 
                    style="cursor: pointer"
                    variant="danger" 
                    icon="eye-fill" 
                    v-b-tooltip.hover title="Failed" 
                    @click="$bvModal.show(`view-error-modal-${props.row.id}`)">
                  </b-icon>
                  <b-modal :id="`view-error-modal-${props.row.id}`" size="lg" hide-footer>
                    <template #modal-title>
                      View schedule error 
                    </template>
                    <div class="d-block text-center">
                      {{props.row.notifications_schedule_error}}
                    </div>
                    <b-button class="mt-3" block @click="$bvModal.hide(`view-error-modal-${props.row.id}`)">Close</b-button>
                  </b-modal>
                </div>
                


                <b-icon v-else-if="props.row.notifications_schedule_job_status == 4" color="warning"  icon="stop-circle-fill" v-b-tooltip.hover title="Canceled"></b-icon>
                <b-icon v-else variant="warning"  icon="question-circle-fill" v-b-tooltip.hover title="Unknown"></b-icon>
                

              </span>
              <span v-else-if="props.column.field == 'notifications_schedule_recurring'">
                <b-icon v-if="props.row.notifications_schedule_recurring"  icon="check-circle" variant="primary"></b-icon>
                <b-icon v-else icon="x-circle" variant="primary"></b-icon>
              </span>
              
              <span v-else-if="props.column.field == 'notifications_schedule_to'">

                <div v-if="props.row['notifications_recipients.notifications_recipients_email']">
                
                  {{ props.row['notifications_recipients.notifications_recipients_email'].split(',').splice(0, 2).join(',') }}
                  
                  <div v-if="props.row['notifications_recipients.notifications_recipients_email'].split(',').length > 2">
                    <b-collapse :id="`collapse-${props.row.id}`" v-model="props.row.visible" class="mt-2">
                      {{props.row['notifications_recipients.notifications_recipients_email'].split(',').splice(2,).join(',')}}
                    </b-collapse>
                    <b-button 
                        class="p-0 w-100"
                        style="font-size:8px"
                        :aria-controls="`collapse-${props.row.id}`"
                        @click="props.row.visible = !props.row.visible"
                        variant="outline-primary">
                          <b-icon v-if="!props.row.visible" icon="chevron-double-down" ></b-icon>
                          <b-icon v-else icon="chevron-double-up" ></b-icon>
                      </b-button>
                  </div>
                </div>
                <div v-else>
                  {{props.row.notifications_schedule_to}}
                </div>
                
              </span>
              <span v-else-if="props.column.field == 'notifications_schedule_month'">
                
                {{ 
                  !props.row.notifications_schedule_month ?  'NA' : $months[parseInt(props.row.notifications_schedule_month) - 1] ? $months[parseInt(props.row.notifications_schedule_month) - 1].text :
                  props.row.notifications_schedule_month
                }}

              </span>
              <span v-else-if="props.column.field == 'notifications_schedule_day_of_month'">
                {{props.row.notifications_schedule_day_of_month}}
              </span>
              <span v-else-if="props.column.field == 'notifications_schedule_day'">
                {{ 
                  !props.row.notifications_schedule_day ?  'NA' : $months[parseInt(props.row.notifications_schedule_day) - 1] ? $months[parseInt(props.row.notifications_schedule_day) - 1].text :
                  props.row.notifications_schedule_day
                }}
              </span>
              <span v-else-if="props.column.field == 'notifications_schedule_tz'">
                {{props.row.notifications_schedule_tz}}
              </span>
              <span v-else-if="props.column.field == 'notifications_schedule_provider_message_id'">
                {{props.row.notifications_schedule_provider_message_id}}
              </span>
              <span v-else-if="props.column.field == 'notifications_schedule_provider_timestamp'">
                {{props.row.notifications_schedule_provider_timestamp}}
              </span>
              
              <span v-else-if="props.column.field == 'notifications_schedule_options'">
                  <b-button-group>
                      
                      <b-button size="sm" variant="outline-success" @click="editRow(props.row)"  v-b-tooltip.hover title="Edit Schedule">
                        <b-icon icon="pencil-square"></b-icon>
                      </b-button>
                      <b-button  @click="cancelSchedule(props)" v-b-tooltip.hover title="Cancel Schedule" size="sm" variant="outline-warning">
                          <b-icon icon="x"></b-icon>
                      </b-button>
                      <b-button   @click="resumeSchedule(props)" v-b-tooltip.hover title="Resume Schedule" size="sm" variant="outline-primary">
                          <b-icon icon="play"></b-icon>
                      </b-button>
                      <b-button  
                        :disabled="/[2]/.test(props.row.notifications_schedule_job_status)"
                        @click="deleteSchedule(props)" v-b-tooltip.hover title="Delete Schedule" size="sm" variant="outline-danger">
                          <b-icon icon="trash"></b-icon>
                      </b-button>
                      <b-button @click="$bvModal.show(`more-info-modal-${props.row.id}`)" v-b-tooltip.hover title="More details" size="sm" variant="outline-primary">
                          <b-icon icon="eye"></b-icon>
                      </b-button>
                      
                      <b-modal :id="`more-info-modal-${props.row.id}`" size="lg" hide-footer>
                        <template #modal-title>
                          More info
                        </template>
                        <div class="d-block text-center">
                          <b-row>
                            <b-col>
                              <b>Name</b>
                            </b-col>
                            <b-col>
                              <b>Value</b>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>Category</b-col><b-col>{{props.row.notifications_schedule_template_category}}</b-col>
                          </b-row>
                          <b-row>
                            <b-col>Provider message id</b-col><b-col>{{props.row.notifications_schedule_provider_message_id}}</b-col>
                          </b-row>
                          <b-row>
                            <b-col>Provider time zone</b-col><b-col>{{props.row.notifications_schedule_provider_tz}}</b-col>
                          </b-row>
                          <b-row>
                            <b-col>Provider time stamp</b-col><b-col>{{props.row.notifications_schedule_provider_timestamp}}</b-col>
                          </b-row>
                          
                        </div>
                        <b-button class="mt-3" block @click="$bvModal.hide(`more-info-modal-${props.row.id}`)">Close</b-button>
                      </b-modal>
                      <!--
                      <b-button :disabled="/[2]/.test(props.row.notifications_schedule_job_status)" size="sm" variant="outline-success" @click="editRow(props.row)"  v-b-tooltip.hover title="Edit Schedule">
                        <b-icon icon="pencil-square"></b-icon>
                      </b-button>
                      <b-button :disabled="/[2]/.test(props.row.notifications_schedule_job_status)" @click="cancelSchedule(props)" v-b-tooltip.hover title="Cancel Schedule" size="sm" variant="outline-warning">
                          <b-icon icon="x"></b-icon>
                      </b-button>
                      <b-button :disabled="/[2]/.test(props.row.notifications_schedule_job_status)"  @click="resumeSchedule(props)" v-b-tooltip.hover title="Resume Schedule" size="sm" variant="outline-primary">
                          <b-icon icon="play"></b-icon>
                      </b-button>
                      <b-button :disabled="/[2]/.test(props.row.notifications_schedule_job_status)" @click="deleteSchedule(props)" v-b-tooltip.hover title="Delete Schedule" size="sm" variant="outline-danger">
                          <b-icon icon="trash"></b-icon>
                      </b-button>
                      <b-button :disabled="!/[2]/.test(props.row.notifications_schedule_job_status)" @click="$bvModal.show(`more-info-modal-${props.row.id}`)" v-b-tooltip.hover title="More details" size="sm" variant="outline-primary">
                          <b-icon icon="eye"></b-icon>
                      </b-button>
                      -->
                  </b-button-group>
              </span>
             
          </template>
      </vue-good-table>
      
    </b-row>
    
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
import fileds from '../json/schedulesCols.json'
import Modal from './Modal.vue'

let startDate = new Date();
let endDate = new Date();
startDate.setDate(endDate.getDate() - 7)

export default {
  components:{
    Modal,
    DateRangePicker
  },
  mounted(){
    this.getData()
  },
  methods: {
    
    updateValues(){
      console.log(this.dateRange)
    },
    newByDate(){
      this.row.notifications_schedule_recurring =  false

      this.editRow(this.row)
    },
    newRecurring(){
      this.row.notifications_schedule_recurring =  true
      this.editRow(this.row)
    },
    editRow(rowData){
      this.rowData = rowData
      this.$root.$emit('bv::show::modal', this.modalId, '#btnShow')
      
    },
    resetRow(){
      this.row = {
         "notifications_schedule_minute": null,
          "notifications_schedule_hour": null,
          "notifications_schedule_day": null,
          "notifications_schedule_day_of_month": null,
          "notifications_schedule_month": null,
          "notifications_schedule_full_date": null,
          "notifications_schedule_full_time": null,
          "notifications_schedule_year": null,
          "notifications_schedule_template": null,
          "notifications_schedule_template_category": null,
          "notifications_schedule_subject": 'Message-deck notification',
          "notifications_schedule_from": 'support@message-deck.com',
          "notifications_schedule_to": null,
          "notifications_schedule_recurring": null,
          "notifications_schedule_job_running": null,
          "notifications_schedule_job_status": null,
      }
    },
    addedRow(rowData){
      //console.log(rowData)
      rowData.notifications_schedule_to = rowData.notifications_schedule_to.split(',')
      this.resetRow()
      //this.rows.push(rowData)
    },
    async resumeSchedule(rowData){
      const postive = await this.$bvModal.msgBoxConfirm('Are you sure you wish to resume this schedule?',{
        title: 'Confirmation required'
      })
      
      if(!postive){
        return
      }

      const row = {...rowData.row}
      row.notifications_schedule_job_status = 1

      let res = await this.$http.post(`/api/notifications/scheduler/update`, row);
      if(res && res.status == 200){
          rowData.row.notifications_schedule_job_status = row.notifications_schedule_job_status
          
      }
    },
    async deleteSchedule(rowData){
      
      const postive = await this.$bvModal.msgBoxConfirm('Are you sure you wish to delete this schedule?',{
        title: 'Confirmation required'
      })

      if(!postive){
        return
      }
     

      let res = await this.$http.post(`/api/notifications/scheduler/delete`, rowData.row);
      if(res && res.status == 200){
          this.rows.splice(rowData.index, 1)
      }
      
      
    },
    async cancelSchedule(rowData){
      const postive = await this.$bvModal.msgBoxConfirm('Are you sure you wish to cancel this schedule?',{
        title: 'Confirmation required'
      })
      
      if(!postive){
        return
      }

      
      const row = {...rowData.row}
      row.notifications_schedule_job_status = 4
      
      let res = await this.$http.post(`/api/notifications/scheduler/update`, row);
      if(res && res.status == 200){
        rowData.row.notifications_schedule_job_status = row.notifications_schedule_job_status
      }
      
    },
    async getData() {
      //let res = await this.$http.get(`/api/notifications/scheduler/read`);
      let res = await this.$http.post(`/api/notifications/scheduler/read`, this.dateRange);
      if(res && res.status == 200){
          this.rows = res.data
      }
    }
  },
  filters: {
    dateCell (value) {
      let dt = new Date(value)
      return dt.getDate()
    },
    date (val) {
      //return val ? val.toLocaleString() : ''
       return val ? moment(val).format("DD-MM-YYYY") : "";
    }
  },
  data(){
    return {
      startDate: String((new Date()).getDate()).padStart(2, '0') ,
      endDate: new Date(),
      dateRange: { startDate, endDate },
      locale: {
          direction: 'ltr', //direction of text
          format: 'DD-MM-YYYY', //fomart of the dates displayed
          separator: ' - ', //separator between the two ranges
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
          monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
          firstDay: 1 //ISO first day of week - see moment documenations for details
      },
      modalId: 'modal-schedule',
      rowData:{},
      row: {
          "notifications_schedule_minute": null,
          "notifications_schedule_hour": null,
          "notifications_schedule_day": null,
          "notifications_schedule_day_of_month": null,
          "notifications_schedule_month": null,
          "notifications_schedule_full_date": null,
          "notifications_schedule_full_time": null,
          "notifications_schedule_year": null,
          "notifications_schedule_template": null,
          "notifications_schedule_template_category": null,
          "notifications_schedule_subject": 'Message-deck notification',
          "notifications_schedule_from": 'support@message-deck.com',
          "notifications_schedule_to": null,
          "notifications_schedule_recurring": null,
          "notifications_schedule_job_running": null,
          "notifications_schedule_job_status": null,
      },
      rows: [],
      fields: fileds,
      paginationOptions: {
        enabled: true,
        //mode: 'records',
        mode: 'pages',
        perPage: 10,
        position: 'top',
        perPageDropdown: [10, 20, 50],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast : true,
        firstLabel : 'First Page',
        lastLabel : 'Last Page',
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
        //infoFn: (params) => {`Displaying ${params.totalRecords}/${this.rows.length}`}, 
      },
      searchOptions: {},
      selectOptions: {}
    }
  }
}
</script>

<style>

</style>