<template>

    <b-row>            
        <b-col>
            <b-form-group 
                :label="label"
                :invalid-feedback="invalidFeedback"
                :state="true">
                <b-form-select
                    @input="inputType()"
                    @change="changeType()"
                    class="w-100"
                    :state="selectType != null"
                    v-model="selectType"
                    :required="required">
                    <b-form-select-option :value="null" disabled>{{titles[0]}}</b-form-select-option>
                    <b-form-select-option value="E">{{titles[1]}}</b-form-select-option>
                    <b-form-select-option value="S">{{titles[2]}}</b-form-select-option>
                    <b-form-select-option value="R">{{titles[3]}}</b-form-select-option>
                </b-form-select>
            </b-form-group>
        </b-col>
        <b-col v-if="['E', null].includes(selectType)">
            <b-form-group 
                :label="labelValue"
                :invalid-feedback="invalidFeedback"
                :state="true">
                    <b-form-input
                        disabled
                        :state="selectValue != null"
                        v-model="selectValue">
                    </b-form-input>
            </b-form-group>
        </b-col>
        <b-col v-if="['S','R'].includes(selectType)">
            <b-form-group 
                :label="labelValue"
                :invalid-feedback="invalidFeedback"
                :state="true">

                <b-form-select
                    v-if=" type == 'range' "
                    @input="inputValue()"
                    @change="changeValue()"
                    class="w-100"
                    :state="selectValue != null"
                    v-model="selectValue"
                    :required="required">
                    <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                    <b-form-select-option v-for="n in maxRange" :key="n" :value="n">{{n}}</b-form-select-option>
                </b-form-select>
                <b-form-select
                    v-else
                    @input="inputValue()"
                    @change="changeValue()"
                    class="w-100"
                    :state="selectValue != null"
                    v-model="selectValue"
                    :required="required">
                    <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                    <b-form-select-option v-for="o in values" :key="o.value" :value="o.value">{{o.text}}</b-form-select-option>
                </b-form-select>

            </b-form-group>
        </b-col>
        <b-col v-if="['R'].includes(selectType)">
                <b-form-group 
                :label="labelValue"
                :invalid-feedback="invalidFeedback"
                :state="true">

                <b-form-select
                    v-if=" type == 'range' "
                    @input="inputRangeValue()"
                    @change="changeRangeValue()"
                    class="w-100"
                    :state="selectToRangeValue != null"
                    v-model="selectToRangeValue"
                    :disabled="[null,'',undefined].includes(selectValue)"
                    :required="required">
                    <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                    <b-form-select-option v-for="n in maxRange" :key="n" :value="n">{{n}}</b-form-select-option>
                </b-form-select>
                <b-form-select
                    v-else
                    
                    @input="inputRangeValue()"
                    @change="changeRangeValue()"
                    class="w-100"
                    :state="selectToRangeValue != null"
                    :disabled="[null,'',undefined].includes(selectValue)"
                    v-model="selectToRangeValue"
                    :required="required">
                    <b-form-select-option :value="null" disabled>Select</b-form-select-option>
                    <b-form-select-option v-for="o in values.filter(item => item.value > selectValue)" :key="o.value" :value="o.value">{{o.text}}</b-form-select-option>
                </b-form-select>

            </b-form-group>
        </b-col>
    </b-row>
    
</template>

<script>
export default {
    props:{
        titles:{
            type: Array,
            required: true
        },
        type:{
            type: String,
            required: true,
            default: "range",
            validator: function(value) {
                return ['days', 'months', 'range'].includes(value);
            }
        },
        required:{
            type: Boolean,
            required: true,
            default: true
        },
        invalidFeedback: {
            type: String,
            required: true,
            default: "Please choose day of week type"
        },
        label: {
            type: String,
            required: true,
            default: "Title left select"
        },
        labelValue: {
            type: String,
            required: true,
            default: "Title left select"
        },
        submitRef:{
            type: String,
            required: true,
            default: "submitDayOfMonth"
        },
       
        maxRange:{
            type: Number,
            required: true,
            default: 31
        },
        
    },
    mounted(){
        this.value = 'E'
        this.selectType = 'E'
        this.selectValue = '*'
        this.selectToRangeValue = null
    },
    methods:{
        getVlues(){    
            this.values = this[`$${this.type}`]
        },
        inputType(){
            if(this.selectType == 'E'){
                this.value = this.selectValue = "*"
            }else{
                this.value = this.selectValue = null
            }
                
            

            this.$emit('input', this.value)
            
        },
        changeType(){},
        inputValue(){
            if(this.selectType == 'S'){
                this.value = `*/${this.selectValue}`
            }else if(this.selectType == 'R'){
                this.value = `${this.selectValue}-${this.selectToRangeValue}`
            }

            this.$emit('input', this.value)
            
        },
        changeValue(){},
        inputRangeValue(){
            this.value = `${this.selectValue}-${this.selectToRangeValue}`
            this.$emit('input', this.value)
        },
        changeRangeValue(){},
        submitForm(){
            this.$refs[this.submitRef].click();
        }
    },
    data(){
        return {

            value: null,
            selectType: null,
            selectValue: null,
            selectToRangeValue: null,

           
            values: this[`$${this.type}`],
            reangeValues: []
        }
    }
}
</script>

<style>
label,.invalid-feedback,legend {
    text-align: left;
}
</style>