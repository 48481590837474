<template>
  <b-form @submit.stop.prevent="() => onSubmit(tag, config, includes.filter(i => i))">
    <b-row>
      <b-col cols="6">
        <b-card style="padding: 10px 10px 10px 10px;">
          <b-row>
            <b-form-group style="text-align:left">
              <h6 style="color:green;">{{this.params.uid}}</h6>
            </b-form-group>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Tag:" label-for="input-tag" :invalid-feedback="'Tag is required'" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  v-model="tag"
                  id="input-tag"
                  :state="tag && tag.length > 0 ? true : false"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Admin Port:" label-for="input-admin-port" invalid-feedback="Enter a valid port" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  id="input-admin-port"
                  v-model="config['admin-port']"
                  :state="/^[0-9]{1,10}$/.test(config['admin-port'])"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group 
              
              label="Admin Password:" label-for="input-admin-password" style="text-align:left">
                <b-form-input
                  @input="config['status-password'] = config['admin-password']"
                  required
                  size="sm"
                  placeholder="Required"
                  id="input-admin-password"
                  autocomplete="new-password"
                  v-model="config['admin-password']"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label="Status Password:" label-for="input-status-password" style="text-align:left">
                <b-form-input
                  size="sm"
                  disabled
                  placeholder="Optional"
                  id="input-status-password"
                  autocomplete="new-password"
                  v-model="config['status-password']"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-form-group label="SMS Box Port:" label-for="input-smsbox-port" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  id="input-smsbox-port"
                  v-model="config['smsbox-port']"
                  :state="/^[0-9]{1,10}$/.test(config['smsbox-port'])"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="SMS Max Pending:" label-for="input-smsbox-max-pending" style="text-align:left">
                <b-form-input id="input-smsbox-max-pending" placeholder="Optional" size="sm" v-model="config['smsbox-max-pending']" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-form-group label="SMS Resend Retry:" label-for="input-sms-resend-retry" style="text-align:left">
                <b-form-input id="input-sms-resend-retry" placeholder="Optional" size="sm" v-model="config['sms-resend-retry']" />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="SMS Resend Frequency:" label-for="input-sms-resend-freq" style="text-align:left">
                <b-form-input id="input-sms-resend-freq" placeholder="Optional" size="sm" v-model="config['sms-resend-freq']" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              
              <b-form-group label="Storage type:" label-for="input-log-level" style="text-align:left"
                :invalid-feedback="'Storage type is required'"
                :state="config['store-type'] != null">
                <b-form-select
                    style="width:100% !important;"
                    v-model="config['store-type']"
                    required>
                      <template #first>
                        <b-form-select-option :value="null" disabled>Store type</b-form-select-option>
                        <b-form-select-option value="spool">spool</b-form-select-option>
                        <b-form-select-option value="internal">internal</b-form-select-option>
                        <b-form-select-option value="file">file</b-form-select-option>
                        <b-form-select-option value="database">database</b-form-select-option>

                      </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Storage location:" style="text-align:left">
                <b-form-input placeholder="Storage location" size="sm" v-model="config['store-location']" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="DLR Storage type:" label-for="input-log-level" style="text-align:left"
                :invalid-feedback="'DLR Storage type is required'"
                :state="config['dlr-storage'] != null">
                <b-form-select
                    style="width:100% !important;"
                    v-model="config['dlr-storage']"
                    required>
                      <template #first>
                        <b-form-select-option :value="null" disabled>Store type</b-form-select-option>
                        <b-form-select-option value="internal">internal</b-form-select-option>
                        <b-form-select-option value="mysql">mysql</b-form-select-option>
                        <b-form-select-option value="spool">spool</b-form-select-option>
                        <b-form-select-option value="file">file</b-form-select-option>
                        <b-form-select-option value="log">log</b-form-select-option>
                        <b-form-select-option value="pgsql">pgsql</b-form-select-option>
                        <b-form-select-option value="sqlite">sqlite</b-form-select-option>
                        <b-form-select-option value="odbc">odbc</b-form-select-option>
                        <b-form-select-option value="pgsql-log">pgsql-log</b-form-select-option>
                        <b-form-select-option value="mysql-log">pgsql-log</b-form-select-option>
                      </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
            <b-col v-if="config['dlr-storage'] == 'spool'">
              <b-form-group label="DLR spool location:" style="text-align:left">
                <b-form-input 
                
                placeholder="DLR spool location" size="sm" v-model="config['dlr-spool']" />
              </b-form-group>
            </b-col>
          </b-row>
          
          
          <b-row>
            <b-col>
              <b-form-group label="Smsbox's folder:" label-for="input-smsbox" style="text-align:left">
                <b-form-select v-model="smsbox" size="xs" class="w-100" required @change="setIncludeEtitites('smsbox', smsbox)">
                  <b-form-select-option :value="null" disabled>smsboxes config path</b-form-select-option>
                  <b-form-select-option :value="`${this.getEntityPath('smsbox')}`">smsbox</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="SMSc's folder:" label-for="input-smsc" style="text-align:left">
                <b-form-select v-model="smsc" size="xs" class="w-100" required @change="setIncludeEtitites('smsc', smsc)">
                  <b-form-select-option :value="null" disabled>smscs config path</b-form-select-option>
                  <b-form-select-option :value="`${this.getEntityPath('smsc')}`">smsc</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Users folder:" label-for="input-users" style="text-align:left">
                <b-form-select v-model="sendsmsusers" size="xs" class="w-100"  @change="setIncludeEtitites('sendsms-user', sendsmsusers)">
                  <b-form-select-option :value="null" disabled>sendsmsusers config path</b-form-select-option>
                  <b-form-select-option :value="`${this.getEntityPath('sendsms-user')}`">sendsms-user</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Smsbox Routes folder:" label-for="input-smsc" style="text-align:left">
                <b-form-select v-model="smsboxroute" size="xs" class="w-100"  @change="setIncludeEtitites('smsbox-route', smsboxroute)">
                  <b-form-select-option :value="null" disabled>smsbox-route config path</b-form-select-option>
                  <b-form-select-option :value="`${this.getEntityPath('smsbox-route')}`">smsbox-route</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row>
            <b-col>
              <b-form-group label="DB connection config id:" label-for="input-db-connection" style="text-align:left">
                <b-form-select v-model="connection" size="xs" class="w-100" @input="setIncludeEtitites('db-connection', connection)">
                  <b-form-select-option :value="null" disabled>db connection config path</b-form-select-option>
                  <b-form-select-option v-for="conn in connections" :key="conn.id" :value="`${conn.uid}.conf`" >
                        {{conn.config.id}}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            
            <b-col>
              <b-form-group label="DB dlr table config id:" label-for="input-table" style="text-align:left">
                <b-form-select v-model="dbtbl" size="xs" class="w-100" @input="setIncludeEtitites('dlr-db', dbtbl)">
                  <b-form-select-option :value="null" disabled>db table config path</b-form-select-option>
                  <b-form-select-option v-for="tbl in dlrdbs" :key="tbl.id" :value="`${tbl.uid}.conf`" >
                        {{tbl.config.id}}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Sms Service:" label-for="input-table" style="text-align:left">
                <b-form-select v-model="services" size="xs" class="w-100" @input="setIncludeEtitites('smss', services)">
                  <b-form-select-option :value="null" disabled>Services config path</b-form-select-option>
                  <b-form-select-option v-for="service in smss" :key="service.id" :value="`${service.uid}.conf`" >
                        {{service.uid}}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row>
            <b-col cols="5">
              <b-form-group label="Log Level:" label-for="input-log-level" style="text-align:left">
                <b-form-select v-model="config['log-level']" size="xs" :options="[
                  { value: 0, text: 'Debug' },
                  { value: 1, text: 'Info' },
                  { value: 2, text: 'Warning' },
                  { value: 3, text: 'Error' },
                  { value: 4, text: 'Panic' }
                ]" />
              </b-form-group>
            </b-col>
          </b-row>

          <ConfigIncludes :includes="includes" :disabled="false" />

          <br />

          <b-row>
            <b-button-group>
              <b-button variant="outline-danger" @click="() => onClose()">Close</b-button>
              <b-button type="submit" variant="outline-primary">Submit</b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <ConfigPreview :config="config" :includes="includes" />
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
  import ConfigPreview from '../../Common/Config/ConfigPreview.vue'
  import ConfigIncludes from '../../Common/Config/ConfigIncludes.vue'

  export default {
    components: {
      ConfigPreview,
      ConfigIncludes
    },
    props: [
      'params', 
      'onSubmit', 
      'onClose',
      'smsboxes',
      'connections',
      'dlrdbs',
      'smscs',
      'smss'
    ],
    methods:{
      setIncludeEtitites(entity, value){ 
        if(value){
          const path = this.getEntityPath(entity)
          const idx = this.includes.findIndex(item=> item.includes(path))
          if(idx > -1) this.includes.splice(idx,1)
          if(value.includes(path)){
            this.includes.push(value)
          }else{
            this.includes.push(`${path}/${value}`)
          }
          
        }
      }
    },
    data () {
      return {
        dbtbl: null,
        connection: null,
        smsbox: null,
        smsc: null,
        sendsmsusers: null,
        smsboxroute: null,
        services: null,
        tag: null,
        config: {
          'sms-resend-freq': 1200,
          'sms-resend-retry': 1,
          'smsbox-max-pending': 100,
          'admin-port': 13000,
          'smsbox-port': 13032,
          'store-type': 'spool',
          'dlr-storage': 'mysql',
          'store-location': '/var/spool/kannel/store',
          'log-level': 0
        },
        includes: []
      }
    },
    created () {
      this.tag = this.params.tag
      this.includes = this.params.includes

      var idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('db-connection') ))
      this.connection = idx > -1 ? this.includes[idx].split(/\//)[5] : null

      idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('dlr-db') ))
      this.dbtbl = idx > -1 ? this.includes[idx].split(/\//)[5] : null

      idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('smsbox') ))
      this.smsbox = idx > -1 ? this.includes[idx] : null
      

      idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('smsc') ))
      this.smsc = idx > -1 ? this.includes[idx] : null

      idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('sendsms-user') ))
      this.sendsmsusers = idx > -1 ? this.includes[idx] : null

      idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('smsbox-route') ))
      this.smsboxroute = idx > -1 ? this.includes[idx] : null

      idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('smss') ))
      this.services = idx > -1 ? this.includes[idx].split(/\//).pop() : null

      this.config = { ...this.config, ...this.params.config }
    },
  }
</script>

<style>
  .row {
    margin: 10px;
  }
</style>
