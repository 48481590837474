<template>
  <b-row>
    <b-col cols="12">
        <b-card>
            <b-card-header>
                Client Campaigns
            </b-card-header>
            <b-card-body style="min-height:500px;"> 
                <vue-good-table 
                    theme="polar-bear"
                    :columns="fields" 
                    :rows="filteredData" 
                    :line-numbers="true"
                    :pagination-options="paginationOptions"
                    :search-options="searchOptions"
                    :select-options="selectOptions">

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'campaign_mt_sender'">
                            <span v-b-tooltip.hover 
                                title="Sender is random Local Msisdn"
                                v-if="props.row.campaign_local_originator">
                                <b-icon icon="megaphone" variant="primary"></b-icon> Local Msisdn
                            </span>
                            <span v-else>
                                {{props.row.campaign_mt_sender}}
                            </span>
                        </span>
                        
                        
                        
                        <span v-else-if="props.column.field == 'campaign_ssl_qs'">
                            <span @click="toggleSslQs(props)" style="cursor: pointer">
                                <b-icon v-if="props.row.campaign_ssl_qs" icon="toggle-on" font-scale="1.5" ></b-icon>
                                <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
                            </span>
                        </span>

                        <span v-else-if="props.column.field == 'more_info_summery'">
                            <b-icon 
                            v-b-tooltip.hover title="Shorten URL"
                            v-if="props.row.campaign_shorten_url" icon="link45deg" variant="primary"></b-icon>

                            <b-icon 
                            v-b-tooltip.hover title="Filter Crawlers"
                            v-if="props.row.campaign_filter_crawlers" icon="eyeglasses" variant="primary"></b-icon>

                            <b-icon 
                            v-b-tooltip.hover title="Append Network"
                            v-if="props.row.campaign_append_operator" icon="hdd-network" variant="primary"></b-icon>

                            <b-icon 
                            v-b-tooltip.hover title="Append Price"
                            v-if="props.row.campaign_append_price" icon="currency-dollar" variant="primary"></b-icon>
                            

                        </span>
                    </template>

                </vue-good-table>
            </b-card-body> 
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
    data() {
        return {
            filteredData: [],
            selectOptions: { enabled: false },
            searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
            },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [5,10,100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
            fields: [
                
                { label: 'Id', field: 'campaign_id', width:'50px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Id'
                    }
                },
                { label: 'Uuid', field: 'campaign_account_uuid', width:'100px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Id'
                    }
                },
                { label: 'Name', field: 'campaign_name', width:'100px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Name'
                    }
                },
                { label: 'Type', field: 'campaign_type', width:'100px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Type'
                    }
                },
                { label: 'Sender', field: 'campaign_mt_sender', width:'100px'},
                { label: 'Token', field: 'campaign_short_token', width:'100px'},
                { label: 'Amount', field: 'campaign_to_send', width:'100px'},
                { label: 'SSL Qs', field: 'campaign_ssl_qs', width:'100px'},
                { label: 'More Info', field: 'more_info_summery', width:'100px'},
                { label: 'Message Text', field: 'campaign_mt_text', width:'300px'},
                { label: 'Tracking Link', field: 'campaign_tracking_link', width:'300px'},
                { label: 'Created', field: 'createdAt', width:'200px'},
                { label: 'Updated', field: 'updatedAt', width:'200px'},
                { label: 'Options', field: 'options'},
            ],
        }
    },
    created(){
        this.loadData()
    },
    methods: {
        /**
         * FINISH THIS AND URLS!
         */
        async loadData(){
            const res = await this.$http.get('/api/campaigns/read')
            if(res && res.status == 200){
                this.filteredData = res.data
            }
        },
        async toggleSslQs(props){
            const res = await this.$http.post(`/api/campaigns/ssl/${props.row.id}`, props.row)
            if(res && res.status == 200){
                props.row.campaign_ssl_qs = res.data.campaign_ssl_qs
            }
        },
    }
}
</script>

<style>

</style>