<template>
  <div class="p-2 w-100">
    
    <b-row>
        <TopFilter
            :showOperators="true"
            :apiKeyTokens="apiKeyTokens"
            :accountUuids="accountUuids"
            :providers="smscAccounts"
            @filter="fetch"
        />
    </b-row>
    <b-row>
        <b-col cols="12">
            <OptionButtons :disabled="buttonsDisabled"/>
        </b-col>
    </b-row>
    <b-row>
        <Table 
            :loading="loading"
            :rows="rows"  
            @selectedInTable="setRowsSelected"
            
        />
    </b-row>

    <BatchRouting :filtered="filtered" :providers="smscAccounts" @done="done" />
    <SingleRouting :apiKeyTokens="apiKeyTokens" :providers="smscAccounts" @done="done"/>
    <CopyExistingAccount  :filtered="filtered" :apiKeyTokens="apiKeyTokens"  @done="done" />
    <ResetDefaults :filtered="filtered" @done="done" />
    <DeleteRouting :filtered="filtered" @done="done" />
    <SendRouting   :filtered="filtered" @done="done" />

  </div>
</template>

<script>
import TopFilter from '../Common/Filter.vue'
import Table from './Table.vue'
import OptionButtons from './Buttons.vue'

import BatchRouting from './BatchRouting.vue'
import SingleRouting from '../Tokens/BatchRouting.vue'
import CopyExistingAccount from '../Tokens/CopyExistingAccount.vue'
import ResetDefaults from '../Tokens/ResetDefaults.vue'
import DeleteRouting from '../Tokens/DeleteRouting.vue'
import SendRouting from '../Tokens/SendRouting.vue'


export default {
    components: {
        TopFilter,
        OptionButtons,
        Table,
        BatchRouting,
        SingleRouting,
        CopyExistingAccount,
        ResetDefaults,
        DeleteRouting,
        SendRouting
    },
    props:{
        smscAccounts: {
            type: Array,
            default: () => []
        },
        apiKeyTokens: {
            type: Array,
            default: () => []
        },
        accountUuids: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            filtered: {},
            buttonsDisabled: true,
            routing: [],            
            rows: [],
            hlrProviders: [],
            accountsSelected: [],
            countriesInRouting: [],
            selecetedForBulkAction: [],
            smsProviders: [],
            uuidList: [],
            loading: false,
        }
    },
    methods: { 
        async fetch(filter){        


            if(filter) this.filtered = { ... this.filtered, ... filter }


            this.toggleBusy()
            this.rows = []
            let res = await this.$http.post('/api/routing/token/read', 
            {
                routing_account_uuid: this.filtered.uuids,
                routing_country: this.filtered.countries,
                routing_account: this.filtered.apiKeyTokens,
                routing_mcc: this.filtered.routing_mcc,
                routing_mnc: this.filtered.routing_mnc,
                routing_type: this.filtered.routing_type,
                //routing_route_provider_account_id: this.filtered.smscAccounts,
                routing_route_provider_account_id: this.filtered.providers,
                routing: []
            })

            if(res && res.status == 200){
                this.rows = res.data
            }
            
            this.toggleBusy()

        },
        done(model){
            this.$bvModal.hide(model)
            this.fetch()
        },
        setRowsSelected(rows){
            this.buttonsDisabled = true
            this.filtered = { ... this.filtered, ... {routing: rows.selectedRows} }
            if(this.filtered.routing.length){
                this.buttonsDisabled = false
            }
        },
        toggleBusy(){
            this.loading = !this.loading
        },
        toggleModal(modalId){
            this.$bvModal.show(modalId)
        },
        

    }
}
</script>

<style>

</style>