<template>
  <b-nav align="center">
    <b-nav-item @click="runCommand('core:undo')" v-b-tooltip.hover title="Undo">
        <b-icon icon="arrow90deg-left"></b-icon>
    </b-nav-item>
    <b-nav-item @click="runCommand('core:redo')" v-b-tooltip.hover title="Redo">
        <b-icon icon="arrow90deg-right"></b-icon>
    </b-nav-item>
    <b-nav-item @click="toggleCommand(commands.togglePreView)" v-b-tooltip.hover title="Preview">
        <b-icon icon="eye"></b-icon>
    </b-nav-item>
    <b-nav-item @click="toggleCommand(commands.toggleFullScreen)" v-b-tooltip.hover title="Full screen">
        <b-icon icon="arrows-fullscreen"></b-icon>
    </b-nav-item>
    <b-nav-item  @click="toggleCommand(commands.toggleGrid)" v-b-tooltip.hover title="Border">
        <b-icon icon="border"></b-icon>
    </b-nav-item>
    <b-nav-item v-if="!showHideImages"
        v-b-tooltip.hover 
        title="Hide images"
        @click="toggleCommand(commands.toggleImages);showHideImages=!showHideImages" >

        <b-iconstack>
            <b-icon stacked icon="image"></b-icon>
            <b-icon stacked icon="slash-lg"></b-icon>
        </b-iconstack>

    </b-nav-item>
    
    <b-nav-item v-else 
        @click="toggleCommand(commands.toggleImages);showHideImages=!showHideImages"
        v-b-tooltip.hover 
        title="Show images">
        <b-icon icon="image"></b-icon>
    </b-nav-item>
    <b-nav-item @click="toggleCommand(commands.clearCanvas)" v-b-tooltip.hover title="Clear canvas">
        <b-icon icon="trash"></b-icon>
    </b-nav-item>
    
  </b-nav>
</template>

<script>
export default {
    data(){
        return {
            showHideImages: false,
            commands: this.$parent.commands
        }
    },
    methods:{
        toggleCommand(command){
            if(command in this.$parent.editor.Commands.getActive()) {
                this.$parent.editor.stopCommand(command)
                return
            }
            this.$parent.editor.runCommand(command)
        },
        runCommand(command){
            if(command in this.$parent.editor.Commands.getAll()) {
                this.$parent.editor.runCommand(command)
            }
        },
    }
}
</script>

<style scoped>
.nav {
    height: 40px;
}
.nav-item {
    width: calc(100% / 7);
    
}

.nav-link {
    color: #606C5D;
    padding:10px !important;
}

.nav-link:hover {
    background: #F1C376;
}
</style>7