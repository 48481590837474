<template>
<div>
  <b-row>
      <b-col lg="12" sm="12" md="12">
        <filter-area @filterData="loadData"/>
      </b-col>
  </b-row>
  
  
  <br>
<!--
  <ChartLine />
-->
  <br>
  
  <tiles v-if="showSmsLayout" :FilteredtData="smsData" :Loading="loading"/>
  <!--
  <hlr-tiles v-if="Object.keys(hlrData).length" :FilteredtData="hlrData" :Loading="loading"/>
  -->
  
  <br>
  <b-row>
      <b-col lg="12" sm="12" md="12">
        <results-table 
        v-if="showSmsLayout"
        @groupBy="loadDataFromTable"  
        :FilteredtData="smsData" 
        :Loading="loading"
        :Fields="fields[fieldsBy]" />
        <hlr-results v-if="!showSmsLayout" :FilteredtData="hlrData" :Loading="loading" />
      </b-col>
  </b-row>
</div>
</template>

<script>
import FilterArea from './Statistics/Filter.vue'
import ResultsTable from './Statistics/ResultsTable.vue'
import HlrResults from './Statistics/HlrResults.vue'
import Tiles from './Statistics/Tiles.vue'
//import HlrTiles from './Statistics/HlrTiles.vue'
import moment from 'moment'
//import ChartLine from './Statistics/ChartLine.vue'
export default {
  components: {
    FilterArea,
    ResultsTable,
    HlrResults,
    Tiles,
    //HlrTiles,
    //ChartLine
  },
  watch:{
    //$route (to, from){
      $route (){
         if(this.$router.currentRoute.path.split(/\//)[2].toUpperCase() == 'HLR'){
            this.showSmsLayout = false
          }else{
            this.showSmsLayout = true
          }
    }
  },
  created () {
   if(this.$router.currentRoute.path.split(/\//)[2].toUpperCase() == 'HLR'){
      this.showSmsLayout = false
    }else{
      this.showSmsLayout = true
    }
    
  },
  data(){
    return {
      fields: {
        'standard': [
          { label: 'Group', field: 'group_by_field',
            filterOptions: {
                enabled: true,
                placeholder: 'Filter Group'
            }
          },
          { label: 'Sent',  field: 'total_sent', type: 'number'},
          { label: 'Parts',  field: 'total_parts', type: 'number'},
          { label: 'SMS Cost',  field: 'total_cost', type: 'number'},
          { label: 'HLR Cost',  field: 'total_hlr_cost', type: 'number'},
          { label: 'Cost',  field: 'total_cost_local', type: 'number'},
          { label: 'Sell',  field: 'total_sell', type: 'number'},
          { label: 'Profit',  field: 'total_profit', type: 'number'},
          { label: 'Pending',  field: 'total_pending', type: 'number'},
          { label: 'Delivered',  field: 'total_delivered', type: 'number'},
          { label: 'Failed',  field: 'total_failed', type: 'number'},
          { label: 'Buffered',  field: 'total_buffered', type: 'number'},
          { label: 'Rejected',  field: 'total_rejected', type: 'number'},
          { label: 'Other',  field: 'total_other', type: 'number'}
        ],
        'operator': [
          { label: 'Group', field: 'group_by_field'},
          { label: 'Mcc',  field: 'optioanl_column_a' , type: 'number'},
          { label: 'Mnc',  field: 'optioanl_column_b' , type: 'number'},
          { label: 'Sent',  field: 'total_sent' , type: 'number'},
          { label: 'Parts',  field: 'total_parts' , type: 'number'},
          { label: 'SMS Cost',  field: 'total_cost' , type: 'number'},
          { label: 'HLR Cost',  field: 'total_hlr_cost' , type: 'number'},
          { label: 'Cost',  field: 'total_cost_local' , type: 'number'},
          { label: 'Sell',  field: 'total_sell' , type: 'number'},
          { label: 'Profit',  field: 'total_profit' , type: 'number'},
          { label: 'Pending',  field: 'total_pending' , type: 'number'},
          { label: 'Delivered',  field: 'total_delivered' , type: 'number'},
          { label: 'Failed',  field: 'total_failed' , type: 'number'},
          { label: 'Buffered',  field: 'total_buffered' , type: 'number'},
          { label: 'Rejected',  field: 'total_rejected' , type: 'number'},
          { label: 'Other',  field: 'total_other' , type: 'number'},
          
        ]
      }
      ,
      startDate: String((new Date()).getDate()).padStart(2, '0') ,
      endDate: new Date(),
      queryData:{
        filterData: {
          period_start: moment(this.startDate).format("YYYY-MM-DD"),
          period_end: moment(this.endDate).format("YYYY-MM-DD"),
          current_dlr_mask: null,
          mccmnc: null,
          country_code: null,
          client_account: null,
          client_accounting_token: null,
          smsc_id: null
        },
        groupBy: 'send_sms_mt_country_name'
      },
      fieldsBy: 'standard',
      smsData:[],
      hlrData:[],
      loading:false,
      showSmsLayout: true,

    }
  },
  methods: {
    loadDataFromTable(data){
      this.queryData.groupBy = data
      this.fieldsBy = 'standard'
      if(data == 'send_sms_mt_operator_name'){
        this.fieldsBy = 'operator'
      }
      
      this.loadData()// call without passing data
    },
    async loadData(data){
      
      if(data){
        this.queryData.filterData = data
      }
      let DataSource = this.$router.currentRoute.path.split(/\//)[2].toUpperCase() == 'HLR' ? 'hlr' : 'sms'
      this.loading = !this.loading
      const res = await this.$http.post('/api/analytics/' + DataSource, this.queryData)
      this.loading = !this.loading

      if(res && res.status == 200){
        
        if(DataSource == 'hlr'){
          this.hlrData = res.data
        }else{
          this.smsData = res.data
        }
      }

      
    }
  },

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}



.dashbaord{
  text-align:left;
}
</style>
