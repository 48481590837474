<template>
    
    <b-tabs @input="topTabChange" content-class="mt-3">
        <b-tab title="System notifications settings">


            <div id="system-noitifications-settings">
                <Modal @addedRow="addedRow" :currentTabName="currentTabName"/>

                <b-tabs @input="systemTabChange" content-class="mt-3" pills card vertical>
                    
                    
                    <b-tab  v-for="notificationType in notificationTypes" :key="'tab-' + notificationType.text" :title="notificationType.text">
                        <b-button-group>
                            <b-button variant="outline-primary" v-b-modal.modal-add-setting-type>
                                Add a {{currentTabName}} notification setting
                            </b-button>
                        </b-button-group>
                        <Table :items="rows" :templates="templates"/>
                    </b-tab>   
                </b-tabs>
            </div>
        </b-tab>
        <b-tab title="Users notifications settings" @click="loadUsersData()">
            <Users :notificationUsersData="users"/>
        </b-tab>
    </b-tabs>

</template>

<script>
import Modal from './Modal.vue'
import Table from './Table.vue'
import Users from './Users.vue'
export default {
    components: {
        Table,
        Modal,
        Users
    },
    methods: {
        addedRow(rowData){
            this.rows.push(rowData)
        },
        async loadUsersData(){
            const res = await this.$http.get(`/api/notifications/users/read`)
            if(res && res.status == 200){
                this.users = res.data.map(user => {user.testing = false; return user})
            }
        },
        //async topTabChange(tabIndex){
        async topTabChange(){
        },
        async systemTabChange(tabIndex){
           
            this.currentTabName = this.$notificationTypes[tabIndex].text

            const [response1, response2] = await Promise.all([
                this.$http.get(`/api/notifications/settings/read?category=${this.$notificationTypes[tabIndex].text.toUpperCase()}`),
                this.$http.get(`/api/notifications/templates/read?category=${this.$notificationTypes[tabIndex].value.toUpperCase()}`),
            ]);
            
            if(response1 && response1.status == 200){
                this.rows = response1.data
            }

            if(response2 && response2.status == 200){
                this.templates = response2.data
            }
        }
    },
    data(){
        return {
            notificationTypes: this.$notificationTypes,
            currentTabName: this.$notificationTypes[0].text,
            rows: [],
            templates: [],
            users: []
        }
    }
    
}
</script>

<style>

</style>