const pagination = require('./paginations.json')
const cols = require('./cols.json')

exports.settings = {
    groupOptions: {
        enabled: true,
        collapsable: true
    },
    searchOptions: {
        enabled: false,
        placeholder: 'Search this table',
    },
    selectOptions: { enabled: false },
    paginationOptions: pagination.paginationOptions,
    clientFields: cols,            
}