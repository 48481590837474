<template>
  <b-container class="container-fluid p-0">
    <b-row>
      <b-col sm="12" lg="4" class="mt-1">
        <StatusCard  
          header="System"
          :data="data" 
          :bbox="bbox" 
          :styles="{'height' : '155px'}"
        />
      </b-col>
      <b-col sm="12" lg="4" class="mt-1">
        <DlrCard  
          header="Dlr Settings"
          :data="data" 
          :bbox="bbox" 
          :styles="{'height' : '155px'}"
        />
      </b-col>
      <b-col sm="12" lg="4" class="mt-1">
        <SMScCard  
          header="SMSc Count"
          :data="data" 
          :bbox="bbox" 
          :styles="{'height' : '155px'}"
        />
      </b-col>
    </b-row>
  </b-container>
  
</template>

<script>
import StatusCard from './Tiles/StatusCard.vue'
import DlrCard from './Tiles/DlrCard.vue'
import SMScCard from './Tiles/SMScCard.vue'

export default {
  components: {
    StatusCard,
    DlrCard,
    SMScCard
  },
  props: {
    data: {
      type: Object,
    },
    bbox: {
      type: Object
    },
  },
  data() {
    return {}
  }
}
</script>

<style>

</style>