import SystemSettings from'./../components/Settings/SystemSettings.vue'
import UsersSettings  from './../components/Settings/Users/Layout.vue'
import NotificationsSettings from './../components/Settings/Notifications/Layout.vue' 
import MonitorSettings  from './../components/Settings/Monitor/Layout.vue'

export default {
    path: 'settings', 
    name: 'settings', 
    redirect: 'settings/users',
    component: SystemSettings,
    children:[
      {
        path: 'users',
        component: UsersSettings,
        name:'Users'
      },
      {
        path: 'notifications',
        component: NotificationsSettings,
        name:'Notifications'
      },
      {
        path: 'monitor',
        component: MonitorSettings,
        name:'Monitor'
      },
      
    ]
  }