0<template>
    <b-row>
        <Modal :submit="submit"/>
        <b-col cols="12">
            <b-card>
                <b-card-header>
                    Media Blocking Rules
                </b-card-header>
                <b-card-body style="min-height:500px;" class="tbl-wrpr">
                    <vue-good-table 
                        theme="polar-bear"
                        :columns="fields" 
                        :rows="filteredData" 
                        :line-numbers="true"
                        :pagination-options="paginationOptions"
                        :search-options="searchOptions"
                        :select-options="selectOptions">
                        <div slot="table-actions">
                            <b-button-group>
                                <b-button variant="primary" @click="$bvModal.show('modal-add-block')">
                                    <b-icon icon="plus"></b-icon> Add Block Rule
                                </b-button>
                            </b-button-group>
                        </div>

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'block_is_regex'">
                                <b-icon v-if="props.row.block_is_regex" icon="check-circle" variant="primary"></b-icon> 
                                <b-icon v-else icon="x-circle" variant="primary"></b-icon> 
                            </span>
                            <span v-else-if="props.column.field == 'options'">
                                <b-button v-b-tooltip.hover title="Delete Rule" variant="outline-danger" size="sm" 
                                    @click="deleteRule(props.row) ">
                                    <b-icon icon="trash" variant="danger"></b-icon>
                                </b-button>
                            </span>
                        </template>
                    </vue-good-table>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import Modal from './RuleModal.vue'
export default {
     components: {
        Modal
    },
    data() {
        return {
            filteredData: [],
            selectOptions: { enabled: false },
            searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
            },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [5,10,100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
            fields: [
                { label: 'Id', field: 'id', width:'100px' },
                { label: 'Block Type', field: 'block_type', width:'120px' },
                { label: 'Country', field: 'block_country_code', width:'100px' },
                { label: 'IP', field: 'block_ip', width:'100px'},
                { label: 'User Agent', field: 'block_user_agent', width:'300px'},
                { label: 'Msisdn', field: 'block_msisdn', width:'100px'},
                { label: 'Is Regex', field: 'block_is_regex', width:'120px'},
                { label: 'Regex', field: 'block_regex', width:'200px'},
                { label: 'Created', field: 'createdAt', width:'100px'},
                { label: 'Options', field: 'options', width:'100px'}
            ],
        }
    },
    created(){
       this.loadData()
    },
    methods: {
        async deleteRule (row) {
            const res = await this.$http.delete(`/api/campaigns/block/${row.id}`)
            if(res && res.status == 200){
                this.filteredData = [...this.filteredData].filter(item => item.id != row.id)
            }
            
        },
        async submit(data){
            
            
            const res = await this.$http.post(`/api/campaigns/block/create`,data)
            if(res && res.status == 200){       
                this.filteredData.push(res.data)
                this.$bvModal.hide('modal-add-block') 
                
            }
            
        },
        async toggle(row){
            console.log(row)
            /*
            const res = await this.$http.get(`/api/campaigns/urls/geoToggle/${row.geo_short_url_id}/${row.geo_short_url_country_code}`)
            if(res && res.status == 200){
                row.geo_short_url_status = res.data.geo_short_url_status
            }
            */
        },
        async loadData(){
            const res = await this.$http.get(`/api/campaigns/block/read`)
            if(res && res.status == 200){
                console.log(res.data)
                this.filteredData = res.data
            }
            //const id = 1
            //const res = await this.$http.get(`/api/campaigns/urls/geo/${id}`)
            //if(res && res.status == 200){
            //    this.filteredData = res.data
            //}
        }
    }
}
</script>

<style>

</style>