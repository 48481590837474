export default {
  enabled: true,
  mode: 'records',
  position: 'bottom',
  setCurrentPage: 1,

  jumpFirstOrLast : true,
  dropdownAllowAll: false,

  perPage: 10,
  perPageDropdown: [5,10,100],

  ofLabel: 'of',
  allLabel: 'All',
  nextLabel: 'next',
  prevLabel: 'prev',
  rowsPerPageLabel: 'Rows per page',

  firstLabel : 'First Page',
  lastLabel : 'Last Page',
  pageLabel: 'page', // for 'pages' mode

  infoFn: (params) => `Page ${params.firstRecordOnPage}`
}
