<template>
    <b-container>
        <b-form ref="filterForm"  @submit.prevent="onSubmit" @reset="onReset">
            <!--
            <b-row>
                <b-col cols="6">
                    <b-form-group label="Clients:">
                        <multiselect 
                            :multiple="true"
                            v-model="filter.uuids" 
                            :options="accounts" 
                            placeholder="Select UUIDs" 
                            :custom-label="customAccountsLabel"
                            label="client_account_name" 
                            track-by="client_accounts_cliend_uuid">
                        </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>
            -->
            <b-row class="mt-2">
                <b-col>
                    <b-button-group>
                        <b-button type="submit" variant="outline-primary">Submit</b-button>
                        <b-button type="reset" variant="outline-danger">Reset</b-button>
                    </b-button-group>
                    
                </b-col>
            </b-row>
        </b-form>
    </b-container>

</template>

<script>
//import Multiselect from 'vue-multiselect'
//import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
    components:{
        //Multiselect
    },
    created(){
        this.fetchAccounts()
    },
    data(){
        return {
            accounts: [],
            filter: {
                uuids: [],
            },
            resultSmscAccounts: null,
            resultOperators: null,
        }
    },
    methods:{
        onSubmit(){
            this.$emit('filter', this.filter)
        },
        onReset(){},
        async fetchAccounts(){
            const res = await this.$http.get('/api/gateway/accounts/read')
            if(res && res.status == 200){
                this.accounts = res.data
            }
        },
        customAccountsLabel(account){
            return `${account.client_account_name} ${account.client_accounts_cliend_uuid}`
        }
    }
}
</script>

<style>

</style>