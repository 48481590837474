<template>
<div>
    
  <vue-good-table
        :columns="table.fields" 
        :pagination-options="table.paginationOptions"
        :search-options="table.searchOptions"
        :rows="rows">
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'payment_generated_by'">
                <span v-if="props.row.payment_generated_by == 'BFFA'">Admin</span>
                <span v-else>Client</span>
            </span>
            <span v-else-if="props.column.field == 'options'">
                <b-button-group>
                    <b-button 
                        v-if="/SAVED|PAID/i.test(props.row.payment_status)"
                        v-b-tooltip.hover title="Add to client balance"
                        variant="outline-success" 
                        @click="add(props.row)" >
                        <b-icon icon="plus"></b-icon>
                    </b-button> 
                     
                </b-button-group> 
            </span>
            
        </template>
    </vue-good-table>
</div>    
</template>

<script>
const table = require('./Table/tbl')
export default {
    components: {},
    props:{
        rows: {
            Type: Array,
            default: () => []
        }
    },
    methods:{
        async confirm(action){
            return await this.$bvModal.msgBoxConfirm(`Are you sure you whish to ${action}?`)
        },
        async invoice(rowData){
            console.log(rowData)
            if(!await this.confirm('create invoice from proforma')) return
            const res = await this.$http.post('/api/payment/billing/billing/draft-from-proforma', rowData)
            console.log(res.status)
            //TODO: Finish this
        },
        async add(rowData){
            if(!await this.confirm('add to client balance')) return
            const res = await this.$http.post( '/api/payment/history/update/balance', {
                details: rowData,
            } )
            if(res && res.status == 200){
                if(res.data.status){
                    rowData.payment_status = res.data.payment_status
                    rowData.payment_history_standing_balance = res.data.payment_history_standing_balance
                }

            }
        }
    },
    data(){
        return {
            table: table.settings
        }
    }
}
</script>

<style>

</style>