import View  from './../components/Routing/Layout.vue'
import Clients from './../components/Clients.vue'
import InvoicesLayout from '../components/Clients/Payments/Invoices/Layout'
import HistoryLayout from '../components/Clients/Payments/History/Layout'
//import HistoryLayout from './../components/Clients/Finance.vue'
import BillingLayout from '../components/Clients/Billing/Layout'

//Dead
//import Finance  from './../components/Clients/Finance.vue'
//import FinanceCreateInvoice  from './../components/Clients/CreateInvoice.vue'
//import FinanceAddPayment  from './../components/Clients/AddPayment.vue'
//import FinanceAddBalance  from './../components/Clients/AddClientBalance.vue'


export default {
    path: 'finance',
    name: 'finance',
    component: View,
    redirect: 'finance/accounts',
    children:[
      {
        path: 'accounts',
        name: 'accounts',
        component: Clients
      },
      {
        path: 'billing',
        name: 'billing',
        component: BillingLayout
      },
      {
        path: 'invoices',
        name: 'invoices',
        component: InvoicesLayout
      },
      {
        path: 'history',
        name: 'history',
        component: HistoryLayout
      },
      /*
      {
        path: 'add-balance',
        name: 'add balance',
        component: FinanceAddBalance
      },
      {
        path: 'create-payment',
        name: 'create payment',
        component: FinanceAddPayment
      },
      
      {
        path: 'create-invoice',
        name: 'create invoice',
        component: FinanceCreateInvoice

      },
      */
    ]
  }