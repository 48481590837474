<template>
    
    <b-row>   
        <b-col lg="12" sm="12" md="12">
            <b-overlay :show="showOverlay" rounded="sm">
                <div class="routes-tbl-wrpr">
                    <vue-good-table 
                        theme="polar-bear"
                        :columns="fields" 
                        :rows="filteredData" 
                        :line-numbers="true"
                        :pagination-options="paginationOptions"
                        :search-options="searchOptions"
                        :select-options="selectOptions">
                            <template slot="table-row" slot-scope="props">
                                
                                <span v-if="props.column.field == 'name'">
                                    <div class=" ">{{ props.row.provider_name }}</div>
                                </span>
                                <span v-else-if="props.column.field == 'provider_id'" class="connection-input-group">
                                    {{props.row.provider_id}}
                                </span>
                                <span v-else-if="props.column.field == 'provider_status'">
                                    <div @click="switchOnOf(props.row)" class="text-left" style="cursor:pointer">
                                        <b-icon v-if="props.row.provider_status" icon="toggle-on" font-scale="1.5" ></b-icon>
                                        <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
                                    </div>
                                </span>
                                <span v-else-if="props.column.field == 'providers_accounts'">
                                    <span v-if="typeof(props.row.providers_accounts) == 'string'">
                                        <table v-for="item in props.row.providers_accounts.split(',')" :key="item" style="border-collapse: collapse; border: none;">
                                            <tbody>
                                                <td style="border:none;padding:10px 1px"><b>{{item.split('-')[0]}}</b> ({{ item.split('-')[2] }})</td>
                                                <td style="border:none;padding:1px">
                                                    <b-button-group>
                                                        <b-button variant="white" @click="account=item;provider=props.row;$bvModal.show('account-modal')">
                                                            <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                                        </b-button>
                                                        <b-button variant="white" @click="toggleAccount(item, props.row)" >
                                                            <b-icon v-if="!!Number(item.split('-')[1])" icon="toggle-on" font-scale="1.5" ></b-icon>
                                                            <b-icon v-else variant="danger"  icon="toggle-off" font-scale="1.5" ></b-icon>
                                                        </b-button>
                                                    </b-button-group>
                                                </td>
                                                
                                            </tbody>
                                        </table>

                                    </span>
                                    <span v-else>
                                        -
                                    </span>
                                </span>
                                

                                <span v-else-if="props.column.field == 'options'">
                                    <b-button-group>

                                        <b-button 
                                            v-b-tooltip.hover 
                                            title="Add account"
                                            variant="outline-primary" @click="account=null;provider=props.row;$bvModal.show('account-modal')">
                                            <b-icon icon="briefcase"></b-icon>
                                        </b-button>

                                        <b-button 
                                            v-b-tooltip.hover 
                                            title="Edit SMSc"
                                            variant="outline-primary" @click="editProvider(props)">
                                            <b-icon icon="pencil-square"></b-icon>
                                        </b-button>
                                        <b-button 
                                            v-b-tooltip.hover 
                                            size="sm"
                                            :disabled="props.row.provider_connections != null || props.row.providers_accounts != null"
                                            title="Delete"
                                            @click="remove(props.row)" variant="outline-danger">
                                            <b-icon icon="trash"></b-icon>
                                        </b-button>

                                        <b-button
                                            @click="showHttpMoCfgModal(props.row)"
                                            v-b-tooltip.hover 
                                            title="Edit http MO parameters"
                                            variant="outline-primary"
                                            size="sm">
                                            <b-icon icon="wrench"></b-icon>
                                        </b-button>
                                        <b-button 
                                            v-b-tooltip.hover 
                                            title="View http MO access token"
                                            @click="getKey(props.row)" variant="outline-primary" size="sm">
                                            <b-iconstack>
                                                <b-icon stacked icon="eye" shift-v="4" shift-h="5" scale="0.75"></b-icon>
                                                <b-icon stacked icon="key" shift-v="-3"></b-icon>
                                            </b-iconstack>
                                        </b-button>

                                    </b-button-group>   
                                </span>
                                
                            </template>

                            <div slot="selected-row-actions">
                                <b-button  variant="primary">Toggle all</b-button>
                            </div>
                            
                            <div slot="table-actions">
                                <b-button-group>
                                        <!--
                                        <router-link class="btn btn-primary btn-sm" to="/accounts/edit/smsc/" tag="button">
                                            <b-icon icon="folder-plus"></b-icon>
                                            New Provider
                                        </router-link>
                                        -->
                                        <b-button variant="outline-primary" @click="provider=null;$bvModal.show('new-provider-modal')">
                                            <b-icon icon="plus"></b-icon>
                                            New Provider
                                        </b-button>
                                        <b-button variant="outline-primary" @click="fetchData">
                                            <b-icon icon="recycle"></b-icon>
                                            Refresh lists
                                        </b-button>
                                        
                                </b-button-group>

                            
                            </div>
            
                    </vue-good-table>
                </div>
            </b-overlay>

        </b-col>
        <HttpMoParameters :show="showHttpMoModal" :rowData="rowDataForHttpMoCfg"/>
        <TestRouteConnectionModal :connData="testConnData"/>
        <CreateNewProviderVue @added="added" :provider="provider"/>
        <CreateProviderAccount @done="done" @added="addedAccount" :account="account" :provider="provider"/>
    </b-row>
    
</template>

<script>
import CreateNewProviderVue from './Routes/CreateNewProvider.vue'
import CreateProviderAccount from './Routes/Accounts/editProviderAccountModal.vue'
import HttpMoParameters from './Routes/HttpMoParameters.vue'
import TestRouteConnectionModal from './Routes/TestRouteConnectionModal.vue'

export default {
    components: {
        CreateNewProviderVue,
        HttpMoParameters,
        TestRouteConnectionModal,
        CreateProviderAccount
    },
    
    created () {
        this.fetchData()
    },
    mounted(){
      
    },
    methods: {
        editAccount(item, rowData){
            this.account = item
            this.provider = rowData
            this.$bvModal.show('account-modal')
        },
        editProvider(props){
            this.provider = props.row
            this.$bvModal.show('new-provider-modal')
        },
        addedAccount(account){
            if(!account) return
            var accountr = `${account.providers_accounts_account_alias}-`
            accountr += `${account.providers_accounts_account_status}-`
            accountr += `${account.providers_accounts_provider_id}-`
            accountr += `${account.providers_accounts_user_name}-`
            accountr += `${account.providers_accounts_password}-`
            this.filteredData[this.provider.originalIndex].providers_accounts += `${accountr}`

        },
        done(){

            this.$bvModal.hide('account-modal')
            this.fetchData()
        },
        added(provider){
            
            if(provider) this.filteredData.push(provider)
            this.$bvModal.hide('new-provider-modal')
            if(this.provider) this.provider = null
        },
        showTestProviderConnectionModal(connData){
            this.testConnData = connData
            this.$bvModal.show('bv-modal-test-smsc-connection')
        },
        showHttpMoCfgModal(rowData) {
            this.rowDataForHttpMoCfg = rowData
            this.showHttpMoModal = !this.showHttpMoModal
        },
        async getKey(data){
            
            let res = await this.$http.post('/api/nodes/create-http-smsc-access',data)
            if(res && res.status == 200 ){
                return this.$bvModal.msgBoxOk(res.data, 'HTTP SMSc MO access token', 'lg')
            }

        },
        async localRequests(type, uri, data){
            this.showOverlay = !this.showOverlay
            let result = this.$http[type]( uri, data )
            this.showOverlay = !this.showOverlay
            return result
        },
        async toggleAccount(account, rowData){
            // Waisted valiable time on this god damn it
            if(!rowData.providers_accounts) return
            const parts = account.split('-')
            const toggled =  Number(parts[1]) ? false : true
            let res = await this.$http.post('/api/routing/smsc/accounts/update/status', {
                providers_accounts_account_status: toggled,
                providers_accounts_id: parts[2]
            })

            if(res && res.status == 200){
                if(res.data.status){

                    const accountId = account.split('-')[2]
                    const accounts = rowData.providers_accounts.split(',')
                    const index = accounts.findIndex(item => item.split('-')[2] == accountId )
                    parts[1] = toggled ? 1 : 0
                    accounts[index] = parts.join('-')
                    rowData.providers_accounts = accounts.join(',')
                    
                }
            }

        },
        switchOnOf: async function (rowData) {
            let results = await this.localRequests(
                'post',
                '/api/providers/toggle-status',
                {
                    kannel_smsc_provider: this.providers[rowData.originalIndex].kannel_smsc_provider,
                    provider_status: !this.providers[rowData.originalIndex].provider_status
                }
            )
            if(results.status == 200){
                this.providers[rowData.originalIndex].provider_status = !this.providers[rowData.originalIndex].provider_status;
            }
            
            
        },
        remove: async function ( rowData ) {
            let results = await this.localRequests( 'post', '/api/providers/delete', {
                    provider_id: this.providers[rowData.originalIndex].provider_id
                }
            )
            if(results.status == 200){
                this.providers.splice(rowData.originalIndex , 1);
            }            
        },
        fetchData: async function () {
            this.showOverlay = !this.showOverlay
            let results = await this.localRequests( 'get', '/api/routing/smsc/read' )
            this.showOverlay = !this.showOverlay
            if(results.status == 200){
                this.providers = results.data;
                this.filteredData = this.providers
            }  
        }
    },
    data() {
        return {
            accountRowData: null,
            provider: null,
            account: null,
            testConnData: null,
            showHttpMoModal: false,
            rowDataForHttpMoCfg: {},
            filteredData:[],
            showOverlay: false,
            searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
            },
            selectOptions: { enabled: false },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [5,10,100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
            fields: [
                //'index',
                { label: 'Provider Name', field: 'kannel_smsc_provider', width:'200px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Name'
                    }
                },
                { label: 'Our Id', field: 'provider_id', width:'200px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter ID'
                    }
                },
                { label: 'Status', field: 'provider_status', width:'150px',},
                { label: 'Accounts', field: 'providers_accounts', width:'500px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Accounts'
                    }
                },
                /*
                { label: 'Configured connections', field: 'provider_connections', width:'900px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Connections',
                        filterFn: this.filterConnections
                    }
                },
                */
                /*
                { label: 'HTTP Connections', field: 'provider_http_connections',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Connections'
                    }
                },
                */
                { label: 'Options', field: 'options'},
            ],
            providers: []
        };
    }
}
</script>
<style>

</style>