<template>
  <b-modal
    ref="modal-smpp"
    title="Edit access key"
    size="lg"
    ok-only
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk">
    
        <b-container>
            <b-form ref="myForm" @submit.prevent="submitForm">
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            label="Key Status:"
                            description="The status of the key">
                                <b-form-select v-model="formData.key_status">
                                    <b-form-select-option value="true">
                                        Active
                                    </b-form-select-option>
                                    <b-form-select-option value="false">
                                        Not Active
                                    </b-form-select-option>
                                </b-form-select>
                                <b-form-invalid-feedback id="name">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            label="Key Status:"
                            description="The status of the key">
                                <b-form-select v-model="formData.key_mode">
                                    <b-form-select-option value="Live">
                                        Live
                                    </b-form-select-option>
                                    <b-form-select-option value="Test">
                                        Test
                                    </b-form-select-option>
                                </b-form-select>
                                <b-form-invalid-feedback id="name">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            label="Key Admin Blocked:"
                            description="Admin Blocked status of the key">
                                <b-form-select v-model="formData.key_admin_block">
                                    <b-form-select-option value="true">
                                        Admin Blocked
                                    </b-form-select-option>
                                    <b-form-select-option value="false">
                                        Not Admin Blocked
                                    </b-form-select-option>
                                </b-form-select>
                                <b-form-invalid-feedback id="name">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Throughput:" label-for="throughput">
                            <b-form-input type="number" id="throughput" v-model="formData.key_tp" :step="1"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Description:" label-for="description">
                            <b-form-textarea id="description" v-model="formData.key_desc" rows="5"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-button ref="submitButton" style="display:none" type="submit">Submit</b-button>

            </b-form>
        </b-container>
    </b-modal>
</template>

<script>
export default {
    props:['rowData'],
    watch:{
        'rowData': {
            handler: function(nv){
                this.formData = {...nv}
            },
            deep: true
        }
    },
    data() {
        return {
            formData: {
                key_status: null,
                key_mode: null,
                key_desc: null,
                key_admin_block: null,
                key_tp: null,
            }
        }
    },
    methods: {
        toggle(){
            this.$refs['modal-smpp'].show()
        },
        submitForm(e){
            e.preventDefault();
            this.$emit('update', this.formData)
        },
        resetModal(){},
        handleOk(){
            this.$refs.submitButton.click()
        },
    }
}
</script>

<style>

</style>