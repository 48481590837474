<template>
  <div>
    <b-row>
        <b-col cols="6">
            <b-form-group
            style="text-align:left"
            label="Invoice number:"
            description="The number of the Invoice">
                <b-form-input
                v-model="form.number"
                type="text"
                placeholder="Invoice number"
                disabled>
                </b-form-input>
            </b-form-group>
        </b-col>
    </b-row> 
    <b-row>
        <b-col>
            <b-form-group
                label="Invoice from:"
                description="Company to issue the Invoice from"
            >
            <b-form-select
            :disabled="disabled"
            style="width:100% !important"
            v-model="form.from" 
            :state="form.from!='' && form.from!=null"
            :options="origins"
            required>
            </b-form-select>
            <b-form-invalid-feedback id="from">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
            style="text-align:left"
            label="Invoice currency:"
            description="Invoice main currency">
                <b-form-select
                style="width:100% !important"
                :disabled="!!form.number"
                v-model="form.currency" 
                :state="form.currency!='' && form.currency!=null"
                :options="currencies"
                required>
                </b-form-select>
                <b-form-invalid-feedback id="currency">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
    </b-row> 
    <b-row>
        <b-col>
            <b-form-group
            style="text-align:left"
            label="Invoice date:"
            description="The date of the Invoice">
                <b-form-datepicker 
                :disabled="!!form.date"
                required
                style="width:100% !important" 
                :state="form.date!='' && form.date!=null"
                v-model="form.date">
                </b-form-datepicker>
                <b-form-invalid-feedback id="date">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
                label="Invoice due date:"
                description="The due date of the Invoice">
                <b-form-datepicker 
                    :disabled="!!form.due"
                    required
                    style="width:100% !important"
                    :state="form.due!='' && form.due!=null"
                    v-model="form.due">
                </b-form-datepicker>
            <b-form-invalid-feedback id="due">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
    </b-row> 
    
  </div>
</template>


<script>
export default {
    props: ['value', 'disabled'],
    data(){
        return {
            origins: [
                { 
                    text: 'Israel', 
                    value: {
                        company: 'Message-deck',
                        address: 'Beit lehem 6',
                        zip: '7532347',
                        city: 'Rishon le zion',
                        country: 'Israel',
                        vat: '307500421', //VAT
                        reg: '307500421', //REG
                        bank: {
                            name: 'BANK HAPOALIM B.M.',
                            address: 'ROTHSCHILD BOULEVARD, 50, TEL AVIV ISRAEL',
                            iban: 'IL810126340000000013647',
                            swift: 'POALILITXXX',
                            account: '013647'
                        }
                    }
                },
                { 
                    text: 'Cyprus', 
                    value: {
                        company: 'Veloxbit LTD',
                        address: 'Agias zonis 30a, christos fantaros court 1',
                        zip: '3027',
                        city: 'Limassol',
                        country: 'Cyprus',
                        vat: '10358174B', //VAT
                        reg: 'HE358174', //REG//taxid wahtever
                        bank: {
                            name: 'Bank of Cyprus',
                            address: 'I.B.U. Limassol 2 (0387)',
                            iban: 'CY96002001950000357023540113',
                            swift: 'BCYPCY2N',
                            account: '357023540113'
                        }
                    }
                },

            ],
            currencies:[
                { text: 'Select currency' , value: null },
                { text: 'Euro (EUR)' , value: 'EUR' }
            ],
        }
    },
    computed: {
        form: {
            get(){
                return this.value
            },
            set(newValue){
                this.$emit('input', newValue);
            }
        }
    }
}
</script>

<style>

</style>