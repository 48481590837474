<template>
  <b-card          
    no-body
    :style="styles"
    border-variant="info" 
    header-bg-variant="light"
    header-text-variant="primary">
        <template #header>
            <b-icon style="display:inline-block" :icon="icon"></b-icon> &nbsp;
            <h6 style="display:inline-block" class="mb-0" >{{header}}</h6>
        </template>
        <b-card-body>
             
            <b-row>
                <b-col>
                    
                    <b-card-text class="p-3">

                        <p class="mb-1" v-if="data">Storage type: {{ data.kannelSystemInfo.storageType}}</p>
                        <p class="mb-1" v-else>{{noData}}</p>


                        <small class="text-muted" v-if="data">Queded delivery reports {{data.kannelStatistics.DLR.queued}}</small>
                        <small class="text-muted" v-else>{{noData}}</small>
                    </b-card-text>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
export default {
    data(){
        return {
            noData: 'No data to show'
        }
    },
    props: {
        icon: {
            type: String,
            default: () => { return 'question-circle' }
        },
        data:{
            type: Object
        },
        bbox:{
            type: Object
        },
        header: {
            type: String
        },
        styles: {
            type: Object
        }
    }
}
</script>

<style>

</style>