<template>
   <b-modal 
    size="xl" 
    id="http-mo-params-modal"
    ref="http-mo-params-modal"
    :hide-footer="false"
    title="Edit HTTP MO parameters"
    @ok="handleOk"
    @hidden="onReset">
        <b-form ref="mo-params-form">

            <b-row>
                <b-col>
                    <b-row>
                        
                        <b-col>
                            <b-form-group label="Provider ID:" style="text-align:left">
                                <b-form-input
                                v-model="form.ourProivderId"
                                disabled>
                                </b-form-input>
                            </b-form-group>
                            
                            <!--
                            <b-form-group label="Arguments type:" style="text-align:left">
                                <b-form-select
                                style="width:100% !important"
                                v-model="form.argumentsType"
                                :options="argumentsTypes"
                                required
                                ></b-form-select>
                            </b-form-group>
                            -->
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Request type:" style="text-align:left">
                                <b-form-select
                                style="width:100% !important"
                                v-model="form.requestType"
                                :options="requestTypes"
                                required
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-form-group
                             style="text-align:left"
                            :invalid-feedback="'Please enter at least two letters'"
                            label="Stop keyword:"
                            description="The stop keyword(s)-seperated by coma">
                            <b-form-input
                            v-model="form.stopKeyword"
                            placeholder="The stop keyword(s)-seperated by coma"
                            :state="/.{2,}/.test(this.form.stopKeyword)"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox
                            style="text-align:left"
                            id="checkbox-1"
                            v-model="form.sendStopReply"
                            name="checkbox-1"
                            value="accepted"
                            unchecked-value="not_accepted">
                                &nbsp;&nbsp;Send STOP reply message
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row v-if="form.sendStopReply">
                        <b-form-group
                            style="text-align:left"
                            :invalid-feedback="'Please enter at least two letters'"
                            label="Stop reply message:"
                            description="The stop reply message">
                            <b-form-input
                            v-model="form.stopReply"
                            placeholder="The stop reply message"
                            :state="/.{2,}/.test(this.form.stopKeyword)"
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    
                </b-col>
                <b-col>
                    <b-row>
                        <b-form-group
                             style="text-align:left"
                            :invalid-feedback="'Please enter at least two letters'"
                            label="From paramater name:"
                            description="The name of the from parameter">
                            <b-form-input
                            v-model="form.fromName"
                            placeholder="From param name"
                            :state="/^.{2,}/.test(this.form.fromName)"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                             style="text-align:left"
                            :invalid-feedback="'Please enter at least two letters'"
                            label="To paramater name:"
                            description="The name of the to parameter">
                            <b-form-input
                            v-model="form.toName"
                            placeholder="To param name"
                            :state="/^.{2,}/.test(this.form.toName)"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                             style="text-align:left"
                            :invalid-feedback="'Please enter at least two letters'"
                            label="Text paramater name:"
                            description="The name of the text parameter">
                            <b-form-input
                            v-model="form.textName"
                            placeholder="Text param name"
                            :state="/^.{2,}/.test(this.form.textName)"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                </b-col>
            </b-row>
        </b-form>
        
    </b-modal>
</template>

<script>
export default {
    props:['show', 'rowData'],
    watch:{
        'show': {
            handler: function (){
                
                if (this.show)
                    this.$refs['http-mo-params-modal'].show()
            
                this.$refs['http-mo-params-modal'].hide()
            },
            deep: true
        },
        'rowData': {
            handler: function (){
                this.form.ourProivderId = this.rowData.provider_id
            },
            deep: true
        }
    },
    data(){
       
        return {
            form: {
                requestType: 'GET', 
                fromName: 'from',
                toName: 'to',
                textName: 'text',
                stopKeyword: 'STOP',
                argumentsType: 'QUERY',
                ourProivderId: '',
                sendStopReply: false,
                stopReply: ''
            },
            requestTypes: [
                {
                    text: 'Please select a request type',
                    value: ''
                },
                {
                    text: 'GET',
                    value: 'GET'
                },
                {
                    text: 'POST',
                    value: 'POST'
                },
                {
                    text: 'PUT',
                    value: 'PUT'
                }
            ],
            argumentsTypes: [
                {
                    text: 'Please select a arguments type',
                    value: ''
                },
                {
                    text: 'JSON',
                    value: 'JSON'
                },
                {
                    text: 'QUERY',
                    value: 'QUERY'
                },
                {
                    text: 'BODY',
                    value: 'BODY'
                }
            ],
        }
    },
    methods: {
        async handleOk(event){
            event.preventDefault()
            let valid = this.$refs['mo-params-form'].checkValidity()
            //TODO: add connection aliases!
            if(valid){
                let res = await this.$http.post('/api/connections/http-mo-cfg', this.form)
                this.$refs['http-mo-params-modal'].hide()
                if(res && res.status == 200){
                    this.$parent.msgBoxOk( ( res.data[1] ? "New Record was created" : "Record was updated" ) +  JSON.stringify(res.data[0], null, 2), 'Notice', 'xl'  )
                }
            }
                

        },
        onReset(){
            this.$parent.showHttpMoModal = !this.$parent.showHttpMoModal
            this.form.requestType = 'GET',
            this.form.fromName = 'from'
            this.form.toName = 'to'
            this.form.textName = 'text'
            this.form.stopKeyword = 'STOP'
            this.form.argumentsType = 'QUERY'
            this.sendStopReply =  false
            this.stopReply = ''
        },
    }
}
</script>

<style>

</style>