<template>

    <b-row >
        <b-col cols="12">
        <b-card>
     
            <b-card-header>
                <b-row>
                    <b-col>
                        API Keys and Routing
                    </b-col>
                </b-row>
            </b-card-header>

                <b-card-body style="min-height:500px; "> 
                    <b-tabs  
                    pills card vertical
                    content-class="">
                        <b-tab @click="tabChanged('keyTokens')" title="Keys and Tokens">
                            <ApiTokensTypes :clientData="clientData"/>
                        </b-tab>
                        <b-tab @click="tabChanged('routing')" title="Routing">
                            <b-overlay :show="show" rounded="sm">
                                <RoutingManagmentLayout 
                                    :smscAccounts="smscAccounts"
                                    :apiKeyTokens="apiKeyTokens"
                                    :accountUuids="accountUuids"
                                />
                            </b-overlay>
                        </b-tab>
                        <b-tab @click="tabChanged('defs')" title="Default Routing">
                            <b-overlay :show="show" rounded="sm">
                                <ManageDefaultRouting />
                            </b-overlay>
                        </b-tab>

                    </b-tabs>
                </b-card-body> 
       
            
        </b-card>
        
        
        </b-col>
    </b-row>
    

</template>

<script>
import ManageDefaultRouting  from './ManageDefaultRouting.vue'
//import TokensList from '../Clients/ApiTokens/TokensList.vue'
import RoutingManagmentLayout from './RoutingManagment/Layout.vue'
//import MoRoutingLayout from './MoRouting/Layout.vue'
//import HlrGeoSettings from './Hlr/Layout.vue'
//import ApiTokensLayout from '../Clients/ApiTokens/TokensList.vue'
import ApiTokensTypes from './ApiTokensTypes.vue'
export default {
    components: {
        ManageDefaultRouting,
        //HlrGeoSettings,
        RoutingManagmentLayout,
        //MoRoutingLayout,
        //ApiTokensLayout
        ApiTokensTypes
    },
    data() {
        return {
            currTab: 0,
            notificationUsersDataLocal: null,
            usersDataLocal: null,
            clientUuid: this.$route.params.id,
            show: false,
            entities: {},
            clientData: [],
            smscAccounts: [],
            apiKeyTokens: [],
            accountUuids: []
        }
    },
    props: ['id'],
    created () {
        this.loadData()
    },
    computed:{
        
    },
    methods: {
        normalizeSmscAccounts(data){
             this.smscAccounts = data.map(item => {
                item['db_id'] = item.providers_accounts_id
                item['label'] = this.getProvidersLabel(item)
                item['value'] = `${item.providers_accounts_provider_id}${item.providers_accounts_id}`
                return item;
            })
        },
        normalizeKeys(data){
            this.apiKeyTokens = data.map(item => { 
                return { 
                    text: item.http_api_jwt_short_token, 
                    value: item.http_api_jwt_short_token,
                    uuid: item.http_api_jwt_uuid
                } 
            })
        },
        normalizeAccounts(data){
            this.accountUuids = Object.keys(data.reduce((acc,item)=>{
                acc[item.http_api_jwt_uuid] = 1;
                return acc;
            },{})).map(item => { return { text: item, value: item } })
        },

        async getLists() {
            let promises = await Promise.all([
                this.$http.get('/api/clients/keys/all/read'), //all api key types
                this.$http.get('/api/routing/smsc/accounts/read'),
            ])
            const apiKeysPromise = promises.shift()
            const smscAccounts = promises.shift()
            this.normalizeKeys(apiKeysPromise.data)
            this.normalizeAccounts(apiKeysPromise.data)
            this.normalizeSmscAccounts(smscAccounts.data)
            
        },
        tabChanged(tab){
            if( tab == 'routing' ) this.getLists()
        },
        toggleShow(){
            this.show = !this.show
        },
        async loadData(){
            const res = await this.$http.get('/api/clients/read-accounts')
            if(res && res.status == 200){
                this.clientData = res.data
            }
        },
        getProvidersLabel(item){
            return `${item.providers_accounts_provider_id} (${item.providers_accounts_our_provider_id}) |
                     ${item.providers_accounts_id} (${item.providers_accounts_user_name}) `
        },
    }
}
</script>

<style>


</style>