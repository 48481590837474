<template>
  <b-form @submit.stop.prevent="() => onSubmit(tag, config, includes.filter(i => i))">
    <b-row>

      <b-col cols="6">
        <b-card style="padding: 10px 10px 10px 10px;">
          <b-row>
            <b-form-group style="text-align:left">
              <h6 style="color:green;">{{this.params.uid}}</h6>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col cols="6">
              <b-form-group label="Tag:" label-for="input-tag" :invalid-feedback="'Tag is required'" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  v-model="tag"
                  id="input-tag"
                  :state="tag !== ''"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Smsbox id:" label-for="input-smsbox-id" invalid-feedback="smsbox-id is required" style="text-align:left">
                <b-form-input
                  required                  
                  size="sm"
                  placeholder="Required"
                  v-model="config['smsbox-id']"
                  id="input-smsbox-id"
                  :state="config['smsbox-id'] != null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Smsc id:" label-for="input-smsc-id" invalid-feedback="smsc-id is required" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['smsc-id']"
                  id="input-smsc-id"
                  :state="config['smsc-id'] != null"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Shortcode:" label-for="input-shortcode" invalid-feedback="shortcode is required" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  v-model="config['shortcode']"
                  id="input-shortcode"
                  :state="config['shortcode'] != null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          
          <!--
          <ConfigIncludes :includes="includes" />
          -->
          <br />

          <b-row>
            <b-button-group>
              <b-button variant="outline-danger" @click="() => onClose()">Close</b-button>
              <b-button type="submit" variant="outline-primary">Submit</b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <ConfigPreview :config="config" :includes="includes" />
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
  import ConfigPreview from '../../Common/Config/ConfigPreview.vue'
  //import ConfigIncludes from '../../Common/Config/ConfigIncludes.vue'

  export default {
    components: {
      ConfigPreview,
      //ConfigIncludes
    },
    props: ['params', 'onSubmit', 'onClose'],
    data () {
      return {
        tag: '',
        config: {
          'smsbox-id': null,
          'smsc-id': null,
          'shortcode': null,
        },
        includes: []
      }
    },
    created () {
      this.tag = this.params.tag
      this.includes = this.params.includes
      this.config = { ...this.config, ...this.params.config }
    },
  }
</script>

<style>
  .row {
    margin: 10px;
  }
</style>
