<template>
  <b-form @submit.stop.prevent="() => onSubmit(tag, config, includes.filter(i => i))">
    <b-row>

      <b-col cols="6">
        <b-card style="padding: 10px 10px 10px 10px;">
          <b-row>
            <b-form-group style="text-align:left">
              <h6 style="color:green;">{{this.params.uid}}</h6>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Tag:" label-for="input-tag" :invalid-feedback="'Tag is required'" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  v-model="tag"
                  id="input-tag"
                  :state="tag !== ''"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Id:" label-for="input-id" :invalid-feedback="'Id is required'" style="text-align:left">
                  <b-form-input
                    required
                    :disabled="this.params.uid !== null && this.params.uid !== undefined"
                    size="sm"
                    id="input-id"
                    v-model="config['id']"
                    placeholder="Required"
                    :state="config['id'] != null"
                  />
                </b-form-group>
            </b-col>
          </b-row>
        <b-row>
          <b-col>
              <b-form-group label="Database:" label-for="input-database" :invalid-feedback="'Database is required'" style="text-align:left">
                  <b-form-input
                    required
                    size="sm"
                    id="input-database"
                    v-model="config['database']"
                    placeholder="Required"
                    :state="/^[0-9]{1,10}$/.test(config['port'])"
                  />
                </b-form-group>
            </b-col>
        </b-row>
          <b-row>
            <b-col >
                <b-form-group label="Host:" label-for="input-host" :invalid-feedback="'Host for the connection to SMSC is required'" style="text-align:left">
                  <b-form-input
                    required
                    size="sm"
                    id="input-host"
                    v-model="config['host']"
                    placeholder="Required"
                    :state="config['host'] != null"
                  />
                </b-form-group>
              </b-col>
            <b-col>
              <b-form-group label="Port:" label-for="input-port" :invalid-feedback="'Port for MO is required'" style="text-align:left">
                  <b-form-input
                    required
                    size="sm"
                    id="input-port"
                    v-model="config['port']"
                    placeholder="Required"
                    :state="/^[0-9]{1,10}$/.test(config['port'])"
                  />
                </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Username:" label-for="input-username" :invalid-feedback="'Username is required'" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  id="input-username"
                  v-model="config['username']"
                  placeholder="Required"
                  :state="config['username'] != null"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Password:" label-for="input-password" :invalid-feedback="'Password is required'" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  id="input-password"
                  v-model="config['port']"
                  placeholder="Required"
                  :state="config['password'] != null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <br />

          <b-row>
            <b-button-group>
              <b-button variant="outline-danger" @click="() => onClose()">Close</b-button>
              <b-button type="submit" variant="outline-primary">Submit</b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <ConfigPreview :config="config" :includes="includes" />
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
  import ConfigPreview from '../../Common/Config/ConfigPreview.vue'
  //import ConfigIncludes from '../../Common/Config/ConfigIncludes.vue'
  export default {
    components: {
      ConfigPreview,
      //ConfigIncludes
    },
    props: ['params', 'onSubmit', 'onClose'],
    data () {
      return {
        tag: 'tag111',
        config: {
          'id': 'dlrdb',
          'host': 'gateway-db',
          'port': 3306,
          'database': 'kannel_db',
          'username': 'kannel',
          'password': 'kannel',
        },
        includes: []
      }
    },
    created () {
      this.tag = this.params.tag
      this.includes = this.params.includes
      this.config = { ...this.config, ...this.params.config }
    },
    methods: {
      
    }
  }
</script>

<style>
  .row {
    margin: 10px;
  }
</style>
