<template>
    <b-modal 
        @shown="loadData"
        @ok="handleOk"
        size="md"
        id="modal-add-balance" :title="`Add Payment`">
            <b-row>
                <b-col class="p-4">
                    <b-overlay :show="show" rounded="sm">
                        <b-form ref="addBalanceForm" @submit.prevent="handleSubmit" @reset="onReset" >
                            
                            <b-row>
                                <b-col cols="6">
                                    <b-form-group
                                        id="input-group-2"
                                        label="Current balance:"
                                        description="The current balance of the account"
                                        label-for="input-2"
                                        valid-feedback="Thank you!"
                                        :invalid-feedback="'Current balance is 0 or lower'"
                                        :state="true"
                                        >
                                        <b-form-input 
                                        id="input-2" 
                                        v-model="form.client_account_balance"
                                        disabled
                                        trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group
                                        
                                        label="Add balance:"
                                        description="The balance to add to account"
                                        valid-feedback="Thank you!"
                                        :invalid-feedback="'Current balance is 0 or lower'"
                                        :state="form.add_balance_amount > 0"
                                        >
                                        <b-form-input 
                                        required
                                        v-model="form.add_balance_amount"
                                        trim></b-form-input>
                                    </b-form-group>
                                    
                                </b-col>
                                <!--
                                <b-col>
                                    <b-form-group
                                        id="input-group-2"
                                        label="Allowed balance:"
                                        description="The min allowed balance of the account"
                                        label-for="input-2"
                                        valid-feedback="Thank you!"
                                        :invalid-feedback="'Current allowed min balance is 0'"
                                        :state="true"
                                        >
                                        <b-form-input 
                                        id="input-3" 
                                        v-model="form.client_allowed_balance"
                                        :state="true"
                                        trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                                -->
                            </b-row>
                            <b-alert variant="success" :show="form.add_balance_amount > 0">
                                Reset out of credit notification setting
                            </b-alert>

                            <b-button ref="submitBtn" type="submit" style="display: none"></b-button>
                        </b-form>
                    </b-overlay>
                </b-col>
            </b-row>
    </b-modal>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
            show: false,
            form: this.getForm(),
            clientStatusList: [    
                { value: null, text: 'Please select an option' },
                { value: true, text: 'OK' },
                { value: false, text: 'NOK' }
            ],
        }
    },
    props: ['uuid'],
    methods: {
        getForm (){
            return {
                client_account_name: null,
                client_account_balance: 0,
                client_allowed_balance: 0,
                client_accounts_cliend_uuid: null,
                client_status: null,
                client_low_balance_notification_limit: null,
                client_low_balance_notification_sent: null,
                add_balance_amount:0
            }
        },
        async loadData () {
            //const res = await this.$http.get('/api/gateway/accounts/common/read-one', { params: { uuid: this.uuid } })
            const res = await this.$http.get('/api/gateway/accounts/read', { params: { uuid: this.uuid } })
            if(res && res.status == 200){
                this.form = {...res.data, ...{ add_balance_amount: this.add_balance_amount }}
            }
            
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.$refs.submitBtn.click()
            //this.$refs.addBalanceForm.submit() this shit doesnt work?!!
        },
        async handleSubmit(event) {
            event.preventDefault()
            
            
            this.form.details = {}
            this.form.details.payment_cliend_uuid = this.form.client_accounts_cliend_uuid
            this.form.details.payment_usable_amount = this.form.add_balance_amount
            
            //this.form.client_account_balance = parseFloat(this.form.client_account_balance) + parseFloat(this.form.add_balance_amount)

            this.show = !this.show
            const res = await this.$http.post('/api/gateway/accounts/balance', this.form)
            this.show = !this.show
            if(res && res.status == 200){
                if(res.data) {
                    this.$emit('updateRowData', res.data)
                    this.$emit('done', 'modal-add-balance')
                }
            }
            

        },
        onReset(event) {
            event.preventDefault()
            this.reset();
        },
        reset() {
            this.form = this.getForm()
        }
    }
}
</script>

<style>

.closeEditBox {
    cursor:pointer
}


</style>