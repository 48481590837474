<template>
    <div>
        <b-row class="p-3">
            <b-col col cols="2">
                <b-button-group>
                    <router-link 
                        class="btn btn-outline-primary"
                        tag="button"
                        :to="{
                            'path': '/notifications/templates/editor',
                            query: { 'tid': 'new'}
                        }">
                        <b-icon icon="file-plus"></b-icon>&nbsp;&nbsp;New template
                    </router-link>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row>
            <vue-good-table 
                theme="polar-bear"
                :columns="fields" 
                :rows="rows" 
                :pagination-options="paginationOptions"
                :search-options="searchOptions"
                :select-options="selectOptions">
                
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'template_options'">
                        <b-button-group>
                            <router-link 
                                v-b-tooltip.hover title="Edit"
                                class="btn btn-outline-primary btn-sm"
                                tag="button"
                                :to="{
                                    'path': '/notifications/templates/editor',
                                    query: { 
                                        'tid': props.row.template_name,
                                        'cid': props.row.template_category
                                    }
                                }">
                                <b-icon icon="pencil-square"></b-icon>
                            </router-link>
                            <b-button @click="deleteTemplate(props.row)" v-b-tooltip.hover title="Delete" size="sm" variant="outline-danger">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                            <b-button @click="duplicateTemplate(props.row)" v-b-tooltip.hover title="Duplicate" size="sm" variant="outline-primary">
                                <b-icon icon="files"></b-icon>
                            </b-button>
                        </b-button-group>
                    </span>
                </template>
            </vue-good-table>
        </b-row>
    </div>
    
</template>

<script>
import tblCols from '../json/templatesListCols.json'

export default {
    methods:{
        async duplicateTemplate(rowData){
            if(await this.$bvModal.msgBoxConfirm(
                `Are you sure you wish to duplicate ${rowData.template_name} template?`
                , {title:"Duplicate template"})){

                let res = await this.$http.post('/api/notifications/templates/duplicate-template', rowData)
                if(res.status == 200){
                    this.rows.push(res.data);
                }
                
            }
        },
        async deleteTemplate(rowData){
            
            
            
            if(await this.$bvModal.msgBoxConfirm(
                `Are you sure you wish to delete ${rowData.template_name} template?`
                , {title:"Delete template"})){

                let res = await this.$http.post('/api/notifications/templates/delete-template', rowData)
                if(res.status == 200){
                    // remove row
                    this.rows.splice(rowData.originalIndex, 1);
                }
                
            }
            
        },
        async getTemplatesList(){
            let res = await this.$http.get('/api/notifications/templates/read')
            if(res.status==200){
                this.rows = res.data ? res.data : []
            }
        }
    },
    mounted(){
        this.getTemplatesList()
    },
    data(){
        return {
            fields: tblCols,
            rows: [],
            paginationOptions: {
                enabled: true,
                //mode: 'records',
                mode: 'pages',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 50],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                //infoFn: (params) => {`Displaying ${params.totalRecords}/${this.rows.length}`}, 
            },
            searchOptions: {},
            selectOptions: {}
        }
    }
}
</script>

<style>

</style>