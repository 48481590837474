<template>
  <div class="tbl-wrpr">

    <b-modal size="xl" ref="smsbox-modal-form" hide-footer>
      <template #modal-header>
        <h6>{{`${params.mode} Core Configuration File`}}</h6>
      </template>

      <Form :smss="smss" :params="params" :onSubmit="submitForm" :onClose="closeForm" />
    </b-modal>

    <vue-good-table 
      theme="polar-bear"
      :line-numbers="false"
      :rows="rows" 
      :columns="table.cols" 
      :pagination-options="table.pagination"
    >
      <div slot="table-actions">
        <b-button-group>
          <Button icon="file-plus" text="New Config" variant="primary" :onClick="() => openForm('Create')" />
          <Button icon="recycle" text="Refresh lists" variant="primary" :onClick="reload" />
        </b-button-group>
      </div>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'" style="align:center">
          <b-icon v-if="props.row['status'] === 'running'" icon="cloud-check" variant="success"></b-icon>
          <b-icon v-else-if="props.row['status'] === 'stopped'" icon="cloud-slash" variant="danger"></b-icon>
          <b-icon v-else icon="cloud-slash" variant="warning"></b-icon>
        </span>

        <span v-else-if="props.column.field == 'options'">
          <b-button-group>
            <Button icon="play" title="Start" variant="outline-primary" :onClick="() => start(props.row.uid)" />
            <Button icon="stop" title="Stop" variant="outline-warning" :onClick="() => stop(props.row.uid)" />
            <Button icon="trash" title="Delete" variant="outline-danger" :onClick="() => remove(props.row)" />
            <Button icon="pencil-square" title="Edit" variant="outline-primary" :onClick="() => openForm('Edit', props.row)" />
          </b-button-group>
        </span>
      </template>

    </vue-good-table>
  </div>
</template>

<script>
  import cols from './Table'
  import api from '../../../api'
  import Form from './Form.vue'
  import Button from '../../Common/Button.vue'
  import alert from '../../Common/Alerts/DefaultAlertConfig'
  import pagination from '../../Common/Tables/DefaultPaginationConfig'

  export default {
    components:{
      Form,
      Button
    },
    props:{
      rows: {
        type: Array
      },
      smss: {
        type: Array
      },
      fetch: {
        type: Function
      }
    },
    created () {
      //this.fetch()
    },
    data () {
      return {
        table: {
          cols,
          rows: [],
          pagination
        },
        group: 'smsbox',
        params: {}
      }
    },
    methods: {
      async ok (text, title) {
        return await this.$bvModal.msgBoxOk(text , { title, ...alert })
      },
      async confirm (text, title) {
        return await this.$bvModal.msgBoxConfirm(text , { title, ...alert })
      },
      async reload () {
        this.fetch(this.group);
      },
      async start (uid) {
        if (await this.confirm('Are you sure?', 'Confirmation')) {
          const message = await api.groups.start(uid, this.group)

          await this.ok(message, 'Notification')
          this.reload()
        }
      },
      async stop (uid) {
        if (await this.confirm('Are you sure?', 'Confirmation')) {
          const message = await api.groups.stop(uid, this.group)

          await this.ok(message, 'Notification')
          this.reload()
        }
      },
      async create (params) {
        if (await this.confirm('Are you sure?', 'Confirmation')) {
          const result = await api.groups.create(this.group, params)
          if(result) this.rows.push(result)
        }
      },
      async update (uid, params) {
        if (await this.confirm('Are you sure?', 'Confirmation')) {
          const result = await api.groups.update(uid, this.group, params)

          if(result) {
            const index = this.table.rows.findIndex(item => item.id == result.id)
            this.rows.splice(index,1)
            this.rows.push(result)
          }
        }
      },
      async remove (rowData) {
        
        if (await this.confirm('Are you sure?', 'Confirmation')) {
          const result = await api.groups.delete(rowData.uid, this.group)
          if(result) this.rows.splice(rowData.originalIndex, 1)  
        }
      },
      async openForm (mode, params = { tag: '', config: {}, includes: []}) {
        this.params = { mode, ...params }

        this.$refs['smsbox-modal-form'].show()
      },
      async submitForm (tag, config, includes) {
        if (this.params.mode === 'Create') {
          await this.create({ tag, config, includes })
        } else {
          await this.update(this.params.uid, { tag, config, includes })
        }
        this.$refs['smsbox-modal-form'].hide()
      },
      async closeForm () {
        this.$refs['smsbox-modal-form'].hide()
      }
    }
  }
</script>

<style>
  .tbl-wrpr .vgt-global-search__input {
    display: none !important;
  }
</style>
