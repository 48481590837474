<template>
  <b-modal
      id="modal-add-smsc"
      ref="modal"
      title="Select a Bbox"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Bbox"
          label-for="name-input"
          invalid-feedback="Bbox is required"
          :state="bbox != null"
        >
           <b-form-select
            style="width:100% !important;"
            v-model="bbox"
            required>
                <template #first>
                <b-form-select-option :value="null" disabled>Choose a bearerbox to attach to</b-form-select-option>
                <b-form-select-option 
                    v-for="box in bearerboxes" :key="box.id"
                    :value="box" >{{box.tag}} {{box.id}} {{box.config ? box.config['admin-port'] : ''}}</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
      </form>
    </b-modal>
</template>

<script>
export default {
    props:['bearerboxes'],
    data() {
      return {
        bbox: null
      }
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        return valid
      },
      resetModal() {
        this.bbox = null
      },
      handleOk(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        
        this.$emit('bbox', this.bbox)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-add-smsc')
        })
      }
    }
}
</script>

<style>

</style>