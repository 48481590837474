import Vue from 'vue'
import axios from 'axios'
import store from './store/store.js'

axios.interceptors.request.use(
  request => {
    store.dispatch('request')
    return request;
  },
)

axios.interceptors.response.use(
  //response => response,
  response => {
    store.dispatch('request')
    return response
  },
  error => {
    store.dispatch('request')
    const vm = new Vue()
    const h = vm.$createElement;
    // Using HTML string
    const description = h("div", {
      class: ["modal-scrollable"],
      domProps: { innerHTML: JSON.stringify(error.response.data) },
      //domProps: { innerHTML: JSON.stringify(error.response) },
    });
    vm.$bvModal.msgBoxOk([description], {
        title: 'Something went wrong!',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
    })
    //.then(value => {
      .then(() => {
        //this.boxOne = value
    })
    .catch(() => {
    //.catch(err => {
        //this.boxOne = err
    })
  }
);


export default {
  axios:axios,
  groups: {
    list: async (groupId) => {
      
      const res = await axios.get(`/api/gateway/groups/${groupId}/list`)
      /*
      return res.data.map(item => {
        return {
          id: item.id,
          tag: item.tag,
          uid: item.uid,
          config: item.config,
          includes: item.includes,
          pid: item.pid ? item.pid : null,
          status: item.status ? item.status : null,
        }
      })
      */
      if(res && res.status == 200)
        return res.data
    },
    start: async (uid, groupId) => {
      let message = ''
      let res = await axios.post(`/api/gateway/groups/${groupId}/${uid}/start`)
  
      if (res && res.status === 200) {
        if (res.data.pid) {
          message += `pid: ${res.data.pid} `
        }
        if (res.data.status) {
          message += `status: ${res.data.status}`
        }
      }
      return message
    },
    stop: async (uid, groupId) => {
      let message = ''
      let res = await axios.post(`/api/gateway/groups/${groupId}/${uid}/stop`)
  
      if (res && res.status === 200) {
        console.log(res.data)
        if (res.data.pid) {
          message += `pid: ${res.data.pid} `
        }
        if (res.data.status) {
          message += `status: ${res.data.status}`
        }
      }
      return message
    },
    create: async (groupId, params) => {
      let res = await axios.post(`/api/gateway/groups/${groupId}`, params)

      if(res && res.status === 200){
        return res.data
      }
      //return res && res.status === 200
      return false
    },
    update: async (uid, groupId, params) => {
      let res = await axios.put(`/api/gateway/groups/${groupId}/${uid}`, params)
      if(res && res.status === 200){
        return res.data
      }
      return false
      //return res && res.status === 200
    },
    delete: async (uid, groupId) => {
      let res = await axios.delete(`/api/gateway/groups/${groupId}/${uid}`)

      return res && res.status == 200
    }
  }
}
