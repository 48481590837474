var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" History ")]},proxy:true}])},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('vue-good-table',{attrs:{"theme":"polar-bear","columns":_vm.cols,"rows":_vm.rows,"pagination-options":{
          enabled: true,
          mode: 'records',
          perPage: 5,
          position: 'bottom',
          perPageDropdown: [5,10,100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          jumpFirstOrLast : true,
          firstLabel : 'First Page',
          lastLabel : 'Last Page',
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
          infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'options')?_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Details","variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.toggleModal(props)}}},[_c('b-icon',{attrs:{"icon":"file-earmark-medical","variant":"primary"}})],1)],1):_vm._e()]}}])}),_c('Details',{attrs:{"data":_vm.detailsRow}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }