<template>
    <b-container>
        <b-row>
            <b-col>
                <b-table
                    show-empty
                    :emptyText="emptyText"
                    :items="items"
                    :fields="fields">
                        <template #empty="scope">
                            <h4>{{ scope.emptyText }}</h4>
                        </template>
                        <template #emptyfiltered="scope">
                            <h4>{{ scope.emptyFilteredText }}</h4>
                        </template>
                        <template v-slot:cell(proforma_invoice_line_items_tax_rate)="data">
                            {{ data.item.proforma_invoice_line_items_tax_rate ? data.item.proforma_invoice_line_items_tax_rate : "0" }}%
                        </template>
                        <template v-slot:cell(options)="data">
                            <b-button 
                                :disabled="disabled"
                                @click="remove(data.index)" variant="outline-danger" size="sm">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                </b-table>
            </b-col>
        </b-row>
        <LineItemVue :disabled="disabled" :waveItems="waveItems" :waveTaxes="waveTaxes" @add="add"/>
    </b-container>
</template>

<script>
const tbl = require('../../Clients/Invoice/Table/lineItems/tbl')
import LineItemVue from './LineItem.vue'
export default {
    components: {
        LineItemVue
    },
    props: {
        value: {
            type: Array
        },
        waveItems: {
            type: Array,
            default: () => []
        },
        waveTaxes: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        add(item){
            var digest = {
                proforma_invoice_line_items_wave_product_id: item.waveProductId,
                proforma_invoice_line_item_description: item.waveProductDescription,
                proforma_invoice_line_items_price: item.price,
                proforma_invoice_line_items_quantity: item.quantity,
                proforma_invoice_line_items_line_total: item.total,
                proforma_invoice_line_items_wave_tax_item_id:null,
                proforma_invoice_line_items_tax_description:null,
                proforma_invoice_line_items_tax_rate:null,
                proforma_invoice_line_items_total_tax: null
                
            }

            if(item.waveTaxItemId){
                digest['proforma_invoice_line_items_wave_tax_item_id'] = item.waveTaxItemId
                digest['proforma_invoice_line_items_tax_description'] = item.waveTaxItemDescription
                digest['proforma_invoice_line_items_tax_rate'] = item.waveTaxItemRate
                digest['proforma_invoice_line_items_total_tax'] = item.totalTax
            }

            this.value.push(digest)
        },
        remove(index){
            this.value.splice(index,1)
        }
        
    },
    computed: {
        items: {
            get(){
                return this.value
            },
            set(newValue){
                this.$emit('input', newValue);
            }
        }
    },
    data(){
        return {
            fields: tbl.settings.fields,
            emptyText: 'Please add a line item'
        }
    }
}
</script>

<style>

</style>