export default [
  {
    width: '100px',
    label: 'ID',
    field: 'id',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Id'
    }
  },
  {
    width: '100px',
    label: 'Status',
    field: 'status'
  },
  {
    width: '100px',
    label: 'Tag',
    field: 'tag',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Tag'
    }
  },
  {
    //width: '150px',
    label: 'BB Host',
    field: 'config.bearerbox-host',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    //width: '150px',
    label: 'BB Port',
    field: 'config.bearerbox-port',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    //width: '150px',
    label: 'Port',
    field: 'config.sendsms-port',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    width: '500px',
    label: 'URL',
    field: 'config.sendsms-url',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  {
    width: '100px',
    label: 'Recode MO',
    field: 'config.mo-recode',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter Port'
    }
  },
  
  {
    width: '100px',
    label: 'Log level',
    field: 'config.log-level',
  },
  {
    width: '250px',
    label: 'Options',
    field: 'options',
  },
]
