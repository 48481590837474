<template>
  <div>
    <b-row>
      <b-form-group style="text-align:left">
        <h6>Includes</h6>
        <b-button :disabled="disabled" variant="success" size="sm" @click="() => includes.push('')">Add new includes</b-button>
      </b-form-group>
    </b-row>

    <div v-for='(include, index) in includes' :key='index' >
      <b-row>
        <b-col cols="10">
          <b-form-group label-for="add-includes" style="text-align:left">
            <b-form-input :disabled="disabled" size="sm" v-model="includes[index]" /> 
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-button variant="danger" size="sm" @click="() => includes.splice(index, 1)">Delete</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      includes: Array,
      disabled: {
        type: Boolean,
        default: true
      }
    }
  }
</script>
