<template>
  <b-card>
    <vue-good-table 
          theme="polar-bear"
          :columns="tblCfg.fields" 
          :rows="tblRows" 
          :line-numbers="false"
          :sort-options="{
              enabled: true,
              initialSortBy: {field: 'http_api_jwt_created_at', type: 'desc'}
          }"
          :pagination-options="tblCfg.paginationOptions"
          :search-options="tblCfg.searchOptions"
          :select-options="tblCfg.selectOptions"
      >
      <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field == 'http_api_jwt_status'">
          <div @click="toggle(props)" class="text-left">
                <b-icon v-if="props.row.http_api_jwt_status.toString() == 'true'" icon="toggle-on" font-scale="1.5" ></b-icon>
                <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
            </div>
        </span>
        <span v-else-if="props.column.field == 'client_name'">{{ props.row.client_name }}</span>
        <span v-else-if="props.column.field == 'http_api_jwt_token'">
          <span style="display: inline-block; max-width:200px; word-break: break-all;">{{ props.row.http_api_jwt_token }}</span>
        </span>
        <span v-else-if="props.column.field == 'options'">
          
          <b-button-group>
              <!--
              <b-button variant="outline-primary">
                <router-link :to="{
                      'path': '/routing/key',
                      query: {
                        'uuid': props.row.http_api_jwt_uuid,
                        'acid': props.row.http_api_jwt_short_token,
                        'cname': props.row.client_name
                      }
                  }" 
                >
                  <b-icon icon="globe2"></b-icon>
                </router-link>
              </b-button>


              <b-button variant="outline-primary">
                <router-link :to="{
                      'path': '/accounts/keys/geo-hlr',
                      query: {
                        'uuid': props.row.http_api_jwt_uuid,
                        'token': props.row.http_api_jwt_short_token,
                        'cname': props.row.client_name
                      }
                  }" 
                >
                  <b-icon icon="sim"></b-icon>
                </router-link>
              </b-button>              
              -->
              <b-button 
                  variant="primary" 
                  v-b-tooltip.hover 
                  title="Edit key" 
                  @click="toggleModal(props.row)">
                      <b-icon icon="pen"></b-icon>
              </b-button>
          </b-button-group> 
        </span>
      </template>
    </vue-good-table>
    <EditKeyModalHttp ref="key-modal" :rowData="modalRowData" @update="update"/>
  </b-card>
</template>

<script>
const tblCfg = require('./tblConfig/http.json')
import EditKeyModalHttp from './EditKeyModalHttp.vue'
export default {
  components: {        
    EditKeyModalHttp
  },
  props: ['tblData', 'type'],
  watch: {
    'tblData': {
        handler: function(nv){
            this.tblRows = nv
        },
        deep: true
    }
  },
  data(){
    return {
      tblCfg: tblCfg,
      modalRowData: {},
      tblRows: []
    }
  },
  methods: {
    async update(formData){
      
      let res = await this.$http.post(`/api/clients/keys/${this.type}/update`, formData)
      if(res && res.status == 200){
        if(res.data.status) {
          const tblData = [...this.tblRows]
          this.tblRows = []
          const index = tblData.findIndex(item => item.http_api_jwt_short_token == formData.http_api_jwt_short_token)
          tblData[index] = { ...tblData[index], ...formData }
          this.tblRows = [...tblData]
        }
      }
    },
    toggleModal(rowData){
      this.modalRowData = rowData
      this.$refs['key-modal'].toggle()
    },
    toggle: async function (rowData) {
      let res = await this.$http.post(`/api/clients/keys/${this.type}/toggle`, rowData.row)
      if(res && res.status == 200){
        if(res.data.status) rowData.row.http_api_jwt_status = !rowData.row.http_api_jwt_status
      }
    }
  },
  
}
</script>

<style>

</style>