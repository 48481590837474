<template>
  <b-modal    
    
    id="copy-from-existing-account-routing-modal" 
    ref="copy-from-existing-account-routing-modal"
    size="lg"
    title="Copy From API Key"
    @ok="handleOk"
    @hidden="resetModal">

      <b-form ref="form" @submit="handleOk" @reset="resetModal">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                  style="text-align:left"
                  :label="`Routes (${submit.routing.length}):`">
                    <multiselect 
                      :class="'multiselect-hlr-managment'"
                      :multiple="true"
                      v-model="submit.routing" 
                      :options="filtered.routing ? filtered.routing: []" 
                      placeholder="Selected counries" 
                      :custom-label="customLabel"
                      @remove="removeRoutingItem"
                      height="500px"
                      label="routing_lookup_config_country_name" 
                      track-by="routing_lookup_config_country_code">
                    </multiselect>
                 </b-form-group>

                
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                 style="text-align:left"
                 label="Source API Key Token"
                  description="Source API Key Token">
                  <multiselect 
                  v-model="submit.source" 
                  :options="apiKeyTokens" 
                  placeholder="Select one" 
                  label="text" 
                  track-by="value"
                  required>
                </multiselect>
                 </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        
      </b-form>
      
  </b-modal>

</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
  components: {
    Multiselect
  },
  props: {
    apiKeyTokens: {
      type: Array
    },
    filtered: {
      type: Object
    }
  },
  watch:{
      filtered: {
          handler(val) {
              for (const [key, value] of Object.entries(val)) {
                  this.submit[key] = value
              }
          },
          deep: true
      },
  },
  created(){
    //this.loadAccountsList()
  },
  data(){
    return {
      submit:{
        routing: [],
        source: null
      },
      confirmed: false,
      modalShow:false,
      accountsList: [],
      from_routing_account: null
    }
  },
  methods: {
    async handleOk(event){
      
      event.preventDefault()
      

      const res = await this.$http.post('/api/routing/token/copy/source', this.submit)
      if(res && res.status == 200){
        this.$emit('done', 'copy-from-existing-account-routing-modal')
      }



      /*
      const valid = this.$refs.form.checkValidity() && ( this.from_routing_account != null )
      if(!valid){
        return
      }
      */
      //this.confirmed = await this.$parent.confirmModal(`Are you sure you whish to copy all settings from: ${this.from_routing_account.routing_account}?`)
      /*
      if(this.confirmed){
        //this.$emit('loading', true)
        this.$nextTick(function() {      
          this.$bvModal.hide('copy-from-existing-account-routing-modal')
        })

  
        let res = await this.$http.post('/api/routing/copy-from-existing-account', {
          copy_from_account: this.from_routing_account.routing_account,
          copy_to_account: this.$route.query.acid,
          copy_to_uuid: this.$route.query.uuid
        })
        

        if(res.status == 200){
          
          //this.$emit('updateRotuing', res.data)
          this.$emit('reloadTable', true)
          
          
        }

        //this.$emit('loading', true)
        this.resetModal()
      }
      */

    },
    resetModal(){
      //this.from_routing_account = null

    },
    customLabel(item){
        return `
          ${item.routing_mcc}${item.routing_mnc} ${item.routing_account} (${item.routing_country} - ${item.routing_operator_name})          
          `
    },
    removeRoutingItem(){},
    async loadAccountsList(){
      let res = await this.$http.get('/api/routing/read-accounts-to-copy')
      
      if(res.status == 200){
        
        this.accountsList = res.data
        

      }
    },
  }
}
</script>

<style>

</style>