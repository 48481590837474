module.exports = {
    groupOptions: {
        enabled: false,
        collapsable: false
      },
      searchOptions: {
          enabled: false,
          placeholder: 'Search this table'
      },
      selectOptions: { enabled: false },
      paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: 5,
          position: 'bottom',
          perPageDropdown: [5,10,100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          jumpFirstOrLast : true,
          firstLabel : 'First Page',
          lastLabel : 'Last Page',
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
          infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
      },
    fields: [
        { 
            label: 'Date', 
            field: 'hlr_alytics_date',
            filterOptions: {
                enabled: true,
                placeholder: 'Filter'
            }
        },
        { 
            label: 'Country', 
            field: 'hlr_country_name',
            filterOptions: {
                enabled: true,
                placeholder: 'Filter'
            }
        },
        { 
            label: 'Operator',  
            field: 'hlr_operator_name',
            filterOptions: {
                enabled: true,
                placeholder: 'Filter'
            }
        },
        { 
            label: 'Provider',  
            field: 'hlr_provider_name',
            filterOptions: {
                enabled: true,
                placeholder: 'Filter'
            }
        },
        { 
            label: 'Total',  
            field: 'hlr_count'
        }
      ],
      opFields: [
        { label: 'Country', field: 'hlr_country_name'},
        { label: 'Operator Name', field: 'hlr_operator_name'},
        { label: 'MccMnc',  field: 'hlr_mccmnc'},
        { label: 'Total',  field: 'hlr_total'}
      ],
      ccFields:[
        { label: 'Country', field: 'hlr_country_name'},
        { label: 'Country code', field: 'hlr_country_code'},
        { label: 'Total',  field: 'hlr_total'}
      ],
      dateFields: [
        { label: 'Date', field: 'hlr_created_at'},
        { label: 'Total', field: 'hlr_total'}
      ],
      providerFields: [
        { label: 'Provider  ', field: 'hlr_provider_name'},
        { label: 'Total', field: 'hlr_total'}
      ]
}