<template>
  <b-modal    
    
    id="send-routing-modal" 
    ref="send-routing-modal"
    size="lg"
    title="Send Routing"
    @ok="handleOk"
    @hidden="resetModal">

      <b-form ref="form" @submit="handleOk" @reset="resetModal">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                  style="text-align:left"
                  :label="`Routes (${submit.routing.length}):`">
                    <multiselect 
                      :class="'multiselect-hlr-managment'"
                      :multiple="true"
                      v-model="submit.routing" 
                      :options="filtered.routing ? filtered.routing: []" 
                      placeholder="Selected routing" 
                      :custom-label="customLabel"
                      @remove="removeRoutingItem"
                      height="500px"
                      label="routing_lookup_config_country_name" 
                      track-by="routing_lookup_config_country_code">
                    </multiselect>
                 </b-form-group>

                
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        
      </b-form>
      
  </b-modal>

</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
  components: {
    Multiselect
  },
  props: {
    filtered: {
      type: Object
    }
  },
  watch:{
      filtered: {
          handler(val) {
              for (const [key, value] of Object.entries(val)) {
                  this.submit[key] = value
              }
          },
          deep: true
      },
  },
  created(){
    //this.loadAccountsList()
  },
  data(){
    return {
      submit:{
        routing: []
      },
    }
  },
  methods: {
    async handleOk(event){
      
      event.preventDefault()
      const res = await this.$http.post('/api/routing/token/send/partial', this.submit)
      if(res && res.status == 200){
        if(res.data.status)
          this.$emit('done', 'send-routing-modal')
      }

    },
    resetModal(){
      this.from_routing_account = null
    },
    customLabel(item){
        return `
          ${item.routing_mcc}${item.routing_mnc} ${item.routing_account} (${item.routing_country} - ${item.routing_operator_name})          
          `
    },
    removeRoutingItem(){},
    async loadAccountsList(){
      let res = await this.$http.get('/api/routing/read-accounts-to-copy')
      
      if(res.status == 200){
        
        this.accountsList = res.data
        

      }
    },
  }
}
</script>

<style>

</style>