<template>

    <b-row>
        <b-col cols="12">
        <b-card>
     
<!--
    to do: MANUAL update account balance
    check deduction of balance is correct!
-->
            <b-card-header>
                <b-row>
                    <b-col>
                        GEO Settings
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body style="min-height:500px; "> 
                <b-tabs  
                    pills card  vertical
                    content-class="">
                    <b-tab @click="tabChanged('mtRouting')" title="API Key Settings" active>
                        <b-overlay :show="show" rounded="sm">
                            <Layout />
                        </b-overlay>
                    </b-tab>
                    <b-tab @click="tabChanged('mtRouting')" title="HLR Provider Accounts" >
                        <b-overlay :show="show" rounded="sm">
                            <Table />
                        </b-overlay>
                    </b-tab>
                </b-tabs>
            </b-card-body> 
        </b-card>
        
        
        </b-col>
    </b-row>
    

</template>

<script>

import Table from '../../Hlr/Providers.vue'
import Layout  from './Layout.vue'

export default {
    components: {
        Table,
        Layout
    },
    data() {
        return {
            currTab: 0,
            show: false,
            /*
            notificationUsersDataLocal: null,
            usersDataLocal: null,
            clientUuid: this.$route.params.id,
            entities: {}
            */
        }
    },
    //props: ['id'],
    created () {
        this.loadData()
    },
    computed:{
        
    },
    methods: {
        
        //tabChanged(tab){             
        tabChanged(){
            //console.log(tab)
            //this.currTab = tab

        },
        toggleShow(){
            this.show = !this.show
        },
        async loadData(){
            
        },
    }
}
</script>

<style>


</style>