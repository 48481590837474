import View  from './../components/Routing/Layout.vue'
import Campaigns from './../components/Media/Campaigns.vue'
import ShortUrls from './../components/Media/ShortUrls.vue'
import UrlGeos from './../components/Media/UrlGeos.vue'
import Block from './../components/Media/Block.vue'


export default {
    path: 'media',
    name: 'media',
    component: View,
    redirect: 'media/campaigns',
    children:[
        //tokens,
        {
            path: 'campaigns',
            name: 'campaigns',
            component: Campaigns
        },
        {
            path: 'urls',
            name: 'urls',
            component: ShortUrls
        },
        {
            path: 'block',
            name: 'block',
            component: Block
        },
        {
            path: 'urls/geos/:id',
            name: 'urls-geos',
            component: UrlGeos
        },
        
        /*
        {
            path: 'clients/edit/:id?',
            component: EditClient
        },
        */
    ]
  }
