<template>

    <base-card>
        <template #header>
            Client accounts
        </template>
        
        <div slot="body">
            <div class="row">
                <div class="col-lg-12 m-2">
                    <b-button variant="outline-primary" @click="fetchData">
                        <b-icon icon="recycle"></b-icon>
                        Load list
                    </b-button>
                </div>
            </div>    
            
            <vue-good-table 
                theme="polar-bear"
                :columns="table.clientFields" 
                :rows="clientAccounts" 
                :line-numbers="false"
                :pagination-options="table.paginationOptions"
                :search-options="table.searchOptions"
                :select-options="table.selectOptions">
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'client_account_name'" class="class-bg-white">
                            <div v-if="props.row.client_account_name" class="text-left">{{ props.row.client_account_name }}</div>
                            <span v-else>
                                -
                            </span>
                        </span>
                        
                        <span v-else-if="props.column.field == 'client_api_keys'">
                            {{ props.row.children.length }}
                        </span>
                        
                        <span v-else-if="props.column.field == 'client_status'">
                            <div @click="toggleClientStatus(props)" class="text-left" style="cursor: pointer">
                                <b-icon v-if="props.row.client_status" icon="toggle-on" font-scale="1.5" ></b-icon>
                                <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
                            </div>
                        </span>
                        <span v-else-if="props.column.field == 'client_low_balance_notification_sent'">
                            <div class="text-left" >
                                <b-icon 
                                    v-if="props.row.client_low_balance_notification_sent"
                                    style="cursor: pointer"  
                                    @click="resetNotificationSetting(props.row, 'client_low_balance_notification_sent')" 
                                    icon="recycle" 
                                    font-scale="1.5">
                                </b-icon>
                                <span v-else> - </span>
                            </div>
                        </span>
                        <span v-else-if="props.column.field == 'client_out_of_balance_notification_sent'">
                            <div class="text-left" >
                                <b-icon 
                                    v-if="props.row.client_out_of_balance_notification_sent"
                                    style="cursor: pointer"  
                                    @click="resetNotificationSetting(props.row, 'client_out_of_balance_notification_sent')" 
                                    icon="recycle" font-scale="1.5">
                                </b-icon>
                                <span v-else> - </span>
                            </div>
                        </span>
                        <span v-else-if="props.column.field == 'options'">
                            <b-button-group>
                                <b-button v-b-tooltip.hover title="Add Payment" variant="outline-primary" size="sm" @click="toggleModal('modal-add-payment',props.row) ">
                                    <b-icon icon="file-earmark-medical" variant="primary"></b-icon>
                                </b-button>
                            

                                <b-button v-b-tooltip.hover title="Create Invoice" variant="outline-primary" size="sm" @click="toggleModal('modal-new-invoice',props.row) ">
                                    <b-icon icon="file-earmark-post-fill" variant="primary"></b-icon>
                                </b-button>
                            
                            <b-button v-b-tooltip.hover title="Add Balance" variant="outline-primary" size="sm" @click="toggleModal('modal-add-balance',props.row) ">
                                    <b-icon icon="bag-plus" variant="primary"></b-icon>
                                </b-button>

                                <b-button v-b-tooltip.hover title="Edit Client Details" variant="outline-primary" size="sm" @click="toggleModal('modal-edit-client',props.row) ">
                                    <b-icon icon="pencil-square" variant="primary"></b-icon>
                                </b-button>
                            
                            </b-button-group>
                        </span>

                    </template>
            
            </vue-good-table>
            
            <FinanceCreateInvoice :name="modalName" :uuid="modalUuid" @done="done"/>
            <FinanceAddPayment :uuid="modalUuid" :name="modalName" @done="done"/>

            <FinanceAddBalance :uuid="modalUuid" :name="modalName" @done="done" @updateRowData="updateRowData"/>
            <FinanceEditClient :uuid="modalUuid" :name="modalName" @done="done" @updateRowData="updateRowData"/>
        </div>
    </base-card>

</template>

<script>
import BaseCard from './Base/card.base.vue'
import FinanceAddPayment  from './Clients/AddPayment.vue'
import FinanceCreateInvoice  from './Clients/Invoice/CreateInvoice.vue'
import FinanceAddBalance  from './Clients/AddClientBalance.vue'
import FinanceEditClient from './Clients/EditClient.vue'
const table = require('./Clients/Invoice/Table/clients/tbl.js')

export default {
    components: {
        BaseCard,
        FinanceAddPayment,
        FinanceCreateInvoice,
        FinanceAddBalance,
        FinanceEditClient
    },
    created () {
        //this.fetchData()
    },
    mounted(){
      
    },
    methods: {
        async resetNotificationSetting(rowData, type){
            const submit = { ...rowData }
            submit[type] = !submit[type]
            const res = await this.$http.post('/api/gateway/accounts/update', submit)
            if(res &&res.status == 200 ){
                rowData[type] = !rowData[type]
            }

        },
        updateRowData(data){
            
            //client_account_balance
            const idx = this.clientAccounts.findIndex(item => item.client_accounts_cliend_uuid == data.client_accounts_cliend_uuid)
            this.clientAccounts[idx].client_account_balance = data.client_account_balance
            this.clientAccounts[idx].client_account_name = data.client_account_name
            this.clientAccounts[idx].client_allowed_balance = data.client_allowed_balance
            //this.clientAccounts[idx].client_low_balance_notification_sent = data.client_low_balance_notification_sent
            //this.clientAccounts[idx].client_out_of_balance_notification_sent = data.client_out_of_balance_notification_sent
            this.clientAccounts[idx].client_low_balance_notification_sent = false
            this.clientAccounts[idx].client_out_of_balance_notification_sent = false
            this.clientAccounts[idx].client_status = data.client_status
            this.clientAccounts[idx].client_status = data.client_account_currency
            
            
        },
        toggleModal(modal, rowData){

            this.modalUuid = rowData.client_accounts_cliend_uuid
            this.modalName = rowData.client_account_name
            this.$bvModal.show(modal)
        },
        done(model){
            this.$bvModal.hide(model)
        },
        toggleClientStatus: async function (rowData) {
            let res = await this.$http.post('/api/gateway/accounts/update',{
                client_accounts_cliend_uuid: rowData.row.client_accounts_cliend_uuid,
                client_status: !rowData.row.client_status
            })
            if(res && res.status == 200 && res.data) 
                rowData.row.client_status = res.data.client_status
            
        },
        async fetchData() {
            const res = await this.$http.get('/api/gateway/accounts/read')
            if(res && res.status == 200){
                this.clientAccounts = res.data
            }
        }
    },
    data() {
        return {
            modalUuid: null,
            modalName: null,
            modalTitle: null,
            clients: [],
            clientAccounts:[],
            table: table.settings
        };
    }
}
</script>
<style>
.btn-secondary {
    border:none;
    background-color:none;
}

.class-green{
    cursor:pointer;
    color:rgb(17, 192, 17);
}

.class-red{
    cursor:pointer;
    color:rgb(233, 12, 12);
}

.vgt-row-header{
    background-color: white !important;
}
.class-bg-white{
    background-color: white;
}
</style>