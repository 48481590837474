<template>
  <vue-good-table
    theme="polar-bear"
    :columns="columns" 
    :rows="rows" 
    :line-numbers="false"
    :pagination-options="paginationOptions"
    :search-options="searchOptions"
    :select-options="selectOptions">
    <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'id'">
        {{props.row.id ? props.row.id : 'NO BOXID - GENERIC'}}
        </span>
    </template>
  </vue-good-table>
</template>

<script>
import paginationOptions from './paginationOptions.json'
import searchOptions from './searchOptions.json'

export default {
  components:{
    
    
  },
  methods:{
  },
  props: ['rows'],
  data() {
    return {
      
      paginationOptions: paginationOptions,
      searchOptions: searchOptions,
      selectOptions: { enabled: false },
      columns:[
          { field: 'id', label: 'Name',
             filterOptions: {
                enabled: true,
                placeholder: 'Filter Name'
            }
            
         },
        { field: 'type', label: 'Type',
        
          filterOptions: {
                enabled: true,
                placeholder: 'Filter Type'
            }
        
         },
        { field: 'IP', label: 'IP',
        
          filterOptions: {
                enabled: true,
                placeholder: 'Filter IP'
            }
        
         },
        { field: 'queue', label: 'queue' },
        { field: 'status', label: 'status',
        
         filterOptions: {
                enabled: true,
                placeholder: 'Filter status'
            }
        
        },
        { field: 'ssl', label: 'ssl' },
      ]
    }
  }
}
</script>

<style>

</style>