<template>
    <b-modal 
        @shown="loadData"
        @ok="handleOk"
        size="xl"
        id="modal-add-payment" :title="`Add Payment`">
        <b-row style="width:100%;margin:0;">
            <b-col lg="12">
                <b-overlay :show="submited" rounded="sm" blur="2px">
                    <b-form   
                        ref="paymentForm"  
                        @submit.prevent="onSubmit"
                        @reset="onReset">
                        <b-row>
                            <b-col lg="12">
                                <div style="width:350px;float:left">
                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group
                                            label="Draft Invoice number:">
                                            <b-form-input
                                                aria-describedby="draft-invoice-number-feedback"
                                                v-model="form.payment_proforma_invoice_number"
                                                :state="form.payment_proforma_invoice_number!='' && form.payment_proforma_invoice_number!=null"
                                                type="text"
                                                placeholder="Enter draft invoice number"
                                                required
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="draft-invoice-number-feedback">This is a required field.</b-form-invalid-feedback>
                                        </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group
                                            label="Invoice number:">
                                            <b-form-input
                                                aria-describedby="invoice-number-feedback"
                                                v-model="form.payment_invoice_number"
                                                :state="form.payment_invoice_number!='' && form.payment_invoice_number!=null"
                                                type="text"
                                                placeholder="Enter invoice number"
                                                required
                                            ></b-form-input>
                                        <b-form-invalid-feedback id="invoice-number-feedback">This is a required field.</b-form-invalid-feedback>
                                        </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group
                                            label="Payment type:">
                                            <b-form-select
                                                aria-describedby="payment-type-feedback"
                                                :state="form.payment_type!='' && form.payment_type!=null"
                                                style="width:100% !important"
                                                v-model="form.payment_type" 
                                                :options="optionsInvoiceType"
                                                required>
                                            </b-form-select>
                                            <b-form-invalid-feedback id="payment-type-feedback">This is a required field.</b-form-invalid-feedback>
                                        </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group
                                            label="Payment status:">
                                            <b-form-select
                                                aria-describedby="payment-status"
                                                style="width:100% !important"
                                                :state="form.payment_status!='' && form.payment_status!=null"
                                                v-model="form.payment_status" 
                                                :options="invoiceStatusOptions"
                                                required>
                                            </b-form-select>
                                            <b-form-invalid-feedback id="payment-status">This is a required field.</b-form-invalid-feedback>
                                        </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div style="width:350px;float:right">
                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group label="Invoice date:">
                                            <b-form-datepicker 
                                            aria-describedby="invoice-date-feedback"
                                            required
                                            :state="form.payment_issue_timestamp!='' && form.payment_issue_timestamp!=null"
                                            style="width:100% !important"
                                            v-model="form.payment_issue_timestamp">
                                            </b-form-datepicker>
                                            <b-form-invalid-feedback id="invoice-date-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-row> 
                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group label="Invoice paid date:">
                                            <b-form-datepicker 
                                            aria-describedby="invoice-due-date-feedback"
                                            required
                                            style="width:100% !important"
                                            :state="form.payment_payment_timestamp!='' && form.payment_payment_timestamp!=null"
                                            v-model="form.payment_payment_timestamp">
                                            </b-form-datepicker>
                                            <b-form-invalid-feedback id="invoice-due-date-feedback">This is a required field.</b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-row> 
                                    <b-row>
                                        <b-col lg="12">
                                            <b-form-group
                                            label="Payment currency:">
                                            <b-form-select
                                                aria-describedby="invoice-currency-feedback"
                                                :state="form.payment_currency!='' && form.payment_currency!=null"
                                                style="width:100% !important"
                                                v-model="form.payment_currency" 
                                                :options="invoiceCurrencyOptions"
                                                required>
                                            </b-form-select>
                                            <b-form-invalid-feedback id="invoice-currency-feedback">This is a required field.</b-form-invalid-feedback>
                                        </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>
                                
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row style="margin-top:20px">
                            <b-col cols="5">
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group
                                        label="Invoice sub total:">
                                        <b-form-input
                                            aria-describedby="invoice-subtotal-feedback"
                                            :state="form.payment_usable_amount!='' && form.payment_usable_amount!=null"
                                            v-model="form.payment_usable_amount"
                                            type="text"
                                            placeholder="Invoice sub total"
                                            required
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="invoice-subtotal-feedback">This is a required field.</b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group
                                        label="Invoice total tax1:">
                                        <b-form-input
                                            
                                            v-model="form.payment_tax_1"
                                            type="text"
                                            placeholder="Invoice total tax1"
                                            
                                        ></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group
                                        label="Invoice total tax2:">
                                        <b-form-input
                                            v-model="form.payment_tax_2"
                                            type="text"
                                            placeholder="Invoice total tax2"
                                            
                                        ></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group
                                        label="Invoice total:">
                                        <b-form-input
                                            aria-describedby="invoice-total-feedback"
                                            v-model="form.payment_total_amount"
                                            :state="form.payment_total_amount!='' && form.payment_total_amount!=null"
                                            type="text"
                                            placeholder="Invoice total"
                                            required
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="invoice-total-feedback">This is a required field.</b-form-invalid-feedback>
                                    </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="7">
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group
                                        label="Payment ref1: (Invoice/ID)">
                                        <b-form-input
                                            aria-describedby="invoice-ref1-feedback"
                                            :state="form.payment_ref_1!='' && form.payment_ref_1!=null"
                                            v-model="form.payment_ref_1"
                                            type="text"
                                            placeholder="Payment ref1"
                                            required
                                        ></b-form-input>
                                        <b-form-invalid-feedback id="invoice-ref1-feedback">This is a required field.</b-form-invalid-feedback>
                                    </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group
                                        label="Payment ref2:">
                                        <b-form-input
                                            v-model="form.payment_ref_2"
                                            type="text"
                                            placeholder="Payment ref2"
                                            
                                        ></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group
                                        label="Download link:">
                                        <b-form-input
                                            v-model="form.payment_invoice_download_link"
                                            type="text"
                                            placeholder="Download link"
                                            
                                        ></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="12">
                                        <b-form-group
                                        label="Preview link:">
                                        <b-form-input
                                            v-model="form.payment_invoice_preview_link"
                                            type="text"
                                            placeholder="Preview link"
                                            
                                        ></b-form-input>
                                    </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-overlay>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
export default {
    componenets: {},
    created () {
        //this.getData()
    },
    props:['uuid', 'name'],
    data() {
        return {
            submited:false,
            invoiceCurrencyOptions:[
                { text: 'Please choose an option', value: null },
                { text: 'Eur (€)', value: 'EUR' },
            ],
            optionsInvoiceType: [
                { text: 'Please choose an option', value: null },
                { text: 'Invoice', value: 'INVOICE' },
            ],
            invoiceStatusOptions:[
                { text: 'Please choose an option', value: null },
                { text: 'Paid', value: 'PAID' },
            ],
            form: {
                payment_cliend_uuid: this.uuid,
                payment_cliend_name: this.name,
                payment_proforma_invoice_number: null,
                payment_invoice_number: null,
                payment_generated_by: 'BFFA',
                payment_type: null,
                payment_ref_1: null,
                payment_ref_2: null,
                payment_status: null,
                payment_currency: null,
                payment_usable_amount: null,
                payment_total_amount: null,      
                payment_tax_1: null,
                payment_tax_2: null,
                payment_invoice_download_link: null,
                payment_invoice_preview_link: null,
                payment_issue_timestamp: null,
                payment_issue_date: null,
                payment_payment_date: null,
                payment_payment_timestamp: null,
                payment_history_standing_balance: null
            }
        }
    },
    methods:{
        handleInvoiceData(data){
            //console.log(data)
            this.form.payment_usable_amount = data.proforma_invoice_sub_total
            this.form.payment_total_amount = data.proforma_invoice_total
            this.form.payment_tax_1 = data.proforma_invoice_total_tax1
            this.form.payment_tax_2 = data.proforma_invoice_total_tax2
            this.form.payment_total_tax = data.proforma_invoice_total_tax1 + data.proforma_invoice_total_tax2
            this.form.payment_type = 'INVOICE'
            this.form.payment_currency = data.proforma_invoice_main_currency
        },
        handlePaymentClosed(data){
            console.log('To do: handle after closed')
            console.log(data)
        },
        loadData(){
            //console.log(this.$route.params.uuid)
            //console.log(this.$route.params.pinv)
            if(!this.$route.params.uuid){
                return
            }

            Promise.all([
                this.$http.get('/api/finance/proforma-invoice-details?uuid=' + this.$route.params.uuid + '&pinv=' + this.$route.params.pinv ),
            ]).then(([res1])=>{
                this.handleInvoiceData(res1.data)
                //console.log(res1.data)
            })
        },
        handleOk(bvModalEvent){
            bvModalEvent.preventDefault()
            this.onSubmit()
            
        },
        //onSubmit(e){
        async onSubmit(){
            
            this.form.payment_cliend_uuid = this.uuid
            this.form.payment_cliend_name = this.name
            const res = await this.$http.post( '/api/payment/history/create', {
                details: this.form
            } )

            

            if(res && res.status == 200){
            
                this.$bvModal.hide('modal-add-payment')	
            }

        },
        onReset(){
             this.form = {
                payment_cliend_uuid: this.uuid,
                payment_proforma_invoice_number: null,
                payment_invoice_number: null,
                payment_generated_by: 'BFFA',
                payment_type: null,
                payment_ref_1: null,
                payment_ref_2: null,
                payment_status: null,
                payment_currency: null,
                payment_usable_amount: 0,
                payment_total_amount: 0,      
                payment_tax_1: 0,
                payment_tax_2: 0,
                payment_invoice_download_link: null,
                payment_invoice_preview_link: null,
                payment_issue_timestamp: null,
                payment_issue_date: null,
                payment_payment_date: null,
                payment_payment_timestamp: null,
                payment_history_standing_balance: null
            }
        }
    }
}
</script>

<style>

</style>