<template>
    <div class="routing-managment-wrapper">

        <vue-good-table
        theme="polar-bear"
        :rows="routing" 
        :columns="fields" 
        :pagination-options="paginationOptions"
        :search-options="{
            enabled: false,
            placeholder: 'Search this table',
        }"
        :fixed-header="true"
        max-height="1000px"
        :select-options="{ enabled: false }"
        @on-select-all="onSelectAll"
        @on-selected-rows-change="selectionChanged"
        @on-column-filter="onColumnFilter">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'options'">
                    <b-button-group style="height:100% !important">
                        <b-button 
                            v-b-tooltip.hover 
                            title="Delete SMSc"
                            @click="deleteRow(props)" variant="danger" size="sm">
                                <b-icon  icon="trash"></b-icon>
                            </b-button>
                        </b-button-group>
                </span>
            </template>

            <div slot="table-actions" style="width: 100%">
                <b-button-group>
                    <b-button
                        @click="toggleModal('modal-mo-batch-routing')"
                        variant="outline-primary"
                        size="sm">
                        <b-icon icon="layers"></b-icon> Batch routing
                    </b-button>
                </b-button-group>
            </div>

            <div slot="table-actions-bottom">
                <h6 class="m-1" style="float:left">
                    Row count: {{tableDisplayData.length}}  
                </h6>
            </div>
        </vue-good-table>
    </div>
</template>

<script>
export default {
    props:['routing'],
    data(){
        return {
            tableDisplayData: [],
            fields: [
                {   
                    label: 'UUID',
                    field: 'mo_client_uuid',
                    width: '150px',
                    filterOptions: {
                        enabled: true,
                         placeholder: 'Filter UUID'
                    }
                },
                {   
                    label: 'ShID',
                    field: 'mo_receiver',
                    width: '100px',
                    filterOptions: {
                        enabled: true,
                         placeholder: 'Filter To'
                    }
                },
                {   
                    label: 'Keyword',
                    field: 'mo_key_word',
                    width: '100px',
                    filterOptions: {
                        enabled: true,
                         placeholder: 'Filter Keyword'
                    }
                },
                {   
                    label: 'Country',
                    field: 'mo_cc_code',
                    width: '100px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Country'
                    }
                },
                {   
                    label: 'Callback',
                    field: 'mo_callback_uri',
                    width: '150px',
                    filterOptions: {
                        enabled: true,
                         placeholder: 'Filter Callback'
                    }
                },
                {   
                    label: 'Created',
                    field: 'createdAt',
                    width: '150px'
                },
                {   
                    label: 'updatedAt',
                    field: 'updatedAt',
                    width: '150px'
                },
                {   
                    label: 'options',
                    field: 'options',
                    width: '300px'
                },

            ],
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5,10,100, 4000],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },

        }
    },
    methods:{
        toggleModal(modalId){
            this.$emit('toggleModal', modalId)
        },
        onSelectAll(){},
        selectionChanged(){},
        onColumnFilter(){},
        async deleteRow(rowProps){
            
            let res = await this.$http.post('/api/routing/smsc/mo/delete', rowProps.row)
            if(res && res.status == 200){
                this.$parent.deleteRow(rowProps.index)
            }
            
        }
    }
}
</script>

<style>
    .routing-managment-wrapper .vgt-global-search__input{
        display: none !important;
    }
</style>