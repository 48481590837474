<template>
  <b-modal    
    
    id="single-routing-modal" 
    ref="single-routing-modal"
    size="xl"
    title="Add routing"
    @hidden="resetModal"
    @show="resetModal"
    @ok="handleOk"
    >     
      <b-form ref="form">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                  style="text-align:left"
                  content-cols-lg="12"
                  description="The accounts of the routing"
                  label="Enter accounts"
                  label-for="cc-list-id-input">
                    <multiselect 
                      :max-height="150"
                      v-model="localApiKeyTokens" 
                      :allow-empty="true"
                      :multiple="true" 
                      :searchable="true"
                      label="text" 
                      track-by="value"
                      :options="apiKeyTokens"
                      @input="/** */">
                      </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  style="text-align:left"
                  content-cols-lg="12"
                  description="The country of the routing"
                  label="Enter country"
                  label-for="cc-list-id-input">

                    <multiselect 
                    required
                    :max-height="150"
                    v-model="countries" 
                    :allow-empty="true"
                    :multiple="true" 
                    :searchable="true"
                    label="label" 
                    track-by="value"
                    @remove="removeCountry"
                    :options="countriesList"
                    @input="getOperatorsList()">
                    </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  style="text-align:left"
                  content-cols-lg="12"
                  description="The operator of the routing"
                  label="Enter operator"
                  label-for="op-list-id-input">

                    <multiselect 
                      required
                      :class="'multiselect-local'"
                      :max-height="350"
                      v-model="localRouting" 
                      :options="operatorsList"
                      :custom-label="customLabelOps"
                      :multiple="true" 
                      group-values="ops" 
                      group-label="country" 
                      :group-select="true"                
                      label="operator" 
                      track-by="mccmnc"
                      @input="/*getRoutes()*/"
                    ></multiselect>


                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                    style="text-align:left"
                    content-cols-lg="12"
                    description="The routing"
                    label="Route"
                    label-for="rt-list-id-input"> 

                    <multiselect 
                    required
                    v-model="submit.route" 
                    @input="/*routingSelected*/"
                    :custom-label="customLabelRoutes"
                    :searchable="true"
                    label="providers_accounts_account_alias" 
                    track-by="providers_accounts_id"
                    :options="providers/*routingList*/">
                    </multiselect>

                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-checkbox
                  @input="/*markUpFromCostInput*/"
                  style="width:fit-content"
                  id="checkbox-2"
                  v-model="submit.isMarkUp"
                  name="checkbox-2"
                  value="true"
                  unchecked-value="false">
                  <p class="text-primary ">
                            &nbsp;&nbsp;Mark up from cost</p>
                  
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                  <b-form-checkbox
                      style="width:fit-content"
                      id="checkbox-1"
                      v-model="submit.setAsDefault"
                      name="checkbox-1"
                      value="true"
                      unchecked-value="false">
                      <p class="text-primary ">
                          &nbsp;&nbsp;Set route as defualt for selected countries</p>
                  </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                  <b-form-group
                    style="text-align:left"
                    content-cols-lg="12"
                    description="Sell price"
                    label="Sell price"
                    label-for="rt-list-id-input"> 
                      <b-form-input
                          placeholder="Sell price"
                          :required="!(submit.isMarkUp === 'true')"
                          :disabled="submit.isMarkUp === 'true'"
                          v-model="submit.sell"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
            </b-row>
            <b-row>
              <b-col>
                    <b-form-group
                      style="text-align:left"
                      content-cols-lg="12"
                      description="Mark up value"
                      label="Mark up"
                      label-for="rt-list-id-input"> 
                      <b-form-input
                          :required="submit.isMarkUp === 'true'"
                          type="number"
                          step="0.0001"
                          placeholder="Mark up"
                          :disabled="!(submit.isMarkUp === 'true')"
                          v-model="submit.markUp"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                    style="text-align:left"
                    label="Cost"
                    description="Set cost"
                    invalid-feedback="Maximum 5 floating point number"
                    content-cols-lg="12"

                    label-for="rt-list-id-input"> 
                    <b-form-input
                        placeholder="Cost"
                        type="number"
                        :state="/^[0-9]+\.[0-9]{1,5}$/.test(submit.cost)"
                        step="0.00001"
                        v-model="submit.cost"
                    ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  style="text-align:left"
                  content-cols-lg="12"
                  description="Sell currency"
                  label="Sell currency"
                  label-for="rt-list-id-input"> 
                    <b-form-input
                        placeholder="Sell currency"
                        v-model="sellCurrency"
                    ></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  style="text-align:left"
                  content-cols-lg="12"
                  description="Cost currency"
                  label="Cost currency"
                  label-for="rt-list-id-input"> 
                    <b-form-input
                        placeholder="Cost currency"
                        v-model="costCurrency"
                    ></b-form-input>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                    <b-form-group
                    :invalid-feedback="'Please enter a valid number'"
                    style="text-align:left"
                    label="Fixed sender">
                      <b-form-input
                        :state="/^[0-9]+$/.test(fixedSenderNumber)"
                        v-model="submit.fixedSender"
                        placeholder="Fixed sender">

                      </b-form-input>
                    </b-form-group>
                  </b-col>
            </b-row>
            <b-row>
               <b-col>
                    <b-form-group
                    style="text-align:left"
                    label="Custom routing rule">
                      <multiselect 
                        :max-height="150"
                         v-model="submit.customRule"  
                        :allow-empty="false"
                        :multiple="false" 
                        :searchable="true"
                        label="text" 
                        track-by="value"
                        :options="customRules">
                        </multiselect>
                    </b-form-group>
                  </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
      <!--
      <template #modal-footer="{ ok, cancel }">

      <b-button size="sm" variant="outline-secondary" @click="resetModal();">
        Reset
      </b-button> 
      <b-button size="sm" variant="warning" @click="resetModal();cancel()">
        Cancel
      </b-button>
       <b-button size="sm" variant="success" @click="handleOk">
        OK
      </b-button>
      
      
      </template>
      -->
  </b-modal>

</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
const list = countries.getNames('en', { select: 'official' })
const mcc_mnc_list = require('../../../json/newmccmnc.json');
//console.log(mcc_mnc_list)

export default {
  components: {
    Multiselect
  },
  props: ['providers', 'apiKeyTokens'],
  created(){
    
  },
  data(){
    return {
      
      countriesList: Object.keys(list).map((key) => ({ value: key, label: list[key] })),
      operatorsList: [],
      routingList: this.providers,
      countries: [],
      operators: [],
      routing: null,
      markUpFromCost: false,
      fixedPriceValue: null,
      markUpFromCostValue: null,
      setAsDefault:false,
      fixedSenderNumber: null,
      customRules: this.$customRules,
      customRule: '',

      localApiKeyTokens: [],
      localRouting: [],
      sellCurrency: 'EUR',
      costCurrency: 'EUR',
      submit: {
          uuids: [],
          apiKeyTokens: [],
          route: null,
          routing: [],
          setAsDefault: false,
          sell: null,
          isMarkUp: null,
          markUp: null,
          cost: null,
          fixedSender: null,
          customRule: null
      },
      
    }
  },
  methods: {
    removeCountry(cc){
      console.log(cc)

    },
    async getRoutes(){
       let res = await this.$http.post('/api/routing/smsc/routes/read', {
        routing_countries: this.countries.map(v=>{return v.value})
      })

      if(res && res.status == 200) {
        console.log(res.data)
        //this.routingList = res.data
      }
                
    },
    digestBeforeSubmit(){
      for(const i in this.localApiKeyTokens){
        for(const j in this.localRouting){
          this.submit.routing.push({

            routing_account_uuid: this.localApiKeyTokens[i].uuid,
            routing_account: this.localApiKeyTokens[i].value,
            routing_mcc: this.localRouting[j].mcc,
            routing_mnc: this.localRouting[j].mnc,
            routing_operator_name: this.localRouting[j].operator,
            routing_country_name: this.localRouting[j].countryName,
            routing_country: this.localRouting[j].countryCode,
            routing_sell_currency: this.sellCurrency,
            routing_cost_currency: this.costCurrency,

          })
        }
      }
    },
    async handleOk(event){
      event.preventDefault()
      

      const valid = this.$refs.form.checkValidity() && 
        !!+this.localApiKeyTokens.length  && 
        !!+this.localRouting.length && 
        ( typeof this.submit.route === 'object' && this.submit.route !== null )

      
      if(!valid){
        alert(`Valid: ${valid}`)
        return
      }
      
      this.digestBeforeSubmit()
            
      let res = await this.$http.post('/api/routing/token/update', this.submit)
      if(res && res.status == 200){

        this.$emit('done', 'single-routing-modal')
      }
      
            

    },
    resetModal(){
      this.operatorsList = []
      this.routingList = []
      this.countries = []
      this.operators = []
      this.routing = null
      this.markUpFromCost =  false
      this.fixedPriceValue =  null
      this.markUpFromCostValue =  null
      this.setAsDefault = false
      this.fixedSenderNumber = null
      this.customRule = ''
      
      this.localApiKeyTokens = []
      this.localRouting = []
      this.submit = {
          uuids: [],
          apiKeyTokens: [],
          route: null,
          routing: [],
          setAsDefault: false,
          sell: null,
          isMarkUp: null,
          markUp: null,
          cost: null,
          fixedSender: null,
          customRule: null
      }
    },
    markUpFromCostInput(input){
    
      
    
      input = (input === 'true');
      if(this.routing){
        
        if(input){
          this.fixedPriceValue = null
          this.markUpFromCostValue = Number(this.routing.routes_route_mark_up)  
        }else{
          
          this.markUpFromCostValue = null
          this.fixedPriceValue = Number(this.routing.routes_route_cost) + Number(this.routing.routes_route_mark_up)  
        }
      }
    
    },
    getCustomLabel(item){
      return item.routing_account
    },
    customLabelOps(item){
      return `${item.operator} (${item.mcc}${item.mnc})`
    },
    customLabelRoutes(item){
      //return `${item.routes_route_cost},${item.routes_route_mark_up} - ${item.route_account_alias} (${item.routes_provider})`  
      return `${item.providers_accounts_provider_id} - ${item.providers_accounts_account_alias} (${item.providers_accounts_id})`  
    },
    routingSelected(item){
    
      if(this.markUpFromCost && item){
        this.markUpFromCostValue = Number(item.routes_route_mark_up)
      }else if(item){
        this.fixedPriceValue = Number(item.routes_route_cost) + Number(item.routes_route_mark_up)
      }
    
    },
    getOperatorsList(){
        
        this.operatorsList = []

        let countyCodeKeys = this.countries.reduce((acc, item)=>{ 
            acc[item.value] = item
            return acc
        },{})

        
        Object.keys(countyCodeKeys).forEach(cCode=>{
            let ops = mcc_mnc_list.filter(obj=>{

                if(obj.countryCode == cCode){
                    obj['mccmnc'] = `${obj.mcc}${obj.mnc}`
                    return obj
                }
            })
            this.operatorsList.push({
                country:  countyCodeKeys[cCode]['label'],
                ops: ops
            })
          
        })
        

    }
    
  }
}
</script>

<style>
.modal-xl {
    --bs-modal-width: 1000px !important;
}

@media (min-width: 1200px){
    .modal-xl {
        --bs-modal-width: 1300px !important;
    }
}

.multiselect-local .multiselect__tags{
  min-height:200px;
  max-height:300px;
  overflow-y: scroll;
}

</style>