<template>
<div>
  <b-row>
        <b-col lg="12" sm="12" md="12">
          <b-card>
            <b-card-header >
              Statistics
            </b-card-header>
            <b-overlay :show="Loading" rounded="sm">
              <b-card-body>
                
                <vue-good-table
                theme="polar-bear"
                :columns="Fields" 
                :rows="rows" 
                :line-numbers="true"
                :pagination-options="paginationOptions"
                @on-sort-change="onSortChange"
                :sort-options="{
                  enabled: true,
                  initialSortBy: [
                    {
                      field: 'total_parts', 
                      type: 'desc'
                    },
                    {
                      field: 'total_sent', 
                      type: 'desc'
                    }
                  ]
                }"
                :search-options="searchOptions">

                <div slot="table-actions">
                  <b-input-group>

                    <b-input-group-prepend  >
                      <b-input-group-text style="border-top-right-radius: 0; border-bottom-right-radius: 0;color:black">
                        Group by: 
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <!--
                    <b-form-select 
                      style="width:150px !important"
                      :class="'form-control'"
                      v-model="groupBy"
                      :options="groupByOptions"
                      required
                      @change="groupByChanged">
                    </b-form-select>
                    -->
                    <b-form-select required @change="groupByChanged" v-model="groupBy" :class="'form-control'" style="width:150px !important">
                      <b-form-select-option value="send_sms_mt_country_name">Country</b-form-select-option>
                      <b-form-select-option value="send_sms_mt_kannel_date">Date</b-form-select-option>
                      <b-form-select-option value="send_sms_mt_operator_name">Operator</b-form-select-option>
                      <b-form-select-option value="send_sms_account">Account</b-form-select-option>
                      <b-form-select-option value="send_sms_route">Route</b-form-select-option>
                    </b-form-select>

                    <b-input-group-append>
                      
                      <download-csv 
                          @export-started="exported"
                          @export-finished="exported"
                          
                          :data = "FilteredtData">
                      <b-button variant="primary" style="border-top-left-radius: 0; border-bottom-left-radius: 0;color:white" :disabled="!FilteredtData.length">
                        <b-icon icon="file-earmark-arrow-down"></b-icon> Download CSV
                      </b-button>
                    </download-csv>
                    </b-input-group-append>
                  </b-input-group>

                  

                    
                </div>

                <template slot="table-row" slot-scope="props">
                  <!--
                  <span v-if="props.column.field == 'total_cost_local'">
                    {{ Number(props.row.total_cost) + Number(props.row.total_hlr_cost) }}
                  </span>
                  <span v-else-if="props.column.field == 'total_profit'">
                    {{ ( Number(props.row.total_sell) - Number(props.row.total_cost) + Number(props.row.total_hlr_cost) ).toFixed(5) }}
                  </span>
                  -->

                  <span v-if="props.column.field == 'total_cost_local'">
                    {{  ( Number(props.row.total_cost) + Number(props.row.total_hlr_cost) ).toFixed(4)}}
                  </span>

                  <span v-else-if="props.column.field == 'group_by_field'">
                    {{ ( props.row.group_by_field ? props.row.group_by_field : 'N/A' ) }}
                  </span>

                  <span v-else-if="props.column.field == 'total_cost'">
                    {{ Number(props.row.total_cost).toFixed(4) }}
                  </span>


                  <span v-else-if="props.column.field == 'total_hlr_cost'">
                    {{ ( props.row.total_hlr_cost ? Number(props.row.total_hlr_cost) : 0 ).toFixed(4) }}
                  </span>

                  <span v-else-if="props.column.field == 'total_sell'">
                    {{ Number(props.row.total_sell).toFixed(4) }}
                  </span>
                  
                  
                  <span v-else-if="props.column.field == 'total_profit'">
                    {{ ( Number(props.row.total_sell) - Number(props.row.total_cost) + Number(props.row.total_hlr_cost) ).toFixed(4) }}
                  </span>
                </template>

              </vue-good-table>
              </b-card-body>
            </b-overlay>
          </b-card>
        </b-col>
  </b-row>
  
</div>  
</template>

<script>
//import BaseCard from '@/components/Base/card.base.vue'


export default {
  components: {
    //BaseCard
  },
  props:['FilteredtData', 'Loading', 'Fields'],
  watch:{
    //'FilteredtData' : function(dataFromServer) {
      
      //console.log(dataFromServer)
      //this.allData = dataFromServer
      //this.tblData = this.allData[this.groupBy]
    //},
    'Loading': function(value){
      //console.log(value)
      this.isLoading = value
    },
    'FilteredtData' : function() {
      this.rows = this.FilteredtData
    }
    /*
    'FilteredtData' : function(newVal) {
      this.isLoading = true
      this.$http.post('/api/analytics/sms',newVal)
      .then((res)=>{
        console.log(res.data)
        this.tblData = res.data['byDay']
        this.countryTblCountry = res.data['byCountry']
        this.mccmncTbl = res.data['byMccMnc']
        this.isLoading = false
      })
    }
    */
  },
  created () {
    //this.fetchData()
  },
  methods: {
    //onSortChange(params){
      onSortChange(){
      //console.log(params)
      
      //this.rows.sort(function(a, b){return a['total_delivered'] - b['total_delivered']})
      /*
      this.rows.sort(function(a, b){
        if(params[0].type == "none"){
          return b[params[0].field] - a[params[0].field]
        }else if(params[0].type == "asc"){
          return b[params[0].field] - a[params[0].field]
        }else{
          return a[params[0].field] - b[params[0].field]
        }
        
      })
      */
    
      

    },
    exported() {
    //exported(event) {
      //console.log(event)

    },
    csvError(val){
      console.log(val)
    },
    groupByChanged(){
      this.$emit('groupBy', this.groupBy)
      /*
      this.tblData = this.allData[this.groupBy]
      this.fields[0].label = this.firstColOptions[this.groupBy].label
      this.fields[0].field = this.firstColOptions[this.groupBy].field
      */
    },
    getFields(){
      
    },
    fetchData () {
      /*
      axios({url: '/api/dashboard/read', method: 'GET' })
      .then(resp => {
        this.isLoading = false
        this.data = resp.data
        console.log(this.data);
        
      })
      .catch(err => {
          
          console.log(err)
      })
      */
    }
  },


  data(){
    return {
      rows:[],
      groupBy: 'send_sms_mt_country_name',
      groupByOptions:[
        /*
        'byDay',
        'byCountry',
        'byOperator'
        */
       'day',
       'country',
       'operator',
       ''
      ],
      firstColOptions: {
        'byDay': {label : 'Date', field: 'date'},
        'byCountry': {label : 'Country', field: 'country_name'},
        'byOperator': {label : 'Operator', field: 'operator_name'}
      },
      isLoading: false,
      mccmncPerPage:10,
      mccmncCurrentPage:1,
      mccmncFilter:null,
      mccmncPageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      countryPageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      ccSortBy: 'total_sent',
      //sortDesc: false,
      perPage: 30,
      countryCurrentPage:1,
      opCurrentPage:1,
      countryPerPage:10,
      opPerPage:10,
      currentPage: 1,
      testPerPage: 10,
      testCurrentPage: 1,
      testFilter: null,
      countryFilter: null,
      filter: null,
      tblData: [],
      allData: [],
      mccmncTbl: [],
      groupOptions: {
        enabled: true,
        collapsable: true
      },
      searchOptions: {
          enabled: false,
          placeholder: 'Search this table'
      },
      selectOptions: { enabled: false },
      paginationOptions: {
          enabled: true,
          mode: 'records',
          perPage: 5,
          position: 'bottom',
          perPageDropdown: [5,10,100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          jumpFirstOrLast : true,
          firstLabel : 'First Page',
          lastLabel : 'Last Page',
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
          infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.custom-select{
  position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1% !important;
    min-width: 0;
    margin-bottom: 0;
}

.dashbaord{
  text-align:left;
}

.input-group-prepend, .input-group-append{
  display: flex;
    margin-right: -1px;
    box-sizing: border-box;
    border-top-right-radius: 0 !important;
}
</style>
