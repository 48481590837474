<template>
  <b-container>
    <b-row>
        <b-col>
            <b-form-group
                style="text-align:left"
                label="Company name:"
                description="Bill to company name">
                    <b-form-input
                        :disabled="disabled"
                        v-model="form.name"
                        :state="form.name != '' && form.name != null"
                        type="text"
                        placeholder="Company name"
                        required>
                    </b-form-input>
                <b-form-invalid-feedback id="name">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
                style="text-align:left"
                label="Company city:"
                description="Bill to company city">
                    <b-form-input
                        :disabled="disabled"
                        v-model="form.city"
                        :state="form.city!='' && form.city!=null"
                        type="text"
                        placeholder="Company city"
                        required>
                    </b-form-input>
                <b-form-invalid-feedback id="city">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
                style="text-align:left"
                label="Wave ID:"
                description="Wave customer id">
                <b-form-input
                    :disabled="disabled"
                    v-model="form.waveClientId"
                    :state="form.waveClientId!='' && form.waveClientId!=null"
                    type="text"
                    placeholder="Company address"
                    required>
                </b-form-input>
                <b-form-invalid-feedback id="address">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="9">
            <b-form-group
                style="text-align:left"
                label="Company address:"
                description="Bill to company address">
                <b-form-input
                    :disabled="disabled"
                    v-model="form.address"
                    :state="form.address!='' && form.address!=null"
                    type="text"
                    placeholder="Company address"
                    required>
                </b-form-input>
                <b-form-invalid-feedback id="address">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>

        <b-col>
            <b-form-group
            style="text-align:left"
            label="Company country:"
            description="Bill to company country">
                <b-form-input
                :disabled="disabled"
                v-model="form.country"
                :state="form.country!='' && form.country!=null"
                type="text"
                placeholder="Company country"
                required
                ></b-form-input>
                <b-form-invalid-feedback id="country">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <b-form-group
                style="text-align:left"
                label="Company vat:"
                description="Bill to company vat">
                <b-form-input
                    :disabled="disabled"
                    id="input-6"
                    v-model="form.vat"
                    :state="form.vat!='' && form.vat!=null"
                    type="text"
                    placeholder="Company vat">
                </b-form-input>
                <b-form-invalid-feedback id="vat">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
        <b-col >
            <b-form-group
                style="text-align:left"
                label="Company tax id:"
                description="Bill to company tax id">
                <b-form-input
                    :disabled="disabled"
                    v-model="form.tin"
                    :state="form.tin!='' && form.tin!=null"
                    type="text"
                    placeholder="Company tax id"
                    required>
                </b-form-input>
                <b-form-invalid-feedback id="tin">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group
                style="text-align:left"
                label="Company zip:"
                description="Bill to company zip">
                <b-form-input
                    :disabled="disabled"
                    v-model="form.zip"
                    :state="form.zip!='' && form.zip!=null"
                    type="text"
                    placeholder="Company zip"
                    required>
                </b-form-input>
                <b-form-invalid-feedback id="proforma_invoice_company_zip">This is a required field.</b-form-invalid-feedback>
            </b-form-group>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
    props: ['value', 'disabled'],
    data(){
        return {
            
        }
    },
    computed: {
        form: {
            get(){
                return this.value
            },
            set(newValue){
                this.$emit('input', newValue);
            }
        }
    }
}
</script>

<style>

</style>