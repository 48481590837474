import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store/store.js'
//import Axios from 'axios'
import api from './api'
import './prototypes';

import VueProgressBar from 'vue-progressbar'

import { BootstrapVue, IconsPlugin, AlertPlugin } from 'bootstrap-vue'

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

import JsonCSV from 'vue-json-csv'
Vue.component('downloadCsv', JsonCSV)

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);

import 'vue-good-table/dist/vue-good-table.css'
import VueGoodTablePlugin from 'vue-good-table';
Vue.use(VueGoodTablePlugin);


Vue.use(AlertPlugin)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px'
})


Vue.config.productionTip = false

Vue.prototype.$http = api.axios;


const token = localStorage.getItem('token')

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = `${token}`
  
}

const app = new Vue({
  store,
  router,
  render: h => h(App),
})

app.$mount('#app')


