<template>
  
    <base-card>
        <template #header>
            Client billing
        </template>
        <div slot="body">
            <Table :rows="data"/>
        </div>
    </base-card>
</template>

<script>
import BaseCard from '../../Base/card.base.vue'
import Table from './Table.vue'
export default {
    components: {
        BaseCard,
        Table
    },
    data(){
        return {
            data: []
        }
    },
    created () {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            const res = await this.$http.get('/api/payment/billing/all')
            if(res && res.status == 200){
                this.data = res.data
            }
        }
    }
}
</script>

<style>

</style>