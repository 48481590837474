var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{style:(_vm.styles),attrs:{"no-body":"","border-variant":"info","header-bg-variant":"light","header-text-variant":"primary"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-icon',{staticStyle:{"display":"inline-block"},attrs:{"icon":_vm.icon}}),_vm._v("   "),_c('h6',{staticClass:"mb-0",staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.header))])]},proxy:true}])},[_c('b-card-body',[_c('b-row',[_c('b-col',[(_vm.data)?_c('b-card-text',{staticClass:"p-3"},[_c('span',{staticClass:"text-success",staticStyle:{"font-size":"28px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.data.datasets[0].data[0]))]),_c('small',{staticClass:"text-danger",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.data.datasets[0].data[1]))]),_c('br'),(_vm.data.datasets[0].data[0])?_c('small',{staticClass:"text-primary",staticStyle:{"font-weight":"bold"}},[_vm._v(" Queued "+_vm._s((_vm.data.datasets[0].data[1] / _vm.data.datasets[0].data[0]))+"% ")]):_c('small',{staticClass:"text-primary",staticStyle:{"font-weight":"bold"}},[_vm._v(" Queued 100% ")])]):_vm._e()],1),_c('b-col',[_c('DoughnutChart',{attrs:{"data":_vm.data,"styles":{
                          width: '75px', 
                          height: '75px', 
                          'margin-left': 'auto',
                          'margin-right': 'auto',
                          'margin-top': '10px',
                          
                      }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }