var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-width":"1800px"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12","md":"12"}},[_c('b-card',[_c('b-card-header',[_vm._v(" MO Statistics ")]),_c('b-overlay',{attrs:{"show":_vm.Loading,"rounded":"sm"}},[_c('b-card-body',[_c('vue-good-table',{attrs:{"theme":"polar-bear","columns":_vm.fields,"rows":_vm.rows,"line-numbers":true,"pagination-options":_vm.paginationOptions,"sort-options":{
                  enabled: true,
                  initialSortBy: [
                    {
                      field: 'mo_created_ts', 
                      type: 'desc'
                    }
                  ]
                },"search-options":_vm.searchOptions}},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"})])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }