<template>
  <b-card          
    no-body
    :style="styles"
    :border-variant="data && data.kannelSystemInfo.status.match(/running/g) ? 'success' : 'info'" 
    header-bg-variant="light"
    header-text-variant="primary">
        <template #header>
            <b-icon style="display:inline-block" :icon="icon" v-bind:class="{ 'mb-0': true, 'text-success': data && data.kannelSystemInfo.status.match(/running/g) }"></b-icon> &nbsp;
            <h6 style="display:inline-block" v-bind:class="{ 'mb-0': true, 'text-success': data && data.kannelSystemInfo.status.match(/running/g) }">{{header}}</h6>
        </template>
        <b-card-body>
             
            <b-row>
                <b-col>
                    
                    <b-card-text class="p-3">

                        <small v-if="data">{{ data.kannelSystemInfo.status.split(',')[1]}}</small>
                        <small v-else><b-icon icon="question-circle"></b-icon></small>
                        <p v-if="bbox" v-bind:class="{ 'mb-1': true, 'text-success': data && data.kannelSystemInfo.status.match(/running/g) }">
                            <b>{{`${bbox.tag} is ${data ? data.kannelSystemInfo.status.split(',')[0] : 'stopped'}`}}</b>
                        </p>
                        <p class="mb-1" v-else>{{noData}}</p>
                        <small v-if="data" class="text-muted">{{ data.kannelSystemInfo.version }}</small>
                        <small class="text-muted" v-else>{{noData}}</small>
                    </b-card-text>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
export default {
    data(){
        return {
            noData: 'No data to show'
        }
    },
    props: {
        icon: {
            type: String,
            default: () => { return 'question-circle' }
        },
        data:{
            type: Object
        },
        bbox:{
            type: Object
        },
        header: {
            type: String
        },
        styles: {
            type: Object
        }
    }
}
</script>

<style>

</style>