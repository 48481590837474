<template>
  <b-navbar id="sindNavBar">
    <b-navbar-nav>
        <b-tabs content-class="mt-3" class="w-100">
            <b-tab title="Template Settings" active>
                <template slot="title">
                    <b-iconstack font-scale="1.5">
                        <b-icon stacked icon="file" ></b-icon>
                        <b-icon stacked icon="gear-fill" scale="0.50" shift-v="0" shift-h="0"></b-icon>
                    </b-iconstack>
                </template>
                <b-nav-item href="#" style="height:780px;" class="p-3">
                    <b-form-group
                        class="mt-3"
                        label="Template name"
                        description="Provide a meaningful template name">
                        <b-form-input
                            @input="$emit('templateName', templateName)"
                            v-model="templateName"
                            type="text"
                            placeholder="Enter template name"
                            required
                            :disabled="changesMade"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        class="mt-3"
                        label="Template category"
                        description="Which category the teplate belongs to">

                        <b-form-select v-model="templateCategory" class="w-100"  @input="$emit('templateCategory', templateCategory)">
                        <b-form-select-option :value="null">Please select an option</b-form-select-option>
                        <b-form-select-option value="MAINTENANCE">MAINTENANCE</b-form-select-option>
                        <b-form-select-option value="PRODUCT">PRODUCT</b-form-select-option>
                        <b-form-select-option value="PRICING">PRICING</b-form-select-option>
                        <b-form-select-option value="BALANCE">BALANCE</b-form-select-option>
                        </b-form-select>

                    </b-form-group>
                </b-nav-item>
            </b-tab>
            <b-tab title="Settings" >
                 <template slot="title">
                    <b-icon icon="gear"></b-icon>
                </template>
                <div class="traitsWrapper">
                    <div id="traits"></div>
                </div>
            </b-tab>
            <b-tab title="Modules" >
                 <template slot="title">
                    <b-icon icon="columns"></b-icon>
                </template>
                <div class="blocksWrapper">
                     <div id="blocks"></div>
                </div>
            </b-tab>
            <b-tab title="Style" >
                 <template slot="title">
                    <b-icon icon="brush"></b-icon>
                </template>
                
                
                <div class="stylesWrapper">
                    <div id="testing"></div>
                    <div id="styles"></div>
                </div>
            </b-tab>
            <b-tab title="Layers" >
                 <template slot="title">
                    <b-icon icon="layers"></b-icon>
                </template>
                <div class="layersWrapper">
                    <div id="layers"></div>
                </div>
            </b-tab>
            <b-tab title="Tags" >
                 <template slot="title">
                    <b-icon icon="braces"></b-icon>
                </template>
                <div class="macrosWrapper">
                    <div id="macros"></div>
                </div>
            </b-tab>
        </b-tabs>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
    data(){
        return {
            templateName: this.$parent.templateName, 
            changesMade: this.$parent.changesMade,
            templateCategory: this.$parent.templateCategory
        }
    }
}
</script>

<style >
#sindNavBar .navbar {
    height: calc(100% - 40px);
    align-items:baseline;
    overflow-y:hidden;
    --bs-navbar-padding-y: unset;
}

#sindNavBar .navbar-nav {
    height: 100%;
    width:100%;
    align-items:baseline;
    background:white;
    --bs-nav-link-padding-y: unset !important;
}

#sindNavBar .nav-tabs {
    --bs-nav-tabs-border-width: unset !important;
    border-bottom: 1px solid #F7E6C4;

}

#sindNavBar .nav-tabs .active{
    border-bottom: 2px solid #F1C376;
}

#sindNavBar .nav-tabs .nav-item{
    width: calc(100% / 6);
}

#sindNavBar .nav-tabs .nav-link{
    height:50px;
    color: #606C5D;
    margin:auto;
    display:block;
}

#sindNavBar .nav-tabs .active{
    color: #116A7B;
}


.stylesWrapper, .blocksWrapper, .layersWrapper {
    overflow-y: scroll;
    padding: 0.2rem;
}

 
.stylesWrapper, .layersWrapper, .blocksWrapper, .traitsWrapper, .macrosWrapper{
    height: 780px !important;
    max-height: unset !important;
}


</style>