<template>
<div>
  <b-row>
    <b-col lg="12" sm="12" md="12">
      <b-row>
        <b-col lg="12" sm="12" md="12">
          <base-card>
            <template #header>
                Lookup statistics
            </template>
            <div slot="body">
              
              <vue-good-table
              theme="polar-bear"
                :columns="table.fields" 
                :pagination-options="table.paginationOptions"
                :search-options="table.searchOptions"
                :rows="rows" 
                :line-numbers="true">

                <div slot="table-actions">
                  <!--
                  <b-form-select 
                    style="min-width:100% !important"
                    class="select"
                    v-model="groupBy"
                    :options="groupByOptions"
                    required
                    v-on:change="groupByChanged">
                  </b-form-select>
                  -->
                </div>

              </vue-good-table>
            </div>
          </base-card>
        </b-col>
      </b-row>
    </b-col>
   
  </b-row>
  
</div>  
</template>

<script>
import BaseCard from '@/components/Base/card.base.vue'
const table = require('./table')

export default {
  components: {
    //CircleIcon,
    BaseCard
    //ZapOffIcon,
    //ZapIcon
  },
  props:['FilteredtData', 'Loading', 'rows'],
  watch:{
    'FilteredtData' : function(dataFromServer) {
      this.serverData = dataFromServer
      //this.opTblData = dataFromServer['byOperator']
      //this.ccTblData = dataFromServer['byCountry']
      //this.dateTblData = dataFromServer['byDate']
      //this.providerTblData = dataFromServer['byProvider']
      
      
    },
    'Loading': function(value){
      //console.log(value)
      this.isLoading = value
    }

  },
  created () {
    //this.fetchData()
  },
  methods: {
    groupByChanged(){
      
    },
    fetchData () {
      /** */
    }
  },

  data(){
    return {
      table: table,
      groupBy: 'byCountry',
      groupByOptions:[
        'byDay',
        'byCountry',
        'byProvider'
      ],
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}



.dashbaord{
  text-align:left;
}
</style>
