<template>
  <b-form @submit.stop.prevent="() => onSubmit(tag, config, includes.filter(i => i), meta)">
    <b-row>

      <b-col cols="6">
        <b-card style="padding: 10px 10px 10px 10px">
          <b-row>
            <b-form-group style="text-align:left">
              <h6 style="color:green">{{this.params.uid}}</h6>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Tag:" label-for="input-tag" :invalid-feedback="'Tag is required'" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  v-model="tag"
                  id="input-tag"
                  placeholder="Required"
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  :state="tag.length > 0"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Connection System Type:" label-for="input-system-type" style="text-align:left">
                <!--
                <b-form-select
                  class="select"
                  id="input-system-type"
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  v-model="config['system-type']">
                      <b-form-select-option :value="null" disabled>Select System Type</b-form-select-option>
                      <b-form-select-option value="generic">Generic - use for APIs</b-form-select-option>
                      <b-form-select-option value="kannel">Kannel - use for relay ONLY!</b-form-select-option>
                </b-form-select>
              </b-form-group>
              -->
              <b-form-input
                  size="sm"
                  v-model="config['system-type']"
                  id="input-system-type"
                  placeholder="Optional"
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  :state="tag.length > 0"
                />
              </b-form-group>
            </b-col>
          </b-row>
           <b-row>
              <b-col>
                <b-form-group label="Round Robin:" label-for="input-round-robin" style="text-align:left">
                    <b-form-select
                      class="select"
                      id="input-round-robin"
                      :disabled="this.params.uid !== null && this.params.uid !== undefined"
                      v-model="meta['roundRobin']"
                      required>
                          <b-form-select-option :value="null" disabled>Select Round Robin</b-form-select-option>
                          <b-form-select-option value="rrobin">Yes</b-form-select-option>
                          <b-form-select-option :value="false">No</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                  
              </b-col>
              <b-col>
                  <b-form-group v-if="meta.roundRobin == 'rrobin'" label="Prefered SMSC id:" label-for="input-preferred-smsc-id" style="text-align:left">
                    <b-form-select
                      class="select"
                      id="input-preferred-smsc-id"
                      :disabled="this.params.uid !== null && this.params.uid !== undefined"
                      v-model="config['preferred-smsc-id']"
                      @change="getSmscId"
                      required>
                        <b-form-select-option :value="null" disabled>Select SMSC Id</b-form-select-option>
                        <b-form-select-option v-for="(item, index) in accounts" :key="index" :value="item.account">{{item.account}}</b-form-select-option>
                      </b-form-select>
                  </b-form-group>
                  <b-form-group v-else label="SMSC id:" label-for="input-smsc" style="text-align:left">
                    <b-form-select
                      class="select"
                      id="input-smsc"
                      :disabled="this.params.uid !== null && this.params.uid !== undefined"
                      v-model="config['smsc-id']"
                      @change="getSmscId"
                      required>
                        <b-form-select-option :value="null" disabled>Select SMSC Id</b-form-select-option>
                        <b-form-select-option v-for="(item, index) in accounts" :key="index" :value="item.account">{{item.account}}</b-form-select-option>
                        </b-form-select>
                  </b-form-group>
                </b-col>
              
            </b-row>
          <b-row>
            <b-col >
              <b-form-group label="SMS Center Type:" label-for="input-smsc-type" style="text-align:left">
                <b-form-select
                  class="select"
                  id="input-smsc-type"
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  v-model="config['smsc']"
                  required>
                    <b-form-select-option :value="null" disabled>Select SMSC Id</b-form-select-option>
                    <b-form-select-option value="smpp">SMPP</b-form-select-option>
                    <b-form-select-option value="http">HTTP</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
                <b-form-group 
                  v-if="config['smsc'] == 'smpp'"
                  label="Transceiver Mode:" 
                  :state="false"
                  :invalid-feedback="'Transceiver Mode is required'" 
                  style="text-align:left">
                  <b-form-select
                    style="width:100% !important;"
                    v-model="config['transceiver-mode']"
                    required>
                      <template #first>
                        <b-form-select-option :value="null" disabled>Transceiver mode</b-form-select-option>
                        <b-form-select-option :value="true" >Yes</b-form-select-option>
                        <b-form-select-option :value="false" >No</b-form-select-option>
                      </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            
           
          </b-row>
          

          <b-row>
            <b-col v-if="!meta['roundRobin']">
              <b-form-group label="Optional fixed Sender:" label-for="input-fixed-sender" :invalid-feedback="'Optional fixed Sender'" style="text-align:left">
                <b-form-input
                  size="sm"
                  autocomplete="nope"
                  placeholder="Optional fixed Sender"
                  id="input-fixed-sender"
                  v-model="meta.fixedSenderId"
                  :state="meta.fixedSenderId != null "
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="SMSC Username:" label-for="input-smsc-username" :invalid-feedback="'Username is Optional'" style="text-align:left">
                <b-form-input
                  size="sm"
                  autocomplete="nope"
                  placeholder="Optional"
                  id="input-smsc-username"
                  v-model="config['smsc-username']"
                  :state="config['smsc-username'].length > 0"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="SMSC Password:" label-for="input-smsc-password" :invalid-feedback="'Password is Optional'" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Optional"
                  id="input-smsc-password"
                  autocomplete="new-password"
                  v-model="config['smsc-password']"
                  :state="config['smsc-password'].length > 0"
                />
              </b-form-group>
            </b-col>
          </b-row>

         
          <div v-if="config['smsc'] === 'http'">
            <b-row >
              <b-col cols="8">
                <b-form-group label="Send URL:" label-for="input-send-url" :invalid-feedback="'Send URL is required'" style="text-align:left">
                  <b-form-input
                    required
                    size="sm"
                    id="input-send-url"
                    v-model="config['send-url']"
                    placeholder="Required"
                    :state="this.isValidUrl()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="4">
                <b-form-group label="Port:" label-for="input-port" :invalid-feedback="'Port for MO is Optional'" style="text-align:left">
                  <b-form-input                    
                    size="sm"
                    id="input-port"
                    v-model="config['port']"
                    placeholder="Port"
                    :state="/^[0-9]{1,10}$/.test(config['port'])"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row>
              <b-col cols="8">
                <b-form-group label="Host:" label-for="input-host" :invalid-feedback="'Host for the connection to SMSC is required'" style="text-align:left">
                  <b-form-input
                    required
                    size="sm"
                    id="input-host"
                    v-model="config['host']"
                    placeholder="Required"
                    :state="this.isValidHost()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="4">
                <b-form-group label="Port:" label-for="input-port" :invalid-feedback="'Port for the connection to SMSC is required'" style="text-align:left">
                  <b-form-input
                    required
                    size="sm"
                    id="input-port"
                    v-model="config['port']"
                    placeholder="Required"
                    :state="/^[0-9]{1,10}$/.test(config['port'])"
                  />
                </b-form-group>
              </b-col>
            </b-row>
           
            <b-row>
              <b-col>
                <b-form-group 
                  label="Source TON"
                  invalid-feedback="Source TON is required"
                  :state="false">

                  <b-form-select
                  style="width:100% !important;"
                  v-model="config['source-addr-ton']"
                  required>
                  <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                      <b-form-select-option  v-for="(item, index) in tons" :key="index"
                        :value="item.value">{{item.text}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
              </b-col>
              <b-col>
                <b-form-group 
                  label="Source NPI"
                  invalid-feedback="Source NPI is required"
                  :state="false">

                  <b-form-select
                  style="width:100% !important;"
                  v-model="config['source-addr-npi']"
                  required>
                  <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                      <b-form-select-option  v-for="(item, index) in npis" :key="index"
                        :value="item.value">{{item.text}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group 
                  label="Destination TON"
                  invalid-feedback="Destination TON is required"
                  :state="false">

                  <b-form-select
                  style="width:100% !important;"
                  v-model="config['dest-addr-ton']"
                  required>
                  <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                      <b-form-select-option  v-for="(item, index) in tons" :key="index"
                        :value="item.value">{{item.text}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
              </b-col>
              <b-col>
                <b-form-group 
                  label="Destination NPI"
                  invalid-feedback="Destination NPI is required"
                  :state="false">

                  <b-form-select
                  style="width:100% !important;"
                  v-model="config['dest-addr-npi']"
                  required>
                  <template #first>
                      <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                      <b-form-select-option  v-for="(item, index) in npis" :key="index"
                        :value="item.value">{{item.text}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
              </b-col>
            </b-row>
          </div>
         

          <b-row>
            <b-col>
              <b-form-group label-for="input-throughput" label="Connection throughput:" style="text-align:left">
                <b-form-input
                  trim
                  size="sm"
                  id="input-throughput"
                  placeholder="Default 50"
                  v-model="config['throughput']"
                />
              </b-form-group>

              <b-form-group label-for="input-max-sms-octets" label="Max Octates:" style="text-align:left">
                <b-form-input
                  trim
                  size="sm"
                  id="input-max-sms-octets"
                  placeholder="Default 500"
                  v-model="config['max-sms-octets']"
                />
              </b-form-group>

              <b-form-group label-for="input-connect-allow-ip" label="Connect allow IP range:" style="text-align:left">
                <b-form-input
                  trim
                  size="sm"
                  id="input-connect-allow-ip"
                  placeholder="Default *.*.*.*"
                  v-model="config['connect-allow-ip']"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-form-group style="text-align:left">
              <b-form-checkbox
                value=1
                unchecked-value=0
                v-model="config['reroute']"
                class="mt-2 mb-2 text-primary"
              >
                &nbsp; Internal reroute SMSC
              </b-form-checkbox>
            </b-form-group>
          </b-row>

          <div v-if="config['reroute'] === '1'">
            <h6>Reroute configurations</h6>

            <b-row>
              <b-col>
                <b-form-group style="text-align:left">
                  <b-form-checkbox 
                    value=1
                    unchecked-value=0
                    v-model="config['reroute-dlr']"
                    class="mt-2 mb-2 text-primary"
                  >
                    &nbsp; Internal reroute DLR
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group label-for="input-reroute-smsc-id" label="Reroute SMSC ID:" style="text-align:left">
                  <b-form-input
                    trim
                    required
                    size="sm"
                    id="input-reroute-smsc-id"
                    placeholder="Required in case of rerouting"
                    v-model="config['reroute-smsc-id']"
                    :state="config['reroute-smsc-id'] != null"
                  />
                </b-form-group>

                <b-form-group label-for="input-reroute-receiver" label="Kannel internal reroute receiver:" style="text-align:left">
                  <b-form-input
                    trim
                    size="sm"
                    id="input-reroute-receiver"
                    placeholder="Optional"
                    v-model="config['reroute-receiver']"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <div v-if="config['system-type'] === 'generic'">
            <h6>Generic System configurations</h6>

            <b-row>
              <b-col>
                <b-form-group label-for="input-generic-status-sent" label="Success HTTP status:" style="text-align:left">
                  <b-form-input
                    trim
                    required
                    size="sm"
                    id="input-generic-status-sent"
                    placeholder="Success HTTP status"
                    v-model="config['generic-status-sent']"
                    :state="config['generic-status-sent'] != null"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="input-generic-status-error" label="Fail HTTP status:" style="text-align:left">
                  <b-form-input
                    trim
                    required
                    size="sm"
                    id="input-generic-status-error"
                    placeholder="Fail HTTP status"
                    v-model="config['generic-status-error']"
                    :state="config['generic-status-error'] != null"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group label-for="input-status-success-regex" label="Success response regex:" style="text-align:left">
                  <b-form-input
                    trim
                    required
                    size="sm"
                    id="input-status-success-regex"
                    placeholder="Success response text"
                    v-model="config['status-success-regex']"
                    :state="config['status-success-regex'] != null"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="input-status-permfail-regex" label="Failed response regex:" style="text-align:left">
                  <b-form-input
                    trim
                    required
                    size="sm"
                    id="input-status-permfail-regex"
                    placeholder="Failed response text"
                    v-model="config['status-permfail-regex']"
                    :state="config['status-permfail-regex'] != null"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="input-status-tempfail-regex" label="Temp fail response regex:" style="text-align:left">
                  <b-form-input
                    trim
                    required
                    size="sm"
                    id="input-status-tempfail-regex"
                    placeholder="Temp fail response"
                    v-model="config['status-tempfail-regex']"
                    :state="config['status-tempfail-regex'] != null"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              
              <b-col cols="4">
                <b-form-group label-for="input-generic-foreign-id-regex" label="Generic Message-id regex:" style="text-align:left">
                  <b-form-input
                    trim
                    required
                    size="sm"
                    id="generic-foreign-id-regex"
                    placeholder="Temp fail response"
                    v-model="config['generic-foreign-id-regex']"
                    :state="config['generic-foreign-id-regex'] != null"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <b-row>
            <b-col cols="5">
              <b-form-group label="Log Level:" label-for="input-log-level" style="text-align:left">
                <b-form-select v-model="config['log-level']" size="xs" :options="[
                  { value: 0, text: 'Debug' },
                  { value: 1, text: 'Info' },
                  { value: 2, text: 'Warning' },
                  { value: 3, text: 'Error' },
                  { value: 4, text: 'Panic' }
                ]" />
              </b-form-group>
            </b-col>
          </b-row>

          <ConfigIncludes :includes="includes" />

          <br />

          <b-row>
            <b-button-group>
              <b-button variant="outline-danger" @click="() => onClose()">Close</b-button>
              <b-button type="submit" variant="outline-primary">Submit</b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <ConfigPreview :config="config" :includes="includes" />
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
  import ConfigPreview from '../../Common/Config/ConfigPreview.vue'
  import ConfigIncludes from '../../Common/Config/ConfigIncludes.vue'
  //import Tons from '../../Common/Json/ton.json'
  //import Npis from '../../Common/Json/npi.json'
  export default {
    components: {
      ConfigPreview,
      ConfigIncludes
    },
    props: ['params', 'onSubmit', 'onClose', 'accounts', 'getRoundRobinIndexForSmc'],

    data () {
      return {
        account: {},
        tag: '',
        config: {
          'group': 'smsc',
          'smsc': 'smpp',
          'smsc-id': null,
          'allowed-smsc-id': null,
          'preferred-smsc-id': null,
          'generic-foreign-id-regex': null,
          'smsc-username': '',
          'smsc-password': '',
          'system-type': '',
          'transceiver-mode': null,
          'throughput': 10,
          'dest-addr-ton': null,
          'dest-addr-npi': null,
          'source-addr-ton': null,
          'source-addr-npi': null,
          'log-level': 0
        },
        includes: [],
        meta: {
          roundRobin: null,
          fixedSenderId: null
        },
        npis: this.$npi,
        tons: this.$ton
      }
    },
    created () {
      this.tag = this.params.tag
      this.includes = this.params.includes
      this.meta = { ...this.meta, ...this.params.meta}
      this.config = { ...this.config, ...this.params.config }
    },
    methods: {
      toArray(item){
        const array = item ? item.split(',') : []
        return array
      },
      isValidHost(){
        /* eslint-disable */
        const ipv4Regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        const ipv6Regex = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
        const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-.\/?%&=]*)?$/i;
        return ipv4Regex.test(this.config['host']) || ipv6Regex.test(this.config['host']) || urlRegex.test(this.config['host'])
      },
      isValidUrl () {
        /* eslint-disable */
        const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:[\w-]+\.)+[a-z]{2,}(?:\/[\w-.\/?%&=]*)?$/i;
        return urlRegex.test(this.config['send-url'])
      },
      async getSmscId(){
        
        
        if(this.meta['roundRobin']){
          const nextIndex = await this.getRoundRobinIndexForSmc(this.config['preferred-smsc-id'])
          if(typeof nextIndex != undefined && nextIndex != null ){
            const nextSmscId = `${this.config['preferred-smsc-id']}_${nextIndex.nextId}`
            this.config['smsc-id'] = nextSmscId
            this.config['allowed-smsc-id'] = `"${nextSmscId};${this.config['preferred-smsc-id']}"`
          }    
        }else{
          this.config['allowed-smsc-id'] = this.config['smsc-id']
          this.config['preferred-smsc-id'] = null
          
        }
          
        
      }
    }
  }
</script>

<style>
  .row {
    margin: 10px
  }
</style>

