<template>
    <div>
        <b-row>
            <b-col cols="3">
                <b-form-group label="Pricing Type">
                    <b-form-select  
                        v-model="pricingType"
                        @change="typeSelected">
                        <b-select-option :value="null">Marketing & OTP</b-select-option>
                        <b-select-option value="MARKETING">MARKETING</b-select-option>
                        <b-select-option value="OTP">OTP</b-select-option>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-button-group>        
                    <b-button
                        v-b-modal.default-copy-from-account
                        variant="primary"
                        size="sm">
                        <b-icon icon="files"></b-icon> Copy Form Account
                    </b-button>
                    <b-button
                        v-b-modal.batch-routing-modal
                        variant="primary"
                        size="sm">
                        <b-icon icon="layers"></b-icon> Batch routing
                    </b-button>
                    <b-button
                        v-b-modal.copy-routing-modal                   
                        variant="warning"
                        size="sm">
                        <b-icon icon="collection"></b-icon> Set global routing
                    </b-button>
                    <b-button
                        @click="deleteRouting"
                        variant="danger"
                        size="sm">
                        <b-icon icon="trash"></b-icon> Delete
                    </b-button>
                    <b-button
                        @click="fetchDefaultRouting"
                        variant="primary"
                        size="sm">
                        <b-icon icon="arrow-counterclockwise"></b-icon> Refresh table
                    </b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12">
            <vue-good-table 
                theme="polar-bear"
                :columns="fields" 
                :rows="displayRowData" 
                :line-numbers="false"
                :pagination-options="paginationOptions"
                @on-selected-rows-change="selectionChanged"
                :search-options="searchOptions"
                :select-options="selectOptions">

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'select'">
                            <b-form-checkbox
                            :checked="props.row.bulk_action"
                            :value="true"
                            :unchecked-value="false"
                            @input="props.row.bulk_action=!props.row.bulk_action; addToBulkActions(props.row)">
                            </b-form-checkbox>
                        </span>
                        <span v-else-if="props.column.field == 'routes_random_originator'">
                            <b-icon v-if="props.row.routes_random_originator" icon="toggle-on" font-scale="1.5" ></b-icon>
                            <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
                        </span>
                        
                    </template>
                    
                    <div slot="table-actions">
                        
                    </div>
                    <div slot="table-actions-bottom">
                        <h6 class="p-3">
                            Row count: {{displayRowData.length}}  
                        </h6>
                    </div>
                </vue-good-table>
                <batch-modal @reloadTable="fetchDefaultRouting"></batch-modal>
                <global-modal @updatedTable="updateTableData" @loading="toggleBusyMode"></global-modal>
                <CopyFromAccount 
                    :apiKeysAccounts="apiKeysAccounts"
                    :filtered="filtered"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
//import CopyExistingAccount from '../Tokens/CopyExistingAccount.vue'

//import axios from 'axios'
import BatchModal from './DefaultRouting/ManageDefaultRoutingBatchModal.vue'
import GlobalModal from './DefaultRouting/ManageDefaultRoutingGlobalModal.vue'
import CopyFromAccount from './DefaultRouting/CopyFromAccount.vue'
export default {
    components: {
        CopyFromAccount,
        BatchModal,
        GlobalModal
    },
    data(){
        return {
            pricingType: null,
            displayRowData: [],
            apiKeysAccounts: [],
            filtered: [],
            bulkAction: {},
            showBatch: false,
            searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
            },
            selectOptions: { enabled: true },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5,10,100,4000],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
            busyCauseLoading: false,
            tblData: [],
            fields: [
                //{ label: 'select', field: 'select',  width: '100px' },
                { label: 'Country', field: 'routes_country_name',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Country'
                    }
                },
                { label: 'Country code', field: 'routes_country_code',
                 filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Country code'
                    }
                },
                { label: 'Operator', field: 'routes_operator_name',
                filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Operator'
                    }
                },
                { label: 'MCC', field: 'routes_mcc',
                 filterOptions: {
                        enabled: true,
                        placeholder: 'Filter MCC'
                    }
                },
                { label: 'MNC', field: 'routes_mnc',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter MNC'
                    }
                },
                { label: 'SMSC', field: 'routes_provider',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Provider'
                    }
                },
                { label: 'Account Alias', field: 'route_account_alias',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Alias'
                    }
                },
                { label: 'Account id', field: 'route_account_id',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Alias'
                    }
                },
                { label: 'Profile', field: 'routes_type',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Alias'
                    }
                },

                { label: 'MT Cost', field: 'routes_route_cost'},
                { label: 'Mark up', field: 'routes_route_mark_up'},
                
                { label: 'Random Originator', field: 'routes_random_originator',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Random Originator'
                    }
                },
                { label: 'Fixed Sender', field: 'routes_fixed_sender_id',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Fixed Sender'
                    }
                },
                { label: 'Custom Rule', field: 'routes_custom_filter_rule',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Custom Rule'
                    }
                },
                

                { label: 'Created date', field: 'createdAt'},
                { label: 'Updated date', field: 'updatedAt'},
            ]
        }
    },
    
    created () {
        this.fetchDefaultRouting()
        this.getAllApiAccount()
    },
    methods: {
        typeSelected(){
            if(this.pricingType){
                this.displayRowData = this.tblData.filter(route => route.routes_type == this.pricingType)
            }else{
                this.displayRowData = this.tblData
            }
        },
        selectionChanged(list){
            this.filtered = list.selectedRows
        },
        async getAllApiAccount(){
            const res = await this.$http.get('/api/clients/keys/all/read')
            if(res && res.status == 200){
                this.apiKeysAccounts = res.data
            }
        },
        async deleteRouting(){
            let items = Object.keys(this.bulkAction)
            if(!items.length){
                let confirmed = await this.confirmModal("Delete all routing?")    
                if(confirmed){
                    //items = this.routingData
                    items = false
                }else{
                    return
                }
            }else{
                items = Object.values(this.bulkAction)
            }

            let confirmed = await this.confirmModal("Are you sure you want to delete routing?")
            if(confirmed){

                let res = await this.$http.post( '/api/routing/delete-specific-default-routing', {
                    rows: items
                })

                if(res.status == 200){
                    this.bulkAction = {}
                    this.fetchDefaultRouting()
                }
            }
        },
        addToBulkActions(rowData){
            
            let mccmnc = `${rowData.routes_mcc}${rowData.routes_mnc}`
            if(this.bulkAction[mccmnc]){
                delete this.bulkAction[mccmnc]
            }else{
                this.bulkAction[mccmnc] = rowData
            }

        },
        async confirmModal(text){
            
            let confirm =  await this.$bvModal.msgBoxConfirm(text, {
                title: 'Client notification',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'info',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true
            })

            return confirm
            
        },
        toggleBusyMode(){
            this.busyCauseLoading = !this.busyCauseLoading
            this.$emit('busy', this.busyCauseLoading)
        },
        updateTableData(newTblData){
            this.tblData = newTblData
        },
        globalDone(){
            this.$refs['copy-routing-modal'].hide()
            this.$refs['add-single-routing-modal'].hide()
            this.fetchDefaultRouting()
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.rows = filteredItems.length
            this.currentPage = 1
        },
        async fetchDefaultRouting(){
            this.toggleBusyMode()
            this.tblData = []
            const res = await this.$http.get('/api/routing/token/defaults/read')
            if(res && res.status == 200){
                this.tblData = res.data
                this.typeSelected()
            }
            /*
            axios({
                url: '/api/routes/read-defaults',
                method: 'GET' 
             })
            .then(resp => {
                this.toggleBusyMode()
                this.tblData = resp.data
                this.rows = resp.data && resp.data.length ? resp.data.length : 0;
            })
            .catch(err => {
                console.log(err);  
            })
            */
        }
    }
}
</script>

<style>

.def-routing-managment-wrapper .vgt-global-search__input{
    display: none !important;
}

#batch-routing-modal .modal-content{
    height: 800px !important;
}
.modal-xl {
    --bs-modal-width: 1000px !important;
}

@media (min-width: 1200px){
    .modal-xl {
        --bs-modal-width: 1300px !important;
    }
}


</style>