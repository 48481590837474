<template>
    <b-modal    
        id="default-copy-from-account" 
        ref="default-copy-from-account-ref"
        size="lg"
        title="Copy From API Key"
        @ok="handleOk"
        @hidden="resetModal"
        :ok-disabled="false">
        <b-form ref="form" @submit="handleOk" @reset="resetModal">
            <b-row>
                <b-col>
                    <b-form-group
                    style="text-align:left"
                    :label="`Routes (${submit.routing.length}):`">
                        <multiselect 
                            :class="'multiselect-hlr-managment'"
                            :multiple="true"
                            v-model="submit.routing" 
                            :options="filtered" 
                            placeholder="Selected counries" 
                            :custom-label="customLabel"
                            @remove="removeRoutingItem"
                            height="500px"
                            label="routes_country_name" 
                            track-by="routes_country_code">
                            </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="3">
                    <b-form-group label="Pricing Type">
                        <b-form-select  
                            v-model="submit.pricingType"
                            @change="typeSelected">
                            <b-select-option :value="null">Marketing & OTP</b-select-option>
                            <b-select-option value="MARKETING">MARKETING</b-select-option>
                            <b-select-option value="OTP">OTP</b-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                    style="text-align:left"
                    label="Source API Key Token"
                    description="Source API Key Token">
                    <multiselect 
                        v-model="submit.source" 
                        :options="apiKeysAccounts" 
                        placeholder="Select one" 
                        label="http_api_jwt_short_token" 
                        track-by="http_api_jwt_short_token"
                        required>
                    </multiselect>
                    </b-form-group>
                </b-col>
            </b-row>
            
        
        </b-form>
        
    </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
    components: {
        Multiselect
    },
    props: {
        apiKeysAccounts: {
            type: Array,
            default: () => []
        },
        filtered: {
            type: Array
        }
    },
    watch:{
      filtered: {
          handler(val) {
            this.submit.routing = [...val]
          },
          deep: true
      },
  },
    data(){
        return {
            
            submit:{
                pricingType: null,
                routing: [],
                source: null
            },
        }
    },
    methods: {
        typeSelected(){},
        async handleOk(event){
            if(!this.submit.pricingType) return
            event.preventDefault()
            console.log(this.submit)
            const res = await this.$http.post('/api/routing/token/defaults/copy', this.submit)
            if(res && res.data){
                console.log(res.data)
                this.$refs['default-copy-from-account-ref'].hide()
            }
            
            //this.$refs['default-copy-from-account-ref'].hide()
            //this.$bvModal.hide('default-copy-from-account-ref')
            //if(this.$refs['default-copy-from-account-ref'].hide()){
            //    event.preventDefault()
            //}
            
            
        },
        resetModal(){
            console.log('reseting the modal')
            this.submit.routing = []
            this.submit.source = null
        },
        removeRoutingItem(){},
        customLabel(item){
            return `${item.routes_mcc}${item.routes_mnc} (${item.routes_country_name} - ${item.routes_operator_name})`
        },
    }
}
</script>

<style>

</style>