<template>
  <div>
    <b-card id="#groups-tabs" no-body>
      <b-tabs card pills style="min-height:900px;">
        <b-tab 
          @click="getEntities([
              'bearerbox', 
              'smsbox', 
              'db-connection', 
              'dlr-db', 
              'smsc',
              'smss'
          ])" 

          title="Core Group" active>
          
          <CoreGroupLayout 
            @busy="toggleBusy" 
            :active="true" 
            :fetch="getEntities"
            :rows="entities.bearerbox" 
            :smsboxes="entities.smsbox"
            :connections="entities['db-connection']"
            :dlrdbs="entities['dlr-db']"
            :smscs="entities.smsc"
            :smss="entities.smss"
          />
        </b-tab>


        <b-tab @click="getEntities(['smsbox', 'smss'])" title="SMS Box Group">
          <b-tabs pills card vertical>
            <b-tab @click="getEntities(['smsbox', 'smss'])" title="SMSBox" active>
              <SmsBoxGroupLayout :rows="entities.smsbox" :smss="entities.smss" :fetch="getEntities"/>
            </b-tab>
            <b-tab @click="getEntities('smsbox-route')" title="SMSBox Route" >
              <SmsBoxRouteGroupLayout :rows="entities['smsbox-route']" :fetch="getEntities"/>
            </b-tab>

          </b-tabs>

        </b-tab>
        <b-tab @click="getEntities('sendsms-user')" title="Users" >
          <SendsmsUserGroupLayout :rows="entities['sendsms-user']" :fetch="getEntities"/>
        </b-tab>
        <b-tab title="Mysql" @click="getEntities(['sqlbox', 'db-connection'])" >
          <b-tabs pills card vertical>
            <b-tab @click="getEntities(['sqlbox', 'db-connection'])" title="SQL Box" active>
              <SqlBoxGroupLayout :rows="entities.sqlbox" :connections="entities['db-connection']" :fetch="getEntities"/>
            </b-tab>
            <b-tab @click="getEntities('db-connection')" title="MySql connection Group">
              <MysqlConnectionGroupLayout :rows="entities['db-connection']" :fetch="getEntities"/>
            </b-tab>
            <b-tab @click="getEntities('dlr-db')" title="DLR DB Group">
              <DlrDbGroupLayout :rows="entities['dlr-db']" :fetch="getEntities"/>
            </b-tab>
          </b-tabs>
        </b-tab>
        
        <b-tab @click="getEntities(['bearerbox', 'smsc'])" title="SMSC Center Group">
          <SmsCenterGroupLayout 
            :rows="entities.smsc"
            :fetch="getEntities" 
            :bearerboxes="entities.bearerbox" 
            :accounts="accounts"
          />
          
        </b-tab>
        <b-tab @click="getEntities(['opensmppbox', 'smsc', 'bearerbox'])" title="Open SMPP Group">
          <OpensmppGroupLayout 
            :rows="entities.opensmppbox" 
            :smscs="entities.smsc" 
            :bearerboxes="entities.bearerbox" 
            :fetch="getEntities"/>
        </b-tab>
        <b-tab @click="getEntities('smss')" title="SMSC Service Group">
          <SmsServiceGroupLayout :rows="entities.smss"  :fetch="getEntities"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
  import api from '../../api'  
  import CoreGroupLayout from './CoreGroup/Layout.vue'
  import SmsBoxGroupLayout from './SmsBoxGroup/Layout.vue'
  import SqlBoxGroupLayout from './SqlBoxGroup/Layout.vue'
  import OpensmppGroupLayout from './OpensmppGroup/Layout.vue'
  import SmsCenterGroupLayout from './SmsCenterGroup/Layout.vue'
  import SmsServiceGroupLayout from './SmsServiceGroup/Layout.vue'
  import MysqlConnectionGroupLayout from './MysqlConnectionGroup/Layout.vue'
  import DlrDbGroupLayout from './DlrDbGroup/Layout.vue'
  import SmsBoxRouteGroupLayout from './SmsBoxRouteGroup/Layout.vue'
  import SendsmsUserGroupLayout from './SendsmsUserGroup/Layout.vue'

  export default {
    components:{
      CoreGroupLayout,
      SmsBoxGroupLayout,
      SqlBoxGroupLayout,
      MysqlConnectionGroupLayout,
      DlrDbGroupLayout,
      OpensmppGroupLayout,
      SmsCenterGroupLayout,
      SmsServiceGroupLayout,
      SmsBoxRouteGroupLayout,
      SendsmsUserGroupLayout
    },
    created(){
      this.getEntities(['bearerbox', 'smsbox', 'db-connection', 'dlr-db', 'smsc', 'smss'])
    },
    methods: {
      toggleBusy () {
        this.busy = !this.busy
      },
      resetEntities(){
        this.accounts = []
        this.entities = {
          'bearerbox' : [],
          'smsbox' : [],
          'sqlbox' : [],
          'db-connection' : [],
          'dlr-db' : [],
          'smsc' : [],
          'smss' : [],
          'opensmppbox': [],
          'smsbox-route': [],
          'sendsms-user': []
        }
      },
      async getAccounts(){
        //const res = await this.$http.get('/api/providers/read')
        const res = await this.$http.get('/api/routing/smsc/read')
        if(res && res.status == 200){
          for (let i in res.data){
            if(res.data[i].providers_accounts){
              this.accounts = this.accounts.concat(
                res.data[i].providers_accounts.split(',')
                .map( item => {
                      const parts = item.split(/-/)
                      return {
                        account: parts.shift(),
                        user: parts.pop(),
                      }
                    } 
                ))
            }
          }
        }
      },
      async getEntities(entities){
        
        this.resetEntities()
        
        if(entities == 'smsc' || entities.includes('smsc')){
          this.getAccounts() 
        }

        if(typeof entities == 'object'){
          
          const requests = entities.map(entity => { return api.groups.list(entity) } )
          const responses = await Promise.all(requests);

          for (let i in responses) {
            const entity = entities[i]
            this.entities[entity] = responses[i]
          }

        }else{  
          
          const result = await api.groups.list(entities)
          this.entities[entities] = result
          
        }
      

      }
    },
    data () {
      return {
        busy: false,
        accounts: null,
        entities: {
          'bearerbox' : [],
          'smsbox' : [],
          'sqlbox' : [],
          'db-connection' : [],
          'dlr-db' : [],
          'smsc' : [],
          'smss' : [],
          'opensmppbox': [],
          'smsbox-route': [],
          'sendsms-user': []
        }
      }
    }
  }
</script>
<style>
  .group-card{
    padding: 30px 20px 30px 20px;
  }

  .col-auto {
    min-height:850px !important;
  }
</style>
