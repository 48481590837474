<template>
  <b-card          
    no-body
    :style="styles"
    border-variant="info"
    header-bg-variant="light"
    header-text-variant="primary">
        <template #header>
            <b-icon style="display:inline-block" :icon="icon"></b-icon> &nbsp;
            <h6 style="display:inline-block" class="mb-0">{{header}}</h6>
        </template>
        <b-card-body>
            <b-row>
                <b-col>
                    <b-card-text class="p-3" v-if="data">
                        <span class="text-success" style="font-size: 28px; font-weight: bold">{{data.datasets[0].data[0]}}</span>
                        <small class="text-danger" style="font-weight: bold"> {{data.datasets[0].data[1]}}</small><br>

                        
                        <small class="text-primary" style="font-weight: bold" v-if="data.datasets[0].data[0]">
                            Queued  {{(data.datasets[0].data[1]  / data.datasets[0].data[0])}}%
                        </small>
                        

                        <small class="text-primary" style="font-weight: bold" v-else>
                            Queued  100%
                        </small>

                    </b-card-text>
                </b-col>
                <b-col>
                    <DoughnutChart  
                        :data="data" 
                        :styles="{
                            width: '75px', 
                            height: '75px', 
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                            'margin-top': '10px',
                            
                        }"
                    />
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import DoughnutChart from '../../../components/Common/Charts/Doughnut.vue'


export default {
    components: {
        DoughnutChart 
    },
    props: {
        icon: {
            type: String,
            default: () => { return 'question-circle' }
        },
        data:{
            type: Object
        },
        header: {
            type: String
        },
        styles: {
            type: Object
        }
    }
    
}
</script>

<style>

</style>