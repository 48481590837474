<template>
  <b-form @submit.stop.prevent="() => onSubmit(tag, config, includes.filter(i => i))">
    <b-row>

      <b-col cols="6">
        <b-card style="padding: 10px 10px 10px 10px;">
          <b-row>
            <b-form-group style="text-align:left">
              <h6 style="color:green;">{{this.params.uid}}</h6>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col >
              <b-form-group label="Tag:" label-for="input-tag" :invalid-feedback="'Tag is required'" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  v-model="tag"
                  id="input-tag"
                  :state="tag.length > 0"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="opensmppbox-id:" label-for="input-opensmppbox-id" invalid-feedback="Enter a valid id" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  placeholder="Required"
                  id="opensmppbox-id"
                  v-model="config['opensmppbox-id']"
                  :state="config['opensmppbox-id'] != null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="bearerbox-host:" label-for="input-bearerbox-host" invalid-feedback="Enter a valid bearerbox-host" style="text-align:left">
                <b-form-input
                  required
                  size="sm"
                  placeholder="Required"
                  id="input-bearerbox-host"
                  v-model="config['bearerbox-host']"
                  :state="config['bearerbox-host'] != null"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Bearerbox Port:" label-for="input-bearerbox-port" invalid-feedback="Enter a valid port" style="text-align:left">
                <b-form-input
                  required
                  :disabled="this.params.uid !== null && this.params.uid !== undefined"
                  size="sm"
                  placeholder="Required"
                  id="input-bearerbox-port"
                  v-model="config['bearerbox-port']"
                  :state="/^[0-9]{1,10}$/.test(config['bearerbox-port'])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="opensmppbox-port:" label-for="input-opensmppbox-port" invalid-feedback="Enter a valid opensmppbox-port" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="Required"
                  id="input-opensmppbox-port"
                  v-model="config['opensmppbox-port']"
                  :state="/[0-9]{2,}/.test(config['opensmppbox-port'])"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="our-system-id:" label-for="input-our-system-id" invalid-feedback="Enter a valid our-system-id" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="our-system-id"
                  id="input-our-system-id"
                  v-model="config['our-system-id']"
                  :state="config['our-system-id'] != null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
               <b-form-group 
                  label="use-systemid-as-smsboxid:" 
                  :state="config['use-systemid-as-smsboxid'] != null"
                  :invalid-feedback="'use-systemid-as-smsboxid is required'" 
                  style="text-align:left">
                  <b-form-select
                    style="width:100% !important;"
                    v-model="config['use-systemid-as-smsboxid']"
                    required>
                      <template #first>
                        <b-form-select-option :value="null" disabled>Please choose</b-form-select-option>
                        <b-form-select-option :value="1" >Yes</b-form-select-option>
                        <b-form-select-option :value="0" >No</b-form-select-option>
                      </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
             <b-col>
               <b-form-group 
                  label="enable-pam:" 
                  :state="config['enable-pam'] != null"
                  :invalid-feedback="'enable-pam is required'" 
                  style="text-align:left">
                  <b-form-select
                    style="width:100% !important;"
                    v-model="config['enable-pam']"
                    required>
                      <template #first>
                        <b-form-select-option :value="null" disabled>Please choose</b-form-select-option>
                        <b-form-select-option :value="1" >Yes</b-form-select-option>
                        <b-form-select-option :value="0" >No</b-form-select-option>
                      </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
          </b-row>
          <b-row>
             <b-col>
               <b-form-group 
                  label="route-to-smsc:" 
                  :state="false"
                  :invalid-feedback="'route-to-smsc is required'" 
                  style="text-align:left">
                  <b-form-select
                    style="width:100% !important;"
                    v-model="config['route-to-smsc']"
                    required>
                      <template #first>
                        <b-form-select-option :value="null" disabled>Please choose</b-form-select-option>
                        <b-form-select-option v-for="smsc in smscs" :key="smsc.id" :value="smsc.config['smsc-id']" >
                            {{smsc.config['smsc-id']}}
                        </b-form-select-option>
                        
                      </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
            
           <b-col>
              <b-form-group label="bearerbox confi file:" label-for="input-users" style="text-align:left">
                <b-form-select v-model="bearerbox" size="xs" class="w-100" required @change="setIncludeEtitites('bearerbox', bearerbox)">
                  <b-form-select-option :value="null" disabled>bearerbox config file</b-form-select-option>
                   <b-form-select-option v-for="box in bearerboxes" :key="box.id" :value="`${box.uid}.conf`" >
                        {{box.uid}}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
               <b-form-group label="smpp-logins:" label-for="input-smpp-logins" invalid-feedback="Enter a valid smpp-logins" style="text-align:left">
                <b-form-input
                  size="sm"
                  placeholder="smpp-logins"
                  id="input-smpp-logins"
                  v-model="config['smpp-logins']"
                  :state="config['smpp-logins'] != null"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5">
              <b-form-group label="Log Level:" label-for="input-log-level" style="text-align:left">
                <b-form-select v-model="config['log-level']" size="xs" :options="[
                  { value: 0, text: 'Debug' },
                  { value: 1, text: 'Info' },
                  { value: 2, text: 'Warning' },
                  { value: 3, text: 'Error' },
                  { value: 4, text: 'Panic' }
                ]" required />
              </b-form-group>
            </b-col>
          </b-row>

          <ConfigIncludes :includes="includes" />

          <br />

          <b-row>
            <b-button-group>
              <b-button variant="outline-danger" @click="() => onClose()">Close</b-button>
              <b-button type="submit" variant="outline-primary">Submit</b-button>
            </b-button-group>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <ConfigPreview :config="config" :includes="includes" />
      </b-col>

    </b-row>
  </b-form>
</template>

<script>
  import ConfigPreview from '../../Common/Config/ConfigPreview.vue'
  import ConfigIncludes from '../../Common/Config/ConfigIncludes.vue'

  export default {
    components: {
      ConfigPreview,
      ConfigIncludes
    },
    props: ['params', 'onSubmit', 'onClose', 'smscs', 'bearerboxes'],
     methods:{
      setIncludeEtitites(entity, value){ 
        if(value){
          const path = this.getEntityPath(entity)
          const idx = this.includes.findIndex(item=> item.includes(path))
          if(idx > -1) this.includes.splice(idx,1)
          this.includes.push(`${path}/${value}`)
        }
      }
    },
    data () {
      return {
        bearerbox: null,
        tag: '',
        config: {
          'group' : 'opensmppbox', 
          'opensmppbox-id': 'exchg',
          'bearerbox-port': 13033,
          'bearerbox-host': 'localhost',
          'opensmppbox-port': 2775,
          'our-system-id': 'SMPP',
          'use-systemid-as-smsboxid': 1,
          'enable-pam': 1,
          'route-to-smsc': null,
          'smpp-logins':  null,
          'log-level': 0
        },
        includes: []
      }
    },
    created () {
      this.tag = this.params.tag
      this.includes = this.params.includes

      const idx = this.includes.findIndex(item=> item.includes( this.getEntityPath('bearerbox') ))
      this.bearerbox = idx > -1 ? this.includes[idx].split(/\//)[5] : null

      this.config = { ...this.config, ...this.params.config }
    },
  }
</script>

<style>
  .row {
    margin: 10px;
  }
</style>
