<template>
  <div class="dashbaord" style="width:100%; max-width:2300px">

    <b-container>
      <b-row style="min-height:150px">
        <b-col cols="12">
          <b-card  
              class="p-3"
              style="height:100%;"
              border-variant="secondary"
              header-bg-variant="secondary"
              header-text-variant="white"
              title="Welcome to the back office">
              <b-card-text >
                Manage all server functionality
              </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols-lg="6">
          <b-card  
            class="p-3"
            border-variant="secondary"
            header-bg-variant="secondary"
            header-text-variant="white"
            title="Statistics">
            <b-card-text>
              Search/Statistics
            </b-card-text>
            <router-link to="statistics/search">
                <b-button variant="primary"><b-icon icon="search"></b-icon>&nbsp;Search Messages</b-button>
            </router-link>
            &nbsp;
            <router-link to="statistics/mt">
                <b-button variant="primary"><b-icon icon="bar-chart"></b-icon>&nbsp;MT Statistics</b-button>
            </router-link>
            &nbsp;
            <router-link to="statistics/delify/statistics">
                <b-button variant="primary"><b-icon icon="bar-chart"></b-icon>&nbsp;Delify</b-button>
            </router-link>
          </b-card>
        </b-col>
        <b-col cols-lg="6">
          <b-card  
            class="p-3"
            border-variant="secondary"
            header-bg-variant="secondary"
            header-text-variant="white"
            title="Finance">
            <b-card-text>
              Accounts/Invoices/Payments
            </b-card-text>
            <router-link to="finance/accounts">
                <b-button variant="primary"><b-icon icon="people"></b-icon>&nbsp;Accounts</b-button>
            </router-link>
            &nbsp;
            <router-link to="finance/invoices">
                <b-button variant="primary"><b-icon icon="file-earmark-post-fill"></b-icon>&nbsp;Invoices</b-button>
            </router-link>
            &nbsp;
            <router-link to="finance/history">
                <b-button variant="primary"><b-icon icon="file-earmark-medical"></b-icon>&nbsp;History</b-button>
            </router-link>
          </b-card>
        </b-col>
        
        
      </b-row>
      <b-row>
        <b-col cols-lg="4">
            <b-card  
              class="p-3"
              border-variant="secondary"
              header-bg-variant="secondary"
              header-text-variant="white"
              title="Routing">
              <b-card-text>
                Manage system routing
              </b-card-text>
              <router-link to="routing/management">
                  <b-button variant="primary"><b-icon icon="globe"></b-icon>&nbsp;Management</b-button>
              </router-link>
              &nbsp;
              <router-link to="routing/smsc">
                  <b-button variant="primary"><b-icon icon="diagram3"></b-icon>&nbsp;SMSC</b-button>
              </router-link>
            </b-card>
        </b-col>
        <b-col cols-lg="4">
            <b-card  
              class="p-3"
              border-variant="secondary"
              header-bg-variant="secondary"
              header-text-variant="white"
              title="Media">
              <b-card-text>
                Campaigns and Media
              </b-card-text>
              <router-link to="media/campaigns">
                  <b-button variant="primary"><b-icon icon="globe"></b-icon>&nbsp;Campaigns</b-button>
              </router-link>
              &nbsp;
              <router-link to="media/urls">
                  <b-button variant="primary"><b-icon icon="link"></b-icon>&nbsp;URLs</b-button>
              </router-link>
            </b-card>
        </b-col>
        <b-col cols-lg="4">
           <b-card  
                class="p-3"
                border-variant="secondary"
                header-bg-variant="secondary"
                header-text-variant="white"
                title="Notifications">
                <b-card-text>
                  Scheduler/Templates
                </b-card-text>
                <router-link to="notifications/scheduler/list">
                    <b-button variant="primary"><b-icon icon="list"></b-icon>&nbsp;Scheduler</b-button>
                </router-link>
                &nbsp;
                <router-link to="notifications/templates/list">
                    <b-button variant="primary"><b-icon icon="file-earmark-code"></b-icon>&nbsp;Templates</b-button>
                </router-link>
                &nbsp;
              </b-card>
        </b-col>
      </b-row>
      <b-row>
        
        <b-col cols-lg="4">
          <b-card  
            class="p-3"
            border-variant="secondary"
            header-bg-variant="secondary"
            header-text-variant="white"
            title="Ssttings">
            <b-card-text>
              Users/Notifications
            </b-card-text>
            <router-link to="settings/users">
                <b-button variant="primary"><b-icon icon="person"></b-icon>&nbsp;Users</b-button>
            </router-link>
            &nbsp;
            <router-link to="settings/notifications">
                <b-button variant="primary"><b-icon icon="envelope"></b-icon>&nbsp;Notifications</b-button>
            </router-link>
            &nbsp;
          </b-card>            
             
        </b-col>
         <b-col cols-lg="4">
          <b-card  
            class="p-3"
            border-variant="secondary"
            header-bg-variant="secondary"
            header-text-variant="white"
            title="Gateway">
            <b-card-text>
              Dashboard/Groups
            </b-card-text>
            
            <router-link to="gateway/dashboard">
                <b-button variant="primary"><b-icon icon="speedometer"></b-icon>&nbsp;Dashboard</b-button>
            </router-link>
            &nbsp;
            <router-link to="gateway/groups">
                <b-button variant="primary"><b-icon icon="node-plus"></b-icon>&nbsp;Groups</b-button>
            </router-link>
          </b-card>
        </b-col>
      </b-row>
     
      
    </b-container>

    
  </div>
</template>

<script>
//import axios from 'axios'
//import { CircleIcon } from 'vue-feather-icons'
//import { ZapOffIcon } from 'vue-feather-icons'
//import { ZapIcon } from 'vue-feather-icons'



export default {
  components: {
    //CircleIcon,
    //ZapOffIcon,
    //ZapIcon
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      /*
      axios({url: '/api/dashboard/read', method: 'GET' })
      .then(resp => {
        this.isLoading = false
        this.data = resp.data
        console.log(this.data);
        
      })
      .catch(err => {
          
          console.log(err)
      })
      */
    }
  },
  data(){
    return {
      isLoading: true,
      data: {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.card-body{
  padding: 0 !important;
}
</style>
