<template>
<div>
    <base-card >
        <div slot="body">
            <b-row>
                <b-col>
                    <TopFilter @filter="fetch"/>                
                </b-col>
            </b-row>
            <Table :rows="rows"/>
        </div>
    </base-card>
</div>
</template>

<script>
import TopFilter from '../Common/Filter.vue'
import Table from './Table.vue'
import BaseCard from '../../../Base/card.base.vue'
export default {
    components: {
        BaseCard,
        TopFilter,
        Table
    },
    methods: {
        async fetch(){
            const res = await this.$http.get('/api/payment/invoice/read')
            if(res && res.status == 200){
                this.rows = res.data
            }
        }
    },
    data(){
        return {
            rows: []
        }
    }
}
</script>

<style>

</style>