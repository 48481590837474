<template>
  <Form />
</template>

<script>
import Form from './Form.vue'
export default {
  components:{
    Form
  }
}
</script>

<style>

</style>