<template>
    <b-modal
        id="copy-routing-modal" 
        ref="copy-routing-modal"
        
        @ok="handleOk"
        @hidden="resetModal"
        v-model="showGlobal"
        title="Global routing"
    >

    <b-form ref="form" @submit="handleOk" @reset="resetModal">
        <multiselect 
            v-model="copyRouteFrom.from_routing_account_object" 
            :options="providersWithRoutingList" 
            :custom-label="nameWithLang" 
            placeholder="Select one" 
            label="route_account_alias" 
            track-by="route_account_id">
        </multiselect>

        <b-form-group
            style="text-align:left"
            content-cols-lg="12"
            description="Mark up value"
            label="Mark up"
            label-for="rt-list-id-input"> 
            <b-form-input
                type="number"
                step="0.001"
                v-model="copyRouteFrom.routing_mark_up_by"
                required
            ></b-form-input>
        </b-form-group>
        
        <b-form-group
            style="text-align:left"
            content-cols-lg="12"
            description="Default pricing type"
            label="Pricing type"
            label-for="rt-list-id-input"> 
            <b-form-select v-model="copyRouteFrom.type" class="mb-3" >
                <b-form-select-option :value="null">Please select an option</b-form-select-option>
                <b-form-select-option value="OTP">OTP</b-form-select-option>
                <b-form-select-option value="MARKETING">MARKETING</b-form-select-option>
            </b-form-select>
        </b-form-group>
        

        <!--
        <b-button-group>
            <b-button type="submit" variant="outline-primary">Submit</b-button>
            <b-button type="reset" variant="outline-danger">Reset</b-button>
        </b-button-group>
        -->
    </b-form>
    <!--
        <template #modal-footer>
            
            <b-button type="submit" :disabled="isValidForm()" variant="outline-primary" @click.prevent="handleOk()">Submit</b-button>
            <b-button type="reset" variant="outline-info" @click="resetModal()">Reset</b-button>
        </template>
    -->
    </b-modal>
</template>
<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
    components: {
        Multiselect
    },
    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            //console.log('Modal is about to be shown', bvEvent, modalId)
            if(modalId == 'copy-routing-modal')
                this.loadSetRotuingFriomProvidersData()
        })
    },
    created(){
        //this.loadSetRotuingFriomProvidersData()
    },
    updated(){
        //this.loadSetRotuingFriomProvidersData()
    },
    data(){
        return {
            showGlobal: false,
            providersWithRoutingList: [],
            copyRouteFrom: {
                from_routing_account_object: null,
                routing_mark_up_by: null,
                set_as_default: true,
                profile: null
            },
        }
    },
    methods:{
        isValidForm(){
            //console.log(this.$refs)
            //return this.$refs.form.checkValidity()
            //return false
        }, 
        nameWithLang (item) {
            return `${item.route_account_alias}(${item.route_account_id})-${item.routes_count}`
        },
        confirmChangesModel(text){
            
            return this.$bvModal.msgBoxConfirm(text, {
                title: 'Client notification',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'info',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true
            })
        },
        showSubmitResponseChangesModel(resp){
            return  this.$bvModal.msgBoxOk(
                    `
                        Server status: ${resp.status} ${resp.statusText}
                    `, 
                    {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
        },
        async handleOk(event){
            event.preventDefault()
            
            const valid = this.$refs.form.checkValidity()

            if(!valid){
                return
            }
            
            const confirm  = await this.confirmChangesModel(`Are you sure you want to override all data?
            and set it the following provider routing: 
            Porivder routing account: ${this.copyRouteFrom.from_routing_account_object.route_account_alias} 
            Porivder routing our account ID: ${this.copyRouteFrom.from_routing_account_object.route_account_id}
            ${this.copyRouteFrom.from_routing_account_object.route_account_alias} `)

            if(!confirm) return

            const res = await this.$http.post('/api/routing/token/defaults/global', this.copyRouteFrom)
            if(res && res.status == 200){
                this.$emit('loading', false)
                this.$emit('updatedTable', res.data)
                this.$bvModal.hide('copy-routing-modal')
            }

        },
        resetModal(){
            this.copyRouteFrom =  {
                from_routing_account_object: null,
                routing_mark_up_by: null,
                set_as_default: true,
                profile: null
            }
        },
        loadSetRotuingFriomProvidersData(){   
            this.$http.get('/api/routes/read-count-by-primary-key').then(resp => {     
                this.providersWithRoutingList = resp.data ? resp.data : this.providersWithRoutingList
            }).catch(thrown => {
                console.log(thrown)
            });
        }
    },
    
}
</script>