<template>
        
    <main class="form-signin">
    <form
        @submit="checkForm"
        method="post"
    >
        <h1>Message-deck admin panel</h1>
        <br><br>
        <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

        <div class="form-floating">
        <input 
            v-model="input.username"
            type="text" 
            class="form-control" 
            id="floatingInput" 
            placeholder="User name" 
            required>

        <label for="floatingInput">User Name</label>
        </div>
        <div class="form-floating">
        <input 
            v-model="input.password"
            type="password" 
            class="form-control" 
            id="floatingPassword" 
            placeholder="Password"
            required>

        <label for="floatingPassword">Password</label>
        </div>

        <div class="checkbox mb-3">
        <label>
            <input 
                type="checkbox" 
                value="remember-me" 
                v-model="input.remember"> Remember me
        </label>
        </div>
        <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
        <p class="mt-5 mb-3 text-muted">&copy; Message-deck {{ (new Date()).getFullYear() }}</p>

    </form>

    </main>

</template>
<script>

export default {
    components: {
        
    },
    data() {
        return {
            input: {
                username: "",
                password: "",
                remember: false
            }
        };
    },
    methods: {
        checkForm: function (e) {
            e.preventDefault();
            this.$store.dispatch('login', this.input)
            .then(() => this.$router.push('/dashboard'))
            .catch(err => console.log(err))
        }
    }
}
</script>
<style >


body {
  
  text-align: center!important;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  
}


.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}


.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>