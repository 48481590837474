import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    currentRequestStatus: false,
    webSocket: null
  },
  mutations: {
    webSocketConnect(state, webSocket){
      state.webSocket = webSocket
    },
    some_request(state){
      //state.status = 'loading'
      state.currentRequestStatus = !state.currentRequestStatus
    },
    auth_request(state){
        state.status = 'loading'
    },
    auth_success(state, token, user){
        state.status = 'success'
        state.token = token
        state.user = user
    },
    auth_error(state){
        state.status = 'error'
    },
    logout(state){
        state.status = ''
        state.token = ''
    },
  },
  actions: {
    request({commit}){
      //return new Promise((resolve, reject) => {
      return new Promise((resolve) => {
        commit('some_request', 'ok')
        resolve('done')
        //reject
      })
    },
    login({commit}, inputUser){
        return new Promise((resolve, reject) => {
            commit('auth_request')
            
            //axios({url: '/auth/login', data: inputUser, method: 'POST' })
            axios({url: '/auth/local/login-admin', data: inputUser, method: 'POST' })
            .then(resp => {


                const token = resp.data.token
                const user = resp.data.user
                
                if(inputUser.remember){
                  localStorage.setItem('token', token)
                }
                
                  
                
                
                //axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                axios.defaults.headers.common['Authorization'] = `${token}`
                commit('auth_success', token, user)
                resolve(resp)

            })
            .catch(err => {
                commit('auth_error')
                localStorage.removeItem('token')
                reject(err)
            })



        })
    },
    logout({commit}){
      //return new Promise((resolve, reject) => {
        return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
      
    }
  },
  getters : {
    isLoggedIn: state => !!state.token, // !! will return true or false, depending if token is truthy or falsy
    authStatus: state => state.status,
    currentRequestStatus: state => state.currentRequestStatus,
    webSocket: state => state.webSocket
  }
})