<template>
    <div>
        <b-input-group prepend="Setting name:" class="mt-3" style="max-width:500px;">
            <b-form-input @input="searchInTbl" v-model="search"></b-form-input>
            <b-input-group-append>
                <b-button style="border-radius:0px;" variant="outline-primary" v-b-tooltip.hover title="Clear" @click="search = null">
                    <b-icon icon="x"></b-icon>
                </b-button>
            </b-input-group-append>
        </b-input-group>

        <b-card-group deck v-if="filteredItems.length">
            <b-card v-for="(item, index) in filteredItems" :key="index" >
                    <template #header>
                        <b>Setting name: {{item.notifications_settings_name}}</b>
                    </template>
                    
                        <b-list-group flush>
                            <b-list-group-item>
                                <b-badge v-if="item.notifications_settings_template_type.trim() == 'system'" pill variant="primary"> {{item.notifications_settings_template_type}}</b-badge>
                                <b-badge v-else pill variant="success"> {{item.notifications_settings_template_type}}</b-badge>
                            </b-list-group-item>    
                            <b-list-group-item> 
                                <form :id="`form${index}`" :ref="`form${index}`" @submit.stop.prevent="handleSubmit(item)">
                                    <b-form-group 
                                    label="Template"
                                    label-for="template-input"
                                    invalid-feedback="Template name is required"
                                    :state="item.notifications_settings_template_name != null">
                                        <b-form-select
                                            id="template-input"
                                            style="width:100% !important;"
                                            v-model="item.notifications_settings_template_name"
                                            required>

                                            <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
                                            <b-form-select-option  v-for="(item, index) in templates" :key="index"
                                                :value="item.template_name_short">{{item.template_name_short}}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group 
                                    label="Subject"
                                    label-for="subject-input"
                                    invalid-feedback="Subject name is required"
                                    :state="item.notifications_settings_template_subject != null">
                                        <b-form-input
                                            id="subject-input"
                                            style="width:100% !important;"
                                            v-model="item.notifications_settings_template_subject"
                                            required>
                                        </b-form-input>
                                    </b-form-group>
                                    <button :ref="`submit${index}`" style="display:none;">Submit</button>

                                </form>

                            </b-list-group-item>    
                            <b-list-group-item>Created: {{item.createdAt}}</b-list-group-item>    
                            <b-list-group-item>Updated: {{item.updatedAt}}</b-list-group-item>    
                        </b-list-group>
                    
                    <template #footer>
                        <b-button-group>
                            <b-button type="submit" variant="outline-success"  v-b-tooltip.hover title="Save Setting" @click="submitForm(`submit${index}`)">
                                <b-icon icon="save"></b-icon>
                            </b-button>
                            <b-button :disabled="item.notifications_settings_template_type && item.notifications_settings_template_type == 'system'" variant="outline-danger" @click="deleteItem(item, index)" v-b-tooltip.hover title="Delete Setting">
                                <b-icon icon="trash2"></b-icon>
                            </b-button>
                        </b-button-group>
                    </template>
                
            </b-card>
        </b-card-group>
        <b-jumbotron v-else lead="No setting were found" style="margin-top:50px">
        </b-jumbotron>
        
    </div>
  
</template>

<script>
export default {
    props: ['items', 'templates'],
    watch: {
        'items': function(items){
            this.filteredItems = items
        },
        'search': {
            //handler(newValue, oldValue) {
            handler(newValue) {
                this.filteredItems = this.items
                this.filteredItems = this.items.filter(item =>    
                    item.notifications_settings_template_name && 
                    item.notifications_settings_template_name.toLowerCase().includes(newValue.toLowerCase()) ||
                    item.notifications_settings_category.toLowerCase().includes(newValue.toLowerCase()) ||
                    item.notifications_settings_template_type.toLowerCase().includes(newValue.toLowerCase())
                )
            },
            deep: true
        }
    },
    methods:{
        searchInTbl(){
            
        },
        async deleteItem(item, index){
            
            let res = await this.$http.post(`/api/notifications/settings/delete`, item);
            if(res && res.status == 200){
                this.items.splice(index, res.data.deleted)
            }
            
        },
        submitForm(submitId){
            this.$refs[submitId][0].click()
        },
        async handleSubmit(item) {
            let res = await this.$http.post(`/api/notifications/settings/update`, item);
            if(res && res.status == 200){
                //console.log(res.data)
            }
            
        }
    },
    data(){
        return {
            search: null,
            filteredItems: [],
            fields: [
                // A virtual column that doesn't exist in items
                //'index',
                /*
                { key: 'notifications_settings_name', label: 'Name' },
                { key: 'notifications_settings_category', label: 'Category' },
                */
               { key: 'merged', label: 'Setting' }
            ],
        }
    }
}
</script>

<style>

.input-group-text {
    border-radius:0px !important;
}

.card-deck .card {
    max-width:400px;
    float:left;
    margin: 10px;
}
</style>
