<template>
    <div class="p-3">
        
        <b-form ref="filterForm"  @submit.stop.prevent="onSubmit" @reset="onReset">
            <b-row>
                <b-col>
                    <b-form-group label="Clients:">
                        <multiselect 
                            :multiple="true"
                            v-model="filter.uuids" 
                            :options="accountUuids" 
                            placeholder="Select UUIDs" 
                            label="value" 
                            track-by="value">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Accounting tokens:">
                        <multiselect 
                            :multiple="true"
                            v-model="filter.apiKeyTokens" 
                            :options="apiKeyTokens" 
                            :custom-label="getKeyTokensLabel"
                            placeholder="Select tokens" 
                            label="text" 
                            track-by="value">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Countries:">
                        <multiselect 
                            
                            :multiple="true"
                            v-model="filter.countries" 
                            :options="countriesList" 
                            placeholder="Select countries" 
                            label="text" 
                            track-by="value">
                        </multiselect>
                    </b-form-group>
                </b-col>
                <b-col v-if="showOperators">
                    <b-form-group label="Operators:">
                        <multiselect 
                            @input="filterOperators"
                            :multiple="true"
                            v-model="resultOperators" 
                            :options="operatorsList" 
                            :custom-label="getOperatorsLabel"
                            placeholder="Select operators" 
                            label="text" 
                            track-by="value">
                        </multiselect>
                    </b-form-group>
                </b-col>
                
                
            </b-row>
            <b-row>
                <b-col cols="3">
                    <b-form-group label="Providers:">
                        <multiselect 

                            @input="filter.providers = resultSmscAccounts.map(item => { return {  value: item.db_id }  })"
                            
                            :multiple="true"
                            v-model="resultSmscAccounts" 
                            :options="providers" 
                            placeholder="Select providers" 
                            label="label" 
                            track-by="value">
                        </multiselect>
                    </b-form-group>
                </b-col>

                <b-col cols="3">
                    <b-form-group label="Routing Type:">
                        <b-input-group>
                            <b-form-select v-model="filter.routing_type" size="xs">
                                <b-form-select-option :value="null">Routing type</b-form-select-option>
                                <b-form-select-option value="MARKETING">MARKETING</b-form-select-option>
                                <b-form-select-option value="OTP">OTP</b-form-select-option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <b-button-group>
                        <b-button type="submit" variant="outline-primary">Submit</b-button>
                        <b-button type="reset" variant="outline-danger">Reset</b-button>
                    </b-button-group>
                    
                </b-col>
            </b-row>
            
        </b-form>
            
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

var countriesModule = require('../../../json/countries')
var operatorsModule = require('../../../json/newmccmnc')


export default {
    components: {
        Multiselect
    },
    props: {
        apiKeyTokens: { type: Array },
        accountUuids: { type: Array },
        providers: { type: Array },
        smscAccounts: {
            type: Array
        },
        showOperators: {
            type: Boolean,
            defualt: false
        }
    },
    data(){
        return {
            countriesList: countriesModule.countries,
            operatorsList: operatorsModule.map(item =>{ item['value'] = `${item.mcc}${item.mnc}`; return item; }),
            filter: {
                routing_type: null,
                uuids: [],
                apiKeyTokens: [],
                countries: [],
                routing_mcc: [],
                routing_mnc: [],
                providers: []
                //smscAccounts: []
            },
            
            resultSmscAccounts: null,
            resultOperators: null,
        }
    },
    created(){},    
    methods: {
        filterOperators(){
            this.filter.routing_mcc = []
            this.filter.routing_mnc = []
            this.resultOperators.forEach(item => {
                this.filter.routing_mcc.push({value: item.mcc})    
                this.filter.routing_mnc.push({value: item.mnc})    
            })
        },        
        onSubmit() {
            
            const filterKeys = Object.keys(this.filter)
            const keysWithValues = filterKeys.filter(key => this.filter[key] != null && this.filter[key].length)
            let valid = keysWithValues.length           
            if(!( valid )){
                this.mesageBoxOk('Please select at least 1 filter', 'Notice', 'info')
                return
            }
            
            this.$emit('filter',  this.filter)
        },
        onReset() {
            this.resultSmscAccounts = []
            this.filter = {
                uuids: [],
                apiKeyTokens: [],
                countries: [],
                routing_mcc: [],
                routing_mnc: [],
                //smscAccounts: []
                providers: []
            }

        },
        getOperatorsLabel(item){
            return `${item.countryName} - ${item.operator} (${item.mcc}${item.mnc})`
        },
        getProvidersLabel(item){
            return this.$parent.getProvidersLabel(item)
        },
        getKeyTokensLabel(item){
            return `${item.text} (${item.uuid}) `
        },
        async mesageBoxOk(text, title, type){
            return  this.$bvModal.msgBoxOk(
                text, 
                {
                title: title,
                size: 'sm',
                buttonSize: 'sm',
                okVariant: type,
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
            })
        }
    }
}
</script>

<style>

</style>

