<template>
  <vue-good-table 
                theme="polar-bear"
                :columns="fields" 
                :rows="rows" 
                :line-numbers="true"
                 
                :sort-options="{
                    enabled: true,
                    initialSortBy: {field: 'routing_lookup_config_hlr_active', type: 'desc'}
                }"
                :pagination-options="paginationOptions"
                :search-options="searchOptions"
                :select-options="selectOptions"
                @on-select-all="onSelectAll"
                @on-selected-rows-change="selectionChanged"
                @on-column-filter="onColumnFilter"
            >
            <template slot="table-row" slot-scope="props">
                
                

                <span v-if="props.column.field == 'routing_lookup_save_to_cache'">
                    
                    <div @click="toggleCache(props.row)" class="text-left">
                        <b-icon v-if="props.row.routing_lookup_save_to_cache" icon="toggle-on" font-scale="1.5" ></b-icon>
                        <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
                    </div>
                </span>

                <span v-else-if="props.column.field == 'routing_lookup_config_providers_name'">
                    {{props.row.routing_lookup_config_providers_name}}
                </span>
                
                <span v-else-if="props.column.field == 'routing_lookup_config_query_price'">
                    {{props.row.routing_lookup_config_query_price}}
                </span>
                <span v-else-if="props.column.field == 'routing_lookup_save_to_cache_timeout'">
                    {{props.row.routing_lookup_save_to_cache_timeout}}
                </span>
                <span v-else-if="props.column.field == 'routing_lookup_config_hlr_active'">
                    <b-icon v-if="props.row.routing_lookup_config_hlr_active" icon="toggle-on" font-scale="1.5" ></b-icon>
                    <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
                </span>
            </template>

           
<!--
            <div slot="table-actions" style="width: 100%">
                <b-button  variant="primary">Toggle all</b-button>
            </div>

            <div slot="selected-row-actions">
                <b-button  variant="primary">Toggle all</b-button>
            </div>
-->        
             <div slot="table-actions-bottom">
                <h6 class="m-1" style="float:left">
                    Row count: {{rows.length}}  
                </h6>
            </div>
            </vue-good-table>
</template>

<script>


export default {
    components: {
        
    },
    props: ['loading', 'rows', 'providers'],
    methods: {
        
        cache(rowData){
            console.log(rowData)
        },
        submit(rowData){
            console.log(rowData)
        },
        selectAll() {
            this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },
        selectionChanged(list){
            //console.log(list)
            this.$emit('selectedInTable', list)
        },
        onSelectAll(){
            //console.log('all')
        },
        onColumnFilter(item) {
            console.log(item)
        }
    },
    data(){
        return {
             searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
            },
            selectOptions: { enabled: true },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5,10,100],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
            selected: [],
            tblFilter: null,
            tblData: [],
            fields: [
                { label: 'Country', field: 'routing_lookup_config_country_name',filterOptions: {
                        enabled: true,
                         placeholder: 'Filter '
                    }},
                { label: 'Country code', field: 'routing_lookup_config_country_code',filterOptions: {
                        enabled: true,
                         placeholder: 'Filter '
                    }},
                { label: 'Account', field: 'routing_lookup_config_accounting_token',filterOptions: {
                        enabled: true,
                         placeholder: 'Filter '
                    }},
                { label: 'HLR Porvider', field: 'routing_lookup_config_providers_name', width:"200px",filterOptions: {
                        enabled: true,
                         placeholder: 'Filter '
                    }},
                { label: 'Price', field: 'routing_lookup_config_query_price'},
                { label: 'Cache result', field: 'routing_lookup_save_to_cache', sortable: true,filterOptions: {
                        enabled: true,
                         placeholder: 'Filter '
                    }},
                { label: 'Cache Timeout (Hours)', field: 'routing_lookup_save_to_cache_timeout', sortable: true,},
                { label: 'HLR status', field: 'routing_lookup_config_hlr_active', sortable: true},
                { label: 'Sanitize', field: 'routing_sanitize', sortable: true},
                { label: 'Created', field: 'routing_created_at'},
                { label: 'Updated', field: 'routing_updated_at'}
            ],
            currentPage: 1,
            //rows: 0,
            perPage: 15,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }]
        }
    }
}
</script>

<style>

</style>