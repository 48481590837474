<template>
  <b-button 
    size="sm"
    :title=title
    :variant=variant
    v-b-tooltip.hover
    @click="onClick()"
  >
    <b-icon v-if="(icon)" :icon=icon />
    {{text}}
  </b-button>
</template>

<script>

export default {
  props: {
    label: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    },
    variant: {
      type: String
    },
    icon: {
      type: String
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style>

</style>
