<template>
  
  <b-modal
      id="modal-add-setting-type"
      ref="modal-add-setting-type"
      title="Create a new notification setting"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
    
    
      <form id="new-setting-form" ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label="Name"
            label-for="name-input"
            invalid-feedback="Name is required"
            :state="form.notificationSettingName.length > 2">
          <b-form-input
            id="name-input"
            v-model="form.notificationSettingName"
            :state="form.notificationSettingName.length > 2"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group 
            label="Setting Categroy"
            label-for="categroy-input"
            invalid-feedback="Categroy is required"
            :state="form.notificationSettingCategory != ''">
            <b-form-select
            id="categroy-input"
            style="width:100% !important;"
            v-model="form.notificationSettingCategory"
            :options="this.$notificationTypes"
            required
            disabled
            >
            <template #first>
                <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
            </template>
            </b-form-select>
        </b-form-group>
        
      </form>
    </b-modal>
</template>

<script>

export default {
    props:['currentTabName'],
    methods: {
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        async handleSubmit() {
            if (!this.$refs.form.checkValidity()) {
                return
            }

            let res = await this.$http.post(`/api/notifications/settings/create`, this.form);
            if(res && res.status == 200){
                this.$emit('addedRow', res.data)
                this.$refs['modal-add-setting-type'].hide()
            }

        },
        resetModal(){
            this.form = {
                notificationSettingName: "",
                notificationSettingType: "custom",
                notificationSettingCategory: (this.$notificationTypes.find(item => item.text == this.currentTabName)).value
            }
        }
    },
    data(){
        return {
            form: {
                /*
                notificationName: "",
                notificationCatgery: "",
                notificationSettingCreated: "",
                notificationSettingUpdated: "",
                */
                notificationSettingName: "",
                notificationSettingType: "custom",
                notificationSettingCategory: (this.$notificationTypes.find(item => item.text == this.currentTabName)).value,
                
            },
        }
    }
}
</script>

<style>
#new-setting-form 
label,
.invalid-feedback {
    text-align: left;
}
</style>
