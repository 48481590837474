<template>
  <div>
    <b-row>
        <TopFilter  
            :apiKeyTokens="apiKeyTokens"
            :accountUuids="accountUuids"
            :providers="hlrAccounts"
            @filter="fetch"
        />
    </b-row>
    <b-row>
        <b-col cols="12">
            <OptionButtons />
        </b-col>
    </b-row>
    <b-row>
        <Table 
            :rows="rows" 
            @selectedInTable="setRowsSelected"
        />
    </b-row>
    <b-row>
        <HlrSettings 
            :filtered="filtered"
            :providers="hlrAccounts"
            @done="done"    
        />

    </b-row>    
  </div>
</template>

<script>
import TopFilter from '../Common/Filter.vue'
import Table from './Table.vue'
import HlrSettings from './HlrSettings.vue'
import OptionButtons from './Buttons.vue'

export default {
    components:{
        TopFilter,
        Table,
        OptionButtons,
        HlrSettings
    },
    data(){
        return {
           apiKeyTokens: [], 
           accountUuids: [],
           hlrAccounts: [],
           filtered: {},
           rows: [],
           loading: false
        }
    },
    created(){
       this.lists()
    },
    methods: {
        setRowsSelected(rows){
            this.filtered = { ... this.filtered, ... {routing: rows.selectedRows} }
        },
        async fetch(filter){
        

            if(filter) this.filtered = { ... this.filtered, ... filter }

            this.toggleBusy()
            let res = await this.$http.post('/api/gateway/hlr/settings/read', 
            {
                routing_lookup_config_account_uuid: this.filtered.uuids,
                routing_lookup_config_country_code: this.filtered.countries,
                routing_lookup_config_accounting_token: this.filtered.apiKeyTokens,
                routing_lookup_config_providers_uuid: this.filtered.providers
            })
            if(res && res.status == 200){
                this.rows = res.data
            }
            this.toggleBusy()

        },
        async lists(){
            
            let promises = await Promise.all([
                //this.$http.get('/api/clients/get-all-http-tokens'), //api keys
                this.$http.get('/api/clients/keys/all/read'), //api keys
                this.$http.get('/api/gateway/hlr/read'),  // provider accounts
            ])

            const apiKeysPromise = this.unPromisify(promises.shift())
            this.normalizeApiKeys(apiKeysPromise)
            this.normalizeAccountUuids(apiKeysPromise)
            this.normalizeHlrAccounts(this.unPromisify(promises.shift()))
        },
        unPromisify(promise){
            if(promise && promise.status == 200) return promise.data
            else throw new Error('No promise data')
        },
        normalizeApiKeys(data){
            this.apiKeyTokens = data.map(item => { return { text: item.http_api_jwt_short_token, value: item.http_api_jwt_short_token } })
        },
        normalizeAccountUuids(data){
            this.accountUuids = Object.keys(data.reduce((acc,item)=>{
                acc[item.http_api_jwt_uuid] = 1;
                return acc;
            },{})).map(item => { return { text: item, value: item } })
        },
        normalizeHlrAccounts(data){           
            this.hlrAccounts = data.map(item => {
                item['db_id'] = item.hlr_providers_uuid
                item['label'] = item.hlr_providers_name
                item['value'] = `${item.hlr_providers_uuid}`
                return item;
            })
        },
        getProvidersLabel(item){
            return item.hlr_providers_name
        },
        toggleBusy(){
            this.loading = !this.loading
        },
        done(modalId){
            this.$bvModal.hide(modalId)
            this.fetch()
        }
    }
}
</script>

<style>

</style>