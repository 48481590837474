<template>
  <b-modal
        title="Test SMSC" 
        size="lg"
        :id="'bv-modal-test-smsc-connection'" 
        :ok-disabled="!formIsvalid"
        @ok="handleOk"
        @hidden="onReset">
        
        <b-row>
            <b-col>
                <h6 style="">Test parameters</h6>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                    <b-form 
                        ref="testForm"
                        @reset="onReset"
                        @submit.stop.prevent="handleSubmit(handleOk(event))">

                        <b-form-group
                            style="text-align:left"
                            id="input-group-1"
                            label="Test origin"
                            label-for="input-1"
                            description="Origin to test"
                        >
                        <validation-provider 
                        name="origin" 
                        rules="required" 
                        v-slot="validationContext">
                            <b-form-input
                            id="input-1"
                            v-model="testForm.send_sms_sender"
                            type="text"
                            placeholder="Enter test origin"
                            :state="getValidationState(validationContext)"
                            required
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                        </b-form-group>

                        <b-form-group
                            style="text-align:left"
                            id="input-group-2"
                            label="Test text"
                            label-for="input-2"
                            description="Text to test"
                        >
                        <validation-provider 
                        name="msisdn" 
                        rules="required" 
                        v-slot="validationContext">
                            <b-form-input
                            id="input-2"
                            v-model="testForm.send_sms_msgdata"
                            type="text"
                            placeholder="Enter test text"
                            :state="getValidationState(validationContext)"
                            required
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                        </b-form-group>


                        <b-form-group
                            style="text-align:left"
                            id="input-group-3"
                            label="Test number"
                            label-for="input-3"
                            description="Number to test"
                        >
                        <validation-provider 
                        name="msisdn" 
                        rules="required|msisdn" 
                        v-slot="validationContext">
                            <b-form-input
                            id="input-3"
                            v-model="testForm.send_sms_receiver"
                            type="text"
                            placeholder="Enter test number"
                            :state="getValidationState(validationContext)"
                            required
                            ></b-form-input>
                            <b-form-invalid-feedback id="input-3-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                        </b-form-group>
                        <b-form-group
                            style="text-align:left"
                            id="input-group-4"
                            label="Text encoding"
                            label-for="input-4"
                            description="Encoding of test message"
                        >
                            <b-form-select v-model="testForm.send_sms_coding" class="mb-3">
                            <b-form-select-option :value="null">Please select an option</b-form-select-option>
                            <b-form-select-option value="0">GSM</b-form-select-option>
                            <b-form-select-option value="1">binary</b-form-select-option>
                            <b-form-select-option value="2">UTF-16BE (UNICODE)</b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <!--
                        <hr>
                        <b-button-group>
                            <b-button 
                            :disabled="showTestOverlay"
                            type="submit" variant="outline-success">
                                Submit
                            </b-button>
                            <b-button 
                            variant="outline-primary"
                            @click="$bvModal.hide('bv-modal-test-smsc-connection')">
                                Close Me
                            </b-button>
                        </b-button-group>
                        -->
                    </b-form>
                </validation-observer>
            </b-col>
            <b-col style="border-left:1px solid #D1C8C5">
                <b-overlay :show="showTestOverlay" rounded="sm">
                    
                    <b-jumbotron header="" lead="Submit response">
                        <p>{{ testResponse }}</p>
                    </b-jumbotron>
                    <!--
                        TODO: add polling/socket for dlr status 
                    -->
                </b-overlay>
            </b-col>
        </b-row>
       
        
    </b-modal>
</template>

<script>
export default {
    watch:{
        'testForm': {
            handler: function(){
                this.formIsvalid = this.$refs['testForm'].checkValidity()
            },
            deep: true
        }
    },
    props:['connData'],
    data () {
        return {
            formIsvalid: false,
            testResponse: null,
            showTestOverlay: false,
            testForm:{
                send_sms_receiver:null,
                send_sms_sender:null,
                send_sms_msgdata:null,
                send_sms_coding: 0,
                connection_alias: null
            }
        }
    },
    methods:{
        async handleOk(event){
            event.preventDefault()
            this.testForm.connection_alias = this.connData['smsc-id']
            let res = await this.$http.post('/api/connections/test', this.testForm)
            if(res.status == 200){
                this.testResponse = res.data
            }
            
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onReset(event) {
            event.preventDefault()
            
            this.testForm.send_sms_receiver = null
            this.testForm.send_sms_sender = null
            this.testForm.send_sms_msgdata = null
            this.testForm.send_sms_coding = 0
            this.testForm.connection_alias = null
            
        }
    }
}
</script>

<style>

</style>