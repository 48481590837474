<template>
  <div class="routing-managment-wrapper">
    <b-overlay :show="loading">
        <vue-good-table
            theme="polar-bear"
            :rows="tableDisplayData" 
            :columns="fields" 
            :pagination-options="paginationOptions"
            :search-options="searchOptions"
            :fixed-header="true"
            max-height="1000px"
            :select-options="{ enabled: true }"
            @on-select-all="onSelectAll"
            @on-selected-rows-change="selectionChanged"
            @on-column-filter="onColumnFilter"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'routing_fixed_sender_id'">
                    {{ props.row.routing_fixed_sender_id ? props.row.routing_fixed_sender_id : '---' }}
                </span>
                <span v-else-if="props.column.field == 'routing_status'">
                    <b-icon v-if="props.row.routing_status" icon="toggle-on" font-scale="1.5" ></b-icon>
                    <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
                </span>
                <span v-else-if="props.column.field == 'routing_route_random_originator'">
                    <b-icon v-if="props.row.routing_route_random_originator" icon="toggle-on" font-scale="1.5" ></b-icon>
                    <b-icon variant="danger" v-else icon="toggle-off" font-scale="1.5" ></b-icon>
                </span>
                <span v-else-if="props.column.field == 'routing_custom_filter_rule'">
                    {{ props.row.routing_custom_filter_rule ? props.row.routing_custom_filter_rule : '---' }}
                </span>
            </template>
            <div slot="table-actions" style="width: 100%">
   
            </div>
        
            <div slot="table-actions-bottom">
                <h6 class="m-1" style="float:left">
                    Row count: {{tableDisplayData.length}}  
                </h6>
            </div>
        </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>

export default {
    components: {

    },
    props: ['loading', 'rows', 'providers'],
    watch: {
        rows: function() {
            this.tableDisplayData = this.rows
        }
    },
    data(){
        return {
            tableDisplayData: this.rows,
            fields: [
                //{ label: '', field: 'select'},
                {   
                    label: 'Account',
                    field: 'routing_account',
                    width: '150px',
                    filterOptions: {
                        enabled: true,
                         placeholder: 'Filter account'
                    }
                },
                {   
                    label: 'Type',
                    field: 'routing_type',
                    width: '150px',
                    filterOptions: {
                        enabled: true,
                         placeholder: 'Filter type'
                    }
                },
                { 
                    label: 'Country',    
                    field: 'routing_country_name',
                    width: '200px',
                    filterOptions: {
                        enabled: true,
                         placeholder: 'Filter country'
                    }
                },
                { 
                    label: 'Operator',  
                    field: 'routing_operator_name', 
                    width: '250px',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter operator'
                    }
                },
                { 
                    label: 'Mcc',             
                    field: 'routing_mcc', 
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter MCC'
                    }
                },
                { 
                    label: 'Mnc',             
                    field: 'routing_mnc', 
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter MNC'
                    }
                },
                { label: 'Fixed sender', field: 'routing_fixed_sender_id',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Sender'
                    }
                },
                { label: 'Random Sender', field: 'routing_route_random_originator',
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Random Sender'
                    }
                },
                { label: 'Cost',            field: 'routing_route_cost',
                  width: '100px'
                },
                { 
                    label: 'Sell', 
                    field: 'routing_route_sell',
                    width: '100px'
                },
                { 
                    label: 'Status',          
                    field: 'routing_status',
                    width: '100px'
                },
                { 
                    label: 'Provider Rules',         
                    field: 'routing_custom_filter_rule'
                },
                { label: 'Provider',        
                    field: 'routing_route_provider_id', 
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Provider'
                    }
                },
                
                { 
                    label: 'Alias',         
                    field: 'routing_provider_account_alias', 
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Alias'
                    }
                },
                { 
                    label: 'Provider Account',         
                    field: 'routing_route_provider_account_id', 
                    filterOptions: {
                        enabled: true,
                        placeholder: 'Filter Provider Account'
                    }
                },
                
                
                
                /*
                {   
                    label: 'Routing', 
                    field: 'routing_select',
                    width: '400px'
                },
                */
                
            ],
            searchOptions: {
                enabled: false,
                placeholder: 'Search this table',
            },
            paginationOptions: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [5,10,100, 4000],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                jumpFirstOrLast : true,
                firstLabel : 'First Page',
                lastLabel : 'Last Page',
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                infoFn: (params) => `Page ${params.firstRecordOnPage}`, 
            },
        }
    },
    methods: {
        async resetToDefaults(){
            let confirmed = await this.confirmModal("Restore to defualts?")
            
            if(confirmed){
                
                this.toggleBusy()
                let res = await this.$http.post('/api/routing/reset-client-routing', {
                    routing_account: this.$route.query.acid,
                    routing_account_uuid: this.$route.query.uuid,
                })
                if(res.status == 200){
                    this.toggleBusy()
                    //console.log(res.data)
                    this.routingData = res.data
                }
            }
        },
         async deleteRouting(){
            
            let items = Object.keys(this.sendPricing)
            if(!items.length){
                let confirmed = await this.confirmModal("Delete all routing?")    
                if(confirmed){
                    //items = this.routingData
                    items = false
                }else{
                    return
                }
            }else{
                items = Object.values(this.sendPricing)
            }

            
            let confirmed = await this.confirmModal("Arew you sure you want to delete routing?")
            if(confirmed){
                
                
                let res = await this.$http.post( '/api/routing/delete-specific-routing', {
                    routing_account: this.$route.query.acid,
                    routing_account_uuid: this.$route.query.uuid,
                    rows: items
                })

                if(res.status == 200){
                    this.reloadTableData()
                }
                

            }
        },
        async sendRoutingToClient(){
            let toNotifyPricing = Object.values(this.sendPricing)
            if(!toNotifyPricing.length){
                let confirmed = await this.confirmModal("Send entire pricing list?")
                if(confirmed){
                    toNotifyPricing = this.routingData;
                }else{
                    return
                }
            }

            let confirmed = await this.confirmModal("Are you sure?")
            if(confirmed){
                console.log(toNotifyPricing)
                
                let res = await this.$http.post('/api/notifications/send-routing-to-client',{
                    toUpdateArray: toNotifyPricing,
                    user_account_uuid: this.$route.query.uuid

                });
                if(res.status == 200){
                    console.log(res.data)
                }
                
            }
            
        },
        toggleModal(modalId){
            this.$emit('toggleModal', modalId)
        },
        onSelectAll(){
            //console.log('all')
        },
        selectionChanged(list){
            //console.log(list)
            this.$emit('selectedInTable', list)
        },
        onColumnFilter(item) {
            console.log(item)
        }
    }

}
</script>

<style>
.routing-managment-wrapper .vgt-global-search__input{
    display: none !important;
}

</style>