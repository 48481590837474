<template>

    <b-modal
        id="modal-batch-routing" 
        ref="modal-batch-routing"
        size="xl"
        title="Existing routing"
        :ok-disabled="!submit.routing.length"
        @show="resetModal"
        @ok="handleOk"
        @hidden="resetModal">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row>
              <b-col>
                <!--
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            :label="`Client UUIDs (${submit.uuids.length})`">
                            <multiselect 
                                disabled
                                :class="'multiselect-accounts-managment'"
                                :multiple="true"
                                v-model="submit.uuids" 
                                :options="filtered.uuids ? filtered.uuids: []" 
                                placeholder="Selected uuids"
                                label="text" 
                                track-by="value">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            :label="`Api Key Tokens (${submit.apiKeyTokens.length})`">
                            <multiselect 
                                disabled
                                :class="'multiselect-accounts-managment'"
                                :multiple="true"
                                v-model="submit.apiKeyTokens" 
                                :options="filtered.apiKeyTokens ? filtered.apiKeyTokens: []" 
                                placeholder="Selected accounts" 
                                label="text" 
                                track-by="value">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
                -->
                <b-row>
                    <b-col>
                        <b-form-group
                            style="text-align:left"
                            :label="`Routes (${submit.routing.length}):`">
                            <multiselect 
                                :class="'multiselect-hlr-managment'"
                                :multiple="true"
                                v-model="submit.routing" 
                                :options="filtered.routing ? filtered.routing: []" 
                                placeholder="Selected operators" 
                                :custom-label="getRoutesLabel"
                                @remove="removeRoutingItem"
                                height="500px"
                                label="text" 
                                track-by="value">
                            </multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
              </b-col>
              
                <b-col>
                    <b-row>
                        <b-col>
                            
                            <b-form-group
                                style="text-align:left"
                                description="The routing"
                                label="Route"> 

                                <multiselect 
                                    required
                                    v-model="submit.route" 
                                    :options="providers"
                                    :custom-label="customLabelRoutes"
                                    :searchable="true"
                                    label="text" 
                                    track-by="value">
                                </multiselect>

                            </b-form-group>
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col>
                            <b-form-checkbox
                            class="pt-2"
                            @input="markUpFromCostInput"
                            style="width:fit-content"
                            id="checkbox-2"
                            v-model="submit.isMarkUp"
                            name="checkbox-2"
                            value="true"
                            unchecked-value="false">
                            <p class="text-primary ">
                                        &nbsp;&nbsp;Mark up from cost</p>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox
                                style="width:fit-content"
                                id="checkbox-1"
                                v-model="submit.setAsDefault"
                                name="checkbox-1"
                                value="true"
                                unchecked-value="false">
                                
                                <p class="text-primary">
                                    &nbsp;&nbsp;Set route as defualt for selected countries</p>
                                    
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                            style="text-align:left"
                            content-cols-lg="12"
                            label="Sell price"
                            description="Sell price"
                            invalid-feedback="Maximum 5 floating point number"
                            label-for="rt-list-id-input"> 
                            <b-form-input
                                placeholder="Sell"
                                :required="!(submit.isMarkUp === 'true')"
                                :state="/^[0-9]+\.[0-9]{1,5}$/.test(submit.sell)"
                                type="number"
                                step="0.00001"
                                :disabled="submit.isMarkUp === 'true'"
                                v-model="submit.sell"
                            ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row> 
                        <b-col>
                            <b-form-group
                            style="text-align:left"
                            content-cols-lg="12"
                            invalid-feedback="Maximum 5 floating point number"
                            description="Mark up value"
                            label="Mark up"
                            label-for="rt-list-id-input"> 
                            <b-form-input
                                placeholder="Mark up"
                                :required="submit.isMarkUp === 'true'"
                                :state="/^[0-9]+\.[0-9]{1,5}$/.test(submit.markUp)"
                                type="number"
                                step="0.00001"
                                :disabled="!(submit.isMarkUp === 'true')"
                                v-model="submit.markUp"
                            ></b-form-input>
                        </b-form-group>
                        </b-col>
                    </b-row> 
                    <b-row> 
                        <b-col>
                            
                            <b-form-group
                            style="text-align:left"

                            label="Cost"
                            description="Set cost"
                            invalid-feedback="Maximum 5 floating point number"
                            
                            content-cols-lg="12"
                            label-for="rt-list-id-input"> 
                            <b-form-input
                                placeholder="Cost"
                                type="number"
                                :state="/^[0-9]+\.[0-9]{1,5}$/.test(submit.cost)"
                                step="0.00001"
                                v-model="submit.cost"
                            ></b-form-input>
                        </b-form-group>
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col>
                            <b-form-group
                                :invalid-feedback="'Please enter a valid number'"
                                style="text-align:left"
                                label="Fixed sender">
                                <b-form-input
                                    :state="/^[0-9]+$/.test(submit.fixedSender)"
                                    v-model="submit.fixedSender"
                                    placeholder="Fixed sender">

                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox
                            class="pt-2"
                            style="width:fit-content"
                            id="checkbox-2"
                            v-model="submit.randomSender"
                            name="checkbox-2"
                            value="true"
                            unchecked-value="false">
                            <p class="text-primary ">
                                &nbsp;&nbsp;Random Local Origintaor</p>
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                            style="text-align:left"
                            label="Custom routing rule">
                            <multiselect 
                                :max-height="150"
                                v-model="submit.customRule" 
                                :allow-empty="false"
                                :multiple="false" 
                                :searchable="true"
                                label="text" 
                                track-by="value"
                                :options="customRules">
                                </multiselect>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            
        </form>
    </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
    components: {
        Multiselect
    },
    //props:['routing', 'accounts', 'providers', 'uuids'],
    
    props: {
        filtered: {
            type: Object
        },    
        providers: {
            type: Array
        }
    },
    watch:{
        filtered: {
            handler(val) {
                for (const [key, value] of Object.entries(val)) {
                    this.submit[key] = value
                }
            },
            deep: true
        }
    },
    created(){
        
    },
    data(){
        return {
            submit: {
                uuids: [],
                apiKeyTokens: [],
                route: null,
                routing: [],
                setAsDefault: false,
                sell: null,
                isMarkUp: null,
                markUp: null,
                cost: null,
                fixedSender: null,
                randomSender: null,
                customRule: null
            },

            
            
            selectedProvider: null,
            fixedPriceValue: null,
            markUpFromCostValue: null,
            providerCostValue: null,
            markUpFromCost: false,
            setAsDefault: false,
            fixedSenderNumber: null,
            customRules: this.$customRules,
            customRule: ''
        }
    },
    methods: {
        removeRoutingItem(routing){

            const idx = this.submit.routing.findIndex(item => (
                    item.routing_mcc == routing.routing_mcc && 
                    item.routing_mnc == routing.routing_mnc 
                )
            )
            if(idx) this.submit.routing.splice(idx, 1)

        },
        handleOk(bvModalEvent){
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        async handleSubmit() {
            const valid = (
                this.$refs.form.checkValidity() && 
                ( typeof this.submit.route === 'object' && this.submit.route !== null )
            )
            if(!valid){
                alert(`Valid: ${valid}`)
                return
            }
            let res = await this.$http.post('/api/routing/token/update', this.submit)
            if(res && res.status == 200){
                this.$emit('done', 'modal-batch-routing')                
            }



        },
        resetModal(){
            this.selectedProvider = null
            this.fixedPriceValue = null
            this.markUpFromCostValue = null
            this.providerCostValue = null
            this.markUpFromCost = false
            this.setAsDefault = false
            this.fixedSenderNumber = null
            this.customRule = ''
            

        },
        routingSelected(){},
        markUpFromCostInput(){
            /*
            this.fixedPriceValue = this.markUpFromCost === 'true' ? null : this.fixedPriceValue
            this.markUpFromCostValue = this.markUpFromCost  === 'true' ? this.markUpFromCostValue : null
            */
            
        },
        getAccountsLabel(item){
            return `
                    ${item.http_api_jwt_short_token} | 
                    ${item.http_api_jwt_description} |
                    ${item.http_api_jwt_uuid}
                    `
        },
        getRoutesLabel(item){
            return `
                    ${item.routing_country}-
                    ${item.routing_operator_name}-
                    (${item.routing_mcc}${item.routing_mnc})
                    `
        },
        customLabelRoutes(item){
            return `${item.providers_accounts_provider_id} - ${item.providers_accounts_account_alias} (${item.providers_accounts_id})`  
        }
    }
}
</script>

<style>
.multiselect-hlr-managment .multiselect__tags{
  height:300px;
  overflow-y: scroll;
}

.multiselect-accounts-managment .multiselect__tags{
  height:135px;
  overflow-y: scroll;
}
</style>