<template>

    <b-row >
        <b-col cols="12">
        <b-card>
  
            <b-card-header>
                <b-row>
                    <b-col>
                        SMSC Routing and Accounts
                    </b-col>
                </b-row>
            </b-card-header>
                <b-card-body style="min-height:500px; "> 
                    <b-tabs  
                        pills card  vertical
                        content-class="">
                        <b-tab @click="tabChanged('accounts')" title="SMSCs and Accounts" active>
                            <b-overlay :show="show" rounded="sm">
                                <Table />
                            </b-overlay>
                        </b-tab>
                        <b-tab @click="tabChanged('routing')" title="SMSC Accounts Routing" >
                            <b-overlay :show="show" rounded="sm">
                                <Routing :providerAccountsList="providerAccountsList"/>
                            </b-overlay>
                        </b-tab>
                        <b-tab @click="tabChanged('moRouting')" title="SMSC MO Routing">
                            <b-overlay :show="show" rounded="sm">
                                <MoRoutingLayout />
                            </b-overlay>
                        </b-tab>
                    </b-tabs>
                </b-card-body> 
       
            
        </b-card>
        
        
        </b-col>
    </b-row>
    

</template>

<script>

import Table from '../Routes.vue'
import Routing  from './ProviderRoutes.vue'
import MoRoutingLayout from '../Routing/MoRouting/Layout.vue'

export default {
    components: {
        Table,
        Routing,
        MoRoutingLayout
    },
    data() {
        return {
            currTab: 0,
            show: false,
            providerAccountsList: []
            /*
            notificationUsersDataLocal: null,
            usersDataLocal: null,
            clientUuid: this.$route.params.id,
            entities: {}
            */
        }
    },
    //props: ['id'],
    created () {
        this.loadData()
    },
    computed:{
        
    },
    methods: {
        normalizeAccounts(data){
            this.providerAccountsList = data.map(item => {
                return {
                    text: item.providers_accounts_account_alias,
                    value: item
                }
            })
        },
        async getLists() {
            const res = await this.$http.get('/api/accounts/read', {
                providers_accounts_account_status: 1
            })

            if(res && res.status == 200){
                this.normalizeAccounts(res.data)
            }
        },
        tabChanged(tab){             
            if( tab == 'routing' ) this.getLists()
        },
        toggleShow(){
            this.show = !this.show
        },
        async loadData(){
            
        },
    }
}
</script>

<style>


</style>