<template>
  
    <b-modal 
        @hidden="resetModal"
        @ok="handleOk"
        :ok-disabled="formOkayIsDisabled()"
        size="xl"
        id="modal-hlr-routing" title="HLR Settings">
        <form ref="form" @submit.prevent="handleSubmit">
            <b-row>
                <b-col>
                     <b-row>
                        <b-col>
                            <b-form-group
                                style="text-align:left"
                                :label="`Routes (${submit.routing.length}):`">
                                <multiselect 
                                    :class="'multiselect-hlr-managment'"
                                    :multiple="true"
                                    v-model="submit.routing" 
                                    :options="filtered.routing ? filtered.routing: []" 
                                    placeholder="Selected counries" 
                                    :custom-label="customLabel"
                                    @remove="removeRoutingItem"
                                    height="500px"
                                    label="routing_lookup_config_country_name" 
                                    track-by="routing_lookup_config_country_code">
                                </multiselect>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
                
                <b-col>
                    <b-row>
                        <b-form-group 
                        style="text-align:left;cursor:pointer"
                        label="Switch ON/OFF"  >
                            <b-icon @click="submit.checked=false" v-if="submit.checked" class="switchOnOff text-success" icon="toggle-on"/>
                            <b-icon @click="submit.checked=true" v-else class="switchOnOff text-danger" icon="toggle-off"/>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group 
                                style="text-align:left"
                                label="Provider">
                                <multiselect 
                                    :disabled="!submit.checked"
                                    :multiple="false"
                                    v-model="submit.provider" 
                                    :options="providers" 
                                    placeholder="Selected provider" 
                                    label="hlr_providers_name" 
                                    track-by="hlr_providers_uuid">
                                </multiselect>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group 
                                style="text-align:left"
                                label="Hlr cost">
                                <b-form-input
                                :disabled="!submit.checked"
                                v-model="submit.cost"
                                type="number"
                                step="0.0001" 
                                min="0.0001"
                                max="0.003"
                                required>
                            </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group 
                                style="text-align:left;cursor:pointer"
                                label="Cache results?" >
                                    <b-icon @click="submit.cache=false" v-if="submit.cache" class="switchOnOff text-success" icon="toggle-on"/>
                                    <b-icon @click="submit.cache=true" v-else class="switchOnOff text-danger" icon="toggle-off"/>
                                </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                            style="text-align:left"
                            invalid-feedback="Allowed range is 24-96 hours"
                            label="Cache timeout">
                                <b-form-input
                                :disabled="!submit.cache"
                                :state="/^[0-9]{2}$/.test(submit.cacheTimeOut)"
                                v-model="submit.cacheTimeOut"
                                type="number"
                                step="1" 
                                min="24"
                                max="96"
                                :required="submit.cache"
                                ></b-form-input>
                        </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group
                            style="text-align:left"
                            invalid-feedback="Allowed range is 0-25"
                            label="Sanitize">
                                <b-form-input
                                :state="/^[0-9]$/.test(submit.sanitize)"
                                v-model="submit.sanitize"
                                type="number"
                                step="1" 
                                min="0"
                                max="25"
                                ></b-form-input>
                        </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            

        </form>

    </b-modal>
  
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
    components: {
        Multiselect
    },
    props:[ 'filtered', 'providers'],
    //props:[ 'filtered', 'providers', 'countries', 'accounts', 'uuids'],
    watch:{
        filtered: {
            handler(val) {
                for (const [key, value] of Object.entries(val)) {   
                    this.submit[key] = value
                }
            },
            deep: true
        },
    },
    data(){
        return {
            submit: {
                routing: [],
                cache: false,
                cacheTimeOut: 24,
                cost: 0.0012,
                provider: null,
                checked: false,
                sanitize: 0
            },
            hlrCountries: this.countries,
            hlrAccounts: this.accounts,
            clientUuids: this.uuids,
            provider: null,
            cost: 0.0012,
            checked: false,
            cache: false,
            cacheTimeOut: 24
        }
    },
    methods: {
        removeRoutingItem(routing){
            console.log(routing)
            /*
            const idx = this.submit.routing.findIndex(item => (
                    item.routing_mcc == routing.routing_mcc && 
                    item.routing_mnc == routing.routing_mnc 
                )
            )
            if(idx) this.submit.routing.splice(idx, 1)
            */

        },
        formOkayIsDisabled(){
            //return !(this.provider && !!this.hlrCountries.length && (!!this.clientUuids.length || !!this.hlrAccounts.length)) 
           // return !(this.provider && !!this.hlrCountries.length && !!this.hlrAccounts.length) 
           return false;
        },
        resetModal(){
            this.submit = {
                routing: [],
                cache: false,
                cacheTimeOut: 24,
                cost: 0.0012,
                provider: null,
                checked: false,
            }
            /*
            
            this.provider =   null
            this.cost = 0.0012
            this.checked = false
            this.cache =   false
            this.cacheTimeOut = null
            */
        },
        handleOk(e){
            e.preventDefault()
            this.handleSubmit()
            
        },
        async handleSubmit() {            
            //console.log(this.submit)
/*
            let valid = false

            if(this.checked){
                valid = (this.$refs.form.checkValidity() && ( this.provider != null ) )
            }else{
                valid = this.$refs.form.checkValidity()
            }
                     
            if(!valid){
                return
            }
*/

            let res = await this.$http.post('/api/gateway/hlr/settings/update', this.submit)
            if(res && res.status == 200){
                
                this.$emit('done', 'modal-hlr-routing')
            }


        },
        toggleOnOff(what){
            this.submit[what] = !this.submit[what]
            this.submit.cache = this.submit.checked ? this.submit.cache : this.submit.checked
            this.submit.cacheTimeOut = this.submit.checked ? this.submit.cacheTimeOut : null
        },
        customLabel(item){

            return `${item.routing_lookup_config_accounting_token}-${item.routing_lookup_config_country_name}`
        }
    }

}
</script>

<style>
.multiselect-hlr-managment .multiselect__tags{
  min-height:200px;
  max-height:300px;
  overflow-y: scroll;
}
</style>