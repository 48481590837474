<template>
    <b-modal 
        ok-only
        @shown="/** */"
        @ok="hide()"
        size="xl"
        id="modal-more-details" 
        v-model="show"
        :title="`Test details`">
        <table class="table b-table table-striped table-hover">
            <thead>
                <tr>
                <th>Key</th>
                <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(value, key) in data" :key="key">
                <td>{{ key.split(/_/).splice(2,).join(' ') }}</td>
                <td>{{ value }}</td>
                </tr>
            </tbody>
            </table>
    </b-modal>
</template>

<script>
export default {
    props: ['data'],
    data(){
        return {
            show: this.modalShow
        }
    },
    methods: {
        hide(){
            
        }
    }
}
</script>

<style>

</style>