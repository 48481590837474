<template>
  <b-card>
    <b-col cols="12">
      <b-card-header>
        <h6 class="card-title">Config file preview</h6>
      </b-card-header>

      <b-card-body style="text-align:left; margin: 10px 10px 10px 10px;">
        <code>
          <span class="configKey">group = {{config.group ? config.group : ""}}</span>
          <div v-for="(value, name, index) in config" :key="index">
            <div v-if="name && value !== undefined && value !== null && name != 'group'">
              <span class="configKey">{{name}} = </span>
              <span v-if="value !== undefined" class="configVal">{{value}}</span>
              <br>
            </div>
          </div>
          <br>
          <div v-for="(value, name, index) in includes" :key="index">
            <span class="configKey">include = </span>
            <span v-if="value" class="configVal">{{value}}</span>
            <br>
          </div>
        </code>
      </b-card-body>
    </b-col>
  </b-card>
</template>

<script>
  export default {
    props: ['config', 'includes']
  }
</script>

<style scoped>
  .configKey {
    color: rgb(62, 62, 250);
    font-size: 14px;
    font-weight: bold;
  }

  .configVal {
    max-width: 550px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
  }
</style>
